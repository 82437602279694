import React, {useContext, useEffect, useState} from "react"
import {LangContext} from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Switch from "../../components/Buttons/Switch";
import {NotificationManager} from 'react-notifications';
import useHttp from '../../hooks/use-http';

const BasicData = () => {
    const {TRL_Pack} = useContext(LangContext)
    const {fetchMssqlApi} = useFetch()
    const {sendRequest} = useHttp();

    const [machine, setMachine] = useState(null)
    const [multivend, setMultivend] = useState(null)
    const [theme, setTheme] = useState(null)
    const [UIViewType, setUIViewType] = useState(null)

    const [mail, setMail] = useState('')

    const getMachine = () => {
        fetchMssqlApi('machines', {}, machines => {
            setMachine(machines[0]);
        })
    }

    const getMultivend = () => {
        fetchMssqlApi('uivend-type', {}, multivend => {
            setMultivend(multivend[1].Enabled)
        })
    }

    const getViewType = () => {
        fetchMssqlApi('uiview-type', {}, viewType => {
            if (UIViewType == null)
                setUIViewType(viewType)
        })
    }

    const getTheme = () => {
        fetchMssqlApi('maintenance-type', {}, maintenance => {
            const theme = maintenance.UISkin
            
            if (theme == null) {
                setTheme(0)
            } else if (theme === "Dark") {
                setTheme(1)
            } else {
                setTheme(0)
            }
        })
    }

    const getMail = () => {
        fetchMssqlApi('settings', {}, settings => {
            settings.forEach(setting => setting.key === "mail" && setMail(setting.value))
        })
    }

    const formatDateTime = (date) => {
        return date.replace('T', ' ').slice(0, 19)
    }

    useEffect(() => {
        getViewType();
        getMachine();
        getMultivend();
        getMail();
        getTheme();
    }, [])

    const changeUIViewTypeHandler = e => {
        const data = e.target;
        const id = data.id.slice(2, data.length)

        if (data.id.slice(0, 1) === "2") {
            for (let i = 0; i < UIViewType.length; i++) {
                if (id == i) continue;
                UIViewType[i].DefaultView = 0
                document.getElementById('2_' + i).checked = false
            }
        }

        if (UIViewType[id].DefaultView == 0 && UIViewType[id].Enabled == 0) {
            UIViewType[id].Enabled = 1
            document.getElementById(e.target.id.replace('2_', '1_')).checked = true
        }

        if (UIViewType[id].Enabled == 1 && UIViewType[id].DefaultView == 1) {
            UIViewType[id].DefaultView = 0
            document.getElementById(e.target.id.replace('1_', '2_')).checked = false
        }

        data.id.slice(0, 1) === "1" ? UIViewType[id].Enabled = Number(data.checked) : UIViewType[id].DefaultView = Number(data.checked)
    };

    const onSubmit = e => {
        e.preventDefault();
        const {
            name, serialno, machineType, currencyType,
            decimalPoints, lastSqlUpdateDateTime, unlimitedMachineProducts, multivend, mail
        } = e.target.elements

        const data = {
            name: name.value,
            serialno: 'VDM' + serialno.value,
            machineType: machineType.value,
            currencyType: currencyType.value,
            decimalPoints: decimalPoints.value,
            lastSqlUpdateDateTime: lastSqlUpdateDateTime.value,
            unlimitedMachineProducts: Number(unlimitedMachineProducts.checked),
            multivend: Number(multivend.checked),
            uiViewType: UIViewType,
            mail: mail.value,
            uiSkinType: theme
        }

        let countuiViewTypeDefaultViews = 0;
        let countuiViewEnabled = 0;
        data.uiViewType.map(view => {
            if (view.DefaultView)
                countuiViewTypeDefaultViews++
            if (view.Enabled)
                countuiViewEnabled++
        })

        if (data.name.length === 0 || data.serialno.length != 11)
            return NotificationManager.error(TRL_Pack.errors.invalidInput, null, 4000);

        if (countuiViewTypeDefaultViews != 1)
            return NotificationManager.error("Please select one default view", null, 4000);

        if (countuiViewEnabled < 1)
            return NotificationManager.error("Please select at least one view", null, 4000);

        sendRequest({url: 'basic-data', method: 'PUT', data: data}, response => {
            if (response.status === 201) {
                getMachine()
                getTheme();
                getMultivend();
                getMail();
                getViewType();
            }
        })
    }


    const getBasicData = () => {
        if (machine != null) {
            return (
                <>
                    <div>
                        <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="name"
                                               className="form-label">{TRL_Pack.advancedConfig.nameOfTheMachine}</label>
                                        <input type="text" maxLength={255} className="form-control" id="name"
                                               defaultValue={machine.Name}></input>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="serialno"
                                           className="form-label">{TRL_Pack.advancedConfig.serialno}</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">VDM</div>
                                        </div>
                                        <input type="text" maxLength={8} pattern="[0-9]*" className="form-control"
                                               id="serialno" defaultValue={machine.SerialNo.replace('VDM', '')}
                                               placeholder="00000000"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <div className="col-xs-2">
                                            <label htmlFor="machineType" className="form-label">{TRL_Pack.advancedConfig.machineType}</label>
                                            <input type="text" className="form-control" disabled={true}
                                                   defaultValue={machine.Type} id="machineType"></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label
                                            className="col-xs-3 control-label">{TRL_Pack.advancedConfig.currencyType}</label>
                                        <div className="col-xs-2">
                                            <select className="form-control" id="currencyType"
                                                    defaultValue={machine.CurrencyType}>
                                                <option>ZŁ</option>
                                                <option>CZK</option>
                                                <option>EUR</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label
                                            className="col-xs-3 control-label">{TRL_Pack.advancedConfig.decimalPoints}</label>
                                        <div className="col-xs-2">
                                            <select className="form-control" id="decimalPoints"
                                                    defaultValue={machine.DecimalPoints}>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="lastSqlUpdateDateTime"
                                               className="form-label">{TRL_Pack.advancedConfig.lastSqlUpdateDateTime}</label>
                                        <input type="text" className="form-control" disabled={true}
                                               defaultValue={formatDateTime(machine.LastSqlUpdateDateTime)}
                                               id="lastSqlUpdateDateTime"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label
                                            className="form-label">{TRL_Pack.advancedConfig.unlimitedMachineProducts}</label>
                                        <Switch defaultChecked={machine.UnlimitedMachineProducts}
                                                id={"unlimitedMachineProducts"}/>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label className="form-label">{TRL_Pack.advancedConfig.multivend}</label>
                                        <Switch defaultChecked={multivend} id={"multivend"}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor="mail" className="form-label">{TRL_Pack.advancedConfig.contactEmail}</label>
                                        <input type="text" className="form-control" placeholder="example@gmail.com"
                                               defaultValue={mail} id="mail"></input>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">{TRL_Pack.advancedConfig.theme}</label>
                                        <Switch defaultChecked={theme} id={"theme"}/>
                                    </div>

                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm">
                                                <label className="form-label">{TRL_Pack.advancedConfig.viewType}</label>
                                            </div>
                                            <div className="col-sm">
                                                <label className="form-label">{TRL_Pack.advancedConfig.enabled}</label>
                                            </div>
                                            <div className="col-sm">
                                                <label
                                                    className="form-label">{TRL_Pack.advancedConfig.defaultView}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {UIViewType != null ? (UIViewType.map((item, index) => (
                                        <div className="form-group" key={index}>
                                            <div className="row">
                                                <div className="col-sm">
                                                    <input type="text" className="form-control" readOnly={true}
                                                           defaultValue={TRL_Pack.advancedConfig.uiViewTypes[item.Name]}></input>
                                                </div>
                                                <div className="col-sm">
                                                    <Switch id={"1_" + index} defaultChecked={Boolean(item.Enabled)}
                                                            onChange={changeUIViewTypeHandler}/>
                                                </div>
                                                <div className="col-sm">
                                                    <Switch id={"2_" + index} defaultChecked={Boolean(item.DefaultView)}
                                                            onChange={changeUIViewTypeHandler}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))) : null}
                                </div>
                            </div>
                            <div style={{marginTop: 25}}>
                                <input className="btn btn-success mx-auto" type="submit"
                                       value={TRL_Pack.advancedConfig.saveChangdes}/>
                            </div>
                        </form>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12 mb-2">
                    <h5>{TRL_Pack.advancedConfig.basicData}</h5>
                    {getBasicData()}
                </div>
            </div>
        </>
    )
}

export default BasicData;