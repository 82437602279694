import React, { useEffect, useState } from 'react'
import parseURLQuery from '../../../util/parseURLQuery'
import { NotificationManager } from 'react-notifications'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import NoResults from '../../../components/NoResults/NoResults'
import renderPAPR from './components/PAPR'
import renderRD from './components/RD'
import renderRF from './components/RF'
import renderZE from './components/ZE'




export default function FindDocument() {
    const { fetchMssqlApi } = fetchHook()
    const [link, setLink] = useState("")
    const [data, setData] = useState(null)
    const [fiscalDocBtn, setFiscalDocBtn] = useState({Report: null, Id: null})
    const handleSubmit = () => {
        const obj = parseURLQuery(link)
        if (!obj || !obj.nrunik || !obj.client || !obj.fiscaldoc) return NotificationManager.error("incorrect link") 
        
        fetchMssqlApi(`cashRegisters/wkf/${localStorage.getItem('clientId')}/${obj.nrunik}/${obj.fiscaldoc}`, {}, res => {
            if (Array.isArray(res)) {
                setData(null)
                console.log(res)
                setData(res)
            } else {
                NotificationManager.error(res.message)
            }
        })
    }
    const renderElement = (obj, idx) => {
        switch (obj.FiscalDocTypeId) {
            case 1:
            case 2:
                return renderPAPR(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            case 3:
                return renderRD(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            case 4:
                return renderZE(obj, idx,fiscalDocBtn, setFiscalDocBtn)
            case 5:
                return renderRF(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            default:
                return (<tr></tr>)
        } 
    }

    return (
        <>
            <div className='container'>
                <div className='card'>
                    <div className='card-header'>
                        <div>
                            Wyszukaj dokument
                        </div>
                    </div>
                    <div className='card-body'>
                    <div className='d-flex mb-3'>
                            <input
                                value={link || ""}
                                onChange={evt => setLink(evt.target.value)}
                                className='form-control form-control-lg'
                                placeholder='Podaj link do pobierania pliku...'
                                onFocus={() => {
                                    setLink("")
                                }}
                            />
                            <button
                                className='btn btn-success ml-2'
                                onClick={() => {
                                    let clipboardText = ""
                                    try {
                                        window.navigator.clipboard.readText().then(text => {
                                            setLink(text)
                                        })
                                        console.log(clipboardText)
                                    } catch (e) {
                                        console.log(e)
                                        return
                                    }
                                    setLink(clipboardText)
                                }}
                            >
                                Wklej
                            </button>
                            <button
                                onClick={handleSubmit}
                                className='btn btn-primary ml-2'
                            >
                                Szukaj
                            </button>
                        </div>
                        {data ? data.length ? (

                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Typ dokumentu</th>
                                        <th>Nr unikatowy / Nr ewidencyjny</th>
                                        <th>Data / czas</th>
                                        <th colSpan={2} className='text-center'>Dodatkowe informacje</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((fd, idx) => renderElement(fd, idx)) }
                                </tbody>
                            </table>
                            ) :
                            (
                                <NoResults/>
                            ) :
                            (
                                <></>
                            )

                        }
                    </div>
                </div>
            </div>
        </>
    )
}
