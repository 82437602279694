import React, {useContext, useEffect, useState} from "react";
import {NotificationManager} from "react-notifications";
import {LangContext} from "../../../context/lang-context";
import useHttp from "../../../hooks/use-http";
import fetchMSSQLHook from "../../../hooks/fetchMSSQL-hook";

import FormSkel from "../../../components/Modals/FormSkel";
import ConfirmationForm from "../../../components/Modals/ConfirmationForm";

const Control = () => {
    const {sendRequest} = useHttp();
    const {fetchMssqlApi} = fetchMSSQLHook();
    const {TRL_Pack} = useContext(LangContext);

    const [showModal, setShowModal] = useState({
        antiStealerShelf: false,
        giveAwayChamber: false,
        leds: false,
        changeParameter: false,
        getParameter: false,
        returnDefaultSetting: false,
    });

    // Parameters
    const [changeParameterAddress, setChangeParameterAddress] = useState(0);
    const [parametersGetAddress, setParametersGetAddress] = useState(0);

    // Machine type
    const [machineType, setMachineType] = useState("-");
    const [machineSlaveType, setMachineSlaveType] = useState("-");
    const [machineSecondSlaveType, setMachineSecondSlaveType] = useState("-");

    // Current slave of machine TAB
    const [slave, setSlave] = useState(1);
    const [currentMachineType, setCurrentMachineType] = useState("-");

    useEffect(() => {
        getMachineType();
    }, []);

    const getMachineType = () => {
        fetchMssqlApi("machines", {}, (machines) => {
            let types = machines[0].Type;
            setMachineType(types.split("_")[0]);
            setCurrentMachineType(types.split("_")[0]);
            if (types.split("_")[1] !== undefined)
                setMachineSlaveType(types.split("_")[1]);
            if (types.split("_")[2] !== undefined)
                setMachineSecondSlaveType(types.split("_")[2]);
        });
    };

    const parameters = [
        {address: 0, desc: "Steps for first floor", min: 0, max: 10000},
        {address: 1, desc: "Steps for second floor", min: 0, max: 10000},
        {address: 2, desc: "Steps for third floor", min: 0, max: 10000},
        {address: 3, desc: "Steps for fourth floor", min: 0, max: 10000},
        {address: 4, desc: "Steps for fifth floor", min: 0, max: 10000},
        {address: 5, desc: "Steps for sixth floor", min: 0, max: 10000},
        {address: 6, desc: "Steps for seventh floor", min: 0, max: 10000},
        {address: 7, desc: "Steps for eighth floor", min: 0, max: 10000},
        {address: 8, desc: "Drop sensor attenuation", min: 0, max: 80},
        {address: 9, desc: "Drop sensor direction", min: 0, max: 2},
        {address: 10, desc: "Out-time for opening door", min: 0, max: 600},
        {
            address: 11,
            desc: "Locking time after picking up goods",
            min: 0,
            max: 100,
        },
        {address: 12, desc: "Motor 1 max am", min: 0, max: 500},
        {address: 13, desc: "Motor 1 min am", min: 0, max: 500},
        {address: 14, desc: "Motor 1 Am when disconnect", min: 0, max: 500},
        {address: 15, desc: "Motor 2 max am", min: 0, max: 500},
        {address: 16, desc: "Motor 2 min am", min: 0, max: 500},
        {address: 17, desc: "Motor 2 am when disconnect", min: 0, max: 500},
        {address: 18, desc: "Motor 3 max am", min: 0, max: 500},
        {address: 19, desc: "Motor 3 min am", min: 0, max: 500},
        {address: 20, desc: "Motor 3 am when disconnect", min: 0, max: 500},
        {address: 21, desc: "Motor 4 max am", min: 0, max: 500},
        {address: 22, desc: "Motor 4 min am", min: 0, max: 500},
        {address: 23, desc: "Motor 4 am when disconnect", min: 0, max: 500},
        {address: 24, desc: "Motor 5 max am", min: 0, max: 500},
        {address: 25, desc: "Motor 5 min am", min: 0, max: 500},
        {address: 26, desc: "Motor 5 am when disconnect", min: 0, max: 500},
        {address: 27, desc: "1 - spring slot, 2 - belt slot", min: 1, max: 2},
        {
            address: 28,
            desc: "0 - Have inspection when delivery goods, 1 - No inspection when delivery goods",
            min: 0,
            max: 1,
        },
        {address: 29, desc: "Motor max volts", min: 0, max: 100},
        {address: 30, desc: "Buffer steps when up", min: 0, max: 1500},
        {address: 31, desc: "Buffer speed when up", min: 1, max: 40},
        {address: 32, desc: "Speed when down", min: 1, max: 100},
        {address: 33, desc: "Decrease steps when down", min: 0, max: 1500},
        {address: 34, desc: "Speed for backing up origin", min: 1, max: 20},
        {address: 35, desc: "Steps for backing up origin", min: 100, max: 1500},
        {address: 36, desc: "Speed for departure drop sensor", min: 1, max: 20},
        {
            address: 37,
            desc: "Working steps from top to bottom when goods stuck",
            min: 1,
            max: 1000,
        },
        {
            address: 38,
            desc: "Working steps from bottom to top when goods stuck",
            min: 1,
            max: 1000,
        },
        {
            address: 39,
            desc: "Wave filtering time for drop sensor in ms",
            min: 0,
            max: 1000,
        },
        {
            address: 40,
            desc: "Max steps exceed highest dropsensor (Judge if this floor with sensor)",
            min: 200,
            max: 1500,
        },
        {
            address: 41,
            desc: "Max time when hiding sensor (100ms) judge that stuck beyond time",
            min: 0,
            max: 1000,
        },
        {
            address: 42,
            desc: "0 - Have push board, 1 - No push board",
            min: 0,
            max: 1,
        },
        {address: 43, desc: "Current degree (0.1 C)", min: -90, max: 100},
        {
            address: 44,
            desc: "When with belt machine, if need to clear hiding sensor time, 1 stands for clear",
            min: 0,
            max: 1,
        },
        {
            address: 45,
            desc: "Knock switch, 1 - able, 0 - disable",
            min: 0,
            max: 1,
        },
    ];

    const ControlModal = ({name, label}) => {
        let on = TRL_Pack.control.open;
        let off = TRL_Pack.control.close;

        name === "leds" ? (on = TRL_Pack.control.turnOn) : (on = TRL_Pack.control.open);
        name === "leds" ? (off = TRL_Pack.control.turnOff) : (off = TRL_Pack.control.close);

        return (
            <div>
                <label className="form-label">{label}</label>
                <div className="form-group">
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <input
                                    type="button"
                                    onClick={handleModalClick}
                                    className="form-control"
                                    defaultValue={on}
                                    id={name + "_on"}
                                ></input>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <input
                                    type="button"
                                    onClick={handleModalClick}
                                    className="form-control"
                                    defaultValue={off}
                                    id={name + "_off"}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const ControlUI = () => (
        <div>

            <div className="row">
                <div className="col-sm">
                    <ControlModal
                        name="leds"
                        label="LEDy"
                    />
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label className="form-label">{TRL_Pack.control.photocellState}</label>
                        <input
                            type="button"
                            onClick={handleButtonClick}
                            className="form-control"
                            defaultValue={TRL_Pack.control.check}
                            id="photodetector"
                        ></input>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{TRL_Pack.control.changeParameter}</label>
                                <input
                                    type="button"
                                    id="changeParameter"
                                    defaultValue={TRL_Pack.control.change}
                                    onClick={handleButtonClick}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{TRL_Pack.control.readParameter}</label>
                                <input
                                    type="button"
                                    id="getParameter"
                                    defaultValue={TRL_Pack.control.read}
                                    onClick={handleButtonClick}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <label>{TRL_Pack.control.allTcnParameters}</label>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <input
                                    type="button"
                                    id="saveAllParameter"
                                    defaultValue={TRL_Pack.control.saveToFile}
                                    onClick={handleButtonClick}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <input
                                    type="button"
                                    id="readAllParameter"
                                    defaultValue={TRL_Pack.control.readFromFile}
                                    onClick={handleButtonClick}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <Modal
                        name="setChoiceToSingle"
                        label={TRL_Pack.control.setSelectionsToSingle}
                        type="single"
                    />
                </div>
                <div className="col-sm">
                    <Modal
                        name="setChoiceToDouble"
                        label={TRL_Pack.control.setSelectionsToDouble}
                        type="single"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <Modal
                        name="setAllChoiceToSingle"
                        label={TRL_Pack.control.setAllSelectionsToSingle}
                        type="all"
                    />
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label>{TRL_Pack.control.restoreDefaultSettings}</label>
                        <input
                            type="button"
                            id="returnDefault"
                            defaultValue={TRL_Pack.control.restore}
                            onClick={() =>
                                setShowModal({...showModal, returnDefaultSetting: true})
                            }
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-sm">
                    <div className="form-group">
                        <label className="form-label">{TRL_Pack.control.dispenseFromAllBelts}</label>
                        <input
                            type="button"
                            onClick={handleButtonClick}
                            className="form-control"
                            defaultValue={TRL_Pack.control.dispense}
                            id="spendAll"
                        ></input>
                    </div>
                </div>
                <div className="col-sm"></div>
            </div>
        </div>
    );

    const ComponentsV11 = () => (
        <div>
            <h5>Sterowanie [V11]</h5>
            <div className="row">
                <div className="col-sm">
                    <ControlModal
                        name="giveAwayChamber"
                        label={TRL_Pack.control.dispensing}
                    />
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label className="form-label">{TRL_Pack.control.elevatorState}</label>
                        <input
                            type="button"
                            onClick={handleButtonClick}
                            className="form-control"
                            defaultValue={TRL_Pack.control.check}
                            id="liftStatus"
                        ></input>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <Modal
                        name="setChoiceToShelf"
                        label={TRL_Pack.control.setSelectionToSpring}
                        type="single"
                    />
                </div>
                <div className="col-sm">
                    <Modal
                        name="setChoiceToBelt"
                        label={TRL_Pack.control.setSelectionToBelt}
                        type="single"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <Modal
                        name="setAllChoiceToShelf"
                        label={TRL_Pack.control.setAllSelectionsToSprings}
                        type="all"
                    />
                </div>
                <div className="col-sm">
                    <Modal
                        name="setAllChoiceToBelt"
                        label={TRL_Pack.control.setAllSelectionsToBelts}
                        type="all"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    {currentMachineType === "V11" ? (
                        <ControlModal
                            name="antiStealerShelf"
                            label={TRL_Pack.control.antiTheftCabinet}
                        />
                    ) : null}
                </div>
                <div className="col-sm"></div>
            </div>
        </div>
    );

    const Locker = () => (
        <div>
            <h5>{TRL_Pack.control.controlLockerMachine}</h5>
            <div className="row">
                <div className="col-sm">
                    <label className="form-label">{TRL_Pack.control.dispenseFromAllLockers}</label>
                    <input
                        type="button"
                        onClick={handleButtonClick}
                        className="form-control"
                        defaultValue={TRL_Pack.control.dispense}
                        id="spendAllLocker"
                    ></input>

                </div>
            </div>
        </div>
    );

    const handleModalClick = (e) => {
        const data = e.target.id.split("_");
        const id = data[0];
        const bool = data[1];

        let locked;
        bool === "on" ? (locked = false) : (locked = true);

        switch (id) {
            case "antiStealerShelf":
                sendRequest(
                    {
                        url: "antitheft/locker",
                        data: {Locked: locked, Slave: slave},
                        method: "POST",
                    },
                    (response) => {
                    }
                );
                break;
            case "giveAwayChamber":
                sendRequest(
                    {
                        url: "door/V11",
                        data: {Door: locked, Slave: slave},
                        method: "POST",
                    },
                    (response) => {
                    }
                );
                break;
            case "leds":
                sendRequest(
                    {url: "leds", data: {Leds: locked, Slave: slave}, method: "POST"},
                    (response) => {
                    }
                );
                break;
        }
    };

    const handleButtonClick = (e) => {
        e.persist();

        switch (e.target.id) {
            case "antiStealerShelf":
                setShowModal({...showModal, antiStealerShelf: true});
                break;
            case "spendAll":
                sendRequest(
                    {url: "spend-all", data: {Slave: slave}, method: "POST"},
                    (response) => {
                    }
                );
                break;
            case "giveAwayChamber":
                setShowModal({...showModal, giveAwayChamber: true});
                break;
            case "leds":
                setShowModal({...showModal, leds: true});
                break;
            case "changeParameter":
                setShowModal({...showModal, changeParameter: true});
                break;
            case "getParameter":
                setShowModal({...showModal, getParameter: true});
                break;
            case "photodetector":
                sendRequest(
                    {url: "shop/photodetector", data: {Slave: slave}, method: "POST"},
                    (response) => {
                        if (response.status === 200) {
                            NotificationManager.info(
                                `${TRL_Pack.control.photocellStatus}: ` + response.data.PhotodetectorStatus,
                                null,
                                4000
                            );
                        }
                    }
                );
                break;
            case "liftStatus":
                sendRequest(
                    {url: "shop/lift", data: {Slave: slave}, method: "POST"},
                    (response) => {
                        if (response.status === 200) {
                            NotificationManager.info(
                                `${TRL_Pack.control.elevatorStatus}: ` + response.data.LiftStatus,
                                null,
                                4000
                            );
                        }
                    }
                );
                break;
            case "saveAllParameter":
                sendRequest(
                    {
                        url: "shop/parameter/save/all",
                        data: {Slave: slave},
                        method: "POST",
                    },
                    (response) => {
                    }
                );
                break;
            case "readAllParameter":
                sendRequest(
                    {
                        url: "shop/parameter/read/all",
                        data: {Slave: slave},
                        method: "POST",
                    },
                    (response) => {
                    }
                );
                break;
            case "spendAllLocker":
                sendRequest(
                    {url: "locker/spend/all", data: {Slave: slave}, method: "POST"},
                    (response) => {
                    }
                );
                break;
            default:
                NotificationManager.error(
                    TRL_Pack.advancedConfig.functionNotAvaiable,
                    null,
                    4000
                );
        }
    };

    const Modal = ({name, label, type}) => {
        let value = TRL_Pack.control.set;
        type !== "all" ? (value = TRL_Pack.control.set) : (value = TRL_Pack.control.setAll);

        const onSubmit = (e) => {
            e.preventDefault();

            if (type === "single") {
                const {number} = e.target.elements;
                //console.log(number.value);
                switch (name) {
                    case "setChoiceToDouble":
                        sendRequest(
                            {
                                url: "shop/choice/double",
                                data: {Choice: number.value, Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;
                    case "setChoiceToSingle":
                        sendRequest(
                            {
                                url: "shop/choice/single",
                                data: {Choice: number.value, Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;
                    case "setChoiceToShelf":
                        sendRequest(
                            {
                                url: "shop/choice/shelf",
                                data: {Choice: number.value, Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;
                    case "setChoiceToBelt":
                        sendRequest(
                            {
                                url: "shop/choice/belt",
                                data: {Choice: number.value, Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;
                }
            } else {
                switch (name) {
                    case "setAllChoiceToSingle":
                        sendRequest(
                            {
                                url: "shop/choice/single/all",
                                data: {Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;
                    case "setAllChoiceToShelf":
                        sendRequest(
                            {
                                url: "shop/choice/shelf/all",
                                data: {Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;
                    case "setAllChoiceToBelt":
                        sendRequest(
                            {
                                url: "shop/choice/belt/all",
                                data: {Slave: slave},
                                method: "POST",
                            },
                            (response) => {
                            }
                        );
                        break;

                    default:
                        NotificationManager.error(
                            TRL_Pack.advancedConfig.functionNotAvaiable,
                            null,
                            4000
                        );
                }
            }
        };

        return (
            <form onSubmit={onSubmit}>
                <label>{label}</label>
                <div className="row">
                    {type === "single" ? (
                        <div className="col-sm">
                            <div className="form-group">
                                <input
                                    type="number"
                                    id={"number"}
                                    defaultValue={value}
                                    placeholder={TRL_Pack.control.selectionNumber}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="col-sm">
                        <div className="form-group">
                            <input
                                type="submit"
                                id={"button"}
                                value={value}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const ReturnDefaultForm = () =>
        showModal.returnDefaultSetting && (
            <ConfirmationForm
                text={TRL_Pack.control.restoreDefaultSettings}
                handleClose={() =>
                    setShowModal({...showModal, returnDefaultSetting: false})
                }
                handleSubmit={() => {
                    sendRequest(
                        {
                            url: "return-default/settings",
                            data: {Slave: slave},
                            method: "POST",
                        },
                        (response) => {
                            if (response.status === 200) {
                                setShowModal({...showModal, returnDefaultSetting: false});
                            }
                        }
                    );
                }}
            />
        );

    const ParameterChangeForm = () => {
        const onSubmit = (e) => {
            e.preventDefault();

            const {value} = e.target.elements;
            sendRequest(
                {
                    url: "parameter/change",
                    method: "POST",
                    data: {
                        Parameter: parameters[changeParameterAddress].address,
                        Value: value.value,
                        Slave: slave,
                    },
                },
                (response) => {
                    if (response.status === 200) {
                        setShowModal({...showModal, changeParameter: false});
                    }
                }
            );
        };
        return (
            showModal.changeParameter && (
                <FormSkel
                    headerText={TRL_Pack.control.changeParameter}
                    handleClose={() =>
                        setShowModal({...showModal, changeParameter: false})
                    }
                    acceptForm="true"
                >
                    <div>
                        <form
                            onSubmit={onSubmit}
                            id="modal-form"
                        >
                            <div className="form-group">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    {TRL_Pack.control.parameter}
                                </label>

                                <select
                                    value={changeParameterAddress}
                                    className="form-control form-control-sm"
                                    id="name"
                                    onChange={(e) => setChangeParameterAddress(e.target.value)}
                                >
                                    {parameters.map((parameter, index) => (
                                        <option
                                            key={index}
                                            value={index}
                                        >
                                            {parameter.address} - [{parameter.desc}]
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor="parameterValue"
                                    className="form-label"
                                >
                                    {TRL_Pack.control.value}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="value"
                                    placeholder={
                                        "Wartość parametru: [" +
                                        parameters[changeParameterAddress].min +
                                        " - " +
                                        parameters[changeParameterAddress].max +
                                        "]"
                                    }
                                    min={parameters[changeParameterAddress].min}
                                    max={parameters[changeParameterAddress].max}
                                />
                            </div>
                        </form>
                    </div>
                </FormSkel>
            )
        );
    };

    const ParameterGetForm = () => {
        const onSubmit = (e) => {
            e.preventDefault();
            sendRequest(
                {
                    url: "parameter/get",
                    method: "POST",
                    data: {
                        Parameter: parameters[parametersGetAddress].address,
                        Slave: slave,
                    },
                },
                (response) => {
                    if (response.status === 200) {
                        setShowModal({...showModal, getParameter: false});
                    }
                }
            );
        };

        return (
            showModal.getParameter && (
                <FormSkel
                    headerText={TRL_Pack.control.downloadParameter}
                    handleClose={() =>
                        setShowModal({...showModal, getParameter: false})
                    }
                    acceptForm="true"
                >
                    <div>
                        <form
                            onSubmit={onSubmit}
                            id="modal-form"
                        >
                            <div className="form-group">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    {TRL_Pack.control.parameter}
                                </label>

                                <select
                                    value={parametersGetAddress}
                                    className="form-control form-control-sm"
                                    id="name"
                                    onChange={(e) => setParametersGetAddress(e.target.value)}
                                >
                                    {parameters.map((parameter, index) => (
                                        <option
                                            key={index}
                                            value={index}
                                        >
                                            {parameter.address} - [{parameter.desc}]
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                </FormSkel>
            )
        );
    };

    const SlaveManager = () => {
        const changeSection = (id, machineType) => () => {
            setSlave(id);
            setCurrentMachineType(machineType);
        };

        return (
            <div className="container-xl">
                <ul className="nav nav-tabs machine-tabs mb-3">
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${slave === 1 && "active"}`}
                            onClick={changeSection(1, machineType)}
                            tabIndex="0"
                        >
                            Ustawienia (Master)
                        </button>
                    </li>
                    {machineSlaveType !== "-" && (
                        <li className="nav-item">
                            <button
                                className={`nav-link btn ${slave === 2 && "active"}`}
                                onClick={changeSection(2, machineSlaveType)}
                                tabIndex="0"
                            >
                                Ustawienie (Slave #1)
                            </button>
                        </li>
                    )}
                    {machineSecondSlaveType !== "-" && (
                        <li className="nav-item">
                            <button
                                className={`nav-link btn ${slave === 3 && "active"}`}
                                onClick={changeSection(3, machineSecondSlaveType)}
                                tabIndex="0"
                            >
                                Ustawienie (Slave #2)
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        );
    };

    return (
        <div className="row">
            <SlaveManager/>
            <div className="col-12 mb-2">
                {/* Modals */}
                <ParameterChangeForm/>
                <ParameterGetForm/>
                <ReturnDefaultForm/>
                {/* Components */}
                <ControlUI/>
                {currentMachineType === "V11" ? <ComponentsV11/> : null}
                {currentMachineType === "LOCKER" ? <Locker/> : null}
            </div>
        </div>
    );
};

export default Control;
