import React, {useContext, useState, useEffect} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import moment from 'moment'
import lodash from 'lodash'
import { Link, Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { NotificationManager } from 'react-notifications'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


export default function Basket() {
    const _ = lodash(),
    { setHeaderData } = useContext(NavigationContext),
    { fetchMssqlApi } = fetchHook(),
    [initialData, setInitialData] = useState({}),
    [data, setData] = useState({}),
    [initiaItems, setInitiaItems] = useState([]),
    [items, setItems] = useState([]),
    [machines, setMachines] = useState([]),
    [initialPromotionMachines, setInitialPromotionMachines] = useState([]),
    [promotionMachines, setPromotionMachines] = useState([]),
    [machineInput, setMachineInput] = useState(""),
    [changed, setChanged] = useState(false),
    [availableToSave, setAvailableToSave] = useState(false),
    [products, setProducts] = useState([]),
    history = useHistory(),
    params = useParams(),
    defaultBasket = {
        BasketId: null,
        CreateUserId: null,
        Name: "",
        Price: parseFloat(0.00),
        StartDateTime: moment().format('YYYY-MM-DDTHH:MM'),
        EndDateTime: moment().add(7, "d").format('YYYY-MM-DDTHH:MM'),
    }, 
    random = () => { 
        const set = new Set(items.map(item => +item.BasketProductId))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : String(a)
    },
    random2 = () => { 
        const set = new Set(promotionMachines && promotionMachines.map(machine => +machine.BasketMachineId))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : String(a)
    },
    newItem = {
        BasketProductId: random(),
        new: true,
        EAN: null,
        Name: "" 
    }
    const getBasket = (id = params.id) => {
        if (id === "0") {
            setInitialData(defaultBasket)
            setInitiaItems([])
        } else {

            fetchMssqlApi(`promotion-basket/${id}`, {}, res => {
                    setInitiaItems(res.Items)
                    delete res.Items
                    setInitialData(
                        {
                            ...res, 
                            Price: (res.Price)?.toFixed(2), 
                            StartDateTime: moment(res.StartDateTime).format('YYYY-MM-DDTHH:MM'),
                            EndDateTime: moment(res.EndDateTime).format('YYYY-MM-DDTHH:MM')
                        }
                    ) 
                }
            )
        }
    }
    const getProducts = () => {
        fetchMssqlApi('products-recipes-list', {}, products => setProducts(products))
    }
    const getMachines = () => {
        fetchMssqlApi('machines-list', {}, machines => setMachines(machines))
    }
    const getPromotionsMachines = () => {
        fetchMssqlApi(`promotion-basket-machines/${params.id}`, {}, machines => setInitialPromotionMachines(machines))
    }

    const handleDelete = () => {
        fetchMssqlApi(`promotion-basket/${params.id}`, {method: "DELETE"}, () => {
            history.replace('/promotions')
        })  
    }
    const handleChangeItem = (id, evt) => {
        const name = evt.target.value
        setChanged(true)
        const product = products.find(pr => pr.Name === name)
        product ? evt.target.className = "form-control form-control-sm mx-auto mx-lg-0" : evt.target.className += " invalid-input"
        product
        ? setItems(prev => prev.map(item => {
            if(item.BasketProductId === id) {return {
                ...item,
                Name: product.Name,
                EAN: product.EAN,
            }} else return item
            }))
            : setItems(prev => prev.map(item => item.BasketProductId === id ? ({...item, Name: name}) : (item)))
    }
    const deleteItem = (id) => {
        // const item = items.find(item => item.BasketProductId === id)
        // item.new ? 
        setItems(prev => prev.filter(item => item.BasketProductId !== id)) 
        // : setItems(prev => prev.map(a => a.BasketProductId === id ? ({...a, deleted: true}) : (a)))
    }
    const handleSubmit = () => {
        const itemsPayload = JSON.stringify(items.filter(item => !(item.new && item.deleted)).map(({EAN}) => (({EAN})))) 
        const price = parseFloat(data.Price).toFixed(2)
        const machinesArr = JSON.stringify( promotionMachines.filter(machine => !(!machine.deleted && !machine.new)).map(machine => ({MachineId: machine.MachineId, new: machine.new ? 1 : 0, deleted: machine.deleted ? 1 : 0, BasketMachineId: machine.BasketMachineId})))
        if (!price) {
            NotificationManager.error("Something went wrong with parse price")
            return
        } 
        if (!itemsPayload) {
            NotificationManager.error("Something went wrong with parse items")
            return
        }
        const payload = {
            Items: itemsPayload,
            StartDateTime: moment(data.StartDateTime).format('YYYY-MM-DD HH:MM:00.000'),
            EndDateTime: moment(data.EndDateTime).format('YYYY-MM-DD HH:MM:00.000'),
            Price: price,
            Machines: machinesArr
        }

        if (params.id === "0") {
            fetchMssqlApi('promotion-basket', {method: "POST", data: payload}, ({Id}) => {
                setChanged(false)
                if (!Id) return
                history.replace(Id) 
                getBasket(Id)
            })
        } else {
            //promotion-basket/:id
            fetchMssqlApi(`promotion-basket/${params.id}`, {method: "PUT", data: payload}, res => {
                setChanged(false)
                if (!res) return
                setItems(res.Items)
                getPromotionsMachines()
                delete res.Items
                setData(
                    {
                        ...res, 
                        // Price: Intl.NumberFormat('en-IN').format(res.Price), 
                        StartDateTime: moment(res.StartDateTime).format('YYYY-MM-DDTHH:MM'),
                        EndDateTime: moment(res.EndDateTime).format('YYYY-MM-DDTHH:MM')
                    }
                ) 
            })
        }
    }

    useEffect((() => {
        setHeaderData({text: "Zestaw promocyjny"})
        getBasket()
        getProducts()
        getMachines()
        getPromotionsMachines()
    }), [])
    // useEffect(() => {
    //     promotionMachines && promotionMachines.forEach(promotionMachine => promotionMachine.deleted 
    //         ? setMachines(prev => [...prev, {promotionMachine}]) 
    //         : machines.filter(machine => machine.MachineId !== promotionMachine.MachineId))
    // }, [promotionMachines])
    
    useEffect((() => setPromotionMachines(initialPromotionMachines)), [initialPromotionMachines])
    useEffect((() => setData(initialData)), [initialData])
    useEffect((() => setItems(initiaItems)), [initiaItems])
    useEffect((() => {
        data.StartDateTime
        && data.EndDateTime
        && data.Price
        && items.filter(item => !item.deleted).length > 1
        && items.every(item => item.EAN)
        ? setAvailableToSave(true)
        : setAvailableToSave(false)
    }), [items, data])
    return (
        <div className='overflow-auto'>
            {data && (
                    <div>
                    <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
                    <div className='container-xl'>
                    <Link to='/promotions'>
                        <button className="btn btn-link ml-1 text-decoration-none mb-1">
                            <i className="fas fa-arrow-left mr-2" />
                            Wróć
                        </button>
                    </Link>
                    <div className='d-flex justify-content-between' style={{height: "25%"}}>
                        

                        <div className='card w-50 h-20 mr-2' >   
                                <table className="table table-striped">
                                    <tbody>
                                    <tr>
                                        <td className='text-center'>Cena zestawu</td>
                                        <td className='text-center'>Start</td>
                                    </tr>
                                    <tr >
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <input
                                                className={`mt-2 mb-2 form-control form-control-sm ${!data.Price && `invalid-input`}`}
                                                type='number'
                                                value={data.Price || ""}
                                                onChange={evt => {setChanged(true); const value = evt.target.value ;setData(prev => ({...prev, Price: value || ""}))}}
                                                onFocus={() => setData({...data, Price: ""})}
                                            />
                                        </div>
                                    </td>
                                    
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <input
                                                className={`mt-2 mb-2  form-control form-control-sm ${!data.StartDateTime && `invalid-input`}`}
                                                type='datetime-local'
                                                value={data.StartDateTime || ""}
                                                onChange={evt => {setChanged(true); const value = evt.target.value ;setData(prev => ({...prev, StartDateTime: value || ""}))}}
                                            />
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td className='text-center'>Koniec</td>
                                        {params.id !== "0" && (
                                            <td className='text-center'>Stworzona</td>
                                        )}
                                    </tr>
                                    <tr>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <input
                                                className={`mt-2 mb-2  form-control form-control-sm ${!data.EndDateTime && `invalid-input`}`}
                                                type='datetime-local'
                                                value={data.EndDateTime || ""}
                                                onChange={evt => {setChanged(true); const value = evt.target.value ;setData(prev => ({...prev, EndDateTime: value || ""}))}}
                                            />
                                        </div>
                                    </td>
                                    {params.id !== "0" && (
                                        <td className='text-center'>
                                            {data.Created}
                                        </td>
                                    )}
                                    </tr>
                                    </tbody>
                                </table>
                        </div>
                        <div className='card h-20 w-50' style={{marginBottom: "0"}}>
                        <div className='card-header p-1'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center ml-2'>
                                    Produkty w zestawie
                                </div>
                                <button className="btn text-nowrap btn-sm btn-outline-primary mr-2 mb-0 mt-0"
                                    onClick={() => {setChanged(true);
                                        items.length < 3
                                        ? setItems(prev => [{...newItem}, ...prev])
                                        : NotificationManager.error("Maksymalna ilość produktów w zestawie - 3")
                                    }}
                                ><i className="fas fa-plus" /></button>
                            </div>
                        </div>
                        {items.length ?
                        ( <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th className='pl-3 p-2'>Produkt</th>
                                    <th>
                                        {/* <div>
                                            <button
                                                className="d-block btn btn-link text-decoration-none ml-auto"
                                                onClick={() => {setChanged(true);
                                                    items.length < 3
                                                    ? setItems(prev => [{...newItem}, ...prev])
                                                    : NotificationManager.error("Maksymalna ilość produktów w zestawie - 3")
                                                }}
                                            >
                                                <i className="fas fa-plus fa-sm" />
                                            </button>
                                        </div> */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, idx) => 
                                !item.deleted && 
                                (
                                    <tr key={idx}>
                                        <td>
                                            <input
                                                className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                                value={item.Name || ""}
                                                onChange={evt => {setChanged(true); handleChangeItem(item.BasketProductId, evt)}}
                                                list='products'
                                                onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.BasketProductId === item.BasketProductId ? ({...item, Name: "", EAN: ""}) : pr))}}
                                            />
                                            <datalist id='products'>
                                            {products && products.map((product, idx) => (<option key={idx} value={product.Name} />))}
                                            </datalist>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end'>
                                                
                                                <button className="btn btn-link btn-sm"
                                                    onClick={() => {setChanged(true); deleteItem(item.BasketProductId)}} 
                                                >
                                                    <i className="fas fa-trash" />                                
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                
                            </tbody>
                            </table>)
                            :
                            <>
                            {/* <div>
                                <button
                                    className="d-block btn btn-link text-decoration-none ml-auto"
                                    onClick={() => {setChanged(true);
                                        items.filter(item => !item.deleted).length <= 3
                                        ? setItems(prev => [{...newItem, Name: ""}, ...prev])
                                        : NotificationManager.error("Maksymalna ilość produktów w zestawie - 3")
                                    }}
                                >
                                    <i className="fas fa-plus fa-sm" />
                                </button>
                            </div> */}
                            <div className='card h-100' >
                                <div className='d-inline-flex h-100 justify-content-center align-items-center card-header'>
                                    Dokument nie ma produktów
                                </div>
                            </div>
                            </>
                        }
                        </div>
                        {/* <div className='card w-50 ml-2'>
                            <div className='card-header p-1'>
                                <div className='d-flex justify-content-between '>
                                    <div className='d-flex align-items-center ml-2'>
                                        Automaty promocyjne
                                    </div>
                                    <div>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>
                                                    {machines.length !== [...promotionMachines].filter(machine => !machine.deleted).length ? "Dodaj wszystkie automaty" : "Wszystkie automaty są dodane"}
                                                    
                                                </Tooltip>
                                            }>

                                            <button
                                                disabled={machines.length === [...promotionMachines].filter(machine => !machine.deleted).length}
                                                onClick={() => {
                                                    setPromotionMachines(machines.map(({MachineId, Name})=> ({MachineId, Name, BasketMachineId: random2(), new: true})))
                                                }} className="btn text-nowrap btn-sm btn-outline-success mr-2 mb-0 mt-0"><i className="fas fa-plus" />
                                            </button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>
                                                    Dodaj automat
                                                </Tooltip>
                                            }>

                                            <button 
                                                onClick={() => {
                                                    const machine = machines.find(item => item.Name === machineInput)
                                                    if (!machine) return
                                                    const promotionMachine = promotionMachines && promotionMachines.find(m => m.MachineId === machine.MachineId)
                                                    promotionMachine && delete promotionMachine.deleted
                                                    if (promotionMachine) {
                                                        if(promotionMachine.deleted) setPromotionMachines(prev => prev.map(item => item.MachineId === promotionMachine.MachineId ? promotionMachine : item))
                                                        else {
                                                            NotificationManager.warning("Automat już zapisany")
                                                            setMachineInput("")
                                                            return
                                                        }
                                                    }
                                                    if (promotionMachine && promotionMachine.deleted) {
                                                        setPromotionMachines(prev => prev.map(item => {if (item.MachineId === machine.MachineId ) {
                                                            const data = ({MachineId: machine.MachineId, Name: machine.Name, BasketMachineId: machine.BasketMachineId})  
                                                            if (item.new) data.new = true
                                                            return data
                                                        } 
                                                        else return item}))
                                                    } else {
                                                        setPromotionMachines(prev => [{MachineId: machine.MachineId, Name: machine.Name, BasketMachineId: random2(), new: true} ,...prev])
                                                    } 
                                                    setMachineInput("")
                                                }} 
                                                disabled={!machineInput || machines.length === [...promotionMachines].filter(machine => !machine.deleted).length}
                                                className="btn text-nowrap btn-sm btn-outline-primary mr-2 mb-0 mt-0"><i className="fas fa-plus" />
                                            </button>
                                        </OverlayTrigger>
                                        
                                        <OverlayTrigger 
                                            overlay={
                                                <Tooltip>
                                                    Usuń wszystkie automaty 
                                                </Tooltip>
                                            }>

                                            <button onClick={() => {
                                                setPromotionMachines(prev => prev && prev.filter(machine => machine.new && machine.deleted).map(machine => ({...machine, deleted: true})))
                                            }} className="btn text-nowrap btn-sm btn-outline-danger mr-2 mb-0 mt-0"><i className="fas fa-ban" />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body overflow-auto p-1 pt-0' style={{maxHeight: "90vh"}}>
                                <input
                                    type='text'
                                    className='form-control'
                                    list='machines-list'
                                    value={machineInput}
                                    onChange={evt => setMachineInput(evt.target.value)}
                                    // onFocus={}
                                    placeholder='Wybierz automat...'
                                />
                                <datalist id='machines-list'>
                                    {machines && machines.map((machine, idx) => <option key={idx}>{machine.Name}</option>)}
                                </datalist>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th className='p-1 pl-2'>Nazwa</th>
                                            <th className='p-1'> 
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promotionMachines && promotionMachines.filter(machine => !machine.deleted).map((machine, idx) => (
                                            <tr key={idx}>
                                                <td>
                                                    {machine.Name}
                                                </td>
                                                <td className='d-flex justify-content-end align-items-center'>
                                                    <button
                                                        className="d-block btn btn-sm btn-link text-decoration-none"
                                                        onClick={() => setPromotionMachines(prev => machine.new ? prev.filter(item => item.MachineId !== machine.MachineId) : prev.map(item => item.MachineId === machine.MachineId ? ({...item, deleted: true}) : item)) }
                                                    >
                                                        <i className="fas fa-trash mr-2" />
                                                    </button>
                                                </td>
                                            </tr>

                                        )) }
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                    </div>
                        {/* <div className='card w-50' style={{marginBottom: "40px", marginTop: "40px"}}>
                        {items.length ?
                        ( <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th>Produkt</th>
                                    <th>
                                        <div>
                                            <button
                                                className="d-block btn btn-link text-decoration-none ml-auto"
                                                onClick={() => {setChanged(true);
                                                    items.length < 3
                                                    ? setItems(prev => [{...newItem}, ...prev])
                                                    : NotificationManager.error("Maksymalna ilość produktów w zestawie - 3")
                                                }}
                                            >
                                                <i className="fas fa-plus fa-sm" />
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, idx) => 
                                !item.deleted && 
                                (
                                    <tr key={idx}>
                                        <td>
                                            <input
                                                className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                                value={item.Name || ""}
                                                onChange={evt => {setChanged(true); handleChangeItem(item.BasketProductId, evt)}}
                                                list='products'
                                                onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.BasketProductId === item.BasketProductId ? ({...item, Name: "", EAN: ""}) : pr))}}
                                            />
                                            <datalist id='products'>
                                            {products && products.map((product, idx) => (<option key={idx} value={product.Name} />))}
                                            </datalist>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end'>
                                                
                                                <button className="btn btn-link btn-sm"
                                                    onClick={() => {setChanged(true); deleteItem(item.BasketProductId)}} 
                                                >
                                                    <i className="fas fa-trash" />                                
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                
                            </tbody>
                            </table>)
                            :
                            <>
                            <div>
                                <button
                                    className="d-block btn btn-link text-decoration-none ml-auto"
                                    onClick={() => {setChanged(true);
                                        items.filter(item => !item.deleted).length <= 3
                                        ? setItems(prev => [{...newItem, Name: ""}, ...prev])
                                        : NotificationManager.error("maksymalna ilość produktów w zestawie - 3")
                                    }}
                                >
                                    <i className="fas fa-plus fa-sm" />
                                </button>
                            </div>
                            <div className='card'>
                                <div className='d-inline-flex justify-content-center align-items-center card-header'>
                                    Dokument nie ma produktów
                                </div>
                            </div>
                            </>
                        }
                        </div> */}
                        <div className='card w-100 mt-2' style={{ maxHeight: "35vh"}}>
                            {promotionMachines && (
                                <>
                                    <div className='card-header '>
                                        <div className='d-flex justify-content-between '>
                                    <div className='d-flex align-items-center ml-2'>
                                        Automaty promocyjne
                                    </div>
                                    <div>
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip>
                                                    {machines.length === [...promotionMachines].filter(machine => !machine.deleted).length ? "Wszystkie automaty są dodane" : "Dodaj wszystkie automaty"}
                                                    
                                                </Tooltip>
                                            }>

                                            <button
                                                disabled={machines.length === [...promotionMachines].filter(machine => !machine.deleted).length}
                                                onClick={() => {
                                                    setChanged(true)
                                                    // setPromotionMachines(prev => [...prev.map(item => ({...item, deleted: true})), ...machines.map(({MachineId, Name})=> ({MachineId, Name, BasketMachineId: random2(), new: true}))] )
                                                    setPromotionMachines(prev => [...prev, ...machines.filter(machine => !(prev.find(m => m.MachineId === machine.MachineId)) ).map(({MachineId, Name})=> ({MachineId, Name, BasketMachineId: random2(), new: true}))] )
                                                }} className="btn text-nowrap btn-sm btn-outline-success mr-2 mb-0 mt-0"><i className="fas fa-plus" />
                                            </button>
                                        </OverlayTrigger>
                                        
                                        
                                        <OverlayTrigger 
                                            overlay={
                                                <Tooltip>
                                                    Usuń wszystkie automaty 
                                                </Tooltip>
                                            }>

                                            <button onClick={() => {
                                                setChanged(true)
                                                setPromotionMachines(prev => prev && prev.filter(machine => !machine.new).map(machine => ({...machine, deleted: true})))
                                            }} className="btn text-nowrap btn-sm btn-outline-danger mr-2 mb-0 mt-0"><i className="fas fa-ban" />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body overflow-auto p-1 pt-0' style={{maxHeight: "25%"}}>
                                <div className='d-flex align-items-center'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        list='machines-list'
                                        value={machineInput}
                                        onChange={evt => setMachineInput(evt.target.value)}
                                        // onFocus={}
                                        placeholder='Wybierz automat...'
                                    />
                                    <datalist id='machines-list'>
                                        {machines && machines.map((machine, idx) => <option key={idx}>{machine.Name}</option>)}
                                    </datalist>
                                    {/* <OverlayTrigger
                                            overlay={
                                                <Tooltip>
                                                    Dodaj automat
                                                </Tooltip>
                                            }> */}

                                            <button 
                                                onClick={() => {
                                                    setChanged(true)
                                                    const machine = machines.find(item => item.Name === machineInput)
                                                    if (!machine) return
                                                    const promotionMachine = promotionMachines && promotionMachines.find(m => m.MachineId === machine.MachineId)
                                                    promotionMachine && delete promotionMachine.deleted
                                                    // if (promotionMachine && promotionMachine.deleted) {
                                                        // console.log(1)
                                                        // if(promotionMachine.deleted) 
                                                        // {setPromotionMachines(prev => prev.map(item => item.MachineId === promotionMachine.MachineId ? promotionMachine : item))}
                                                        // else {
                                                            // NotificationManager.warning("Automat już zapisany")
                                                            // setMachineInput("")
                                                            // return
                                                        // }
                                                        // return
                                                    // }
                                                    if (promotionMachine && !promotionMachine.deleted) {
                                                        setPromotionMachines(prev => prev.map(item => {if (item.MachineId === machine.MachineId ) {
                                                            const data = ({MachineId: machine.MachineId, Name: machine.Name, BasketMachineId: item.BasketMachineId || random2()})  
                                                            if (item.new) data.new = true
                                                            return data
                                                        } 
                                                        else {
                                                            return item
                                                        } 
                                                    }))
                                                        setMachineInput("")

                                                        return
                                                    } else {
                                                        setPromotionMachines(prev => [{MachineId: machine.MachineId, Name: machine.Name, BasketMachineId: random2(), new: true} ,...prev])
                                                    } 
                                                    setMachineInput("")
                                                }} 
                                                disabled={!machineInput || machines.length === [...promotionMachines].filter(machine => !machine.deleted).length}
                                                className="btn text-nowrap btn-sm btn-outline-primary pr-3 pl-3 pb-2 pt-2 mb-0 mt-0 ml-2"><i className="fas fa-plus" />
                                            </button>
                                        {/* </OverlayTrigger> */}
                                </div>
                                {promotionMachines.length ?
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th className='p-1 pl-2'>Nazwa</th>
                                            <th className='p-1'> 
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promotionMachines.filter(machine => !machine.deleted).map((machine, idx) => (
                                            <tr key={idx}>
                                                <td>
                                                    {machine.Name}
                                                </td>
                                                <td className='d-flex justify-content-end align-items-center'>
                                                    <button
                                                        className="d-block btn btn-sm btn-link text-decoration-none"
                                                        onClick={() =>{
                                                            setChanged(true)
                                                            setPromotionMachines(prev => machine.new ? prev.filter(item => item.MachineId !== machine.MachineId) : prev.map(item => item.MachineId === machine.MachineId ? ({...item, deleted: true}) : item)) 
                                                        } 
                                                    }
                                                    >
                                                        <i className="fas fa-trash mr-2" />
                                                    </button>
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                                :  
                                (<div className='card h-100' >
                                    <div className='d-inline-flex h-100 justify-content-center align-items-center card-header'>
                                        Promocja nie przepisana do żadnego automatu
                                    </div>
                                </div>)}
                            </div>
                                        
                                </>
                            )}
                        </div>
                    </div>
                    <div className='position-fixed container' style={{bottom: "0px"}}>
        
                            <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                                <button 
                                    onClick={handleDelete} 
                                    className={`btn btn-danger mt-auto w-20 btn-sm mr-5`} 
                                >
                                    Usuń
                                </button>
                                <button disabled={!changed || !availableToSave} className={`btn btn-primary w-20  btn-sm`} 
                                    onClick={handleSubmit}
                                    >
                                    Zapisz
                                </button>
                            </div>
                        </div>
                </div>
            )}
        </div>
    )
}
