import React, { useEffect, useState } from 'react'
import FormSkel from './FormSkel'

export default function GroupForm({handleClose, handleSubmit, data, headerText}) {
    const [name,setName] = useState(data && data.Name || "")
    const [snack,setSnack] = useState(data && data.IsSnack || undefined)
    const [coffee,setCoffee] = useState(data && data.IsCoffee || undefined)
    const [changed, setChanged] = useState(false)
    // const toggleChangeStatus = (fnc) => setChanged(true); fnc
    // useEffect(() => {
    //     (name === data.Name && coffee === data.IsCoffee && snack === data.IsSnack) ? setChanged(false) : setChanged(true)
    // }, [name, snack, coffee])
    return (
        <FormSkel headerText={headerText}
        handleClose={handleClose}
        data={data}
        disableSubmit={!name}
        // deleteDisable={changed}
        deleteDisable={data && data.ConnectedProduct || false}
        >
            <form onSubmit={handleSubmit} id='modal-form' autoComplete='off' onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}>
                <div className='form-group'>
                    <label className='h6'>Nazwa</label> 
                    <input
                        value={name}
                        onChange={evt => setName(evt.target.value)}
                        className={`form-control ${!name && `invalid-input`}`}
                        // onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        type='text'
                        name='name'
                        min={1}
                        onFocus={() =>{ setChanged(true); setName("")}}/>
                </div>
                <div className='form-group'>
                    {/* <label className='h6'>Typ automatu</label> */}
                    <div>
                        <div className='d-flex align-items-center'>
                            <input
                                checked={snack}
                                onChange={evt => {setChanged(true); setSnack(evt.checked)}}
                                className='mr-3 ml-1'
                                type='checkbox'
                                name='isSnack'/>
                            <div >Automat snack</div>
                        </div>
                        <div className='d-flex'>
                            <input
                                checked={coffee}
                                onChange={evt => {setChanged(true);setCoffee(evt.checked)}}
                                className='mr-3 ml-1'
                                type='checkbox'
                                name='isCoffee'/>
                            <div>Automat kawowy</div>
                        </div>
                    </div>
                    {/* <select defaultValue={data && data.IsSnack ? 1 : 2 || 1} 
                        aria-label="Default select example"
                        name='type' 
                        className='custom-select'>
                        <option value={1} selected={!data || data.IsSnack }>Snack</option>
                        <option value={2} selected={data && data.IsCoffee}>Kawa</option>
                    </select> */}
                </div>

            </form>
        </FormSkel>
    )
}
