import React, { useEffect, useState, useContext } from 'react'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import { Link, Prompt, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TextInput from '../../../components/FormElements/TextInput'
import IngredientForm from './forms/IngredientForm'
import cleanInput from '../../../util/cleanInput'
import './styles/selectedIngredient.css'
import { NavigationContext } from '../../../context/navigation-context'
import { NotificationManager } from 'react-notifications'


export default function Recipe() {
    const param = useParams()
    const {fetchMssqlApi} = useFetch()
    const [recipe, setRecipe] = useState([])
    const [allVat, setallVat] = useState([])
    const [initialIngredients, setInitialIngredients] = useState([])
    const [recipeIngredients, setRecipeIngredients] = useState([])
    const [ingredients, setIngredients] = useState([]) 
    const [recipeChanged, setRecipeChanged] = useState(false) 
    const [eanIsEditable, setEanIsEditable] = useState(false)
    const [disableToChange, setDisableToChange] = useState(false)
    const history = useHistory()
    const [deletedIngredients, setDeletedIngredients] = useState([])
    const [unsavedChange, setUnsavedChange]  = useState(false)

    // const newIngredientType = {
    //     Name: "",
    //     RecipeId: recipe.RecipeId,
    //     RecipeProductId: null,
    //     Value: "",
    //     new: true,
    // }
    // useEffect(() => {
    //     if(!recipeChanged) setRecipeChanged(true)
    // }, [recipe])
    const {setHeaderData} = useContext(NavigationContext)
    const getRecipe = () => {
        fetchMssqlApi(`recipe/${param.id}`, {}, async recipe => {
            await setRecipe(recipe)
            await setEanIsEditable(!!recipe.Ean)
            // await setDisableToChange(true)
        })
    }

    useEffect(() => {
        deletedIngredients && !deletedIngredients.length && setUnsavedChange(false)
    }, [deletedIngredients])
    useEffect(() => {
        if(recipeIngredients) {
            (!recipeIngredients.every(recipe => recipe.Name && recipe.Value)) ? setDisableToChange(true) : setDisableToChange(false) 
            if (recipeIngredients.some(ing => ing.Deleted)) setUnsavedChange(true) 
            if (recipeIngredients.some(ing => ing.Deleted))  setUnsavedChange(true) 
            if(recipeIngredients.find(ing => ing.new)) setUnsavedChange(true)

        }
        // if (recipeIngredients) {
        // }
        // if(recipeIngredients && recipeIngredients.some(ing => ing.Deleted)) setUnsavedChange(true) 
        // if (recipeIngredients && recipeIngredients?.find(ing => ing.new)) setUnsavedChange(true)
        // if (deletedIngredients.length > 0) setUnsavedChange(true)
        if(!recipeIngredients && !initialIngredients) setUnsavedChange(false) 
    }, [recipeIngredients])
    const random = () => Math.floor(Math.random() * 100000)
    const idGenerator = () => {
        const ids = new Set(ingredients.map(ing => ing.EAN))
        recipeIngredients && ids.add(recipeIngredients.map(recipe => recipe.RecipeProductId))
        let id = random()
        if (ids.has(id)) idGenerator()
        return id
    }
    useEffect(() => {
        // if(!unsavedChange) setUnsavedChange(true)
        const {Ean, VATId, Name} = recipe
        if (!Ean || !VATId || !Name) setDisableToChange(true) 
        else setDisableToChange(false)
    }, [recipe])
    useEffect(() => {setRecipeIngredients(initialIngredients)
        return setRecipeChanged(false)
    }, [initialIngredients])
    const getIngredients = () => {
        fetchMssqlApi('ingredients-list', {}, ingredients => setIngredients(ingredients))
    }
    const getVAT = () => {
        fetchMssqlApi("/vats", {}, vats => setallVat(vats))
    }
    const getVATCode = (vatId) => {
        let code = null;
        if (vatId !== '' && allVat.find(obj => obj.VATId === vatId) !== undefined)
            code = allVat.find(obj => obj.VATId === vatId).Code
        return code;
    }
    const getVATId = (code) => {
        let vatId = null;
        if (code !== '' && allVat.find(obj => obj.Code === code) !== undefined)
            vatId = allVat.find(obj => obj.Code === code).VATId
        return vatId;
    }
    const getRecipeData = () => {
        fetchMssqlApi( `recipe-products/${param.id}`, {}, ingredients =>  {
            
            setInitialIngredients(ingredients)
        })
    }
    useEffect(() => {
        setHeaderData({text: "Podgląd receptury"})
        getRecipe()
        getVAT()
        getRecipeData()
        getIngredients()
        // console.log(window.history.state)
    }, [])
    const handleChange = evt => {
        const { name, value } = evt.target
        setRecipeChanged(true)
        setUnsavedChange(true)
        if(name === "VAT") setRecipe(prev => ({...prev, VAT: +value, VATId: getVATId(value)}))
        else if (name === "Code") setRecipe(prev => ({ ...prev, [name]: value.replace(/[^A-Za-z0-9]/g, '') })) 
        else setRecipe(prev => ({ ...prev, [name]: value }))
    }
    const changeIngredientsList = async (notification = true) => {
        let a = true
        if(recipeIngredients.length) {
                await recipeIngredients.map(ingredient => {
                    const ingredientId = ingredients.find(ing => ing.Name === ingredient.Name)
                    if (!ingredientId || !ingredient.Value) {
                        setRecipeIngredients(prev => prev.map(ing => {if (ing.RecipeProductId == ingredient.RecipeProductId) return ({...ing, Name: "", Value: ""})
                        else return ing }))
                        a = false
                        NotificationManager.error('Ingreient or quantity not found. Choose correct product')
                        // throw new Error('Product not found. Choose correct product')
                    // } else { setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId == ingredient.RecipeProductId) ? ({...ing, Name: ingredientId.Name , RecipeProductId: ingredientId.EAN}) : ing ))}
                    } else {
                        if(a) {

                            if (ingredient.new) {
                                const payload = {
                                    RecipeId: +recipe.RecipeId,
                                    Ean: ingredientId.EAN,
                                    Value: ingredient.Value
                                }
                                fetchMssqlApi('recipe-product', {method: "POST", data: payload, hideNotification: notification}, answ =>{ setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId === ingredient.RecipeProductId) ? answ.rows : ing)); })
                            } else if (ingredient.Deleted) {
                                fetchMssqlApi(`/recipe-product/${ingredient.RecipeProductId}`, {method: "DELETE", data: {}, hideNotification: true}, () => {setDeletedIngredients([]);  setRecipeIngredients(prev => prev.filter(ing => ing.RecipeProductId !== ingredient.RecipeProductId));}) 
                            } else {
                                const payload = {
                                    RecipeId: +recipe.RecipeId,
                                    Ean: ingredientId.EAN,
                                    Value: ingredient.Value
                                }
                                fetchMssqlApi(`recipe-product/${ingredient.RecipeProductId}`, {method: "PUT", data: payload, hideNotification:true}, () => {})
                            }
                        }
                    }
                })
                if (a) deletedIngredients.map(id => fetchMssqlApi(`/recipe-product/${id}`, {method: "DELETE", data: {}, hideNotification: true}, () => setDeletedIngredients(prev => prev.filter(a => a != id))))
            
        }
        setRecipeChanged(false)
        setUnsavedChange(false)
        return a
    }
    const handleChangeRecipeStatus = recipeId => {
        if (recipe.IsActive) {
            fetchMssqlApi(`recipe/${recipeId}`, {method: "DELETE", data:{}}, () => setRecipe(recipe => ({...recipe, IsActive: false})))
            setUnsavedChange(false)
        } else {
            const {Name, VATId} = recipe
            fetchMssqlApi(`recipe/${recipeId}`, {method: "PUT", data: {Name, VatId: VATId, IsActive: true}}, () => setRecipe(prev => ({...prev, IsActive: true})))
            setUnsavedChange(false)
        }
    }
    const handleChangeIngredient = (evt, id) => {
        if (evt.name === "Quantity") return setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId === id) ? {...ing, Value: evt.value.replace(/\D/g, '')} : ing)) 
        else return setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId === id) ? {...ing, Name: evt.value} : ing))
    }
    const handleClearIngredientInputs = (evt, id) => {
        if (evt.name === "Quantity") return setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId === id) ? {...ing, Value: ""} : ing)) 
        else return setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId === id) ? {...ing, Name: ""} : ing))
    }

    const handleSubmitRecipe = async () => {
        const {Name, VATId} = recipe
        if (!VATId) return alert("something went wrong")
        // if (!Name || !recipe.Ean) return alert("Write correct information about product")
        if (!Name) return alert("Write correct information about product")
        const data = {
            Name: recipe.Name,
            VatId: recipe.VATId,
            IsActive: recipe.IsActive,
            Description: recipe.Description,
            Code: recipe.Code
        }
        const a = await changeIngredientsList(true)
        a && fetchMssqlApi(`recipe/${recipe.RecipeId}`, {method: "PUT", data}, () => {unsavedChange && setUnsavedChange(false)})

    }
    // fetchMssqlApi(`/recipe-product/${ingredient.RecipeProductId}`, {method: "DELETE", data: {}, hideNotification: true}, () => {}) 
    const deleteIngredient = ingredient => {
        setUnsavedChange(true)
        
        setRecipeChanged(true)
        if(!ingredient.new) {
            return setRecipeIngredients(prev => prev.map(ing => (ing.RecipeProductId === ingredient.RecipeProductId) ? ({...ing, Deleted: true}) : ing))
        }  
        else return setRecipeIngredients(prev => prev.filter(ing => ing.RecipeProductId !== ingredient.RecipeProductId))
    } 
    return (
        recipe && (
        <div className='d-inline-flex justify-content-center'>
            <Prompt when={unsavedChange} message={"Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"}/>
        <div className='container pb-5'>

        {/* <Link to='/recipes'> */}
        {window.history.state && (

        <Link to={window.history.state.state ? `/product/${window.history.state.state.product}` : '/recipes'}>
            <button className="btn btn-link ml-1 text-decoration-none mb-3">
                <i className="fas fa-arrow-left mr-2" />
                Wróć
            </button>
        </Link>
        )}
        <div className="row mb-4">



        <div className="col-12 col-md-6 md-6 mb-md-0" >
            <div className="card h-100">    
            <h5 className="card-header">Ustawienia</h5>
            <div className="card-body d-flex flex-column justify-content-center">
                <form id="recipe-form" autoComplete="off">
                <div className="row mb-3">
                    <div className="col-lg-3 mb-2 mb-lg-0 text-lg-right">
                    EAN
                    </div>
                    <div className="col-lg-6 my-auto">
                    <TextInput
                        className="mx-auto mx-lg-0"
                        // style={{ maxWidth: 275 }}
                        name="Ean"
                        value={recipe.Ean}
                        // handleChange={handleChange}
                        minLength={1}
                        type="number"
                        // maxLength={50}
                        required
                        disabled={true} 
                    />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-3 mb-2 mb-lg-0 text-lg-right">
                    Nazwa
                    </div>
                    <div className="col-lg-6 my-auto">
                    <input
                        className={`form-control ${!recipe.Name && `invalid-input`} form-control-sm mx-auto mx-lg-0`}
                        // style={{ maxWidth: 275 }}
                        name="Name"
                        value={recipe.Name}
                        onChange={handleChange}
                        minLength={2}
                        maxLength={50}
                        required
                        onFocus={evt =>{ setRecipe(prev => ({...prev, Name: "" })); setUnsavedChange(true)}}
                    />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-3 mb-2 mb-lg-0 text-lg-right">
                    Stawka VAT
                    </div>
                    <div className="col-lg-6 my-auto">
                    <select
                    defaultValue={recipe.VAT}
                    value={recipe.VAT}
                    name='VAT'
                    className="form-control form-control-sm mx-auto mx-lg-0"
                    onChange={handleChange}
                    >
                    {allVat.map(vat =>( 
                        <option key={vat.VATId} value={getVATCode(vat.VATId)} selected={+vat.Code === recipe?.VAT || vat.VATId === 4}>
                            {vat.Code + "%"}
                        </option>
                        ))}
                    </select>
                    </div>
                </div>
                <div className="row mb-3">
                <div className="col-lg-3 mb-2 mb-lg-0 text-lg-right">
                        Opis
                    </div>
                    <div className="col-lg-7 my-auto">
                    <textarea
                        name="Description"
                        className={`form-control form-control-sm mx-auto mx-lg-0`}
                        onChange={handleChange}
                        defaultValue={recipe && recipe.Description || ""}
                        value={recipe.Description}
                        rows={5}
                        // onFocus={clearInput}
                    />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-3 mb-2 mb-lg-0 text-lg-right">
                    Kod dodatkowy
                    </div>
                    <div className="col-lg-6 my-auto">
                    <input
                        className={`form-control  form-control-sm mx-auto mx-lg-0`}
                        // style={{ maxWidth: 275 }}
                        name="Code"
                        value={recipe.Code}
                        onChange={handleChange}
                        minLength={2}
                        maxLength={50}
                        required
                        onFocus={evt =>{ setRecipe(prev => ({...prev, Code: "" })); setUnsavedChange(true)}}
                    />
                    </div>
                </div>
                
                </form>
            </div>
            
            </div>
        </div>
        
        <div className="col-12 col-md-6 ">
            <div className="card h-100 overflow-auto "> 
            <h5 className="card-header">Informacje</h5>
            <div className='d-flex justify-content-center ' >
                <div style={{margin:"5%", width:"100%"}} >

                    {/* <h5 style={{marginBottom:"20px"}}>Ilość składników<span className="ml-2 badge badge-info">{recipeIngredients?.length || "0"}</span></h5> */}
                    <h5 style={{marginBottom:"20px"}}>Status: <span className={recipe?.IsActive ? "ml-2 badge badge-success" : "ml-2 badge badge-danger"}>{recipe?.IsActive ? "Aktywna" : "Nieaktywna"}</span></h5>
                </div>
            </div>
        </div>
        
        </div>
        </div>
            {recipeIngredients ? (
            <>
            <div className="card"> 
            <div className="card-header d-inline-flex align-items-center justify-content-between">
                <h5 className='mb-0'>Lista składników w recepturze <span className="ml-2 badge badge-info">{[...recipeIngredients]?.filter(ing => !ing.Deleted).length || "0"}</span></h5> 
                <button
                    className="d-block btn btn-link text-decoration-none "
                    onClick={() => setRecipeIngredients([...recipeIngredients , 
                        {
                            Name: "",
                            RecipeId: recipe.RecipeId,
                            Value: "",
                            new: true,
                            RecipeProductId: idGenerator()}])}
                >
                    <i className="fas fa-plus mr-2" />
                    Nowy
                </button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th>
                        Składnik
                    </th>
                    <th>
                        Ilość
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {recipeIngredients ? recipeIngredients.map((ingredient, idx) => (
                        !ingredient?.Deleted &&(
                        // <tr key={idx}>
                        <tr key={idx} className={window.history.state && window.history.state.state && ingredient.RecipeProductId === String(window.history.state.state.ingredient) ? `selectIngredient${window.history.state.state && window.history.state.state.IsActive ? `Active` : `NonActive`}` : ""}>
                            <td>
                            <input
                                name='IngredientName'
                                value={ingredient?.Name || ""}
                                onFocus={evt =>{setRecipeChanged(true); handleClearIngredientInputs(evt.target, ingredient.RecipeProductId)}}
                                onChange={evt =>{setRecipeChanged(true); handleChangeIngredient(evt.target, ingredient.RecipeProductId)}}
                                // onChange={evt => console.log(recipeIngredients)}
                                className={`form-control ${!ingredient.Name && `invalid-input`} form-control-sm mx-auto mx-lg-0`}
                                list='ingredients'/>
                                <datalist id="ingredients">
                                    {ingredients && ingredients.filter(a => a.IsActive).map((ingredient, idx) => (
                                        <option key={idx} value={ingredient.Name}/>
                                    ))}
                                </datalist>
                            </td>
                            <td>
                                <TextInput
                                name='Quantity'
                                onFocus={evt =>{setRecipeChanged(true); setUnsavedChange(true); handleClearIngredientInputs(evt.target, ingredient.RecipeProductId);}}
                                defaultValue={ingredient?.Value || ""}
                                value={ingredient?.Value}
                                handleChange={evt => {setRecipeChanged(true); setUnsavedChange(true); handleChangeIngredient(evt.target, ingredient.RecipeProductId); ; setUnsavedChange(true)}}
                                // onChange={evt => console.log(recipeIngredients)}
                                min={1}
                                className={`form-control form-control-sm mx-auto mx-lg-0`}/>
                            </td>
                            <td className='d-flex justify-content-center'>
                                <button className="btn btn-link btn-sm" onClick={() => deleteIngredient( ingredient)}>
                                {deleteIngredient && deletedIngredients.includes(ingredient.RecipeProductId)  ? (
                                    <i className="fas fa-trash-restore" />
                                ) : (
                                    <i className="fas fa-trash" />
                                )}
                                
                                </button>
                            </td>
                        </tr>
                        )
                    )) : <tr></tr>}
                </tbody>
            </table>
            </div>
            {/* <div className="card-footer d-flex justify-content-center ">
        <button 
            onClick={() => setRecipeIngredients([...recipeIngredients , 
                {
                    Name: "",
                    // RecipeId: recipe.RecipeId,
                    Value: "",
                    new: true,
                    RecipeProductId: idGenerator()}])} 
                className='btn btn-success mt-auto w-20 btn-sm mr-5' >Dodaj składnik</button>
        <button className={`btn ${ingredientsChanged ? `btn-primary` : `btn-secondary`} w-20  btn-sm`}
    onClick={changeIngredientsList}>
            Zapisz składniki
        </button>
        </div> */}
        </>
        ) : (
            <div className='d-flex justify-content-center mt-5'>
                <button
                    className="d-block btn btn-link text-decoration-none "
                    onClick={() =>{; setUnsavedChange(true); setRecipeIngredients([...recipeIngredients , 
                        {
                            Name: "",
                            // RecipeId: recipe.RecipeId,
                            Value: "",
                            new: true,
                            RecipeProductId: idGenerator()}])}}
                >
                    <i className="fas fa-plus mr-2" />
                    Dodaj składnik
                </button>
            </div>
        )} 
        </div>
        <div className='position-fixed container m-20' style={{bottom: "0px"}}>

        <div className="card-footer text-center pr-3 pl-0" style={{backgroundColor:"#cbcbcb", 
            borderTopRightRadius: "calc(0.25rem - 1px)",
            borderTopLeftRadius: "calc(0.25rem - 1px)"}}>
        <button 
            onClick={() => handleChangeRecipeStatus(recipe.RecipeId)} 
            className={`btn btn-${recipe.IsActive ? `danger` : `success`} mt-auto w-20 btn-sm mr-5`} >{recipe.IsActive ? "Deaktywuj" : "Aktywuj"}</button>
        <button className={`btn btn-primary w-20  btn-sm`} 
            onClick={handleSubmitRecipe}
            disabled={!(!disableToChange && recipeChanged)}>
            Zapisz
        </button>
        </div>
        </div>

        </div>
        )
        
    )
}
