import React, {useEffect, useState, useContext} from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../../context/navigation-context'
import NoResults from '../../../components/NoResults/NoResults'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ReportBtnForArr from '../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CashRegistersList from './CashRegistersList'
import Reports from './Reports'
import Configuration from './Configuration'
import FindDocument from './FindDocument'


export default function CashRegisters() {

    const { setHeaderData } = useContext(NavigationContext),
    history = useHistory(),
    params = useParams(),
    {fetchMssqlApi} = fetchHook()
    const changeType = (typeId) => {
        setType(typeId)
        switch(typeId) {
            case 0: 
                history.replace("/wkfs/cashregisters")
                break;
            case 1: 
                history.replace('/wkfs/documents')
                break;
            case 2: 
                history.replace('/wkfs/configuration')
                break;
            case 3: 
                history.replace('/wkfs/finddocument')
                break;
            default:
                isConfigurated && history.replace("/wkfs/cashregisters")
                break;
        }
    },
    {state} = useContext(NavigationContext)


    const [isConfigurated, setIsConfigurated] = useState(null)

    const getCustomerIsConfigurated = () => {
        fetchMssqlApi("customerIsConfigurated", {}, res => {
            setIsConfigurated(res.IsConfigurated)
        })
    }
    const getType = (type = params.type) => {
        // console.log("getType is working, isConfigurated: ", state.isAllowed)

            if (isConfigurated !== null) {
                if (type) {
                    switch(type) {
                        case "cashregisters": 
                            return 0;
                        case "documents": 
                            return 1;
                        case "configuration": 
                            return 2;
                        case "finddocument": 
                        default:
                            return 0
                    }
                } else return 0
            }
    } 
    const [type, setType] = useState(null)

    useEffect(() => {
        
        setIsConfigurated(state.isAllowed)
        getCustomerIsConfigurated()
    }, [])
    useEffect(() => {
        isConfigurated === true && setHeaderData({text: "Kasy fiskalne"}) 
        changeType(type)
    }, [isConfigurated])
    useEffect(() => {
        setType(getType())
    }, [type])
    return (
        isConfigurated !== null && (<div>
            {isConfigurated && (

            <ul className="nav nav-tabs machine-tabs mb-3">
                    {/* {!isConfigurated && (
                        <li>
                        <button
                        className={`nav-link btn ${type === 2 && 'active'}`}
                        onClick={() =>type !== 2 && changeType(2)}
                        tabIndex="0"
                        >
                            Dane Podatnika
                        </button>
                    </li>
                    )} */}
                    {/* {isConfigurated && ( */}
                    <>
                    <li>
                        <button
                        className={`nav-link btn ${type === 0 && 'active'}`}
                        onClick={() => type !== 0 && changeType(0)}
                        tabIndex="0"
                        disabled={!isConfigurated}
                        >
                            Kasy
                        </button>
                    </li>
                    <li>
                        <button
                        className={`nav-link btn ${type === 1 && 'active'}`}
                        onClick={() => type !== 1 && changeType(1)}
                        tabIndex="0"
                        disabled={!isConfigurated}
                        >
                            Dokumenty
                        </button>
                    </li>
                    {/*{JSON.parse(localStorage.getItem("lastLogin"))?.email === "serwis@vendim.pl" && (*/}
                    {/*    <li>        */}
                    {/*        /!* uncomment to enable document finder *!/*/}
                    {/*        <button*/}
                    {/*        className={`nav-link btn ${type === 3 && 'active'}`}*/}
                    {/*        onClick={() =>type !== 3 && changeType(3)}*/}
                    {/*        tabIndex="0"*/}
                    {/*        >*/}
                    {/*            Wyszukaj dokument*/}
                    {/*        </button>*/}
                    {/*    </li>*/}
                    {/*)}*/}
                    <li>
                        <button
                        className={`nav-link btn ${type === 2 && 'active'}`}
                        onClick={() =>type !== 2 && changeType(2)}
                        tabIndex="0"
                        >
                            Dane Podatnika
                        </button>
                    </li>
                    </>
                    {/* )} */}
            </ul>
            )}



            {    
                type === 0 && <CashRegistersList/> ||
                type === 1 && <Reports/> ||
                type === 2 &&  <Configuration isNew={!isConfigurated}/> ||
                type === 3 &&  <FindDocument/> ||
                <></>
            }
        </div>)
    )
}
