import React, {useContext, useEffect, useState} from "react";
import {NavigationContext} from "../../context/navigation-context";
import {LangContext} from "../../context/lang-context";

import MachineProducts from "./MachineProducts";
import Temperature from "./Temperature";
import Lift from "./Lift";
import BasicData from "./BasicData";
import PaymentSystems from "./PaymentSystems";
import AdvancedConfig from "./AdvancedConfig";
import Personalization from "./Personalization";

const Config = () => {
    const {setHeaderData} = useContext(NavigationContext);
    const {
        TRL_Pack: {shelves},
    } = useContext(LangContext);

    const [currentMachineSection, setCurrentMachineSection] = useState(0);
    const changeSection = (id) => () => setCurrentMachineSection(id);

    useEffect(() => {
        setHeaderData({text: shelves.configHeader});
    }, []);

    return (
        <div className="container-xl">
            <ul className="nav nav-tabs machine-tabs mb-3">
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 0 && "active"
                        }`}
                        onClick={changeSection(0)}
                        tabIndex="0"
                    >
                        {shelves.config}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 1 && "active"
                        }`}
                        onClick={changeSection(1)}
                        tabIndex="1"
                    >
                        {shelves.basicData}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 2 && "active"
                        }`}
                        onClick={changeSection(2)}
                        tabIndex="2"
                    >
                        {shelves.systemPayment}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 3 ? "active" : ""
                        }`}
                        onClick={changeSection(3)}
                        tabIndex="3"
                    >
                        {shelves.temperature}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 4 ? "active" : ""
                        }`}
                        onClick={changeSection(4)}
                        tabIndex="4"
                    >
                        {shelves.personalization}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 5 && "active"
                        }`}
                        onClick={changeSection(5)}
                        tabIndex="5"
                    >
                        {shelves.elevator}
                    </button>
                </li>

                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 6 && "active"
                        }`}
                        onClick={changeSection(6)}
                        tabIndex="6"
                    >
                        {shelves.advanced}
                    </button>
                </li>
            </ul>
            {currentMachineSection === 0 && <MachineProducts/>}
            {currentMachineSection === 1 && <BasicData/>}
            {currentMachineSection === 2 && <PaymentSystems/>}
            {currentMachineSection === 3 && <Temperature/>}
            {currentMachineSection === 4 && <Personalization/>}
            {currentMachineSection === 5 && <Lift/>}
            {currentMachineSection === 6 && <AdvancedConfig/>}
        </div>
    );
};

export default Config;
