import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'
import { NotificationManager } from 'react-notifications'
import { Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function Contractor() {
    const { TRL_Pack } = useContext(LangContext)
    const { fetchMssqlApi } = useFetch()
    const { id } = useParams()
    const { setHeaderData } = useContext(NavigationContext)
    const [avaiableToSave, setAvaiableToSave] = useState(false)
    const history = useHistory()
    const [contractor, setContractor] = useState([])
    const [initialContractor, setInitialContractor] = useState([])
    const [changed, setChanged] = useState(false)

    const getContractor = (contractorId = id) => {
        if (contractorId !== "0") fetchMssqlApi(`contractor/${contractorId}`, {}, contractor => setInitialContractor(contractor))
        else 
        setInitialContractor({
            ContractorId: null,
            Name: "",
            IsActive: true,
            NIP: "",
            Address: "",
            ZipCode: "",
            City: "",
            Phone: "",
            CreateDateTime: "",
            UpdateDateTime: "",
        })
    }
    const handleSubmit = () => {
        const {
            Name,
            Address,
            ZipCode,
            City,
            Phone,
            Fax,
            NIP,
            ContactPerson,
            Email,
            Comment,
        } = contractor
        const emailRegexp = new RegExp(/^\S+@\S+\.\S+$/)
        if (Email && !emailRegexp.test(Email)) {
            setContractor(prev => ({...prev, Email: ""}))
            NotificationManager.error("Incorrect email")
            return
        }
        if (NIP.replace(/[^0-9]/g, '').length !== 10) {
            setContractor(prev => ({...prev, NIP: ""}))
            NotificationManager.error(NIP.trim().length > 10 ? "NIP is too long" : "NIP is too short" ,"Incorrect NIP", 2000)
            return
        }
        const payload = {
            Name:Name ? Name.trim(): "",
            Address:Address ? Address.trim(): "",
            ZipCode:ZipCode ? ZipCode.trim(): "",
            City:City ? City.trim(): "",
            Phone:Phone ? Phone.trim(): "",
            Fax:Fax ? Fax.trim(): "",
            Nip:NIP ? NIP.trim(): "",
            ContactPerson:ContactPerson ? ContactPerson.trim(): "",
            Email:Email ? Email.trim(): "",
            Comment:Comment ? Comment.trim(): "",
            IsActive: contractor.IsActive
        }
        id === '0' ? fetchMssqlApi('contractor', {method: 'POST', data: payload}, res => {
            setChanged(false)
            setInitialContractor(res.rows)
            history.replace(res.rows.ContractorId)
        } ) : fetchMssqlApi(`contractor/${id}`, {method: "PUT", data: payload}, res => {
            setChanged(false)
            setInitialContractor(prev => ({...prev, ...res.rows}) )
        })
    }
    const handleChangeStatus = () => {
        const {
            Name,
            Address,
            ZipCode,
            City,
            Phone,
            Fax,
            NIP,
            ContactPerson,
            Email,
            Comment,
        } = initialContractor
        const payload = {
            Name:Name ? Name.trim(): "",
            Address:Address ? Address.trim(): "",
            ZipCode:ZipCode ? ZipCode.trim(): "",
            City:City ? City.trim(): "",
            Phone:Phone ? Phone.trim(): "",
            Fax:Fax ? Fax.trim(): "",
            Nip:NIP ? NIP.trim(): "",
            ContactPerson:ContactPerson ? ContactPerson.trim(): "",
            Email:Email ? Email.trim(): "",
            Comment:Comment ? Comment.trim(): "",
            IsActive: !contractor.IsActive
        }
        fetchMssqlApi(`contractor/${id}`, {method: "PUT", data: payload}, res => {
            setChanged(false)
            setInitialContractor(prev => ({...prev, ...res.rows}) )
        })
    }
    useEffect(() => {
        setHeaderData({text: 'Podgląd kontrahenta'})
        getContractor()
    }, [])
    useEffect(() => {
        setContractor(initialContractor)
    }, [initialContractor])
    useEffect(() => {
        contractor.Name
        && contractor.NIP
        && contractor.City
        && contractor.ZipCode
        && contractor.Phone
        && contractor.Address
        ? setAvaiableToSave(true)
        : setAvaiableToSave(false)
    }, [contractor])
    return (
        <div> 
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            <Link to="/contractors">
            <button className="btn btn-link ml-1 text-decoration-none mb-3">
                <i className="fas fa-arrow-left mr-2" />
                Wróć
            </button>
            </Link>
            {contractor && (contractor.ContractorId ? (
            <>
                <div className='card mb-3'>
                    <div className='card-header'>
                        <div className='h5 mt-1'>
                            {initialContractor.Name}
                        </div>
                    </div>
                    <div className='card-body'>
                        <table className="table table-striped">
                            <tbody>
                                <tr className='justify-content-center'>
                                    <td>Nazwa</td>
                                    <td>NIP</td>
                                    <td>Telefon</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className={`mt-2 form-control form-control-sm ${!contractor.Name && `invalid-input`}`}
                                            type='text'
                                            value={contractor.Name}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Name: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Name: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.NIP}
                                            className=  {`mt-2 form-control ${!contractor.NIP && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, NIP: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value.replace(/[^0-9-]/g, '') ;setContractor(prev => ({...prev, NIP: value || ""}))}}
                                            name="FromWarehouse"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.Phone}
                                            type='tex'
                                            className=  {`mt-2 form-control ${!contractor.Phone && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Phone: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value.replace(/[^0-9-+]/g, '') ;setContractor(prev => ({...prev, Phone: value || ""}))}}
                                        />

                                    </td>
                                </tr>
                                <tr className='justify-content-center'>
                                    <td>Adres</td>
                                    <td>Kod pocztowy</td>
                                    <td>Miasto</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            value={contractor.Address}
                                            type='text'
                                            className=  {`mt-2 form-control ${!contractor.Address && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Address: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Address: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.ZipCode}
                                            type='text'
                                            className=  {`mt-2 form-control ${!contractor.ZipCode && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, ZipCode: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value.replace(/[^0-9-]/g, '') ;setContractor(prev => ({...prev, ZipCode: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.City}
                                            type='text'
                                            className=  {`mt-2 form-control ${!contractor.City && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, City: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, City: value || ""}))}}
                                        />
                                    </td>
                                </tr>
                                <tr className='justify-content-center'>
                                    <td>Fax</td>
                                    <td>Email</td>
                                    <td>Osoba kontaktowa</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            value={contractor.Fax}
                                            type='text'
                                            className=  {`mt-2 form-control form-control-sm`}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Fax: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Fax: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.Email}
                                            type='email'
                                            className=  {`mt-2 form-control ${!contractor.Email && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Email: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Email: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.ContactPerson}
                                            type='email'
                                            className=  {`mt-2 form-control form-control-sm`}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, ContactPerson: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, ContactPerson: value || ""}))}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        Komentarz / dodatkowe informacje
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className='d-flex'>
                                            <textarea
                                                value={contractor.Comment}
                                                onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Comment: value || ""}))}}
                                                rows={3}
                                                cols={100}
                                            />
                                        </div>
                                    </td>
                                    <td >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            
                                            <h3 ><span className={contractor.IsActive ? "ml-2 badge badge-success" : "ml-2 badge badge-danger"}>{contractor.IsActive ? "Aktywny" : "Nieaktywny"}</span></h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>) : (<>
                <div className='card mb-3'>
                    <div className='card-header'>
                        <div className='h5 mt-1'>
                            Nowy kontrahent
                        </div>
                    </div>
                    <div className='card-body'>
                        <table className="table table-striped">
                            <tbody>
                                <tr className='justify-content-center'>
                                    <td>Nazwa</td>
                                    <td>NIP</td>
                                    <td>Telefon</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            className={`mt-2 form-control form-control-sm ${!contractor.Name && `invalid-input`}`}
                                            type='text'
                                            value={contractor.Name}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Name: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Name: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.NIP}
                                            className=  {`mt-2 form-control ${!contractor.NIP && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, NIP: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value.replace(/[^0-9-]/g, '') ;setContractor(prev => ({...prev, NIP: value || ""}))}}
                                            name="FromWarehouse"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.Phone}
                                            type='tex'
                                            className=  {`mt-2 form-control ${!contractor.Phone && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Phone: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value.replace(/[^0-9-+]/g, '') ;setContractor(prev => ({...prev, Phone: value || ""}))}}
                                        />

                                    </td>
                                </tr>
                                <tr className='justify-content-center'>
                                    <td>Adres</td>
                                    <td>Kod pocztowy</td>
                                    <td>Miasto</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            value={contractor.Address}
                                            type='text'
                                            className=  {`mt-2 form-control ${!contractor.Address && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Address: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Address: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.ZipCode}
                                            type='text'
                                            className=  {`mt-2 form-control ${!contractor.ZipCode && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, ZipCode: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value.replace(/[^0-9-]/g, '') ;setContractor(prev => ({...prev, ZipCode: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.City}
                                            type='text'
                                            className=  {`mt-2 form-control ${!contractor.City && `invalid-input`} form-control-sm`}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, City: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, City: value || ""}))}}
                                        />
                                    </td>
                                </tr>
                                <tr className='justify-content-center'>
                                    <td>Fax</td>
                                    <td>Email</td>
                                    <td>Osoba kontaktowa</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            value={contractor.Fax}
                                            type='text'
                                            className=  {`mt-2 form-control form-control-sm`}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Fax: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Fax: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.Email}
                                            type='email'
                                            className=  {`mt-2 form-control ${!contractor.Email && `invalid-input`} form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, Email: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Email: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={contractor.ContactPerson}
                                            type='email'
                                            className=  {`mt-2 form-control form-control-sm`}
                                            min={1}
                                            onFocus={() => {setChanged(true); setContractor(prev => ({...prev, ContactPerson: ""}))}}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, ContactPerson: value || ""}))}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        Komentarz / dodatkowe informacje
                                    </td>
                                    <td>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className='d-flex'>
                                            <textarea
                                                value={contractor.Comment}
                                                onChange={evt => {setChanged(true); const value = evt.target.value ;setContractor(prev => ({...prev, Comment: value || ""}))}}
                                                rows={3}
                                                cols={100}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>))}
            <div className='position-fixed container' style={{bottom: "0px"}}>

                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        {id !== "0" && (
                        <button 
                            onClick={handleChangeStatus} 
                            className={`btn btn-${contractor.IsActive ? `danger` : `success`}  mt-auto w-20 btn-sm mr-5`} >{contractor.IsActive ? "Deaktywuj" : "Aktywuj"}</button>
                        )}
                        <button disabled={!changed || !avaiableToSave}  className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
            </div>
        </div>
    )
}
