import React, {useState, useEffect, useContext} from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import moment from 'moment'
import 'moment/locale/pl'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import PrepaidFilter from './PrepaidFilter'
import NoResults from '../../../components/NoResults/NoResults'
import { NotificationManager } from 'react-notifications'

export default function PrepaidsTransactionns() {
    const { setHeaderData } = useContext(NavigationContext),
    { fetchMssqlApi } = fetchHook(),
    [prepaidTransactions, setPrepaidTransactions] = useState([]),
    [visibleFilter, setVisibleFilter] = useState(false)
    const toggleFilter = () => setVisibleFilter(!visibleFilter)


    const getTRXs = (
        data = {
            StartDateTime: moment().add(-7, "d").format("YYYY-MM-DD 00:00:00.000"),
            EndDateTime: moment().format('YYYY-MM-DD 23:59:59.000'),
        }
    ) => {
        if (!moment(data.StartDateTime).isValid() || !moment(data.EndDateTime).isValid()) {
            NotificationManager.error("Wpowadż popraawną date")
            return
        } 
        fetchMssqlApi('prepaid-trxs', {
            method: "POST",
            data,
            hideNotification: true
        }, res => setPrepaidTransactions(res))
    }
    const search = (data) => {
        data.StartDateTime = moment(data.StartDateTime).add(3, 'h').format('YYYY-MM-DD HH:mm:ss')
        data.EndDateTime = moment(data.EndDateTime).add(3, 'h').format('YYYY-MM-DD HH:mm:ss')
        getTRXs(data)
    }
    const mapIcons = new Map()
    mapIcons.set(1, 'mr-1 fas fa-user-cog')
    mapIcons.set(2, 'mr-1 far fa-credit-card')
    mapIcons.set(3, 'mr-1 fab fa-stack-exchange')
    mapIcons.set(4, 'mr-1 fas fa-qrcode')
    const getType = (typeId) => {
        return mapIcons.get(typeId)
    }
    useEffect(() => {
        getTRXs()
    }, [])
    return (
        <>
            <div className='w-100 d-flex justify-content-center mb-2'>
                <button className="btn ml-2 my-auto my-auto" onClick={toggleFilter}>
                    <i
                    className={`fas fa-filter ${visibleFilter ? 'text-primary' : 'text-secondary'
                        }`}
                    />
                </button>
            </div>
            <PrepaidFilter
                visible={visibleFilter}
                search={search}
                toggleFilter={toggleFilter}
            />
            {/* <div className='card'> */}
                {/* <div className='card-header'>
                    <h5 className='mt-2'>Transakcje</h5>
                </div> */}
                <div className='card-body pt-0'>

                    {prepaidTransactions.length ? (
                        
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Właściciel karty prepaid</th>
                                    {/* <td>Kod wewnętrzny</td> */}
                                    <th className='text-center'>Terminal</th>
                                    <th className='text-center'>Automat</th>
                                    <th className='text-center'>Data / czas</th>

                                    <th className='text-center'>Cena /Nr wyboru/ Status 
                                        <br/> 
                                        <span className='badge badge-success'>Pozytywna</span> <span className='badge badge-danger'>Zwrot</span> <span className='badge badge-warning'>Odmowa</span>
                                         {/* <br/>
                                        <div className='d-flex justify-content-around'>
                                            <div className='fas fa-qrcode'></div>
                                            <div className='far fa-credit-card'></div>
                                            <div className='fas fa-user-cog'></div>
                                            <div className="fab fa-stack-exchange"></div>
                                        </div> */}
                                    </th> 
                                    <th className='text-center'>Karta prepaid</th>
                                    {/* <td></td> */}
                                </tr>
                            </thead>
                            <tbody>
                                {prepaidTransactions && prepaidTransactions.map((trx, idx) => (
                                    <tr key={idx}>
                                        
                                        <td className='text-center'>
                                            {`${String(trx.OwnerData).substring(0, 30)}${trx.OwnerData.length > 30 ? `...` : ``}`}
                                        </td>
                                        {/* <td >{trx.Code}</td> */}
                                        <td className='text-center'>{trx.Terminal}</td>
                                        <td className='text-center'>{trx.MachineFullName}</td>
                                        <td className='text-center'>{trx.CreateDateTime}</td>
                                        <td className='text-center'>
                                            {<div className='d-flex justify-content-center'>
                                                {/* <h6>
                                                    <div className={getType(+trx.PrepaidTypeId)}></div>
                                                </h6> */}
                                                <h6>
                                                    <span className={`badge badge-${trx.Price ? `success` : `danger`}`}>
                                                    {`${Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.Price || 0)} (${trx.ProductNo})`}
                                                    </span>
                                                </h6>
                                            </div>}
                                        </td>
                                        <td className='text-center'>{`${trx.PrintedCode} (${trx.Code})`}</td>
                                        {/* <td >
                                            <Link 
                                                to={`/prepaids/card/${trx.PrepaidId}`}
                                                className=" mr-4 btn btn-outline-primary btn-sm "
                                            >
                                                Podgląd karty
                                            </Link>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : <NoResults/>}
                </div>
            {/* </div>               */}
        </>
    )
}
