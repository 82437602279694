import axios from "axios"
import { NotificationManager } from "react-notifications" 
import lodash from "lodash"

export function getSheet({
    callbackToChangeState = (function() {}),
    name,
    body,
    spinnerTriggerState,
    url
}) {
    callbackToChangeState(spinnerTriggerState)
    let dataNullValues
    let isObj = false
    try {
        isObj = lodash.isObject(spinnerTriggerState)
        if (isObj) {
            dataNullValues = {}
            for (let i  of Object.keys(spinnerTriggerState)) {
                dataNullValues[i] = null
            } 
        } else {
            dataNullValues = null
        }
    } catch (e) {
        console.log(e)
    }
    
    axios({
        method: "POST",
        url: url,
        data: { 
            ...body,
        },
        responseType:'blob'
    }).then((res) => {
            callbackToChangeState(dataNullValues)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
    }).catch(err => {   
        callbackToChangeState(dataNullValues)
        if (err.response.data.message === "jwt malformed") window.location.reload();
        else NotificationManager.error(err.response?.data || err.toString())
    })
}