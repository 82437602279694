import React, { useEffect, useState, useContext, useRef } from "react";
import useFetch from "../../hooks/fetchMSSQL-hook";
import useHttp from "../../hooks/use-http";
import { LangContext } from "../../context/lang-context";
import { API_URL } from "../../config/config";

const Personalization = () => {
  const { fetchMssqlApi } = useFetch();
  const { sendRequest } = useHttp();
  const [paymentsStyle, setPaymentsStyle] = useState();
  const [payment, setPayment] = useState();

  useEffect(() => {
    getSettings();
    getPayment();
  }, []);

  const getSettings = () => {
    fetchMssqlApi("settings", {}, (paymentStyle) => {
      paymentStyle.find((payment) => {
        if (payment.key === "paymentStyle") {
          console.log(JSON.parse(payment.value))
          setPaymentsStyle(JSON.parse(payment.value));
        }
      });
    });
  };

  const getIsEnabled = (matchedValue) => {
    let index = payment.findIndex((item) => item.Name === matchedValue);
    return index !== -1 ? payment[index].Enabled : undefined;
  };

  const onButtonClick = () => {
    sendRequest(
      {
        url: "setting",
        data: {
          key: "paymentStyle",
          imagesBool: true,
          value: JSON.stringify(
            [
              {
                 "Name":"Cashless_Terminal",
                 "Text":"Karta zbliżeniowa",
                 "Title":"Karta zbliżeniowa",
                 "Color":"#0c1010",
                 "Image":"CashlessTerminal.png",
              },
              {
                 "Name":"Coin_Acceptor",
                 "Text":"Wrzutnik monet",
                 "Title":"Wrzutnik monet",
                 "Color":"#0c1010",
                 "Image":"CoinAcceptor.png",
              },
              {
                 "Name":"Cashless_Token",
                 "Text":"Token",
                 "Title":"Token",
                 "Color":"0c1010",
                 "Image":"CashlessToken.png",
              },
              {
                 "Name":"BLIK",
                 "Text":"BLIK",
                 "Title":"Blik",
                 "Color":"0c1010",
                 "Image":"BLIK.png",
              },
              {
                 "Name":"Bill_Acceptor",
                 "Text":"Akceptor banknotów",
                 "Title":"Akceptor banknotów",
                 "Color":"0c1010",
                 "Image":"CoinAcceptor2.png",
              },
              {
                 "Name":"Code",
                 "Text":"Kod",
                 "Title":"Kod",
                 "Color":"#0c1010",
                 "Image":"code.png",
              }
           ]
          ),
        },
        method: "POST",
      },
      (response) => {
        if (response.status === 200) {
          getSettings();
          getPayment();
        }
      }
    );
  };

  const getPayment = () => {
    fetchMssqlApi("payment-types", {}, (payment) => {
      console.log(payment)
      setPayment(payment);
    });
  };

  //TODO: FIX SENDING IMAGES
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = new FormData();

    const updatedPaymentsStyle = paymentsStyle.map((payment, idx) => {
      const name = payment.Name;
      
      console.log(formData.get(`${name}_Photo`))
      return {
        ...payment,
        Text:
          formData.get(`${name}-text`) === null
            ? payment.Text
            : formData.get(`${name}-text`),
        Color:
          formData.get(`${name}-color`) === null
            ? payment.Color
            : formData.get(`${name}-color`),
        Image:
        formData.get(`${name}_Photo`) === null
        ? payment.Image
        : formData.get(`${name}_Photo`).name === "" ? payment.Image : formData.get(`${name}_Photo`).name
      };
    });

    

    const {
      Cashless_Terminal_Photo,
      Coin_Acceptor_Photo,
      Cashless_Token_Photo,
      BLIK_Photo,
      Bill_Acceptor_Photo,
      Code_Photo,
    } = e.target.elements;
    formDataObj.append("key", "paymentStyle");
    formDataObj.append("value", JSON.stringify(updatedPaymentsStyle));

    formDataObj.append("Image", Cashless_Terminal_Photo?.files[0]);
    formDataObj.append("Image", Coin_Acceptor_Photo?.files[0]);
    formDataObj.append("Image", Cashless_Token_Photo?.files[0]);
    formDataObj.append("Image", BLIK_Photo?.files[0]);
    formDataObj.append("Image", Bill_Acceptor_Photo?.files[0]);
    formDataObj.append("Image", Code_Photo?.files[0]);

    sendRequest(
    {
    url: "setting",
    data: formDataObj,
    method: "POST",
    },
    (response) => {
    if (response.status === 200) {
          getSettings();
        }
      }
    );
  };

  const PaymentType = ({
    title,
    name,
    defaultText,
    defaultColor,
    defaultPhoto,
  }) => {
    const { TRL_Pack } = useContext(LangContext);
    const [text, setText] = useState(defaultText);
    const [color, setColor] = useState(defaultColor);
    const [fileName, setFileName] = useState(defaultPhoto);

    const photo = useRef(defaultPhoto);

    useEffect(() => {
      setText(defaultText);
    }, [defaultText]);

    useEffect(() => {
      setColor(defaultColor);
    }, [defaultColor]);

    useEffect(() => {
      setFileName(defaultPhoto);
    }, [defaultPhoto]);

    const handleFileChange = (e) => {
      e.persist();
      const file = photo.current.files[0];
      if (file) {
        setFileName(file.name);
      }
    };

    return (
      <>
        <h5>{title}</h5>
        <div className="row">
          <div className="col-sm">
            <label htmlFor={name + "-text"} className="form-label">
              {TRL_Pack.personalization.text}
            </label>
            <input
              className="form-control form-control-text"
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              name={name + "-text"}
            />
          </div>
          <div className="col-sm">
            <label htmlFor={name + "-color"} className="form-label">
              {TRL_Pack.personalization.color}
            </label>
            <input
              className="form-control form-control-color"
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              name={name + "-color"}
            />
          </div>
          <div className="col-sm">
            <label className="form-label">
              {TRL_Pack.personalization.photo}
            </label>
            <div className="col-sm">
              <input
                type="file"
                className="custom-file-input"
                ref={photo}
                name={name + "_Photo"}
                id={name + "_Photo"}
                onChange={handleFileChange}
                accept="image/x-png"
              />
              <label className="custom-file-label" htmlFor={name + "_Photo"}>
                {fileName ? fileName : TRL_Pack.personalization.chooseFile}
              </label>
            </div>
          </div>
          <div className="col-sm pt-3 pt-sm-0">
            <img
              src={`${API_URL}/images/multivend/personalization/${fileName}`}
              alt={fileName}
              style={{ width: 100, height: 100 }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 25 }}></div>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12 mb-2">
        <form onSubmit={onSubmit}>
          {paymentsStyle !== null  &&
            paymentsStyle?.map((paymentStyle, index) => (
              <React.Fragment key={index}>
                {payment != null && getIsEnabled(paymentStyle.Name) ? (
                  <PaymentType
                    title={paymentStyle.Title}
                    name={paymentStyle.Name}
                    defaultText={paymentStyle.Text}
                    defaultColor={paymentStyle.Color}
                    defaultPhoto={paymentStyle.Image}
                  />
                ) : null}
              </React.Fragment>
            ))}
          <div style={{ marginTop: 25 }}>
            <input
              className="btn btn-success mr-3"
              type="submit"
              value="Submit"
            />
            <input
              className="btn btn-secondary mx-auto"
              type="button"
              onClick={onButtonClick}
              value="Przywróć domyślne"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Personalization;
