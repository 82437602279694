
const Config = [
  {
    name: "LEDy",
    taskType: "LED",
    options: [
      {
        name: "Włącz",
        value: "Leds on",
      },
      {
        name: "Wyłącz",
        value: "Leds off",
      },
    ],
  },
  {
    name: "Stan fotoczujki",
    taskType: "PHOTO_SENSOR_STATUS",
    options: [
      {
        name: "Sprawdź",
      },
    ],
  },
  
  {
    name: "Zmiana parametru",
    taskType: "PARAM_CHANGE",
    options: [
      {
        name: "Zmień",
        inputType: "select",
        value: 1,
      },
    ],
  },
  {
    name: "Sczytanie parametru",
    taskType: "GET_PARAMETER",
    options: [{ name: "Sczytaj" }],
  },
  {
    name: "Przywracanie ustawień domyślnych",
    taskType: "RECOVER_SETTINGS",
    options: [
      {
        name: "Przywróc",
      },
    ],
  },
  {
    name: "Agregat",
    taskType: "AGGREGATE",
    options: [
      {
        name: "Włącz",
      },
      {
        name: "Wyłącz",
      },
    ],
  },
  {
    name: "Restart maszyny",
    taskType: "RESTART",
    options: [
      {
        name: "Restartuj",
      },
    ],
  },

];

const Parameters = [
  { address: 0, desc: "Steps for first floor", min: 0, max: 10000 },
  { address: 1, desc: "Steps for second floor", min: 0, max: 10000 },
  { address: 2, desc: "Steps for third floor", min: 0, max: 10000 },
  { address: 3, desc: "Steps for fourth floor", min: 0, max: 10000 },
  { address: 4, desc: "Steps for fifth floor", min: 0, max: 10000 },
  { address: 5, desc: "Steps for sixth floor", min: 0, max: 10000 },
  { address: 6, desc: "Steps for seventh floor", min: 0, max: 10000 },
  { address: 7, desc: "Steps for eighth floor", min: 0, max: 10000 },
  { address: 8, desc: "Drop sensor attenuation", min: 0, max: 80 },
  { address: 9, desc: "Drop sensor direction", min: 0, max: 2 },
  { address: 10, desc: "Out-time for opening door", min: 0, max: 600 },
  {
    address: 11,
    desc: "Locking time after picking up goods",
    min: 0,
    max: 100,
  },
  { address: 12, desc: "Motor 1 max am", min: 0, max: 500 },
  { address: 13, desc: "Motor 1 min am", min: 0, max: 500 },
  { address: 14, desc: "Motor 1 Am when disconnect", min: 0, max: 500 },
  { address: 15, desc: "Motor 2 max am", min: 0, max: 500 },
  { address: 16, desc: "Motor 2 min am", min: 0, max: 500 },
  { address: 17, desc: "Motor 2 am when disconnect", min: 0, max: 500 },
  { address: 18, desc: "Motor 3 max am", min: 0, max: 500 },
  { address: 19, desc: "Motor 3 min am", min: 0, max: 500 },
  { address: 20, desc: "Motor 3 am when disconnect", min: 0, max: 500 },
  { address: 21, desc: "Motor 4 max am", min: 0, max: 500 },
  { address: 22, desc: "Motor 4 min am", min: 0, max: 500 },
  { address: 23, desc: "Motor 4 am when disconnect", min: 0, max: 500 },
  { address: 24, desc: "Motor 5 max am", min: 0, max: 500 },
  { address: 25, desc: "Motor 5 min am", min: 0, max: 500 },
  { address: 26, desc: "Motor 5 am when disconnect", min: 0, max: 500 },
  { address: 27, desc: "1 - spring slot, 2 - belt slot", min: 1, max: 2 },
  {
    address: 28,
    desc: "0 - Have inspection when delivery goods, 1 - No inspection when delivery goods",
    min: 0,
    max: 1,
  },
  { address: 29, desc: "Motor max volts", min: 0, max: 100 },
  { address: 30, desc: "Buffer steps when up", min: 0, max: 1500 },
  { address: 31, desc: "Buffer speed when up", min: 1, max: 40 },
  { address: 32, desc: "Speed when down", min: 1, max: 100 },
  { address: 33, desc: "Decrease steps when down", min: 0, max: 1500 },
  { address: 34, desc: "Speed for backing up origin", min: 1, max: 20 },
  { address: 35, desc: "Steps for backing up origin", min: 100, max: 1500 },
  { address: 36, desc: "Speed for departure drop sensor", min: 1, max: 20 },
  {
    address: 37,
    desc: "Working steps from top to bottom when goods stuck",
    min: 1,
    max: 1000,
  },
  {
    address: 38,
    desc: "Working steps from bottom to top when goods stuck",
    min: 1,
    max: 1000,
  },
  {
    address: 39,
    desc: "Wave filtering time for drop sensor in ms",
    min: 0,
    max: 1000,
  },
  {
    address: 40,
    desc: "Max steps exceed highest dropsensor (Judge if this floor with sensor)",
    min: 200,
    max: 1500,
  },
  {
    address: 41,
    desc: "Max time when hiding sensor (100ms) judge that stuck beyond time",
    min: 0,
    max: 1000,
  },
  {
    address: 42,
    desc: "0 - Have push board, 1 - No push board",
    min: 0,
    max: 1,
  },
  { address: 43, desc: "Current degree (0.1 C)", min: -90, max: 100 },
  {
    address: 44,
    desc: "When with belt machine, if need to clear hiding sensor time, 1 stands for clear",
    min: 0,
    max: 1,
  },
  {
    address: 45,
    desc: "Knock switch, 1 - able, 0 - disable",
    min: 0,
    max: 1,
  },
];

export { Config, Parameters};
