import React, { useState, useContext, useEffect } from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import formHook from '../../hooks/form-hook'
import NoResults from '../../components/NoResults/NoResults'
import FormSkel from '../../components/Modals/FormSkel'
import OnceSelector from '../../components/OptionSelector/OnceSelector'
import { NotificationManager } from 'react-notifications'


const automatTypes = [
    {name: "Snack", value: false },
    {name: "Kawowy", value: true },
]
const actionType = [
    {name: "Brak"             , value: null , colour: "secondary" },
    {name: "Rozładowanie (RO)", value: false, colour: "danger"    },
    {name: "Doładowanie (DO)" , value: true , colour: "primary"   },
]



export default function CorrectionReason() {
    const { form, openForm, closeForm } = formHook()
    const { fetchMssqlApi } = fetchHook()
    const { setHeaderData } = useContext(NavigationContext)
    const [correctionReasons, setCorrectionReasons] = useState(null)
    const defaultFormState = {
        IsActive: undefined,
        IsCoffee: false,
        Name: "",
        IsLoad: null
    }
    const [formState, setFormState] = useState()

    const getCorrectionReasons = () => {
        fetchMssqlApi('/correction-reasons', {}, res => {
            setCorrectionReasons(res)
        })
    }
    const handleChangeAutomatType = () => setFormState(prev => ({...prev, IsCoffee: !prev.IsCoffee}))
    const handleChangeActionType = (value) => setFormState(prev => ({...prev, IsLoad: value})) 
    const handleFocusInput = () =>  setFormState(prev => ({...prev, Name: ""}))
    const handleChangeName = (evt) => {
        const value = evt.target.value
        setFormState(prev => ({...prev, Name: value}))
    } 
    const returnCheckbox = (value) => (
        <div className='h-100 w-100 justify-content-center align-items-center'>
            <input
                type='checkbox'
                onChange={() => {}}
                checked={value}
            />
        </div>
    )
    const checkDeactivateButtonAndReturnData = (evt) => {
        const {elements} = evt.target 
        if (!formState.CorrectionReasonId) return formState

        let IsActive 
        
        // check evt.target.elements has isActive checkbox (when you create new correction reason 
        // "isActive" don`t render and broke app) 
        if(Object.hasOwn(elements, 'isActive')) IsActive = elements.isActive.value === "true"
        

        const prevCr = correctionReasons.find(i => i.CorrectionReasonId === formState.CorrectionReasonId)
        if (!prevCr) return formState

        // check if initial isActive the same to actual - return actual object. 
        // If not - return initial object with changed isActive status
        return IsActive === prevCr.IsActive ? formState : ({...prevCr, IsActive: !prevCr.IsActive}) 
    }
    const handleSubmit = (evt) => {
        evt.preventDefault()
        // check name is unique
        if (
            correctionReasons
            .filter(i => i.CorrectionReasonId !== formState.CorrectionReasonId)
            .filter(i => String(i.Name).trim() === String(formState.Name).trim())
            .length
        ) {
            NotificationManager.error("Nazwa musi być unikalna")
            setFormState(prev => ({...prev, Name: ""}))
            return
        }
        
        fetchMssqlApi(`correction-reason/${formState.CorrectionReasonId}`, {
            method: "POST",
            data: checkDeactivateButtonAndReturnData(evt),
        }, () => {
            setFormState(defaultFormState)
            getCorrectionReasons()
            closeForm()
        })
    }
    useEffect(() => {
        !form && setFormState(defaultFormState) 
    }, [form])
    useEffect(() => {
        formState?.CorrectionReasonId && openForm()(formState.CorrectionReasonId)
    }, [formState])
    useEffect(() => {
        setHeaderData({text: "Powody korekt"})
        getCorrectionReasons();
    }, [])
    return (
        <div className='container'>
            <div className='card'>
                <div className='card-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                        Powody korekt
                        <button onClick={openForm(null)} className="btn text-nowrap btn-sm btn-outline-secondary">
                            <i className="fas fa-plus" />
                        </button>
                    </div>
                </div>
                <div className='card-body'>
                    {correctionReasons ?
                        (<table className="table table-striped">
                            <thead>
                                <tr className="justify-content-center">
                                    <th> <div className='d-flex justify-content-center'>Nazwa</div></th>
                                    <th> <div className='d-flex justify-content-center'>Aktywna </div></th>
                                    <th> <div className='d-flex justify-content-center'>RO </div></th>
                                    <th> <div className='d-flex justify-content-center'>DO </div></th>
                                    <th> <div className='d-flex justify-content-center'>Automat kawowy </div></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {correctionReasons.map((cr, idx) => (
                                    <tr key={idx}>
                                        <td>{cr.Name}</td>
                                        <td className='text-center'>
                                            {returnCheckbox(cr.IsActive)}
                                        </td>
                                        <td className='text-center'>
                                            {returnCheckbox(cr.IsLoad === false)}
                                        </td>
                                        <td className='text-center'>
                                            {returnCheckbox(cr.IsLoad === true)}
                                        </td>
                                        <td className='text-center'>
                                            {returnCheckbox(cr.IsCoffee)}
                                        </td>
                                        <td className='d-flex justify-content-end border-0'>
                                            <button className="btn btn-outline-primary btn-sm pb-1"
                                                onClick={() => {
                                                    let f = correctionReasons && correctionReasons.find(i => i.CorrectionReasonId === cr.CorrectionReasonId) || defaultFormState;
                                                    setFormState(f)
                                                }}
                                            >
                                                Edytuj
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>)
                    : (<NoResults/>)}
                </div>
            </div>
            {/* headerText
            , noFooter
            , classes
            , handleClose
            , children
            , style
            , disableSubmit
            , acceptForm
            , data
            , informationForm
            , deleteDisable
            , btnWords={activate: "Aktywuj", deactivate: "Deaktywuj"}} */}
            {
                form && (
                    <FormSkel
                        headerText={!formState.CorrectionReasonId ? "Nowy powód korekty" : "Powód korekry" }
                        handleClose={closeForm} 
                        deleteDisable={!formState.CorrectionReasonId}
                        disableSubmit={!formState.Name}
                        data={formState}
                        acceptForm={false}
                        handleSubmit={handleSubmit}
                    >
                        <form onSubmit={handleSubmit} id="modal-form" autoComplete="off">
                            <div className="form-group"> 
                                <label className="h6">Nazwa</label>
                                <input
                                    onFocus={handleFocusInput}
                                    name="name"
                                    onKeyDown={evt => {
                                    if(evt.key === "Enter") evt.preventDefault()
                                    }}
                                    onChange={handleChangeName}
                                    minLength={1}
                                    required={true}
                                    value={formState.Name}
                                    className={`form-control ${!formState?.Name && `invalid-input`}`}
                                    placeholder='Wpisz nazwę...'
                                />
                            </div>
                            <div className="form-group"> 
                                <label className="h6">Dokument przesunięcia towaru</label>
                                <OnceSelector
                                    options={actionType}
                                    setState={handleChangeActionType}
                                    defaultValue={formState.IsLoad}
                                />
                            </div>
                            <div className="form-group"> 
                                <div className='d-flex'>
                                    <h6 className='mb-0'>Automat kawowy</h6>
                                    <input
                                        checked={formState.IsCoffee}
                                        onChange={handleChangeAutomatType}
                                        className='mr-3 ml-3'
                                        type='checkbox'
                                        name='isCoffee'/>
                                </div>
                            </div>
                        </form>
                    </FormSkel>
                )
            }
        </div>
    )
}
