    import React, { useState, useEffect, useContext } from 'react'
    import { LangContext } from '../../context/lang-context'

    import sampleProduct from '../../assets/images/sample-product.svg'

    import useFetch from '../../hooks/fetchMSSQL-hook'

    import { API_URL } from '../../config/config'
    import FormSkel from './FormSkel'
    import clearInput from '../../util/cleanInput'

    export default ({ form, productData, getProducts, handleClose, deleteProduct,  eans}) => {
    const { fetchMssqlApi } = useFetch()
    const {
        TRL_Pack: { products }
    } = useContext(LangContext)
    const initialValue = (productData) => {
        if (productData) { return `${API_URL}/${productData.Image}` }
        else {
        return null
        }
    }
    const shared = { ean: true, image: true, name: true, desc: true }
    const notShared = { ean: true, image: false, name: false, desc: false, vatId: false, lastPriceNetto: false }

    const [disabled] = useState(() => {
        if (productData) {
        if (productData.IsSubscribed) {
            return shared
        }
        else {
            return notShared
        }
        }
    }
    )
    const [data, setData] = useState({ 
        ean: productData?.EAN || "",
        Image: productData?.Image || null,
        Name: productData?.Name || "", 
        Description: productData?.Description || "", 
        vatId: productData?.VATId || 4, 
        lastPriceNetto: productData?.LastPriceNetto || "", 
        IngredientQuantity: productData?.IsIngredient ? productData.IngredientQuantity : "", 
        IsIngredient: productData?.IsIngredient || false,
        ProductGroup:  "", 
        IsActive: productData?.IsActive || true
    });
    const [image, setImage] = useState(initialValue(productData))
    const [categoriesSection, setCategoriesSection] = useState(false)
    const [changedImage, setChangedImage] = useState(false)
    const toggleCategoriesSection = () => setCategoriesSection(prev => !prev)
    const [productCategories, setProductCategories] = useState({
        initial: [],
        added: [],
        deleted: [],
        data: []
    })
    // const [productsVat, setProductsVat] = useState({});
    const [isIngredient, setIsIngredient] = useState(productData && productData.IsIngredient || false)
    const [allVat, setVat] = useState([])
    const [productsList, setProductsList] = useState([])
    const getVat = () => {
        fetchMssqlApi('products-vat', {}, data => setVat(data))
    }
    const [allGroups, setAllGroups] = useState([])
    const getAllGroups = () => {
        fetchMssqlApi('/products/groups', {}, groups => setAllGroups(groups))
    }
    // const getProductGroups = 
    // const [ingredient, setIngredients] = useState(productData?.Ingredients || [])

    // const addIngredient = e => {
    //   e.preventDefault()
    //   setIngredients(prev => [...prev, {
    //     IngredientId: ++ingredients.length,
    //     IsIngredient: null,
    //     IngredientQuantity: null
    //   }])}
    // const changeIngredients = (evt, id) => {
    //   const {name, value} = evt.target
    //   const ingredient = ingredients.find(ingredient => ingredient.IngredientId === id)
    //   ingredient[name] = value
    //   setIngredients(ingredients => [...ingredients.filter(ingredient => ingredient.IngredientId !== id), ingredient])
    //   console.log(ingredients)
    // } 
    const getProductCategories = () => {
        fetchMssqlApi(`categories/${productData.EAN}`, {}, productCategories => {
        setProductCategories(prev => ({
            ...prev,
            data: productCategories,
            initial: productCategories.map(category => category.CategoryProductId)
        }))
        })
    }
    const getProductsList = () => {
        fetchMssqlApi('products-list', {}, res => setProductsList(res))
    }
    const toggleProductCategory = (id, categoryId) => () => {
        setProductCategories(prev => {
        if (prev.deleted.includes(id))
            return { ...prev, deleted: prev.deleted.filter(cId => cId !== id) }
        else if (prev.added.includes(id))
            return { ...prev, added: prev.added.filter(cId => cId !== id) }
        else if (categoryId != null)
            return { ...prev, deleted: prev.deleted.concat(id) }
        else return { ...prev, added: prev.added.concat(id) }
        })

    }
    const handleChangeImage = evt => {
        evt.preventDefault()

        if (evt.target.files[0]) {
        setChangedImage(true)
        const reader = new FileReader()
        reader.readAsDataURL(evt.target.files[0])
        reader.onloadend = () => setImage(reader.result)
        } else { setImage(null); setChangedImage(false) }
    }
    
    const handleSubmit = evt => {
        evt.preventDefault()

        const unFormat  = (value) => {
        value = value.replace("zł", "");
        value = value.replace(",", ".")
        value = parseFloat(value);
        return(value);
        }
        const { ean, name, image, description, vatId, lastPriceNetto, ingredientQuantity, isActive, productGroup} = evt.target.elements
        console.log(vatId)
        const group = productGroup.value && allGroups.find(group => group.Name === productGroup.value) || null
        const groupId = group && group.ProductGroupId
        // if(productGroup.value)
        const formData = new FormData()
            formData.append('Ean', ean.value)
            formData.append('Name', name.value)
            formData.append('Description', description.value)
            formData.append("IsSubscribed", 0)
            formData.append('vatId', getVATId(vatId.value))
            formData.append('lastPriceNetto', parseFloat(lastPriceNetto.value) || "")
            formData.append('IsIngredient', isIngredient)
            formData.append('IngredientQuantity', ingredientQuantity?.value || "")
            formData.append('ProductGroupId', groupId)
            // formData.append('IsActive', (productData.IsActive))
            // console.log(isActive.value)
            // formData.append("ParentProductGroup", group.value)
        //   if(!productData?.GroupId && group.value && !productData?.ProductGroupId) {
        //   fetchMssqlApi('product/group', {method:"POST", data: {
        //     IsSnack: false,
        //     IsCoffee: false,
        //     DefaultProductId: productData?.ProductId || "",
        //     ParentProductGroup: group.value,
        //     ProductName: name.value
        //   }, hideNotification: true}, () => {})
        // } else if (productData && productData.GroupId !== +group.value) {
        //   fetchMssqlApi(`product/groups/${productData.ProductId}`, {method: "PUT", data: {ParentProductGroupId: group.value}, hideNotification: true}, () => {})
        // } 
        // else if (productData && productData.GroupId && !group.value) {
        //   fetchMssqlApi()
        // }
        if(productGroup.value && !groupId) {
            setData(prev => ({...prev, ProductGroup: ""}))
            alert("Nie znaleziono takiej grupy")
            return
        }
        if(eans.indexOf(ean.value) !== -1) {
            setData(prev => ({...prev, ean: ""}))
            alert("EAN musi być unikalny")
            return
        }
        if(productsList.find(item => item.Name === name.value)) {
            setData(prev => ({...prev, Name: ""}))
            alert("Nazwa musi być unikalna")
            return
        }
        if (image.files[0]) formData.append('Image', image.files[0])
        else if (productData) {
            formData.append('Image', productData.Image)
        }
        let path, method
        if (!productData) {
            path = 'product'
            method = 'POST'
        }
        else if (productData.IsSubscribed) {
            path = 'product'
            method = 'POST'
        } else {
            path = `product/${productData.EAN}`
            method = 'PUT'
        }
        if (form === "new" || !productData.IsSubscribed) {
            fetchMssqlApi(path, { method, data: formData }, () => {
            productCategories.added.forEach(categoryId => {
                fetchMssqlApi('category-product', {
                method: 'POST',
                data: { CategoryId: categoryId, Ean: ean.value},
                hideNotification: true
                })
            })
    
            productCategories.deleted.forEach(categoryId => {
                const { CategoryProductId } = (productCategories.data.find(pc => { if (pc.CategoryId === categoryId) return pc.CategoryProductId }))
                fetchMssqlApi(
                `category-product/${CategoryProductId}`,
                {
                    method: 'DELETE',
                    data: {},
                    hideNotification: true
                }
                )
            })
            handleClose()
            getProducts()

            }
            )
        }
        else {
        if (productCategories.added.length > 0)
            productCategories.added.forEach(categoryId => {
            fetchMssqlApi('category-product', {
                method: 'POST',
                data: { CategoryId: categoryId, Ean: ean.value, }
            })
            })
        else if (productCategories.deleted.length > 0) {
            productCategories.deleted.forEach(categoryId => {
            const { CategoryProductId } = (productCategories.data.find(pc => { if (pc.CategoryId === categoryId) return pc.CategoryProductId }))
            fetchMssqlApi(
                `category-product/${CategoryProductId}`,
                {
                method: 'DELETE'
                }
            )
            })
        }
        handleClose()
        getProducts()
        }
    }
    const selectCategories = (category) => {
        if ((category.CategoryProductId == null) && productCategories.added.includes(category.categoryId)) return "list-group-item-success"
        else if ((category.CategoryProductId != null) && (!productCategories.deleted.includes(category.CategoryId))) return "list-group-item-success"
        else if (productCategories.added.includes(category.CategoryId)) return "list-group-item-success"
        else return ""
    }

    const getVATCode = (vatId) => {
        let code = null;

        if (vatId !== '' && allVat.find(obj => obj.VATId === vatId) !== undefined)
        code = allVat.find(obj => obj.VATId === vatId).Code

        return code;

    }

    const getVATId = (code) => {
        let vatId = null;

        if (code !== '' && allVat.find(obj => obj.Code === code) !== undefined)
        vatId = allVat.find(obj => obj.Code === code).VATId

        return vatId;

    }

    const   disableButton = () => {
        if (productCategories.added.length === 0 && productCategories.deleted.length === 0 && form !== "new" && JSON.stringify(disabled) === JSON.stringify(notShared)) {
        if (data.Description === productData.Description && data.Name === productData.Name && data.VATId === productData.VATId && changedImage === false) {
            return "disabled"
        }
        else {
            return ""
        }
        }
        else if (productCategories.added.length === 0 && productCategories.deleted.length === 0 && form !== "new" && JSON.stringify(disabled) === JSON.stringify(shared)) {
        return "disabled"
        }
    }

    useEffect(() => {
        getVat()
        if (productData) { getProductCategories() }
        getAllGroups()
        getProductsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        < FormSkel
        headerText={productData ? (productData.IsSubscribed === 1 ? products.editProductDisabledHeader : products.editProductHeader) : products.newProductHeader}
        handleClose={handleClose}
        disableSubmit={disableButton()}
        // data={data}
        >
        {/* {form === "new" && <ul className='nav nav-tabs machine-tabs mb-3'>
            <li className="nav-item">
            <button
            className={`nav-link btn ${!isIngredient && 'active'}`}
            onClick={() => setIsIngredient(false)}
            tabIndex="0">
                Produkt
            </button>
            </li>
            <li className="nav-item">
            <button
            className={`nav-link btn ${isIngredient && 'active'}`}
            onClick={() => setIsIngredient(true)}
            tabIndex="0">
                Skladnik
            </button>
            </li>
        </ul>} */}
        
        <div className="text-center">
            
            {(productData || image) && (
            <img alt="#"
                src={image || API_URL + productData.Image}
                onError={evt => (evt.target.src = sampleProduct)}
                width="256"
                height="256"
            />
            )}
        </div>
        <form onSubmit={handleSubmit} id="modal-form" autoComplete="off">
        {/* {isIngredient ? (<> */}
            {/* <div className="form-group">
            <label className="h6">Nazwa skladnika</label>
            <input
                name="name"
                className="form-control"
                onChange={val => { const value = val.target.value; setData((prev) => ({ ...prev, Name: value })) }}
                defaultValue={productData && productData.Name}
                required
                disabled={form === "new" ? "" : disabled.name}
            />
            </div> */}
            {/* <div className='form-group'>
            <label className='h6'>ilość skladika</label>
            <div className='input-group'>
                <input 
                className='form-control'
                name='ingredientQuantity'
                onChange={evt => form !== "new" && setData(prev => ({...prev, IngredientQuantity: evt.target.value}))}
                defaultValue={productData && productData?.IngredientQuantity || ""}
                required
                />
            </div>
            </div> */}
            {/* <div className="form-group">
            <label className="h6">{products.props.image}</label>
            <div className="input-group">
                <div className="custom-file">
                <input
                    type="file"
                    className="custom-file-input"
                    name="image"
                    onChange={handleChangeImage}
                    id="image-upload"
                    accept="image/x-png"
                    disabled={form === "new" ? "" : disabled.name}
                />
                <label className="custom-file-label" htmlFor="image-upload">
                    Choose file
                </label>
                </div>
            </div>
            </div> */}
            {/* </>) */}
            {/* :(<> */}
            <div className="form-group">
            <label className="h6">EAN</label>
            <input
                // type="number"
                name="ean"
                className="form-control"
                defaultValue={productData && productData.EAN}
                disabled={form === "new" ? "" : disabled.ean}
                value={data.ean}
                onChange={val => { const value = val.target.value.replace(/\D/g, ''); setData((prev) => ({ ...prev, ean: value })) }}
                onFocus={() => setData((prev) => ({ ...prev, ean: "" })) }
                
                required
            />
            </div>
            <div className="form-group">
            <label className="h6">{products.props.image}</label>
            <div className="input-group">
                <div className="custom-file">
                <input
                    type="file"
                    className="custom-file-input"
                    name="image"
                    onChange={handleChangeImage}
                    id="image-upload"
                    accept="image/x-png"
                    disabled={form === "new" ? "" : disabled.name}
                />
                <label className="custom-file-label" htmlFor="image-upload">
                    Choose file
                </label>
                </div>
            </div>
            </div>
            <div className="form-group">
            <label className="h6">Nazwa</label>
            <input
                name="name"
                className="form-control"
                onChange={val => { const value = val.target.value; setData((prev) => ({ ...prev, Name: value })) }}
                defaultValue={productData && productData.Name}
                value={data.Name}
                onFocus={() => setData((prev) => ({ ...prev, Name: "" })) }
                required
                disabled={form === "new" ? "" : disabled.name}
            />
            </div>
            <div className="form-group">
            <label className="h6">{products.props.productLastNetto}</label>
            <input
                name="lastPriceNetto"
                className="form-control"
                // onChange={val => { const value = (format.test(val.target.value) || format2.test(val.target.value)) ? val.target.value : "" ; setData((prev) => ({ ...prev, lastPriceNetto: value })) }}
                onChange={val => { const value = val.target.value  ; setData(prev => ({ ...prev, lastPriceNetto: parseFloat(value) })) }}
                defaultValue={productData && productData.lastPriceNetto}
                value={data.lastPriceNetto}
                type='number'
                // defaultValue={productData && new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(productData.LastPriceNetto)}
                onFocus={() => setData((prev) => ({ ...prev, lastPriceNetto: "" })) }
                // required
            />
            </div>
            <div className="form-group">  
            <label className="h6">Stawka VAT</label><br></br>
            <select
                required
                name="vatId"
                className="form-control"
                onChange={val => { const value = getVATId(val.target.value); setData((prev) => ({ ...prev, vatId: value })) }}
            
                
            >
                <option value={null} selected={!data.VATId}></option>
                {allVat.map(e => (
                <option key={e.VATId} value={getVATCode(e.VATId)} selected={e.VATId === productData?.VATId}>{e.Code + "%"}</option>
                ))}
            </select> 

            {/* <input
                name="vatId"
                className="form-control"
                list="vats"
                defaultValue={ productData && getVATCode(productData.VATId)}
                onChange={val => { const value = val.target.key; setData((prev) => ({ ...prev, vatId: value })) }}
                required
            />
            <datalist id="vats">
            {allVat.map(e => (
                <option key={e.VATId} value={getVATCode(e.VATId)}></option>
                ))}
            </datalist> */}
            </div>
            <div className='d-flex'>

            <input type='checkbox' style={{marginBottom: "8px", marginRight: "10px"}}
            onClick={() => setIsIngredient(!isIngredient)}
            disabled={productData}
            checked={isIngredient}/>
            <div className='h6'>Składnik</div>

            </div>
            {isIngredient && (
                    <div className='form-group'>
                    <label className='h6'>Ilość składnika</label>
                    <div className='input-group'>
                        <input 
                        className='form-control'
                        name='ingredientQuantity'
                        onChange={evt =>{ const value = evt.target.value.replace(/\D/g, ''); setData(prev => ({...prev, IngredientQuantity: value}))}}
                        defaultValue={productData && productData?.IngredientQuantity || ""}
                        value={data.IngredientQuantity}
                        onFocus={() => setData((prev) => ({ ...prev, IngredientQuantity: "" })) }
                        
                        // pattern = "[0-9]"
                        required
                        />
                    </div>
                    </div>
            )}
            <div className="form-group">
            <label className="h6">{products.props.description}</label>
            <textarea
                name="description"
                className="form-control"
                rows="4"
                // onChange={val => { const value = val.target.value; setData((prev) => ({ ...prev, Description: value })) }}
                defaultValue={productData && productData.Description}
                disabled={form === "new" ? "" : disabled.desc}

            />
            </div>
            <div className='form-group'>
            <label className="h6">Grupa</label>
            <div className='input-group'>
                <input
                className='form-control'
                name='productGroup'
                onChange={evt => {const value = evt.target.value; setData(prev => ({...prev, ProductGroup: value}))}}
                value={data.ProductGroup}
                defaultValue={""}
                onFocus={() => setData(prev => ({...prev, ProductGroup: ""}))}
                list='productGroups'
                />
                <datalist id='productGroups'>
                {allGroups && allGroups.map((group, idx) => (<option value={group.Name} key={idx}/>))}
                </datalist>
            </div>
            </div>
            {/* {(ingredients.length) ? ingredients.map((ingredient, idx) => (
            <div key={idx}>
                <input name="IsIngredient" placeholder='Wpisz nazwę skladnika' defaultValue={ingredient.IsIngredient} onChange={evt => changeIngredients(evt, ingredient.IngredientId)}/>
                <input name="IngredientQuantity" placeholder='Wpisz ilość skladnika' defaultValue={ingredient?.IngredientQuantity} onChange={evt => changeIngredients(evt, ingredient.IngredientId)}/>
            </div>
            ))
        : ""} */}
            {/* <button onClick={addIngredient}>Dodaj product</button> */}
            {/* {allGroups && (
            <div className="row mt-3 no-gutters categories-section">
                <label className="h6">Grupa</label>
                <select
                    // key={idx}
                    // className={`col-6 pl-3 font-weight-bolder list-group-item selectCategories ${selectCategories(category)}`}
                    name='group'
                    className="form-control"
                    onChange={evt => {
                    const value = evt.target.value
                    setData(prev => ({...prev, ProductGroupId: value}))
                    }}
                >
                    <option value={""} selected={!productData?.ProductGroupId}></option>
                    { allGroups.map((group, idx) => (
                    <option value={group.ProductGroupId} key={idx} selected={productData && productData?.ParentProductGroupId === group?.ProductGroupId}>{group.Name}</option>
                    ))}
                </select>
            </div>
            )} */}
            {form !== "new" ? (
            <button
                type="button"
                className="btn btn-light btn-block border"
                onClick={toggleCategoriesSection}
            >
                <i
                className={`fas ${categoriesSection ? 'fa-chevron-up' : 'fa-chevron-down'
                    } text-muted`}
                />
            </button>
            ) : ""}
            {categoriesSection && (
            <div className="row mt-3 no-gutters categories-section">
                {productCategories.data.map((category, idx) => (

                <div
                    key={idx}
                    className={`col-6 pl-3 font-weight-bolder list-group-item selectCategories ${selectCategories(category)}`}
                    onClick={toggleProductCategory(category.CategoryId, category.CategoryProductId)}
                >
                    {category.Name}
                </div>
                ))}
            </div>
            )}
            
            {/* </>)} */}
        </form>
        </FormSkel >
    )
    }