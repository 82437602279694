import React, { useContext, useEffect, useState } from "react";
import { LangContext } from "../../context/lang-context";

import FormSkel from "./FormSkel";
import { useMemo } from "react";

export default ({
  terminalData,
  machines,
  handleSubmit,
  handleClose,
  abbr,
  customers,
}) => {
  const { TRL_Pack } = useContext(LangContext);

  // console.log(terminalData);
  // console.log(machines)
  const [customer, setCustomer] = useState(
    terminalData
      ? `${terminalData.CustomerName} (${terminalData.Abbreviation})`
      : ""
  );

  const [userMachines, setUserMachines] =
    localStorage.getItem("clientId") === "console"
      ? useState(machines.filter((machine) => machine.Abbreviation === abbr))
      : useState(machines);
  const terminals = ["Vendotek", "PAX", "NAYAX", "NEWLAND"];
  // setUserMachines()
  const handleChangeMachine = (evt) => {
    const { name, value } = evt.target;
    let machineId = null;
    const serNo = Array.isArray(value)
      ? String(value).split("(")[0].trim()
      : value;
    if (
      value !== "" &&
      machines.find((obj) => (obj.MachineSerialNo === serNo) !== undefined)
    )
      machineId = machines.find(
        (obj) => obj.MachineSerialNo === serNo
      )?.MachineId;
    // console.log(evt.target.value)
    // terminalData.MachineFullName = value
    terminalData.MachineId = machineId;
  };
  const handleDelete = () => {}
  const filterMachines = (value) => {
    const customer = customers && customers.find(
      (customer) => `${customer.Name} (${customer.Abbreviation})` === value
    );
    return customer
      ? [...machines].filter(
          (machine) => machine.Abbreviation === customer.Abbreviation
        )
      : [];
  };
  const memoFilterMachines = useMemo(
    () => filterMachines(customer),
    [customer, machines]
  );
  const handleFocusMachine = (evt) => {
    const { name, value } = evt.target;

    let machineId = null;
    evt.target.value = "";
    terminalData.MachineId = machineId;
  };

  const handleChangeUseTrxForSales = (evt) => {
    const { name, type, value, checked } = evt.target;
    if (type == "checkbox" && name === "useTrxForSales") {
      let useTrxForSales = String(checked);

      terminalData.UseTrxForSales = useTrxForSales;
    }
  };
  const handleChangeUseVMCTrx = (evt) => {
    const { name, type, value, checked } = evt.target;
    if (type == "checkbox" && name === "useVMCTrx") {
      let useTrxForSales = String(checked);

      terminalData.UseTrxForSales = useTrxForSales;
    }
  };
  const handleChangeUseGenerateMachine = (evt) => {
    const { name, checked } = evt.target;
    if (name === "useGenerateMachine") {
      terminalData.UseGenerateMachine = checked;
    }
  };

  return (
    <FormSkel
      headerText={terminalData ? "Ustawienia" : "Nowy terminal"}
      handleClose={handleClose}
      data={terminalData ? {IsActive: true} : null} 
      btnWords={{deactivate: "Usuń"}}
    >
      {/* {console.log(userMachines)} */}
      <form onSubmit={handleSubmit} id="modal-form" autoComplete="off">
        {terminalData && (
          <>
            <div className="form-group">
              {localStorage.getItem("clientId") === "console" && (
                <>
                  <label className="h6 primary align-middle m-1">Klient</label>
                  <input
                    type="text"
                    autoComplete="off"
                    form="modal-form"
                    list="usersLookup"
                    className="mt-2 form-control form-control-sm"
                    name="clientName"
                    // defaultValue={`${terminalData.CustomerName} (${terminalData.Abbreviation})`}
                    value={customer}
                    onChange={(evt) => setCustomer(evt.target.value)}
                  />
                  <datalist id="usersLookup">
                    {customers &&
                      customers.map((user, idx) => (
                        <option
                          value={`${user.Name} (${user.Abbreviation})`}
                          key={idx}
                        />
                      ))}
                  </datalist>
                </>
              )}
              <label className="h6 primary align-middle m-1">Automat</label>
              <input
                onFocus={handleFocusMachine}
                className="mt-2 form-control form-control-sm"
                placeholder="Szukaj"
                onChange={handleChangeMachine}
                name="machine"
                defaultValue={terminalData?.MachineFullName}
                list="machinesLookup"
                autoComplete="off"
              />
              <datalist id="machinesLookup">
                {(localStorage.getItem("clientId") === "console"
                  ? memoFilterMachines
                  : machines
                ).map((entry, idx) => (
                  // (localStorage.getItem("clientId") === "console")
                  // ? <option value={entry.Name} key={idx} />
                  // :
                  <option value={entry.Name} key={idx} />
                ))}
              </datalist>
            </div>
            <div className="d-flex flex-row align-items-center ">
              <div>
                <div className="d-inline-block flex-row align-self-center form-check mb-10px">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="useTrxForSales"
                    onChange={handleChangeUseTrxForSales}
                    defaultChecked={terminalData?.UseTrxForSales}
                    value={undefined}
                  />
                  <label className="h6">
                    Rozliczanie sprzedaży przez Transakcje
                  </label>
                </div>

                <div className="d-inline-block flex-row align-self-center form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="useVMCTrx"
                    onChange={handleChangeUseVMCTrx}
                    defaultChecked={terminalData?.UseVMCTrx}
                    value={undefined}
                  />
                  <label className="h6">
                    Rozliczanie sprzedaży przez Transakcje z płyty głównej R24
                    VMC
                  </label>
                </div>
                <div className="d-inline-block flex-row align-self-center form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="useGenerateMachine"
                    onChange={handleChangeUseGenerateMachine}
                    defaultChecked={terminalData?.UseGenerateMachine}
                  />
                  <label className="h6">
                    Automatyczna konfiguracja automatu na podstawie transakcji
                  </label>
                </div>
              </div>

              {/*         
        <div className='d-flex justify-content-center form-check'>
<label className="badge badge-info align-middle m-1">Przed zaznaczeniem - upewnij się, że automat raportuje wszystkie typy transakcji.</label>
</div> */}
              {/*
        <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusPaymentType}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.searchbar}
                    list="PaymentTypeLookup"
                    name={col.name}
                    onChange={handleChangePaymentType}
                    autoComplete="off"
                  />
                  {(<datalist id="PaymentTypeLookup">
                    {paymentTypes.map((entry, idx) => (
                      <option value={entry.Name} key={idx} />
                    )
                    )}
                  </datalist>)
                  }
                </>*/}
            </div>
          </>
        )}
        {!terminalData && (
          <>
            <div className="form-group">
              <label className="h6 primary align-middle m-1">Klient</label>
              <input
                type="text"
                autoComplete="off"
                form="modal-form"
                list="usersLookup"
                className="mt-2 form-control form-control-sm"
                name="clientName"
              />
              <datalist id="usersLookup">
                {customers &&
                  customers.map((user, idx) => (
                    <option
                      value={`${user.Name} (${user.Abbreviation})`}
                      key={idx}
                    />
                  ))}
              </datalist>
              <label className="h6 primary align-middle m-1">
                Typ terminala
              </label>
              <input
                type="text"
                autoComplete="off"
                form="modal-form"
                list="terminalTypeList"
                className="mt-2 form-control form-control-sm"
                name="terminalType"
              />
              <datalist id="terminalTypeList">
                {terminals.map((terminalType, idx) => (
                  <option value={terminalType} key={idx} />
                ))}
              </datalist>
              <label className="h6 primary align-middle m-1">
                Numer seryjny
              </label>
              <input
                type="text"
                // pattern='/S{10}/'
                className="mt-2 form-control form-control-sm"
                form="modal-form"
                autoComplete="off"
                minLength={4}
                name="serialNo"
              />
            </div>
          </>
        )}
      </form>
    </FormSkel>
  )
};
