import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {LangContext} from '../../context/lang-context'

import logo from '../../assets/images/logo-retail24.png'
import logoSm from '../../assets/images/logo-retail24-sm.png'

import "./style.css"

const Sidebar = ({width, navlinks}) => {
    const currentPath = window.location.pathname.slice(10)
    const {TRL_Pack} = useContext(LangContext)
    const ListItem = ({icon, text, path, group, onClick}) => (
        <>
            {width === 'md' ? (
                <li className="mb-3 mx-2">
                    {path ? (
                        <Link
                            to={path}
                            className="nav-link d-flex align-items-center text-center text-dark btn btn-link p-1"
                        >

                            {group != null ? (
                                    <i className={`mr-3 ${icon}`} style={{marginLeft: 20, fontSize: '1.125em'}}/>) :
                                (<i className={`mr-3 ${icon}`} style={{width: 25, fontSize: '1.125em'}}/>)}
                            <span className=
                                      {(currentPath === path) ?
                                          "font-weight-bolder" :
                                          "font-weight-normal"}
                                  style={{textAlign: "start"}}
                            >{text}</span>
                        </Link>
                    ) : (
                        <div
                            onClick={onClick}
                            className="nav-link d-flex align-items-center text-center text-dark btn btn-link p-1"
                        >

                            <i className={`mr-3 ${icon}`} style={{width: 25, fontSize: '1.125em'}}/>
                            <span className="font-weight-normal">{text}</span>
                        </div>
                    )}
                </li>
            ) : (
                <li className="py-2 text-center lista text-truncate">
                    {path ? (
                        <Link
                            to={path}
                            className="nav-link d-flex flex-column text-dark btn btn-link p-1"
                        >
                            <i className={`${icon} mb-1`} style={{fontSize: '1.25em'}}/>
                            <span className="mt-1 small">{text}</span>
                        </Link>
                    ) : (
                        <div
                            onClick={onClick}
                            className="nav-link d-flex flex-column text-dark btn btn-link p-1"
                        >
                            <i className={`${icon} mb-1`} style={{fontSize: '1.25em'}}/>
                            <span className="mt-1 small">{text}</span>
                        </div>
                    )}
                </li>
            )}
        </>
    )

    let list = []

    // Machine
    let machineList = []
    const [collapseMachine, setCollapseMachine] = useState(false)

    // Warehouse
    let warehouseList = []
    const [collapseWarehouse, setCollapseWarehouse] = useState(false)

    // Raport
    let raportList = []
    const [collapseRaport, setCollapseRaport] = useState(false)

    // Konfiguracja
    let configurationList = []
    const [collapseConfiguration, setCollapseConfiguration] = useState(false)

    const handleClick = (type) => {
        switch (type) {
            case 'machine':
                setCollapseMachine(!collapseMachine)
                break
            case 'warehouse':
                setCollapseWarehouse(!collapseWarehouse)
                break
            case 'raport':
                setCollapseRaport(!collapseRaport)
                break
            case 'configuration':
                setCollapseConfiguration(!collapseConfiguration)
                break
            default:
                break
        }
    }


    const GroupItem = ({text, icon, type, collapsed}) => {
        return (
            <>

                <li className="mb-3 mx-2">
                    {width === 'md' ? (

                            <Link onClick={() => handleClick(type)}
                                  className="nav-link d-flex align-items-center text-center text-dark btn btn-link p-1"
                            >
                                <i className={`mr-3 ${icon}`}
                                   style={{width: 25, fontSize: '1.125em'}}/>
                                <span className=
                                          "font-weight-normal"
                                >{text} </span>
                                {collapsed ? (<span className="fas fa-ellipsis-h ml-auto"></span>) : (
                                    <span className="fas fa-ellipsis-v ml-auto"></span>)}

                            </Link>
                        ) :
                        (
                            <Link onClick={() => handleClick(type)}
                                  className="nav-link d-flex flex-column text-dark btn btn-link p-1"
                            >
                                <i className={`mb-1 ${icon}`}
                                   style={{fontSize: '1.25em'}}/>
                                <span className=
                                          "mt-1 small"
                                >{text} </span>
                                {/* {collapsed ? (<span className="fas fa-ellipsis-h ml-auto"></span>) : (<span className="fas fa-ellipsis-v ml-auto"></span>)} */}

                            </Link>
                        )}
                </li>

            </>
        )
    }
    return (
        <div
            className="sidebar d-none d-lg-block sticky-top shadow"
            style={{
                backgroundColor: "#C5D8FF",
                width: width === 'md' ? 250 : 100,
                zIndex: 1,
            }}
        >
            <div
                className="sidebar-img d-flex align-items-center justify-content-center bg-aqua-dark"
                style={{
                    height: 75,
                    backgroundColor: 'rgba(0, 0, 0, 0.05)'
                }}
            >
                {width === 'md' ? (
                    <img src={logo} alt="logo" height="65"/>
                ) : (
                    <img src={logoSm} alt="logo" height="50"/>
                )}
            </div>
            <ul className="list-group list-group-flush pt-3 px-2 list-unstyled ">

                {sessionStorage.getItem('DB_TYPE') === 'mssql' && (
                    <ListItem text={TRL_Pack.navbar.start} path={'/start'} icon={'fas fa-play-circle'}></ListItem>
                )}

                {navlinks.map((navlink, idx) => (
                    navlink.group === 'machine' && (machineList.push(<ListItem key={idx} {...navlink} />)),
                    navlink.group === 'warehouse' && (warehouseList.push(<ListItem key={idx} {...navlink} />)),
                    navlink.group === 'raport' && (raportList.push(<ListItem key={idx} {...navlink} />)),
                    navlink.group === 'configuration' && (configurationList.push(<ListItem key={idx} {...navlink} />)),
                    navlink.group === undefined && (list.push(<ListItem key={idx} {...navlink} />)),
                        null
                ))}

                {machineList.length > 0 && (
                    <GroupItem text={"Maszyny"} type={'machine'} collapsed={collapseMachine} icon={'fas fa-tablet-alt'}
                               onClick={() => setCollapseMachine(!collapseMachine)}/>)}
                {collapseMachine ? (machineList) : (null)}

                {warehouseList.length > 0 && (
                    <GroupItem text={"Magazyn"} type={'warehouse'} collapsed={collapseWarehouse}
                               icon={'fas fa-tablet-alt'} onClick={() => setCollapseWarehouse(!collapseWarehouse)}/>)}
                {collapseWarehouse ? (warehouseList) : (null)}

                {raportList.length > 0 && (
                    <GroupItem text={"Raporty"} type={'raport'} collapsed={collapseRaport} icon={'fas fa-tablet-alt'}
                               onClick={() => setCollapseRaport(!collapseRaport)}/>)}
                {collapseRaport ? (raportList) : (null)}

                {configurationList.length > 0 && (
                    <GroupItem text={"Konfiguracja"} type={'configuration'} collapsed={collapseConfiguration}
                               icon={'fas fa-tablet-alt'}
                               onClick={() => setCollapseConfiguration(!collapseConfiguration)}/>)}
                {collapseConfiguration ? (configurationList) : (null)}
                {list}
            </ul>
        </div>
    )
}

export default Sidebar

