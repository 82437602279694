import React, { useRef, useEffect, useContext } from 'react'
import { LangContext } from '../../context/lang-context'
import onClickAway from '../../util/onClickAway'

export default ({ headerText, noFooter, classes, handleClose, children, style, disableSubmit, acceptForm, data, informationForm, deleteDisable, btnWords={activate: "Aktywuj", deactivate: "Deaktywuj"}, customName, warning}) => {
  const { TRL_Pack } = useContext(LangContext)
  const modalRef = useRef(null)
  useEffect(
    () => onClickAway(modalRef, handleClose),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className="modal fade show d-block" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div ref={modalRef} className="modal-content border-0">
          <div className="modal-header bg-light align-items-center">
            <h6 className="modal-title">{headerText}</h6>
            <button onClick={handleClose} className="btn text-secondary px-2 py-1">
              <i className="fas fa-times" />
            </button>
          </div>
          <div className={`modal-body ${classes}`} style={style}>
            {children}
          </div>
          {!noFooter && (
            // TODO correct styles
            <div className="modal-footer bg-light"
                  style={( 
                  // sessionStorage.getItem("DB_TYPE") === "mssql" ||
                  data?.MachineCount === 0 || (data?.MachineCount === undefined && data?.IsActive !== undefined)) 
                    ? {display: "flex", justifyContent: "space-between"}
                    : {display: "flex", justifyContent: "flex-end"}}>
              {/* { sessionStorage.getItem("DB_TYPE") === "mssql" 
              && headerText === 'Edycja klienta'
              && <button className='btn btn-danger btn-sm' onClick={setState}>Usuń k</button>} */}
              { !acceptForm && data?.IsActive !== undefined && <button 
                  onClick={evt => {
                        const el = document.querySelector("#isActive")
                        if (el.value === "true") return  el.value = "false" 
                        if (el.value === "false")  return  el.value = "true"
                }}
                  // type="submit"
                  form="modal-form"
                  name="isActive"
                  id="isActive"
                  className={
                    [(data.IsActive) ? 'btn btn-danger btn-sm' : 'btn btn-success btn-sm',
                    (data?.MachineCount > 0) ? "d-none" : "d-flex"]
                    .join(" ")}
                  value={data.IsActive}
                  disabled={deleteDisable ? "disabled" : ""}
                  >
                    {(data.IsActive) ? btnWords.deactivate : btnWords.activate}
                  </button> 
                }
              
              {!informationForm && (
                <button type="submit" disabled={disableSubmit ? "disabled" : ""}  className={`btn ${warning ? 'btn-danger' : 'btn-primary'} btn-sm`} form="modal-form">
                  {customName ? customName : (
                    <>
                    {acceptForm ? TRL_Pack.buttons.confirm : TRL_Pack.buttons.save}
                  </>
                  )}
                </button>)
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
