import React, {useState, useEffect, useContext} from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import PrepaidFilter from './PrepaidFilter'
import NoResults from '../../../components/NoResults/NoResults'

export default function PrepaidsUser() {
    const { setHeaderData } = useContext(NavigationContext),
    { fetchMssqlApi } = fetchHook(),
    [prepaids, setPrepaids] = useState([]),
    [visibleFilter, setVisibleFilter] = useState(false)
    const toggleFilter = () => setVisibleFilter(!visibleFilter)

    
    const getPrepaidUsers = () => {
        fetchMssqlApi('prepaids', {}, res => {
            setPrepaids(res)
        })
    }
    const search = (data) => {
        fetchMssqlApi('prepaids-by-params', {method: "POST", data: data, hideNotification: true}, res => {
            setPrepaids(res)    
        })
    }
    useEffect(() => {
        getPrepaidUsers()
        // setHeaderData({text: "Karty pracownicze"})
    }, [])
    return (
        <>
            <div className='w-100 d-flex justify-content-center mb-2'>
                <button className="btn ml-2 my-auto my-auto" onClick={toggleFilter}>
                    <i
                    className={`fas fa-filter ${visibleFilter ? 'text-primary' : 'text-secondary'
                        }`}
                    />
                </button>
            </div>
            <PrepaidFilter
                visible={visibleFilter}
                search={search}
                toggleFilter={toggleFilter}
            />
            <div className='card'>
                <h5 className="card-header">
                    <div className='d-flex justify-content-between mt-1'>
                        Karty prepaid
                        <Link to={`/prepaids/card/0`} className="btn text-nowrap btn-sm btn-outline-secondary"><i className="fas fa-plus" /></Link>
                    </div>
                </h5>
                <div className='card-body overflow-auto' style={{maxHeight: "70%"}}>
                    {prepaids.length ? (
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Wlaściciel karty prepaid
                                    {localStorage.getItem('clientId') === "console" && (<><br/> Klient</>)}</th>
                                    <th className='text-center'>Dodatkowe informacje <br/> Grupa prepaid</th>
                                    <th className='text-center'>Częstotliwość zasilania / Status <br/>Bieżący kredyt / Typ prepaid <br/> Numer karty prepaid</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {prepaids.map((prepaid, idx) => (
                                    <tr key={idx}>
                                        <td className='text-center'>
                                        {localStorage.getItem('clientId') === "console" ? 
                                            (<h6>{prepaid.OwnerData}</h6>)
                                            :
                                            (<h6>{prepaid.OwnerData}</h6>)
                                        }
                                            {localStorage.getItem('clientId') === "console" && (

                                                    <h6>
                                                        {prepaid.CustomerName}
                                                    </h6>
                                                )
                                            }
                                        </td>
                                        <td className='text-center'>
                                            <div>
                                                {prepaid.AdditionalData}
                                            </div>
                                            <div>
                                                <Link to={`group/${prepaid.PrepaidGroupId}`}
                                                    className="mt-1 btn btn-outline-secondary btn-sm "
                                                >
                                                    {prepaid.GroupName}
                                                </Link>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className='d-flex nowrap justify-content-center'>
                                                <h6><span className="badge badge-primary align-middle mr-2">{prepaid.PrepaidTimespanName}</span></h6>
                                                <h6><span className={`badge badge-${+(prepaid.PrepaidStatusId) <= 2 ? `success ` : `danger `}  align-middle`}>{prepaid.PrepaidStatus}</span></h6>
                                            </div>
                                            <div className='d-flex nowrap justify-content-center'>

                                                <h6><span 
                                                className={`badge mr-2 badge-success` }
                                                    >{
                                                        Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((prepaid.CreditValue).toFixed(2)) || "0,00zł"}
                                                    </span>
                                                </h6>
                                                {/* <h6><span className="badge badge-light align-middle">{prepaid.PrepaidGroupId !== "5" ? Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((prepaid.Credit).toFixed(2)) || "0,00zł" : ""}</span></h6> */}
                                                <h6><span className="badge badge-warning align-middle">{prepaid.PrepaidType}</span></h6>
                                            </div>
                                            <div>
                                                <h6>
                                                    {`${prepaid.PrintedCode} (${prepaid.Code})`}
                                                </h6>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end border-0'>
                                                <Link className=" mr-4 btn btn-outline-primary btn-sm "
                                                    to={`card/${prepaid.PrepaidId}`}
                                                    // key={location.id+"btn"}
                                                >
                                                        Edytuj
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : <NoResults/>}
                </div>
            </div>
        </>
    )
}
