import React, { useContext, useEffect, useState } from "react";
import Switch from "../../../components/Buttons/Switch"
import { LangContext } from "../../../context/lang-context";
import useFetch from "../../../hooks/fetchMSSQL-hook";
import useHttp from "../../../hooks/use-http";

const Tasks = () => {
    const { TRL_Pack } = useContext(LangContext);
    const { fetchMssqlApi } = useFetch();
    const { sendRequest } = useHttp();

    const [crontab, setCrontab] = useState();

    const onClick = (e) => {
        e.preventDefault();
        sendRequest({ url: "crontab/post", data: { id: e.target.id }, method: "POST" }, (response) => { })
    }

    const TaskExecuteButton = (props) => (
        <button type="button" onClick={onClick} id={props["id"]} className="btn btn-primary">{TRL_Pack.tasks.execute}</button>
    )

    const getSettings = () => {
        fetchMssqlApi("settings", {}, (settings) => {
            setCrontab(JSON.parse(settings[settings.findIndex(p => p.key == "crontab")].value));
        });
    };


    useEffect(() => {
        getSettings();
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        const {
            loadAllTransactions,
            heartbeat2,
            heartbeat,
            backup,
            evadts,
            syncImages,
            resetMachine,
            resetMachineTime,
            sendEmail,
        } = e.target;


        const data = {
            loadAllTransactions: { Checked: Number(loadAllTransactions.checked) },
            heartbeat2: { Checked: Number(heartbeat2.checked) },
            heartbeat: { Checked: Number(heartbeat.checked) },
            backup: { Checked: Number(backup.checked) },
            evadts: { Checked: Number(evadts.checked) },
            syncImages: { Checked: Number(syncImages.checked) },
            resetMachine: { Checked: Number(resetMachine.checked), Value: resetMachineTime.value },
            sendEmail: { Checked: Number(sendEmail.checked) },
        }

        sendRequest({ url: "setting", data: { key: "crontab", value: data, json: true }, method: "POST" }, (response) => {
            if (response.status === 200) {
                getSettings();
            }
        })
    }

    const TasksList = () => (
        crontab !== undefined ? (
            <div>
                <form onSubmit={onSubmit}>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{TRL_Pack.tasks.name}</th>
                                <th scope="col">{TRL_Pack.tasks.active}</th>
                                <th scope="col"></th>
                                <th scope="col">{TRL_Pack.tasks.singleExecute}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {Object.entries(crontab).map((task, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{translation[task[0]]}</td>
                                    <td style={{ paddingBottom: 20 }}>
                                    {task[0] === "restartMachine" ? (
                                    <Switch defaultChecked={task[1].checked} id={task[0]} />) :(
                                    <Switch defaultChecked={task[1]} id={task[0]} />)}
                                    </td>
                                    <td>{task[0] === "resetMachine" ?
                                        (<input type="time" className="form-control form-control-sm" name="rr" id={`task[0]`} defaultValue={task[1]} />) :
                                        (null)}
                                    </td>
                                    <td style={{ paddingLeft: 30 }}><TaskExecuteButton/></td>
                                </tr>
                            ))} */}
                            <tr key={1}>
                                <th scope="row">{1}</th>
                                <td>{TRL_Pack.tasks.loadAllTransactions}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.loadAllTransactions.Checked} id="loadAllTransactions" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"loadAllTransactionsClick"} /></td>

                            </tr>
                            <tr key={2}>
                                <th scope="row">{2}</th>
                                <td>{TRL_Pack.tasks.heartbeat2}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.heartbeat2.Checked} id="heartbeat2" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"heartbeat2Click"} /></td>

                            </tr>
                            <tr key={3}>
                                <th scope="row">{3}</th>
                                <td>{TRL_Pack.tasks.heartbeat}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.heartbeat.Checked} id="heartbeat" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"heartbeatClick"} /></td>

                            </tr>
                            <tr key={4}>
                                <th scope="row">{4}</th>
                                <td>{TRL_Pack.tasks.backup}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.backup.Checked} id="backup" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"backupClick"} /></td>

                            </tr>
                            <tr key={5}>
                                <th scope="row">{5}</th>
                                <td>{TRL_Pack.tasks.evadts}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.evadts.Checked} id="evadts" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"evadtsClick"} /></td>

                            </tr>
                            <tr key={6}>
                                <th scope="row">{6}</th>
                                <td>{TRL_Pack.tasks.syncImages}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.syncImages.Checked} id="syncImages" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"syncImagesClick"} /></td>

                            </tr>
                            <tr key={7}>
                                <th scope="row">{7}</th>
                                <td>{TRL_Pack.tasks.resetMachine}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.resetMachine.Checked} id="resetMachine" />
                                </td>
                                <td>
                                    <input type="time" className="form-control form-control-sm" name="rr" id={"resetMachineTime"} defaultValue={crontab.resetMachine.Value} />
                                </td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"resetMachineClick"} /></td>

                            </tr>
                            <tr key={8}>
                                <th scope="row">{8}</th>
                                <td>{TRL_Pack.tasks.sendEmail}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.sendEmail.Checked} id="sendEmail" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"sendEmailClick"} /></td>

                            </tr>
                            <tr key={9}>
                                <th scope="row">{9}</th>
                                <td>{TRL_Pack.tasks.photosensor}</td>
                                <td style={{ paddingBottom: 20 }}>
                                    <Switch defaultChecked={crontab.sendEmail.Checked} id="photosensor" />
                                </td>
                                <td></td>
                                <td style={{ paddingLeft: 30 }}><TaskExecuteButton id={"photosensor"} /></td>

                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginTop: 15 }}>
                        <input
                            className="btn btn-success mx-auto"
                            type="submit"
                            value={TRL_Pack.advancedConfig.saveChangdes}
                        />
                    </div>
                </form>
            </div>
        ) : (null)
    )

    return (
        <div className="row">
            <div className="col-12 mb-2">
                <TasksList />
            </div>
        </div>
    );

}

export default Tasks;