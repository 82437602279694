import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'
import { NotificationManager } from 'react-notifications'

const ROS = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)

  const [ROS, setROS] = useState(null)

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Pokazuj zerowe PA',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 2,
        name: 'Telemetria',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 3,
        name: 'Maszyna',
        searchable: true,
        sortable: true,
        type: 'lookup',
        MachineId: null
      },
      {
        id: 4,
        name: 'Serwisant',
        searchable: true,
        sortable: true,
        type: 'lookup',
        CreateUserId: null
      }, {
        id: 5,
        name: 'Zakres czasowy',
        searchable: false,
        sortable: false,
        type: 'datetimeRange',
        dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'),
        dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
      }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('rosFilter')) {
      return JSON.parse(localStorage.getItem('rosFilter'));
    }
    return defaultFilter;
  })

  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);

    //    search();
    getROS(moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'), moment().format('YYYY-MM-DDT23:59:59'), null, null, null);
  }

  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let isZeroTotal = null;
    if (JSON.parse(columns[0].selectbar == 'false'))
      isZeroTotal = false;
    else if (JSON.parse(columns[0].selectbar == 'true'))
      isZeroTotal = true;

    let isTelemetry = null;
    if (JSON.parse(columns[1].selectbar == 'false'))
      isTelemetry = false;
    else if (JSON.parse(columns[1].selectbar == 'true'))
      isTelemetry = true;

    let machineId = null;
    if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
      machineId = columns[2].MachineId;

    let createUserId = null;
    if (JSON.parse(columns[3].CreateUserId != '') && JSON.parse(columns[3].CreateUserId != null))
      createUserId = columns[3].CreateUserId;

    //set default create from / to date time
    let fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (JSON.parse(columns[4].dateTimeRangeFrom != '') && JSON.parse(columns[4].dateTimeRangeFrom != null))
      //TODO: parse manually entered(?)
      fromCreateDateTime = columns[4].dateTimeRangeFrom.replace('T', ' ');

    if (JSON.parse(columns[4].dateTimeRangeTo != '') && JSON.parse(columns[4].dateTimeRangeTo != null))
      //TODO: parse manually entered(?)
      toCreateDateTime = columns[4].dateTimeRangeTo.replace('T', ' ');

    let machineTags = null;
    if (JSON.parse(filter.activeTags != '') && JSON.parse(filter.activeTags != null))
      machineTags = filter.activeTags;
    //TODO: parse machineTags(?)
    if (!moment(columns[4].dateTimeRangeFrom).isValid() || 
    !moment(columns[4].dateTimeRangeTo).isValid()) {
        NotificationManager.error("Wybierz poprawną datę")
        fromCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
        toCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
    }
    getROS(fromCreateDateTime, toCreateDateTime, machineTags, machineId, createUserId);
  }

  const getROS = (fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'), toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59'), machineTags = null, machineId = null, createUserId = null) => {
    fetchMssqlApi('inm-ros', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        fromCreateDateTime: fromCreateDateTime,
        toCreateDateTime: toCreateDateTime,
        machineTags: machineTags,
        machineId: machineId,
        createUserId: createUserId
      }
    }, response => {
      setROS(response);
      console.log(response)
    })
  }


  useEffect(() => {
    getROS()
    setHeaderData({
      text: TRL_Pack.ro.ros
    });
  }, [])

  useEffect(() => localStorage.setItem('rosFilter', JSON.stringify(filter)), [
    filter
  ])


  const sortRows = (a, b) => {
    /*const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(salesdoc[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'text' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col].replace(',', ''))
      valueB = Number(b[col].replace(',', ''))

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    */
  }

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }

  const getUI = () => {
    if (ROS != null) {
      let temp = JSON.parse(JSON.stringify(ROS));

      // console.log(salesdoc)

      return (
        <div>
          <div className='container-xl'>
            <Pagination
              {...{

                totalItems: ROS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: temp,
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: true
                  }}
                />
              </>
            )}
            <div className="card">
              <h5 className="card-header">
                {TRL_Pack.ro.ros} {/* TO DO: Add translations*/}
              </h5>
              <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>

                {ROS.length ? (
                  <table className="table table-striped">
                    <thead>
                      <tr className="justify-content-center">
                        <td>RO <br />Czas zapisu, Żródło</td>
                        <td>{TRL_Pack.wi.MachineFullName}</td>
                        <td>
                          Rozładowanie
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {ROS
                        .sort(sortRows)
                        // .filter(reportFilter)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                {entry.RO !== '' && (<Link to={`/ro/${entry.MachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-danger">{entry.RO}</Link>)}
                                <div className="btn">{entry.Created}</div>
                              </div>
                            </td>
                            <td className="d-flex flex-column justify-content-center">
                              <Link to={`/machine/${entry.MachineId}`} className="btn btn-sm btn-outline-link">{entry.MachineFullName}</Link></td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              {entry.UnloadQuantity !== null && (<div className="btn text-nowrap">{entry.UnloadQuantity} szt.</div>)}
                            </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )
}

export default ROS;