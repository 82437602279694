import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import useHttp from '../../hooks/use-http';
import { LangContext } from '../../context/lang-context';

import Table from '../../components/Table';

const MachineEvadts = ({ machineId, customerId }) => {
    const [transactions, setTransactions] = useState([]);
    const { sendRequest } = useHttp();
    const { TRL_Pack } = useContext(LangContext);

    const date = new Date();
    const endDateTime = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + (date.getDate())).slice(-2)}`;
    const priorDate = new Date(new Date().setDate(new Date().getDate() - 31));
    const startDateTime = `${priorDate.getFullYear()}-${('0' + (priorDate.getMonth() + 1)).slice(-2)}-${('0' + (priorDate.getDate())).slice(-2)}`;

    const [inputValues, setInputValues] = useState({
        startDateTime,
        endDateTime
    });

    const getTransactionsResponse = useCallback(transactions => {
        setTransactions(transactions.data);
    }, []);

    useEffect(() => {
        const url = `transactions?clientId=${customerId}&machineId=${machineId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;
        sendRequest({ url: url }, getTransactionsResponse);
    }, [customerId, machineId, startDateTime, endDateTime, getTransactionsResponse]);

    const submitHandler = e => {
        e.preventDefault();

        // Send transactions request with specified date range
        const url = `transactions?clientId=${customerId}&machineId=${machineId}&startDateTime=${inputValues.startDateTime}&endDateTime=${inputValues.endDateTime}`;
        sendRequest({ url: url }, getTransactionsResponse);
    };

    const changeHandler = e => {
        const { name, value } = e.target;

        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const groupedVendotekTransactions = _.groupBy(transactions, 'Audit'); // Vendotek transactions grouped by audit

    const tableBody = Object.keys(groupedVendotekTransactions).map(audit => {
        const groupedVmcTransactions = _.groupBy(groupedVendotekTransactions[audit], 'CreateDateTime'); // Vmc transactions grouped by vendotek create date time

        const transaction = groupedVendotekTransactions[audit][0]; // Get grouped transaction details

        return <tr key={audit}>
            {/* <td>{`${transaction.Year}-${('0' + transaction.Month).slice(-2)}-${('0' + transaction.Day).slice(-2)}`}</td> */}
            <td>{new Date(transaction.Audit).toLocaleString()}</td>
            <td>{transaction.Quantity}</td>
            <td>{transaction.TotalPrice} PLN</td>
            {transaction.CreateDateTime ? <td className='p-0'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Cena</th>
                            <th>Status</th>
                            <th>Data utworzenia</th>
                            <th>VMC</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(groupedVmcTransactions).map((vendotekCreateDateTime, i) => {
                            const transaction = groupedVmcTransactions[vendotekCreateDateTime][0];

                            return <tr key={i}>
                                <td>{transaction.VendotekPrice / 100} PLN</td>
                                <td>{transaction.VendotekStatus}</td>
                                <td>{new Date(transaction.CreateDateTime).toLocaleString()}</td>
                                {transaction.CreateDateTime ? <td>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Cena</th>
                                                <th>Status</th>
                                                <th>Data zmian</th>
                                                <th>Numer wydania</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {groupedVmcTransactions[vendotekCreateDateTime].map((vmcTransaction, i) => {
                                                return <tr key={i}>
                                                    <td>{vmcTransaction.Price / 100} PLN</td>
                                                    <td>{vmcTransaction.Status}</td>
                                                    <td>{new Date(vmcTransaction.UpdateDateTime).toLocaleString()}</td>
                                                    <td>{vmcTransaction.GiveAwayNo}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </td> : <td>-</td>}
                            </tr>
                        })}
                    </tbody>
                </table>
            </td> : <td> - </td>}
        </tr>
    });

    return (
        <>
            <div className='row mb-3 justify-content-center'>
                <div className='col-auto'>
                    <form className='input-group input-group-sm' onSubmit={submitHandler}>
                        <input
                            type='date'
                            className='form-control border-right-0'
                            name='startDateTime'
                            style={{ maxWidth: 150 }}
                            value={inputValues.startDateTime}
                            onChange={changeHandler}
                            max={endDateTime}
                        />
                        <div className='input-group-prepend'>
                            <label className='input-group-text'>{TRL_Pack.Reports.to}</label>
                        </div>
                        <input
                            type='date'
                            className='form-control border-right-0'
                            name='endDateTime'
                            style={{ maxWidth: 150 }}
                            value={inputValues.endDateTime}
                            onChange={changeHandler}
                            max={endDateTime}
                        />
                        <div className='input-group-append'>
                            <button className='btn bg-white border'>{TRL_Pack.Reports.generate}</button>
                        </div>
                    </form>
                </div>
            </div>
            <Table className='text-nowrap'>
                <thead>
                    <tr>
                        {/* <th>Data</th> */}
                        <th>Audyt</th>
                        <th>Ilość</th>
                        <th>Cena całkowita</th>
                        <th>Vendotek</th>
                    </tr>
                </thead>
                <tbody>
                    {tableBody}
                    {/* {transactions.map((transaction, i) => (
                    <tr key={i}>
                        <td>{`${transaction.Year}-${('0' + transaction.Month).slice(-2)}-${('0' + transaction.Day).slice(-2)}`}</td>
                        <td>{new Date(transaction.Audit).toLocaleString()}</td>
                        <td>{transaction.Quantity}</td>
                        <td>{transaction.TotalPrice}</td>
                    </tr>
                ))} */}
                </tbody>
            </Table>
        </>
    );
};

MachineEvadts.propTypes = {
    machineId: PropTypes.number,
    customerId: PropTypes.string
};

export default MachineEvadts;