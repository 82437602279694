
class GetId{
    constructor(machineName = null ,
        serialNo = null,
        location = null,
        machineType = null,
        maintenanceName = null) {
            this.machineName = machineName
            this.serialNo = serialNo
            this.location  = location
            this.machineType = machineType
            this.maintenanceName = maintenanceName}
    locations = []
    machines = []
    users = []
    maintenance = []
    machineTypes = []
    getMachineIdByName(machineName) {
        if(this.machines && machineName) {
            const obj = this.machines.filter(machine => machine.MachineName === machineName)[0] 
            return obj?.MachineId  
        } else return null
    }
    getLocationId(location) {
        if(this.locations && location) {
            const obj = this.locations.filter(location => location.Name === location)[0] 
            return obj?.LocationId
        } else return null
    }
    getMachineIdBySerialNo(serialNo) {
        if(this.machines && serialNo) {
            const obj = this.machines.filter(machine => machine.SerialNo === serialNo)[0]
            return obj?.MachineId
        } else return null
    }
    getMaintenanceId(maintenanceName) {
        if(this.maintenance && maintenanceName) {
            const obj = this.maintenance.filter(user => user.Name === maintenanceName)[0]
            return obj?.MaintenanceTypeId
        } else return null
    }
    getMachineTypesId(machineType) {
        if(this.machineTypes && machineType) {
            const obj = this.machineTypes.filter(user => user.Name === machineType)[0]
            return obj?.MachineTypeId
        } else return null
    }
    get getIsActive() {
        const filter = localStorage.getItem("machinesFilter")
        if(filter ) {
            if(JSON.parse(filter).columns !== null && JSON.parse(filter).columns.length > 6  && JSON.parse(filter).columns[6] !== null && JSON.parse(filter).columns[6].selectbar !== null)
            {
                const value = JSON.parse(filter).columns[6].selectbar
                if(value === "null") return null
                if(value === "true") return true
                if(value === "false") return false
            }
            else return null
        }
        return null
    }
    getPayload() {
        return [
            this.machineName,
            this.serialNo,
            this.location,
            this.machineType,
            this.maintenanceName,
        ]
    }
    getData() {
        return[
            this.machines,
            this.locations,
            this.users,
            this.machineTypes
        ]
    }
}

export default GetId