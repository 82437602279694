import React from 'react'

export default function PaginationExSm ({rowsPerPage, page, handleSwitchPage, rowsCount, toggleFilter, filterVisibility, resetBtnVis, resetFilter}) {
    // *direction: forward | backward
    const ListItem = ({ direction }) => {
        switch (direction) {
            case "forward":
                return (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handleSwitchPage(page+1)}>
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </li>
                )
            case "backward":
                return (
                    <li className="page-item">
                        <button className="page-link" onClick={() => handleSwitchPage(page-1)}>
                            <i className="fas fa-chevron-left"></i>
                        </button>
                    </li>
                )
            default:
                return (
                    <li></li>
                )
        }
    }

    const PaginationBar = () => {
        if (page > 1 && rowsPerPage === rowsCount) {
            return (
                <>
                    <ListItem direction="backward"/>
                    <ListItem direction="forward"/>
                </>
            )
        }
        if (page > 1 && rowsPerPage > rowsCount) {
            return (
                <>
                    <ListItem direction="backward"/>
                </>
            )
        }
        if (page === 1 && rowsPerPage === rowsCount) {
            return (
                <>
                    <ListItem direction="forward"/>
                </>
            )
        }
        return (<></>)
    }
    return (
        <>
            <nav className='"mb-4 d-flex justify-content-center"'>
                <ul className="pagination mb-0">
                    <PaginationBar/>
                </ul>
                {toggleFilter && (
                    <button className="btn ml-2 my-auto" onClick={toggleFilter}>
                        <i
                        className={`fas fa-filter ${filterVisibility ? 'text-primary' : 'text-secondary'}`}
                        />
                    </button>
                )}
                {resetBtnVis && (
                    <button className="btn ml-2 my-auto" onClick={resetFilter}>
                        <i
                        className="fas fa-times-circle" style={{color: "#dc3545"}}
                        />
                    </button>
                )}
                {/* <div>
                    Filter
                </div> */}
            </nav>
        </>
    )
}
