import React, { useEffect, useState } from 'react'
import fetchHook from '../../../../hooks/fetchMSSQL-hook'
import { NotificationManager } from 'react-notifications'
import { useHistory, Prompt } from 'react-router-dom/cjs/react-router-dom.min'
import {Form, FormCheck} from 'react-bootstrap'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../../../assets/images/back_to_list_icon.svg'

export default function CreateMachine({setParam}) {


    // ==================== Constants =======================
    const {fetchMssqlApi} = fetchHook(),
    [machines, setMachines] = useState([]),
    newMachineObj = {
        Name: ""
        , SerialNo: ""
        , IsCalcDiff: false
        , Maintenance: null
        , Location: null
        , MachineType: null
    },
    newMachineDataObj = {
        MaintenanceTypeId: null
        , LocationId: null
        , MachineTypeId: null
    },
    [newMachine, setNewMachine] = useState(newMachineObj),
    [newMachineData, setNewMachineData] = useState(newMachineDataObj),
    [locations, setLocations] = useState([]),
    [maintenances, setMaintenances] = useState([]),
    [machineTypes, setMachineTypes] = useState([]),
    [availableToSave, setAvailableToSave] = useState(false),
    [changed, setChanged] = useState(false),
    [maintenanceType, setMaintenanceType] = useState("SNACK"),
    history = useHistory()
    // ======================================================



    // ==================== Functions =======================
    const getMachinesLocations = () => {
        fetchMssqlApi('locations', {}, (locations) => {
            setLocations(locations)
        })
    }
    const getMaintenancesSnack = () => {
            fetchMssqlApi('maintenances/snack', {}, maintenances =>
            setMaintenances(prevMaintenances => [...prevMaintenances, ...maintenances]))
    }
    const getMaintenancesCoffee = () => {

            fetchMssqlApi('maintenances/coffee', {}, maintenances =>
            setMaintenances(prevMaintenances => [...prevMaintenances, ...maintenances]))
    }
    const getMachinesList = () => {
        fetchMssqlApi('machines-list',{}, res => {
            setMachines(res)
        })
    }
    const getMachineTypes = () => {
        fetchMssqlApi('machine-types-manufacturers', {}, res => {
            setMachineTypes(res)
        })
    }
    const handleChangeMaintenanceType = () => {
        console.log("click")
        maintenanceType === "COFFEE" ? setMaintenanceType("SNACK") : setMaintenanceType("COFFEE")
    }
    const handleChangeMachineType = (value) => {
        const machineType = machineTypes && machineTypes.find(m => m.Name === value)
        if (machineType) {
            setNewMachine(prev => ({...prev, MachineType: value}))
            setNewMachineData(prev => ({...prev, MachineTypeId: machineType.MachineTypeId}))
        } else {
            setNewMachine(prev => ({...prev, MachineType: value}))
        }
    }
    const handleChangeMachineLocation = (value) => {
        const location = locations && locations.find(l => l.Name === value)
        if (location) {
            setNewMachine(prev => ({...prev, Location: value}))
            setNewMachineData(prev => ({...prev, LocationId: location.LocationId}))
        } else {
            setNewMachine(prev => ({...prev,Location: value}))
        }
    }
    const handleChangeMaintenance = (value) => {
        const maintenance = maintenances && maintenances.find(m => m.Name === value)
        if (maintenance) {
            setNewMachine(prev => ({...prev, Maintenance: value}))
            setNewMachineData(prev => ({...prev, MaintenanceTypeId: maintenance.MaintenanceTypeId}))
        } else {
            setNewMachine(prev => ({...prev,Maintenance: value}))
        }
    }
    const handleChangeByDefault = ({name, value}) => {
        setNewMachine(prev => ({...prev, [name]: value}))
    }
    const handleChange = (evt) => {
        const {name, value} = evt.target
        switch (name) {
            case "IsCalcDiff":
                setNewMachine(prev => ({...prev, IsCalcDiff: !prev.IsCalcDiff}))
                break;
            case "Maintenance":
                handleChangeMaintenance(value)
                break;
            case "Location":
                handleChangeMachineLocation(value)
                break;
            case "MachineType":
                handleChangeMachineType(value)
                break;
            default: 
                handleChangeByDefault(evt.target)
                break;
        }
    }
    const handleFocus = (evt) => {
        const {name} = evt.target
        switch (name) {
            case "Maintenance":
                setNewMachine(prev => ({...prev, [name]: ""}))
                setNewMachineData(prev => ({...prev, MaintenanceTypeId: null}))
                break;
                case "Location":
                setNewMachine(prev => ({...prev, [name]: ""}))
                setNewMachineData(prev => ({...prev, LocationId: null}))
                break;
                case "MachineType":
                setNewMachine(prev => ({...prev, [name]: ""}))
                setNewMachineData(prev => ({...prev, MachineTypeId: null}))
                break;
            default: 
                setNewMachine(prev => ({...prev, [name]: ""}))
                break;
        }
    }
    const handleSubmit = () => {
        if(machines && machines.map(machine => machine.SerialNo).includes(newMachine.SerialNo)) {
            setNewMachine(prev => ({...prev, SerialNo: ""}))
            NotificationManager.error("Numer seryjny muśi być unikalny");
            return
        }
            // Name
    // , SerialNo
    // , IsCalcDiff
    // , MaintenanceTypeId
    // , LocationId
    // , MachineTypeId
        fetchMssqlApi('new-machine/create', {method: "POST", data: 
            {
                Name: newMachine.Name || null
                , SerialNo: newMachine.SerialNo
                , IsCalcDiff: newMachine.IsCalcDiff
                , MaintenanceTypeId: newMachineData.MaintenanceTypeId
                , LocationId: newMachineData.LocationId
                , MachineTypeId: newMachineData.MachineTypeId
            }},
            res => {
                setChanged(false)
                console.log("res: ",res.MachineId)
                history.replace(res.MachineId)
                setParam(res.MachineId)
            }
            )
    }
    // ======================================================



    // ==================== UseEffects ======================
    useEffect(() => {
        getMachinesList()
        getMachinesLocations()
        getMaintenancesSnack()
        getMaintenancesCoffee()
        getMachineTypes()
    }, [])

    useEffect(() => {
        setNewMachineData(prev => ({...prev, MaintenanceTypeId: null}))
        getMaintenancesSnack()
        getMaintenancesCoffee()
    }, [maintenanceType])

    useEffect(() => {
        newMachine.SerialNo
        && newMachineData.LocationId
        && newMachineData.MachineTypeId
        && newMachineData.MaintenanceTypeId
        ? setAvailableToSave(true)
        : setAvailableToSave(false)
    }, [newMachine, newMachineData])

    useEffect(() => {
        setChanged(true)
    }, [newMachine])
    // ======================================================
    return (
        <>
            <div className='container'> 
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            <Link to='/machines'>
                <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                    <img id='back' src={link}></img>
                </label>
            </Link>
                <div className='card'>
                    <div className='card-header'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <h5 className='mb-0'>
                                    Nowy automat
                                </h5>
                            </div>
                            {/* <div className='d-flex justify-content-center'>
                                <input
                                    type='checkbox'
                                    id="isCoffee"
                                    className='form-control'
                                    style={{width: "25px", height: "25px"}}
                                    label="Automat kawowy"
                                    checked={maintenanceType === "COFFEE"}
                                    onChange={handleChangeMaintenanceType}
                                /> 
                                <label htmlFor='isCoffee' className='ml-2 mb-0'>
                                    Automat kawowy
                                </label>
                            </div> */}
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='mb-2'>
                            <label htmlFor='Name'>
                                Nazwa
                            </label>
                            <input
                                value={newMachine.Name}
                                onChange={handleChange}
                                onClick={handleFocus}
                                name='Name'
                                id='Name'
                                className=' form-control'
                                placeholder='Wpisz nazwę automatu...'
                            />
                        </div>
                        <div className='mb-2'>
                            <label htmlFor='SerialNo'>
                                Nr seryjny
                            </label>
                            <input
                                value={newMachine.SerialNo}
                                onChange={handleChange}
                                onClick={handleFocus}
                                name='SerialNo'
                                id='SerialNo'
                                className={` form-control ${!newMachine.SerialNo && `invalid-input`}`}
                                placeholder='Wpisz numer seryjny automatu...'
                            />
                        </div>
                        <div className='mb-2'>
                            <label htmlFor='Location'>
                                Lokalizacja
                            </label>
                            <input
                                value={newMachine.Location}
                                onChange={handleChange}
                                onClick={handleFocus}
                                name='Location'
                                id='Location'
                                className={` form-control ${!newMachineData.LocationId && `invalid-input`}`}
                                placeholder='Wybierz lokalizację...'
                                list='locations'
                            />
                            <datalist id="locations">
                                {locations && locations.map((location, idx) => (
                                    <option value={location.Name} key={idx}/>
                                ))}
                            </datalist>
                        </div>
                        <div className='mb-2'>
                            <label htmlFor='MachineType'>
                                Typ automatu
                            </label>
                            <input
                                value={newMachine.MachineType}
                                onChange={handleChange}
                                onClick={handleFocus}
                                name='MachineType'
                                id='MachineType'
                                className={` form-control ${!newMachineData.MachineTypeId && `invalid-input`}`}
                                placeholder='Wybierz typ automatu...'
                                list='machineTypes'
                            />
                            <datalist id="machineTypes">
                                {machineTypes && machineTypes.map((mt, idx) => (
                                    <option value={mt.Name} key={idx}/>
                                ))}
                            </datalist>
                        </div>
                        <div className='mb-5'>
                            <label htmlFor='Maintenance'>
                                Typ obsługi
                            </label>
                            <input
                                value={newMachine.Maintenance}
                                onChange={handleChange}
                                onClick={handleFocus}
                                name='Maintenance'
                                id='Maintenance'
                                className={` form-control ${!newMachineData.MaintenanceTypeId && `invalid-input`}`}
                                placeholder='Wybierz tryb obsługi...'
                                list='maintenances'
                            />
                            <datalist id="maintenances">
                                {maintenances && maintenances.map((maintance, idx) => (
                                    <option value={maintance.Name} key={idx}/>
                                ))}
                            </datalist>
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-fixed container' style={{bottom: "0px"}}>
                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button onClick={handleSubmit} className='btn btn-success btn-sm'disabled={!availableToSave}>
                            Zapisz
                        </button>
                    </div>
            </div>
        </>
    )
}
