import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { LangContext } from "../../context/lang-context";
import useFetch from "../../hooks/fetchMSSQL-hook";
import Pagination from "../../components/Pagination/Pagination";
import FilterEx from "../../components/Filter/FilterEx";
import NoResults from "../../components/NoResults/NoResults";
import moment from "moment";
import { NavigationContext } from "../../context/navigation-context";
import ReportBtn from "../../components/Buttons/ReportsBtn/ReportBtn";
import { NotificationManager } from "react-notifications";
import { API_URL } from "../../config/config";
import axios from "axios";
import { getSheet } from "../../util/getSheets";
import ReportBtnForArr from "../../components/Buttons/ReportsBtn/ReportBtnForArr";

const DOS = () => {
  const { TRL_Pack } = useContext(LangContext);
  const { fetchMssqlApi } = useFetch();

  const { setHeaderData } = useContext(NavigationContext);

  const [DOS, setDOS] = useState(null);
  const [rep74, setRep74] = useState(null);
  const [rep47, setRep47] = useState(null);
  const [rep48, setRep48] = useState(null);
  const [rep60, setRep60] = useState(null);
  const [rep78, setRep78] = useState(null);
  const [rep74_per_do, setRep74_per_do] = useState({ Report: null, Id: null });
  const [rep78_per_do, setRep78_per_do] = useState({ Report: null, Id: null });
  const handleSwitchPage = (pageNo) => () =>
    setFilter((prev) => ({ ...prev, page: pageNo }));
  const resetPage = () => setFilter((prev) => ({ ...prev, page: 1 }));

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: "Pokazuj zerowe PA",
        searchable: true,
        sortable: false,
        type: "threeStateBool",
        selectbar: null,
      },
      {
        id: 2,
        name: "Telemetria",
        searchable: true,
        sortable: false,
        type: "threeStateBool",
        selectbar: null,
      },
      {
        id: 3,
        name: "Maszyna",
        searchable: true,
        sortable: true,
        type: "lookup",
        MachineId: null,
      },
      {
        id: 4,
        name: "Serwisant",
        searchable: true,
        sortable: true,
        type: "lookup",
        CreateUserId: null,
      },
      {
        id: 5,
        name: "Zakres czasowy",
        searchable: false,
        sortable: false,
        type: "datetimeRange",
        dateTimeRangeFrom: moment().add(-7, "d").format("YYYY-MM-DDTHH:mm:ss"),
        dateTimeRangeTo: moment().format("YYYY-MM-DDT23:59:59"),
      },
    ],
  };

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem("dosFilter")) {
      return JSON.parse(localStorage.getItem("dosFilter"));
    }
    return defaultFilter;
  });

  const toggleFilter = () =>
    setFilter((prev) => ({ ...prev, visible: !prev.visible }));
  const resetFilter = () => {
    setFilter(defaultFilter);

    //    search();
    getDOS(
      moment().add(-7, "d").format("YYYY-MM-DDTHH:mm:ss"),
      moment().format("YYYY-MM-DDT23:59:59"),
      null,
      null,
      null
    );
  };

  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns;

    for (let a in columns) {
      if (columns[a].searchbar === undefined) {
        columns[a].searchbar = null;
      }
    }

    let isZeroTotal = null;
    if (JSON.parse(columns[0].selectbar === "false")) isZeroTotal = false;
    else if (JSON.parse(columns[0].selectbar === "true")) isZeroTotal = true;

    let isTelemetry = null;
    if (JSON.parse(columns[1].selectbar === "false")) isTelemetry = false;
    else if (JSON.parse(columns[1].selectbar === "true")) isTelemetry = true;

    let machineId = null;
    if (
      JSON.parse(columns[2].MachineId !== "") &&
      JSON.parse(columns[2].MachineId != null)
    )
      machineId = columns[2].MachineId;

    let createUserId = null;
    if (
      JSON.parse(columns[3].CreateUserId !== "") &&
      JSON.parse(columns[3].CreateUserId != null)
    )
      createUserId = columns[3].CreateUserId;

    //set default create from / to date time
    let fromCreateDateTime = moment()
      .add(-7, "d")
      .format("YYYY-MM-DDTHH:mm:ss");
    let toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59");

    if (
      JSON.parse(columns[4].dateTimeRangeFrom != "") &&
      JSON.parse(columns[4].dateTimeRangeFrom != null)
    )
      //TODO: parse manually entered(?)
      fromCreateDateTime = columns[4].dateTimeRangeFrom.replace("T", " ");

    if (
      JSON.parse(columns[4].dateTimeRangeTo != "") &&
      JSON.parse(columns[4].dateTimeRangeTo != null)
    )
      //TODO: parse manually entered(?)
      toCreateDateTime = columns[4].dateTimeRangeTo.replace("T", " ");

    if (!moment(columns[4].dateTimeRangeTo).isValid() || !moment(columns[4].dateTimeRangeFrom).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      fromCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
      toCreateDateTime=moment().add().format("YYYY-MM-DD HH:mm:ss");
    }
    let machineTags = null;
    if (
      JSON.parse(filter.activeTags != "") &&
      JSON.parse(filter.activeTags != null)
    )
      machineTags = filter.activeTags;
    //TODO: parse machineTags(?)

    getDOS(
      fromCreateDateTime,
      toCreateDateTime,
      machineTags,
      machineId,
      createUserId
    );
  };

  const getDOS = (
    fromCreateDateTime = moment().add(-7, "d").format("YYYY-MM-DDTHH:mm:ss"),
    toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59"),
    machineTags = null,
    machineId = null,
    createUserId = null
  ) => {
    if (!moment(fromCreateDateTime).isValid() || !moment(toCreateDateTime).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      return
    }
    fetchMssqlApi(
      "inm-dos",
      {
        method: "POST",
        hideNotification: true,
        data: {
          clientId: localStorage.getItem("clientId"),
          fromCreateDateTime: fromCreateDateTime,
          toCreateDateTime: toCreateDateTime,
          machineTags: machineTags,
          machineId: machineId,
          createUserId: createUserId,
        },
      },
      (response) => {
        setDOS(response);
        //console.log(response)
      }
    );
  };

  useEffect(() => {
    search();
    setHeaderData({
      text: TRL_Pack.do.dos,
    });
  }, []);

  useEffect(
    () => localStorage.setItem("dosFilter", JSON.stringify(filter)),
    [filter]
  );

  const sortRows = (a, b) => {
    /*const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(salesdoc[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'text' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col].replace(',', ''))
      valueB = Number(b[col].replace(',', ''))

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    */
  };

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }
  const getMachineTags = () => {
    let machineTags = null;
    if (
      JSON.parse(filter.activeTags != "") &&
      JSON.parse(filter.activeTags != null)
    )
      machineTags = filter.activeTags;
    return machineTags;
  };
  const getMachineId = () => {
    const columns = filter.columns;
    let machineId = null;
    if (
      JSON.parse(columns[2].MachineId != "") &&
      JSON.parse(columns[2].MachineId != null)
    )
      machineId = columns[2].MachineId;
    return machineId;
  };
  const getMaintenance = () => {
    const columns = filter.columns;
    let createUserId = null;
    if (
      JSON.parse(columns[3].CreateUserId != "") &&
      JSON.parse(columns[3].CreateUserId != null)
    )
      createUserId = columns[3].CreateUserId;
    return createUserId;
  };
  const export74 = () => {
    setRep74(true);
    let fromCreateDateTime = moment().format("YYYY-MM-DDT00:00:00");
    let toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59");
    if (!moment(filter.columns[4].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[4].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep74(false);
      return
    }
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/dos`,
      data: {
        reportId: parseInt(74),
        fromCreateDateTime:
          moment(filter.columns[4].dateTimeRangeFrom).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || fromCreateDateTime,
        toCreateDateTime:
          moment(filter.columns[4].dateTimeRangeTo).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || toCreateDateTime,
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
        machineId: getMachineId(),
      },
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DZ74_${moment()
            .format("YYYY_MM_DDTHH_mm_ss")
            .replace("T", "_")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setRep74(false);
      })
      .catch((err) => {
        if (err.response.data.message === "jwt malformed")
          window.location.reload();
        else NotificationManager.error(err.response?.data || err.toString());
        setRep74(false);
      });
  };
  const export47 = () => {
    setRep47(true);
    let fromCreateDateTime = moment().format("YYYY-MM-DDT00:00:00");
    let toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59");
    if (!moment(filter.columns[4].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[4].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep47(false);
      return
    }
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/dos`,
      data: {
        reportId: parseInt(47),
        fromCreateDateTime:
          moment(filter.columns[4].dateTimeRangeFrom).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || fromCreateDateTime,
        toCreateDateTime:
          moment(filter.columns[4].dateTimeRangeTo).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || toCreateDateTime,
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
        machineId: getMachineId(),
      },
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DZ47_${moment()
            .format("YYYY_MM_DDTHH_mm_ss")
            .replace("T", "_")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setRep47(false);
      })
      .catch((err) => {
        if (err.response.data.message === "jwt malformed")
          window.location.reload();
        else NotificationManager.error(err.response?.data || err.toString());
        setRep47(false);
      });
  };
  const export48 = () => {
    setRep48(true);
    let fromCreateDateTime = moment().format("YYYY-MM-DDT00:00:00");
    let toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59");
    if (!moment(filter.columns[4].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[4].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep48(false);
      return
    }
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/dos`,
      data: {
        reportId: parseInt(48),
        fromCreateDateTime:
          moment(filter.columns[4].dateTimeRangeFrom).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || fromCreateDateTime,
        toCreateDateTime:
          moment(filter.columns[4].dateTimeRangeTo).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || toCreateDateTime,
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
        machineId: getMachineId(),
      },
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DZ48_${moment()
            .format("YYYY_MM_DDTHH_mm_ss")
            .replace("T", "_")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setRep48(false);
      })
      .catch((err) => {
        if (err.response.data.message === "jwt malformed")
          window.location.reload();
        else NotificationManager.error(err.response?.data || err.toString());
        setRep48(false);
      });
  };
  const export60 = () => {
    setRep60(true);
    let fromCreateDateTime = moment().format("YYYY-MM-DDT00:00:00");
    let toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59");
    if (!moment(filter.columns[4].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[4].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep60(false);
      return
    }
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/dos`,
      data: {
        reportId: parseInt(60),
        fromCreateDateTime:
          moment(filter.columns[4].dateTimeRangeFrom).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || fromCreateDateTime,
        toCreateDateTime:
          moment(filter.columns[4].dateTimeRangeTo).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || toCreateDateTime,
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
        machineId: getMachineId(),
      },
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DZ60_${moment()
            .format("YYYY_MM_DDTHH_mm_ss")
            .replace("T", "_")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setRep60(false);
      })
      .catch((err) => {
        if (err.response.data.message === "jwt malformed")
          window.location.reload();
        else NotificationManager.error(err.response?.data || err.toString());
        setRep60(false);
      });
  };
  const export78 = () => {
    setRep78(true);
    let fromCreateDateTime = moment().format("YYYY-MM-DDT00:00:00");
    let toCreateDateTime = moment().format("YYYY-MM-DDT23:59:59");
    if (!moment(filter.columns[4].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[4].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep78(false);
      return
    }
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/dos`,
      data: {
        reportId: parseInt(78),
        fromCreateDateTime:
          moment(filter.columns[4].dateTimeRangeFrom).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || fromCreateDateTime,
        toCreateDateTime:
          moment(filter.columns[4].dateTimeRangeTo).format(
            "YYYY-MM-DDTHH:mm:ss"
          ) || toCreateDateTime,
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
        machineId: getMachineId(),
      },
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DZ78_${moment()
            .format("YYYY_MM_DDTHH_mm_ss")
            .replace("T", "_")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setRep78(false);
      })
      .catch((err) => {
        if (err.response.data.message === "jwt malformed")
          window.location.reload();
        else NotificationManager.error(err.response?.data || err.toString());
        setRep78(false);
      });
  };

  const getRep74 = (reportId, machieId = getMachineId(), loadMachineInventoryId) => {
    if (!loadMachineInventoryId) return;
    setRep74_per_do({ Report: 74, Id: loadMachineInventoryId });
    if (!moment(filter.columns[4].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[4].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      setRep74({ Report: null, Id: null });
      return
    }
    getSheet({
      callbackToChangeState: setRep74_per_do,
      url: `${API_URL}/api/reports/dos`,
      name: `DZ${loadMachineInventoryId}_${moment()
        .format("YYYY_MM_DDTHH_mm_ss")
        .replace("T", "_")}.xlsx`,
      body: {
        reportId,
        loadMachineInventoryId: loadMachineInventoryId,
        machieId,
        fromCreateDateTime: moment(filter.columns[4].dateTimeRangeFrom).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        toCreateDateTime: moment(filter.columns[4].dateTimeRangeTo).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
      },
      spinnerTriggerState: { Report: 74, Id: loadMachineInventoryId },
    });
  };

  const getRep78 = (reportId, machieId = getMachineId(), loadMachineInventoryId) => {
    if (!loadMachineInventoryId) return;
    setRep74_per_do({ Report: 78, Id: loadMachineInventoryId });
    getSheet({
      callbackToChangeState: setRep78_per_do,
      url: `${API_URL}/api/reports/dos`,
      name: `DZ${loadMachineInventoryId}_${moment()
        .format("YYYY_MM_DDTHH_mm_ss")
        .replace("T", "_")}.xlsx`,
      body: {
        reportId,
        loadMachineInventoryId: loadMachineInventoryId,
        machieId,
        fromCreateDateTime: moment(filter.columns[4].dateTimeRangeFrom).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        toCreateDateTime: moment(filter.columns[4].dateTimeRangeTo).format(
          "YYYY-MM-DDTHH:mm:ss"
        ),
        maintenanceId: getMaintenance(),
        machineTags: getMachineTags(),
      },
      spinnerTriggerState: { Report: 78, Id: loadMachineInventoryId },
    });
  };
  const getUI = () => {
    if (DOS != null) {
      let temp = JSON.parse(JSON.stringify(DOS));

      // console.log(salesdoc)

      return (
        <div>
          <div className="container-xl">
            <Pagination
              {...{
                totalItems: DOS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible,
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: temp,
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: true,
                  }}
                />
              </>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <div>
                <ReportBtn
                  placement="top"
                  clickFnc={() => export78()}
                  state={rep78}
                  setState={setRep78}
                  overlayText={
                    <div>
                      <b>
                        Typ raportu: <br />
                        Produkty - doładowania wg dat
                        <br /> [DZ78]
                      </b>
                    </div>
                  }
                  btnColor="info"
                />
              </div>
              <div>
                <ReportBtn
                  placement="top"
                  clickFnc={() => export74()}
                  state={rep74}
                  setState={setRep74}
                  overlayText={
                    <div>
                      <b>
                        Typ raportu: <br />
                        Doładowania <br /> [DZ74]
                      </b>
                    </div>
                  }
                  btnColor="success"
                />
              </div>
              <div>
                <ReportBtn
                  placement="top"
                  clickFnc={() => export47()}
                  state={rep47}
                  setState={setRep47}
                  overlayText={
                    <div>
                      <b>
                        Typ raportu: <br /> Średnie doładowania automatów według
                        serwisantów
                        <br /> [DZ47]
                      </b>
                    </div>
                  }
                  btnColor="secondary"
                />
              </div>
              <div>
                <ReportBtn
                  placement="top"
                  clickFnc={() => export48()}
                  state={rep48}
                  setState={setRep48}
                  overlayText={
                    <div>
                      <b>
                        Typ raportu: <br /> Średnie doładowania automatów wg
                        automatów <br /> [DZ48]
                      </b>
                    </div>
                  }
                  btnColor="primary"
                />
              </div>
              <div>
                <ReportBtn
                  placement="top"
                  clickFnc={() => export60()}
                  state={rep60}
                  setState={setRep60}
                  overlayText={
                    <div>
                      <b>
                        Typ raportu: <br /> Średnie doładowania automatów wg
                        produktów <br /> [DZ60]
                      </b>
                    </div>
                  }
                  btnColor="danger"
                />
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">
                {TRL_Pack.do.dos} {/* TO DO: Add translations*/}
              </h5>
              <div
                className="card-body overflow-auto"
                style={{ maxHeight: 550 }}
              >
                {DOS.length ? (
                  <table className="table table-striped">
                    <thead>
                      <tr className="justify-content-center">
                        <td>
                          DO <br />
                          Czas zapisu, Żródło
                        </td>
                        <td>{TRL_Pack.wi.MachineFullName}</td>
                        <td>Doładowanie</td>
                        <td>Stan maszyny</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {DOS.sort(sortRows)
                        // .filter(reportFilter)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                {entry.DO !== "" && (
                                  <Link
                                    to={`/do/${entry.LoadMachineInventoryId}`}
                                    className="btn text-nowrap btn-sm btn-outline-info"
                                  >
                                    {entry.DO}
                                  </Link>
                                )}
                                <div className="btn">{entry.Created}</div>
                              </div>
                            </td>
                            <td className="d-flex flex-column justify-content-center">
                              <Link
                                to={`/machine/${entry.MachineId}`}
                                className="btn btn-outline-link btn-sm"
                              >
                                {entry.MachineFullName}
                              </Link>
                            </td>
                            <td>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                {entry.LoadQuantity !== null && (
                                  <div className="btn text-nowrap">
                                    {entry.LoadQuantity} szt.
                                  </div>
                                )}
                                {entry.LoadQuantity === null && (
                                  <div className="btn text-nowrap">-</div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                {entry.INm !== "" && (
                                  <Link
                                    to={`/inm/${entry.MachineInventoryId}`}
                                    className="btn text-nowrap btn-sm btn-outline-danger"
                                  >
                                    {entry.INm}
                                  </Link>
                                )}
                                <div className="btn text-nowrap">
                                  {entry.StateAndCapacity}
                                </div>
                                <div className="btn text-nowrap">
                                  {entry.StatePercent}%
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                {/* <ReportBtnForArr
                                  clickFnc={() =>
                                    getRep78(
                                      78,
                                      entry.MachineId,
                                      entry.LoadMachineInventoryId
                                    )
                                  } 
                                  state={rep78_per_do}
                                  setState={setRep78_per_do}
                                  overlayText={
                                    <div>
                                      <b>
                                        Typ raportu: <br />
                                        Produkty - doładowania wg dat
                                        <br /> [DZ78]
                                      </b>
                                    </div>
                                  }
                                  value={{
                                    Report: 78,
                                    Id: entry.LoadMachineInventoryId,
                                  }}
                                  btnColor="info"
                                  permissionName={"VD_REPORT_78"}
                                /> */}
                                <ReportBtnForArr
                                  clickFnc={() =>
                                    getRep74(
                                      74,
                                      entry.MachineId,
                                      entry.LoadMachineInventoryId
                                    )
                                  } // TODO: choose betweem load id and machine inbentory id
                                  state={rep74_per_do}
                                  setState={setRep74_per_do}
                                  overlayText={
                                    <div>
                                      <b>
                                        Typ raportu: <br />
                                        Doładowania <br /> [DZ74]
                                      </b>
                                    </div>
                                  }
                                  value={{
                                    Report: 74,
                                    Id: entry.LoadMachineInventoryId,
                                  }}
                                  btnColor="success"
                                  permissionName={"VD_REPORT_74"}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>{getUI()}</div>
    </>
  );
};

export default DOS;
