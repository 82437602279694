import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'
import { NotificationManager } from 'react-notifications'
import { Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../assets/images/back_to_list_icon.svg'

const FZ = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()
  const { warehouseOperationId } = useParams()
  const { setHeaderData } = useContext(NavigationContext)
  const [avaiableToSave, setAvaiableToSave] = useState(false)
  const [closed, setClosed] = useState(false)
  // const date1 = {year: 'numeric', month: 'numeric', day: 'numeric'}
  // const date = new Intl.DateTimeFormat('pl-PL').format(date1)
  const history = useHistory()
  const [vats, setVats] = useState([])
  const [products, setProducts] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [contractors, setContractors] = useState([])
  const [initialItems, setInitialItems] = useState([])
  const [items, setItems] = useState([])
  const [changed, setChanged] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [initialFZ, setInitialFZ] = useState({      
    TotalNetto: parseFloat(0.0),
    TotalBrutto: parseFloat(0.0),
    TotalVAT: parseFloat(0.0),
    ContractorName: "",
    IssuedOn: moment().format("YYYY-MM-DD"),
    Created: "",
    PaymentDate: moment().format("YYYY-MM-DD"),
    ContractorId: 0,
    Comment: '',
    IsPaid: false,
    OriginalDocNumber: '',
    WarehouseFullName: '',
    FromWarehouseId: null,
    // FromWarehouseId: 0,
  })
  const isNew = warehouseOperationId === "0"
  const [FZ, setFz] = useState({})
  // const newFZItem = {
  //   ProductId: 0,
  //   Quantity: parseInt(0),
  //   Price: parseFloat(0.0),
  //   ProductVATValue: 0.0,
  //   WarehouseOperationItemId: 0,
  //   // IsLast: true
  // }
  // const newFZ = {
  //   TotalNetto: parseFloat(0.0),
  //   TotalBrutto: parseFloat(0.0),
  //   TotalVAT: parseFloat(0.0),
  //   ContractorId: 0,
  //   ContractorName: "",
  //   IssuedOn: Intl.DateTimeFormat('pl-PL').format(date),
  //   Comment: '',
  //   OriginalDocNumber: '',
  //   FromWarehouseId: 0,
  //   // Items: [newFZItem]
  // }
  const random = () => { 
  const set = new Set(items.map(item => +item.WarehouseOperationItemId))
  const a = Math.floor(Math.random()*10000)
  return set.has(a) ? random() : a}
  const newFZItem = {
    new: true,
    Price: 0,
    PriceBrutto: 0,
    PriceNetto: 0,
    ProductId: null,
    Quantity: 0,
    VAT: 0,
    VATId: null,
    Value: 0,
    // ContractorId: null,
    WarehouseOperationItemId: String(random())
  }
  
  const getFZItems = () => {
    if (warehouseOperationId !== "0") fetchMssqlApi(`fz/${warehouseOperationId}`, {}, fz => setInitialItems(fz))
    else setInitialItems([])
  }
  const getVAT = () => {
    fetchMssqlApi('vats', {}, vats => setVats(vats))
  }
  const getVatValue = data => {
    const value = data.VATId ? vats.find(vat => vat.VATId === data.VATId) : null
    return value ? `${value.Code}%` : ""
  }
  const getDate = date => {
    // console.log(date)
    if(!date ) return null
    if (date.includes(" ")) {
      date = date.split(" ")
      return date[0]
    } else if (date.includes("T")) {
      date = date.split("T")
      return date[0]
    }
    return null
  }
  const getFz = () => {
    if (warehouseOperationId !== "0") fetchMssqlApi(`fz`, {method: "POST", data: {warehouseOperationId}, hideNotification: true}, fz => 
    {
      setInitialFZ(() => ({...fz, IssuedOn: getDate(fz.IssuedOn), PaymentDate: getDate(fz.PaymentDate)}))
      console.log(fz)
      fz.WarehouseInventoryId ? setClosed(true) : setClosed(false)
    })
    else setFz(initialFZ)
  }

  // const sumAll = () => {
  //   return setFz(prev => ({
  //     ...prev,
  //     TotalNetto: 
  //     TotalVAT: items && (items.map(item => +item.VAT )).reduce((a, b) => a + b, 0),
  //   }))
  // }

  useEffect(() => {
    setHeaderData({
      text: TRL_Pack.fz.fz
    });
    getFz()
    getFZItems()
    getProducts();
    getWarehouses();
    getContractors();
    getVAT()
    
  }, [])
  useEffect(() => {
    setFz(initialFZ)
    console.log(initialFZ)
  }, [initialFZ])
  useEffect(() => {
    setItems(initialItems)
  }, [initialItems])

  const getNetto = ({Price, Quantity}) =>  (Number(Quantity) * Number(Price)).toFixed(2) || 0
  const getVat = (netto, Value) => (Number(netto) * Number(Value) / 100).toFixed(2) || 0
  const getBrutto = (netto, vat) => (Number(netto) + Number(vat)).toFixed(2) || 0
  const totalBruttoSum = () => items && (items.map(item => !item.deleted && getBrutto(getNetto(item), getVat(getNetto(item), item.Value) ) )).reduce((a, b) => +a + +b, 0)
  const totalNettoSum = () => items && (items.map(item => !item.deleted && getNetto(item))).reduce((a, b) => +a + +b, 0)
  const totalVatSum = () => items && (items.map(item => !item.deleted && getVat(getNetto(item), item.Value) ).reduce((a, b) => +a + +b, 0))
  const deleteItem = (id) => {
    const item = items.find(item => item.WarehouseOperationItemId === id)
    item.new ? setItems(prev => prev.filter(item => item.WarehouseOperationItemId !== id)) 
    : setItems(prev => prev.map(a => a.WarehouseOperationItemId === id ? ({...a, deleted: true}) : (a)))
  }




  const handleChangeItem = (id, evt) => {
    const name = evt.target.value
    setChanged(true)
    const product = products.find(pr => pr.Name === name)
    product ? evt.target.className = "form-control form-control-sm mx-auto mx-lg-0" : evt.target.className += " invalid-input"
    product
    ? setItems(prev => prev.map(item => {
      if(item.WarehouseOperationItemId === id) {return {
        ...item,
        Name: product.Name,
        Price: item.Price,
        PriceBrutto: getBrutto(getNetto(item), getVAT(getNetto(item), +product.VATValue)),
        PriceNetto: getNetto(item),
        ProductId: product.ProductId,
        Quantity: item.Quantity,
        VAT: getVat(getNetto(item), +product.VATValue),
        VATId: product.VATId,
        Value: product.VATValue,
      }} else return item
    }))
    : setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...item, Name: name}) : (item)))
  }

  const handleChangeQuantity = (id, quantity) => {
    setChanged(true)
    setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({
      ...item,
      Quantity: quantity.replace(/[^0-9]/, ""),
    }) : item))
  }

  const handleChangePrice = (id, price) => {
    setChanged(true)
    setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({
      ...item,
      Price: price.replace("e", ""),
    }) : item))
  }

  const handleChangeContractor = (e) => {
    const evt = e.target
    setChanged(true)
    const contractor = contractors.find(data => data.Name === evt.value)
    if (!contractor) {
      evt.className += " invalid-input"
      setFz(prev => ({...prev, ContractorName: evt.value}))
    } else {
      evt.className = "mt-2 form-control form-control-sm"
      setFz(prev => ({...prev, ContractorName: evt.value, ContractorId: contractor.ContractorId}))
    }
  }

  const handleChangeWarehouse = (e) => {
    const evt = e.target
    setChanged(true)
    const warehouse = warehouses.find(data => data.Name === evt.value)
    if (!warehouse) {
      evt.className += " invalid-input"
      setFz(prev => ({...prev, WarehouseFullName: evt.value}))
    } else {
      evt.className = "mt-2 form-control form-control-sm"
      setFz(prev => ({...prev, WarehouseFullName: evt.value, FromWarehouseId: warehouse.WarehouseId}))
    }
  }
  // const sumFunc = arr => arr.reduce((sum, val) => sum + val, 0)
  // const sumAll = () => {
  //   if (items.length && vats.length) {
  //     const sumNetto = sumFunc(items.map(item => (item.Quantity * item.Price)))
  //     const sumBrutto =sumFunc( items.map(item => (item.Quantity * item.Price) + (item.Quantity * item.Price * (item.Value / 100) || 1)) )
  //     const sumVats = sumFunc(items.map(item => item.Quantity * (item.Price * (item.Value / 100) || 1) ))
  //     setFz(prev => ({...prev, TotalBrutto: sumBrutto, TotalNetto: sumNetto, TotalVAT: sumVats}))
  //   }
  // }
  // const sumItem = (id, type, value) => {

  //   // const item = items.find(item => item.WarehouseOperationItemId === id)
  //     if (type === "quantity") {
  //       if(!+value) {
  //         setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...prev, Quantity: 0}) : prev))
  //       }
  //       setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...item, 
  //         Quantity: +value || 0, 
  //         PriceBrutto: parseFloat(+value * item.Price) + (+value * item.Price * (item.Value / 100)) || 0,
  //         PriceNetto: parseFloat(+value * item.Price) || 0,
  //         VAT: parseFloat(+value * item.Price * (item.Value / 100)) || 0
  //       }) : item))
  //     } else if (type === "price") {
  //       if(!+value) {
  //         setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...prev, Price: 0}) : prev))
  //       }
  //       setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...item, 
  //         Price: parseFloat(+value) || 0, 
  //         PriceBrutto: parseFloat((item.Quantity * +value) + (item.Quantity * +value * (item.Value / 100))) || 0,
  //         PriceNetto: parseFloat(item.Quantity * +value) || 0,
  //         VAT: parseFloat(item.Quantity * +value * (item.Value / 100)) || 0
  //       }) : item))
  //     }
  //     return null
  // }
  // useEffect(() => {
  //   console.log("items: ", items, "\n", "FZ: ", FZ)
  // }, [initialItems, initialFZ])
  useEffect(() => {
    setFz(prev => ({...prev, ContractorId: contractors && contractors.find(data => data.Name === prev.ContractorName)?.ContractorId || null }))
  }, [initialFZ, contractors])
  useEffect(() => {
    setFz(prev => ({...prev, FromWarehouseId: warehouses && warehouses.find(data => data.Name === prev.WarehouseFullName)?.WarehouseId || null}))
  }, [initialFZ,warehouses])
  useEffect(() => {
    ![...items].filter(item => !item.deleted).length ||
    items.some(item => !item.Quantity || !item.Price || !item.ProductId) ||
    !FZ.OriginalDocNumber ||
    !FZ.FromWarehouseId ||
    !FZ.ContractorId ||
    !FZ.IssuedOn ||
    !FZ.PaymentDate ? setAvaiableToSave(false) : setAvaiableToSave(true)
  }, [items, FZ])
  //   useEffect(() => {
  //   // getFZ(warehouseOperationId);

  // }, [])

  const getProducts = () => {
    fetchMssqlApi('products-list', {}, products => setProducts(products))
  }

  const getWarehouses = () => {
    fetchMssqlApi('warehouses-list', {}, warehouses => setWarehouses(warehouses))
  }

  const getContractors = () => {
    fetchMssqlApi('contractors-list', {}, contractors => setContractors(contractors))
  }
  const handleSubmit = () => {
    // console.log(items, FZ)
    const {IssuedOn, 
      WarehouseOperationId, 
      WarehouseOperationFullName,
      OriginalDocNumber,
      ContractorId,
      // FromWarehouseId,
      FromWarehouseId,
      PaymentDate,
      ToWarehouseOperationId,
      IsPaid,
      RelatedDoc,
      Comment,
      Created} = FZ
    const TotalBrutto = totalBruttoSum().toFixed(2)
    const TotalNetto = totalNettoSum().toFixed(2)
    const TotalVAT = totalVatSum().toFixed(2)
    const FZpayload = {
      Comment,
      IssuedOn: moment(IssuedOn).format("YYYY-MM-DD 03:00:00") || moment.format("YYYY-MM-DD HH:MM:SS"),
      WarehouseOperationId, 
      WarehouseOperationFullName,
      OriginalDocNumber,
      ContractorId,
      FromWarehouseId,
      FromWarehouseId,
      PaymentDate: moment(PaymentDate).format("YYYY-MM-DDT03:00:00.000") || moment.format("YYYY-MM-DDT03:00:00.000"),
      ToWarehouseOperationId,
      IsPaid,
      RelatedDoc,
      Created,
      TotalBrutto,
      TotalNetto,
      TotalVAT
    }
    const FZitems = items.filter(item => !(item.deleted && item.new)).map(item => {
      // console.log(item)
      return warehouseOperationId === "0" ? {
        ProductId: item.ProductId,
        Price: parseFloat(item.Price),
        Quantity: item.Quantity,
        PriceBrutto: getBrutto(getNetto(item), getVat(getNetto(item), item.Value) ),
        PriceNetto: getNetto(item),
        VAT: getVat(getNetto(item), item.Value),
        WarehouseOperationId: FZ.WarehouseOperationId
      } : {
        deleted: item.deleted ? 1 : 0,
        new: item.new ? 1 : 0,
        WarehouseOperationItemId: item.WarehouseOperationItemId,
        ProductId: item.ProductId,
        Price: parseFloat(item.Price),
        Quantity: item.Quantity,
        PriceBrutto: getBrutto(getNetto(item), getVat(getNetto(item), item.Value) ),
        PriceNetto: getNetto(item),
        VAT: getVat(getNetto(item), item.Value),
        ToWarehouseOperationId: FZ.ToWarehouseOperationId
      }
    })
    // console.log("FZpayload: ",FZpayload)
    // console.log("FZItems: ", FZitems)
    const payload = {
      FZ: FZpayload,
      Items: FZitems
    }
    // console.log(payload)
    if (warehouseOperationId === "0") {
      fetchMssqlApi('fz/new', {method: "POST", data: payload}, () => {
        setIsSaved(true)
      })
    } else {
      fetchMssqlApi(`fz/${warehouseOperationId}`, {method: "PUT", data: payload}, res => setItems(res))
    }
    setChanged(false)
  }
  
  // const getSumFZ = (data, type) => {
  //   let arr = data.map(a => a[type])
  //   return arr.concat((sum, curValue) => sum + curValue ,0)
  // }
  // const handleChangeItem = (id, name) => {
  //     const product = products.find(product => product.Name === name)
  //     if(product) {
  //       setItems(prev => prev.map(item =>( item.WarehouseOperationItemId === id) ? ({...prev, 
  //         Value: product.value, 
  //         VATId: product.VATId, 
  //         VAT: prev.Quantity * prev.Price * (product.Value / 100), 
  //         ProductId: product.ProductId, 
  //         PriceBrutto: (prev.Quantity * prev.Price * (product.Value / 100)) + (prev.Quantity * prev.Price), 
  //         Name: name}) : item))
  //     } 
    //   else {
    //     setItems(prev => prev.map(item =>( item.WarehouseOperationItemId === id) ? ({...prev, 
    //       Value: 0, 
    //       VATId: 4, 
    //       VAT: 0, 
    //       ProductId: null, 
    //       PriceBrutto: 0, 
    //       Name: name}) : item))
    //   }

    // return null
  // }

  // const getFZ = (warehouseOperationId) => {
  //   if (warehouseOperationId === "0") {
  //     setFZ(newFZ);
  //     IsUpdated = true;
  //   }
  //   else {
  //     fetchMssqlApi('fz', {
  //       method: 'POST',
  //       hideNotification: true,
  //       data: {
  //         clientId: localStorage.getItem("clientId"),
  //         warehouseOperationId: warehouseOperationId
  //       }
  //     }, response => {
  //       IsUpdated = false;
  //       console.log(response);
  //       return setFZ(response);
  //     })
  //   }
  // }



  // const submit = evt => {

  //   const { name, value, text } = evt.target

  //   //TODO: is anything changed?

  //   //TODO: validation

  //   //TODO: serialization?

  //   //TODO: call API - Add or Update?

  //   //TODO: refresh?
  //   console.log("IsUpdated:");
  //   console.log(IsUpdated);
  //   console.log(FZ);

  // }

  // const addItem = evt => {
  //   let last = items.at(-1)
  //   setItems(prev => [...prev.pop(), {...last, IsLast: false}, newFZItem])
  //   //console.log(FZ.Items);
  // }


  // const removeItem = (idx, e, entry) => {
  //   if(FZ.Items.length > 1)
  //   {
  //     FZ.Items.splice(idx, 1);
  //     setFZ((prev) => ({ ...prev}));
  //   }
  // }

  // const handleItemChange = (WarehouseOperationItemId, e, entry) => {
  //   const { id, value, checked } = e.target;
  //   //e.preventDefault();
  //   //console.log("Input: " + id + " has changed value to: " + value + ", checked: " + checked);
  //   console.log("WarehouseOperationItemId: " + WarehouseOperationItemId);
  //   console.log("e: " + e);
  //   console.log("id: " + id);
  //   console.log("value: " + value);
  //   console.log("Entry: " + entry);

  //   if (id === "inpProductList") {
  //     let productId = null;
  //     let lastPriceNetto = null;
  //     let lastQuantity = null;
  //     let VATValue = null;

  //     if (value !== '' && products.find(obj => obj.Name === value) !== undefined) {
  //       productId = products.find(obj => obj.Name === value).ProductId;
  //       lastPriceNetto = products.find(obj => obj.Name === value).LastPriceNetto;
  //       lastQuantity = products.find(obj => obj.Name === value).LastQuantity;
  //       VATValue = products.find(obj => obj.Name === value).VATValue;
  //     }

  //     if (productId !== null) {
  //       entry.ProductId = productId;
  //       IsUpdated = true;
  //       entry.Quantity = lastQuantity;
  //       if(lastPriceNetto !==null && lastPriceNetto !== undefined)
  //         entry.Price = lastPriceNetto.toFixed(2);
  //       else
  //         entry.Price = parseFloat(0.0);

  //       entry.ProductVATValue = VATValue;
  //       setFZ((prev) => ({ ...prev}));

  //     }
  //   }
  //   else if (id === "inpQuantity") {
  //     entry.Quantity = value;
  //     IsUpdated = true;
      
  //     setFZ((prev) => ({ ...prev}));
  //   }
  //   else if (id === "inpPrice") {
  //     entry.Price = value;
  //     IsUpdated = true;

  //     setFZ((prev) => ({ ...prev}));
  //   }
    
  //   recalculateFZ();
  //   console.log(FZ);
  // }

  // const recalculateFZ = () =>
  // {
  //   var totalBrutto = parseFloat(0.0);
  //   var totalNetto = parseFloat(0.0);
  //   var totalVAT = parseFloat(0.0);

  //   if(FZ.Items.length > 0){
  //     FZ.Items.forEach((entry)=>
  //     {
  //       totalNetto += parseFloat((entry.Quantity * entry.Price).toFixed(2));
  //       totalVAT += parseFloat((entry.Quantity * (entry.Price * ((entry.ProductVATValue / 100)))).toFixed(2));
  //       totalBrutto += parseFloat((entry.Quantity * (entry.Price * (1 + (entry.ProductVATValue / 100)))).toFixed(2));
  //     })
  //     /*FZ.Items.map((entry, idx) => {
  //       if(entry.Quantity > 0 && entry.ProductId > 0 && entry.Price !== null){
  //         totalNetto = totalNetto + (entry.Quantity * entry.Price).toFixed(2);
  //         totalVAT += (entry.Quantity * (entry.Price * ((entry.ProductVATValue / 100)))).toFixed(2);
  //         totalBrutto += (entry.Quantity * (entry.Price * (1 + (entry.ProductVATValue / 100)))).toFixed(2);
  //       }
  //     })*/
  //   }

  //   FZ.TotalBrutto = totalBrutto.toFixed(2).toString();
  //   FZ.TotalNetto = totalNetto.toFixed(2).toString();
  //   FZ.TotalVAT = totalVAT.toFixed(2).toString();

  //   setFZ((prev) => ({ ...prev}));
  // }
  // const handleChange = evt => {
  //   //evt.preventDefault();
  //   const { id, value, checked, text } = evt.target
  //   console.log("Input: " + id + " has changed value to: " + value + ", checked: " + checked);
  //  // console.log("Input: " + id + " has changed text to: " + text );

  //   if (id === "inpOriginalDocNumber") {
  //     FZ.OriginalDocNumber = value;
  //     setFZ((prev) => ({ ...prev}));

  //     IsUpdated = true;
  //   }
  //   else if (id === "inpIssuedOn") {
  //     FZ.IssuedOn = value;
  //     setFZ((prev) => ({ ...prev}));
  //     IsUpdated = true;
  //   }
  //   else if (id === "inpContractorsList") {
  //     let contractorId = null;

  //     if (value !== '' && contractors.find(obj => obj.Name === value) !== undefined)
  //       contractorId = contractors.find(obj => obj.Name === value).ContractorId;

  //     if (contractorId !== null) {
  //       FZ.ContractorId = contractorId;
  //       setFZ((prev) => ({ ...prev}));
  //       IsUpdated = true;

  //     //  if(evt.target.nextSibling)
  //       //    evt.target.nextSibling.focus();

  //           //evt.target.focus();
  //   /*    const nextSibling = document.querySelector(
  //         `input[name=inpWarehousesList]`
  //       );
  // */
  //       //console.log(evt.target);

  //       // If found, focus the next field
  //     /*  if (nextSibling !== null) {
  //         nextSibling.focus();
  //       }*/
  //     }

  //   }
  //   else if (id === "inpWarehousesList") {
  //     let warehouseId = null;

  //     if (value !== '' && warehouses.find(obj => obj.Name === value) !== undefined)
  //       warehouseId = warehouses.find(obj => obj.Name === value).FromWarehouseId;

  //     if (warehouseId !== null) {
  //       FZ.FromWarehouseId = warehouseId;
  //       setFZ((prev) => ({ ...prev}));
  //       IsUpdated = true;
  //     }
  //   }
  //   else if (id === "inpComment") {
  //     FZ.Comment = value;
  //     setFZ((prev) => ({ ...prev}));

  //     IsUpdated = true;

  //   }
  //   else if (id === "inpPaymentDate") {
  //     FZ.PaymentDate = value;
  //     setFZ((prev) => ({ ...prev}));
  //     IsUpdated = true;
  //   }
  //   else if (id === "inpIsPaid") {
  //     FZ.IsPaid = checked;
  //     setFZ((prev) => ({ ...prev}));
  //     IsUpdated = true;
  //   }

  //   console.log(FZ);
  // }

  // const clearOnFocus = evt => {
  //   console.log("===>"+evt.target.value);
  //   //evt.target.value = '';
  // }

  // const getUI = () => {
  //   if (FZ != null) {
  //     return (
  //       <div>
  //         <div className='container-xl'>
  //           <div className="card">
  //             <h5 className="card-header">
  //               {warehouseOperationId !== '0' && (<Link to={`/fz/${FZ.WarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{FZ.FZ}</Link>)}
  //               {warehouseOperationId === '0' && (<Link to={`/fz/0`} className="btn text-nowrap btn-sm btn-outline-secondary">Nowa</Link>)}
  //               {warehouseOperationId !== '0' && (<Link to={`/pz/${FZ.PZWarehouseOperationId}`} className="btn ml-2 text-nowrap btn-sm btn-outline-warning">{FZ.PZ}</Link>)}
  //             </h5>
  //             <table className="table table-striped">
  //               <tbody>
  //                 <tr className="justify-content-center">
  //                   <td>Nr oryginalny</td>
  //                   <td>Data faktury</td>
  //                   <td>Kontrahent</td>
  //                   <td>Magazyn</td>
  //                 </tr>
  //                 <tr className="justify-content-center">
  //                   <td>
  //                     <input type="text" autoFocus required className="text-center form-control-sm" minLength="1" size="20" id="inpOriginalDocNumber" value={FZ.OriginalDocNumber} onChange={handleChange} ></input>
  //                   </td>
  //                   <td>
  //                     <input type="date" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date" onChange={handleChange} id="inpIssuedOn" defaultValue={FZ.IssuedOn !== undefined && FZ.IssuedOn.slice(0, 19)} min="2018-01-01" max="2099-12-31" ></input>
  //                   </td>
  //                   <td>
  //                     {FZ.ContractorId !== 0 && (<input
  //                       onFocus={clearOnFocus}
  //                       className="mt-2 form-control form-control-sm"
  //                       placeholder="Wybierz..."
  //                       // defaultValue={contractors.length && contractors.find(obj => obj.ContractorId === FZ.ContractorId).Name}
  //                       list="ContractorsList"
  //                       id="inpContractorsList"
  //                       name="ContractorsList"
  //                       onChange={handleChange}
  //                     />)}
  //                     {FZ.ContractorId === 0 && (<input
  //                       onFocus={clearOnFocus}
  //                       className="mt-2 form-control form-control-sm"
  //                       placeholder="Wybierz..."
  //                       list="ContractorsList"
  //                       id="inpContractorsList"
  //                       name="ContractorsList"
  //                       onChange={handleChange}
  //                     />)}
  //                     {(<datalist id="ContractorsList">
  //                       {contractors.map((entry, idx) => (
  //                         <option value={entry.Name} key={idx} ></option>
  //                       )
  //                       )}
  //                     </datalist>)
  //                     }
  //                   </td>
  //                   <td>
  //                     {FZ.FromWarehouseId !== 0 && (<input
  //                       onFocus={clearOnFocus}
  //                       className="mt-2 form-control form-control-sm"
  //                       placeholder="Wybierz..."
  //                       // defaultValue={warehouses.length && warehouses.find(obj => obj.FromWarehouseId === FZ.FromWarehouseId).Name}
  //                       list="WarehousesList"
  //                       id="inpWarehousesList"
  //                       name="WarehousesList"
  //                       onChange={handleChange}
  //                     />)}
  //                     {FZ.FromWarehouseId === 0 && (<input
  //                       onFocus={clearOnFocus}
  //                       className="mt-2 form-control form-control-sm"
  //                       placeholder="Wybierz..."
  //                       list="WarehousesList"
  //                       id="inpWarehousesList"
  //                       name="WarehousesList"
  //                       onChange={handleChange}
  //                     />)}
  //                     {(<datalist id="WarehousesList">
  //                       {warehouses.map((entry, idx) => (
  //                         <option value={entry.Name} key={idx}></option>
  //                       )
  //                       )}
  //                     </datalist>)
  //                     }</td>
  //                 </tr>
  //                 <tr className="justify-content-center">
  //                   <td>Zapłacona</td>
  //                   <td>Data płatności</td>
  //                   <td colSpan="2">Komentarz / dodatkowe informacje</td>
  //                 </tr>
  //                 <tr className="justify-content-center">
  //                   <td><div className="d-flex flex-column justify-content-center align-items-center">
  //                     <input type="checkbox" className="form-check-input" id="inpIsPaid" onChange={handleChange} checked={FZ && FZ.IsPaid }></input>
  //                   </div></td>
  //                   <td><div className="text-nowrap"><input type="date" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date" onChange={handleChange} id="inpPaymentDate" defaultValue={FZ.PaymentDate !== undefined && FZ.PaymentDate !== null && FZ.PaymentDate.slice(0, 19)} min="2018-01-01" max="2099-12-31" ></input></div></td>
  //                   <td colSpan="2"><div className="text-nowrap">
  //                     <input type="text" required className="text-center form-control-sm" minLength="1" size="20" id="inpComment" value={FZ.Comment} onChange={handleChange} ></input>
  //                   </div></td>
  //                 </tr>
  //                 <tr className="justify-content-center">
  //                   <td>Wartość brutto</td>
  //                   <td>Wartość VAT</td>
  //                   <td>Wartość netto</td>
  //                   <td>Stworzona</td>
  //                 </tr>
  //                 <tr className="justify-content-center">
  //                   <td><div className="text-nowrap">{FZ && FZ.TotalBrutto ? Intl.NumberFormat('pl-PL', {style: 'currency', currency: "PLN"}).format(FZ.TotalBrutto) : "0zł"}</div></td>
  //                   <td><div className="text-nowrap"> {FZ && FZ.TotalVAT ? Intl.NumberFormat('pl-PL', {style: 'currency', currency: "PLN"}).format(FZ.TotalVAT) : "0zł"}</div></td>
  //                   <td><div className="text-nowrap"> {FZ && FZ.TotalNetto ? Intl.NumberFormat('pl-PL', {style: 'currency', currency: "PLN"}).format(FZ.TotalNetto) : "0zł"}</div></td>
  //                   <td><div className="text-nowrap">{FZ.Created}</div></td>
  //                 </tr>
  //               </tbody></table>
  //             <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>
  //               {FZ.Items !== undefined && FZ.Items.length ? (
  //                 <table className="table table-striped">
  //                   <thead>
  //                     <tr className="justify-content-center">
  //                       <td>Produkt</td>
  //                       <td>Ilość</td>
  //                       <td>Cena jedn. netto</td>
  //                       <td>Wartość netto</td>
  //                       <td>Stawka VAT</td>
  //                       <td>Wartość VAT</td>
  //                       <td>Wartość brutto</td>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     { items && items
  //                       .map((entry, idx) => (
  //                         <tr key={idx}>
  //                           <td className="justify-content-center"><div className="text-nowrap">
  //                             {entry.WarehouseOperationItemId}
  //                             {entry.ProductId}

  //                             {entry.ProductId === 0 && (<input
  //                               onFocus={clearOnFocus}
  //                               className="mt-2 form-control form-control-sm"
  //                               placeholder="Wybierz..."
  //                               list="ProductsList"
  //                               name="ProductsList"
  //                               id="inpProductList"
  //                               onChange={(e => handleItemChange(entry.WarehouseOperationItemId, e, entry))}
  //                             />)}
  //                             {entry.ProductId !== 0 && (<input
  //                               onFocus={clearOnFocus}
  //                               className="mt-2 form-control form-control-sm"
  //                               placeholder="Wybierz..."
  //                               defaultValue={products.length && products.find(obj => obj.ProductId === entry.ProductId).Name}
  //                               list="ProductsList"
  //                               name="ProductsList"
  //                               id="inpProductList"
  //                               onChange={(e => handleItemChange(entry.WarehouseOperationItemId, e, entry))}
  //                             />)}
  //                             {(<datalist id="ProductsList">
  //                               {products.map((entry, idx) => (
  //                                 <option value={entry.Name} key={idx}></option>
  //                               )
  //                               )}
  //                             </datalist>)
  //                             }
  //                           </div></td>
  //                           <td className="justify-content-center"><div className="text-nowrap">
                            
  //                             <input type="number" required step={1} value={entry.Quantity} className="text-center form-control-sm" id="inpQuantity" onChange={(e => handleItemChange(entry.WarehouseOperationItemId, e, entry))} ></input>
  //                           </div></td>
  //                           <td className="justify-content-center"><div className="text-nowrap">
                              
  //                             <input type="number" required step={0.01} value={entry.Price} className="text-center form-control-sm" id="inpPrice" onChange={(e => handleItemChange(entry.WarehouseOperationItemId, e, entry))} ></input>
  //                           </div></td>
  //                           <td className="justify-content-center"><div className="text-nowrap">{entry.Quantity > 0 && entry.Price > 0 && (entry.Quantity * entry.Price).toFixed(2).replace(".", ",")} zł</div></td>
  //                           <td className="justify-content-center"><div className="text-nowrap">{entry.ProductVATValue.toFixed(0).replace(".00", " %")}</div></td>
  //                           <td className="justify-content-center"><div className="text-nowrap">{entry.Quantity > 0 && entry.Price > 0 && (entry.Quantity * (entry.Price * ((entry.ProductVATValue / 100)))).toFixed(2).replace(".", ",")} zł</div></td>
  //                           <td className="justify-content-center"><div className="text-nowrap">{entry.Quantity > 0 && entry.Price > 0 && (entry.Quantity * (entry.Price * (1 + (entry.ProductVATValue / 100)))).toFixed(2).replace(".", ",")} zł</div></td>
  //                           <td className="justify-content-center">{warehouseOperationId === '0' && entry.IsLast === false && (
                            
  //                           <button
  //                   className="d-block mt-2 mx-auto btn btn-link text-decoration-none"
  //                   onClick={(e => removeItem(idx, e, entry))}
  //                 >
  //                   <i className="fas fa-minus" />
  //                 </button>
  //                           )}</td>
  //                           <td className="justify-content-center">{warehouseOperationId === '0' && entry.IsLast === true && (
                            
  //                           <button
  //                   className="d-block mt-2 mx-auto btn btn-link text-decoration-none"
  //                   onClick={addItem}
  //                 >
  //                   <i className="fas fa-plus" />
  //                 </button>
  //                           )}</td>
  //                         </tr>
  //                       ))}
  //                   </tbody>
  //                 </table>

  //               ) : (
  //                 <button
  //                   className="d-block mt-2 mx-auto btn btn-link text-decoration-none"
  //                   onClick={addItem}
  //                 >
  //                   <i className="fas fa-plus mr-2" />
  //                   Dodaj pozycję
  //                 </button>
  //               )
  //               }
  //             </div>
  //           </div>
  //         </div>
  //         <div className="d-flex flex-row-reverse m-2" ><input className="btn btn-primary" type="submit" onClick={submit} value="Zapisz"></input>
  //         </div>
  //       </div>
  //     )
  //   }
  // }
  // const getDate = date => {
  //   console.log(date)
  //   let a = date?.split(" ") || date?.split("-")
  //   return a[0] || date 
  // }
  return (
    <>
      <div>
        { 
          // getUI()
          FZ && (
            <div >
              <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
              {/* <Link to="/fzs">
                <button className="btn btn-link ml-1 text-decoration-none mb-3">
                    <i className="fas fa-arrow-left mr-2" />
                    Wróć
                </button>
              </Link> */}
              <Link to='/fzs'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={link}></img>
                    </label>
                </Link>
              <div className='card mb-3'>
                <div className='card-header'>
                  <div className='h5'>
                    {isNew ? "Nowa" : initialFZ.WarehouseOperationFullName || ""}
                  </div>
                  <table className="table table-striped">
                    <tbody>
                    <tr className='justify-content-center'>
                      <td>Nr oryginalny</td>
                      <td>Data faktury</td>
                      <td>Kontrahent</td>
                      <td>Magazyn</td>
                    </tr>
                    <tr>

                      <td>
                        <input
                        disabled={closed}
                        className= {`mt-2 ${!FZ.OriginalDocNumber && `invalid-input`} form-control form-control-sm`}
                        value={FZ.OriginalDocNumber}
                        onChange={evt => {const value = evt.target.value ;setFz(prev => ({...prev, OriginalDocNumber: value || ""}))}}
                        onFocus={() => {setFz(prev => ({...prev, OriginalDocNumber: ""})); setChanged(true)}}
                        />
                      </td>

                      <td>
                        <input
                        disabled={closed}
                        className={`mt-2 form-control form-control-sm ${!FZ.IssuedOn && `invalid-input`}`}
                        type='date'
                        value={FZ.IssuedOn}
                        onChange={evt => {setChanged(true); const value = evt.target.value ;setFz(prev => ({...prev, IssuedOn: value || ""}))}}
                        />
                      </td>
                      <td>
                        <input
                        disabled={closed}
                        value={FZ.ContractorName}
                        placeholder='Wybierz...'
                        className={`mt-2 form-control ${!FZ.ContractorName && `invalid-input`} form-control-sm`}
                        min={1}
                        onFocus={() => {setChanged(true); setFz(prev => ({...prev, ContractorName: "", ContractorId: null}))}}
                        onChange={handleChangeContractor}
                        list='contractors'
                        />
                        <datalist id='contractors'>
                          {
                            contractors && contractors.map((contractor, idx) => (
                              <option value={contractor.Name} key={idx}/>
                            ) )
                          }
                        </datalist>
                      </td>
                      
                      
                      <td>
                        <input
                        disabled={closed}
                        value={FZ.WarehouseFullName}
                        datalist='warehouses'
                        placeholder='Wybierz...'
                        className=  {`mt-2 form-control ${!FZ.WarehouseFullName && `invalid-input`} form-control-sm`}
                        min={1}
                        list='warehouses'
                        onFocus={() => {setChanged(true); setFz(prev => ({...prev, WarehouseFullName: "", FromWarehouseId: null}))}}
                        onChange={handleChangeWarehouse}
                        />
                        <datalist id='warehouses'>
                          {warehouses && warehouses.map((warehouse, idx) => (
                            <option value={warehouse.Name} key={idx}/>
                          ))}
                        </datalist>
                      </td>
                    </tr>
                    <tr className='justify-content-center'>
                      <td>Zapłacona</td>
                      <td>Data płatności</td>
                      <td>Komentarz / dodatkowe informacje</td>
                      <td>Powiązane dokumenty magazynowe</td>
                    </tr>
                    <tr className='justify-content-center'>

                    <td >
                      <div className='d-flex justify-content-center align-items-center'>

                        <input
                          disabled={closed}
                          type='checkbox'
                          className='form-check-input'
                          style={{width: "30px", height: "30px"}}
                          checked={FZ.IsPaid}
                          onChange={() => {setChanged(true); setFz({...FZ, IsPaid: !FZ.IsPaid})}}
                        />
                      </div>
                    </td>
                    <td>
                    <input
                        disabled={closed}
                        type='date'
                        className={`mt-2 ${!FZ.PaymentDate && `invalid-input`} form-control form-control-sm`}
                        value={FZ.PaymentDate}
                        onChange={evt => {setChanged(true); const value = evt.target.value ;setFz(prev => ({...prev, PaymentDate: value || ""}))}}
                        />
                    </td>
                    <td>
                      <input
                        className='mt-2 form-control form-control-sm'
                        type='text'
                        value={FZ.Comment}
                        onChange={evt => {setChanged(true);const value = evt.target.value ;setFz(prev => ({...prev, Comment: value || ""}))}}
                        onFocus={() => {setChanged(true); setFz(prev => ({...prev, Comment: ""}))}}
                      />
                    </td>
                    <td>
                        {FZ.ToWarehouseOperationId &&  <div className="text-nowrap"><Link to={`/pz/${FZ.ToWarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{FZ.RelatedDoc}</Link></div>}
                    </td>
                    </tr>
                    <tr className='justify-content-center'>
                      <td>Wartość netto</td>
                      <td>Wartość VAT</td>
                      <td>Wartość brutto</td>
                      <td>Stworzona</td>
                    </tr>
                    <tr className='justify-content-center'>
                      <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(totalNettoSum().toFixed(2)) || "0,00zł"}</td>
                      <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(totalVatSum().toFixed(2)) || "0,00zł"}</td>
                      <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(totalBruttoSum().toFixed(2)) || "0,00zł"}</td>
                      <td>{FZ.Created && `${moment(FZ.Created).format("YYYY.MM.DD")} ${FZ.Created.split(" ").at(-1)}`}</td>
                    </tr>
                    {closed && (
                        <tr>
                          <td colSpan={4} className='text-center'>

                          Inwentaryzacja
                          </td>
                        </tr>
                    )}
                    {closed && (
                        <tr>
                          <td colSpan={4}>
                            <div className='text-center mt-1'>
                                <Link
                                to={`/in/${FZ.WarehouseInventoryId}`}
                                    className="btn btn-outline-success"
                                    // disabled
                                >
                                    {FZ.WarehouseInventory}
                                </Link>
                            </div>
                          </td>
                        </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
              {!closed && (
                  <div>
                  <button
                    className="d-block btn btn-link text-decoration-none ml-auto mr-1"
                    onClick={() => {setChanged(true); setItems(prev => [{...newFZItem, Name: ""}, ...prev]);}}
                >
                    <i className="fas fa-plus mr-2" />
                </button>
                  </div>
              ) }
              <div className='card' style={{marginBottom: "40px"}}>
                
                <table className="table table-striped ">
                  <thead>
                    <tr>
                      <th>Produkt</th>
                      <th>Ilość</th>
                      <th>Cena jedn. netto</th>
                      <th>Wartość netto</th>
                      <th>Wartość VAT</th>
                      <th>Stawka VAT</th>
                      <th>Wartość brutto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.map((item, idx) => 
                      !item.deleted && 
                      (
                        <tr key={idx}>
                          <td>
                            <input
                              disabled={closed}
                              className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                              value={item.Name}
                              onChange={evt => {setChanged(true); handleChangeItem(item.WarehouseOperationItemId, evt)}}
                              list='products'
                              onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Name: "", ProductId: ""}) : pr))}}
                            />
                            <datalist id='products'>
                              {products && products.map((product, idx) => (<option key={idx} value={product.Name} />))}
                            </datalist>
                          </td>
                          <td>
                            <input
                            disabled={closed}
                            type='text'
                            className={`form-control ${(!item.Quantity || item.Quantity == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                            value={item.Quantity}
                            onChange={evt => {setChanged(true); handleChangeQuantity(item.WarehouseOperationItemId, evt.target.value)}}
                            onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Quantity: ""}) : pr))}}
                            />
                          </td>
                          <td>
                            <input
                            disabled={closed}
                            type='number'
                            className={`form-control ${(!item.Price || item.Price == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                            value={item.Price}
                            onChange={evt => {setChanged(true); handleChangePrice(item.WarehouseOperationItemId, evt.target.value)}}
                            onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Price: ""}) : pr))}}
                            />
                          </td>
                          <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(getNetto(item)) || "0,00zł"}</td>
                          <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(getVat(getNetto(item), item.Value)) || "0,00zł"}</td>
                          <td>{item.Value}%</td>
                          <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(getBrutto(getNetto(item), getVat(getNetto(item), item.Value))) || "0,00zł"}</td>
                          <td>
                            {!closed && (
                              <button className="btn btn-link btn-sm" onClick={() => {setChanged(true); deleteItem(item.WarehouseOperationItemId)}}>
                                <i className="fas fa-trash" />                                
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          )
          

        
        }
                <div className='position-fixed container' style={{bottom: "0px"}}>

                  <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                  <button disabled={!avaiableToSave || !changed || isSaved} className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                      Zapisz
                  </button>
                  </div>
                </div>
      </div>
    </>
  )
}

export default FZ;