export default function parseURLQuery (url) {
    

    let urlClass

    try {
        urlClass = new URL(url)
    } catch (e) {
        return null
    }

    const regexToSplitParams = /[?&]([^=#]+)=([^&#]*)/g
    const paramsObj = {}
    let match
    let searchQuery = urlClass.search
    while (match = regexToSplitParams.exec(searchQuery)) {
        paramsObj[match[1]] = match[2]
    }

    return paramsObj
}