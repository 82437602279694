import React, { useContext, useEffect, useState } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import { Prompt, useParams, Link, useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import useFetch from '../../hooks/fetchMSSQL-hook' 
import moment from 'moment'
import lodash from 'lodash'
import { NotificationManager } from 'react-notifications'

export default function WarehouseInventoryRelatedDoc() {
    const param = useParams()
    const [INRelDoc, setINRelDoc] = useState({})
    const { setHeaderData } = useContext(NavigationContext)
    const { fetchMssqlApi } = useFetch()
    const history = useHistory()
    const getNA = () => {
        fetchMssqlApi(`in/rel-doc/${param.id}`, {}, res => setINRelDoc(res))
    }
    useEffect(() => {
        // setHeaderData({text: "Nadstan"})
        getNA()
    }, [])
    useEffect(() => {
        setHeaderData({text: INRelDoc.WarehouseOperationTypeId == 12 && "Nadstan" || INRelDoc.WarehouseOperationTypeId == 13 && "Brak" })
    }, [INRelDoc])
    return (
        INRelDoc && (
            <div className='container'>
            <Link to={`/in/${INRelDoc.WarehouseInventoryId}`} className="btn btn-link mt-0 mb-1 ml-1 text-decoration-none mb-3">
                    <div>

                        <i className="fas fa-arrow-left mr-2" />
                        Wróć do inwentaryzacji
                    </div>
            </Link>
                    <div className='card mb-4'>
                        <div className='card-header'>
                            <div className='h5 mt-2'>{INRelDoc.WarehouseOperationFullName}</div>
                        </div>
                        <div className='card-body'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr className='text-center'>
                                        <td>Magazyn</td>
                                        <td>Stworzona</td>
                                        <td>Suma</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='text-center'>
                                        <td>{INRelDoc.Warehouse}</td>
                                        <td>{INRelDoc.User}</td>
                                        <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(Number(INRelDoc.Total).toFixed(2)) || "0,00zł"}</td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>Opis</td>
                                        <td></td>
                                        <td className='text-center'>Inwentaryzacja</td>
                                    </tr>
                                    <tr >
                                    <td>
                                        <div className='d-flex justify-content-center align-items-center mt-1 mb-1'>
                                            {INRelDoc.Comment}
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div className='d-flex justify-content-center align-items-center mt-1 mb-1'>
                                            
                                            <div>
                                                <Link
                                                    className="btn btn-outline-primary"
                                                    to={`/in/${INRelDoc.WarehouseInventoryId}`}
                                                >
                                                    {INRelDoc.WarehouseInventoryFullName}
                                                </Link>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>
            
        ))
}