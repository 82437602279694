import React, { useState, useEffect } from "react";

export default ({
  className,
  style,
  name,
  value,
  handleChange,
  list,
  newList,
  onFocus,
  disabled,
  required = true,
}) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(
    () => setIsValid(list.includes(value) || value === undefined),
    [value, list]
  );

  return (
    <>
      <input
        className={`form-control form-control-sm ${className} ${
          !isValid && required ? "invalid-input" : ""
        }`}
        {...{ name, value, style }}
        list={name}
        onChange={handleChange}
        data-valid={isValid}
        onFocus={onFocus}
        disabled={disabled}
      />
      {newList && (
        <datalist id={name}>
          {list.map((item, idx) => (
            <option key={idx} value={item} />
          ))}
        </datalist>
      )}
    </>
  );
};
