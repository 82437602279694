import React, {useContext, useEffect, useState} from 'react'
import {NavigationContext} from '../../../context/navigation-context'
import {LangContext} from '../../../context/lang-context'
import {useHistory, useParams} from 'react-router'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useForm from '../../../hooks/form-hook'
import useFilter from '../../../hooks/filter-hook'

import NoResults from '../../../components/NoResults/NoResults'
import Pagination from '../../../components/Pagination/Pagination'
import Filter from '../../../components/Filter/Filter'
import FilterEx from '../../../components/Filter/FilterEx'
import ProductsTable from './components/ProductsTable'
import Recipes from './components/RecipeTable'

import sampleProduct from '../../../assets/images/sample-product.svg'

import {API_URL} from '../../../config/config'
import filterItems from '../../../util/filterItems'
import ProductForm from '../../../components/Modals/ProductForm'
import AcceptForm from '../../../components/Modals/AcceptForm'
import {Link} from 'react-router-dom/cjs/react-router-dom.min'
import RecipeForm from './forms/RecipeForm'
import ProductFormDB from '../../../components/Modals/ProductFormDashboard'

const Products = () => {
    const {fetchMssqlApi} = useFetch()
    const {setHeaderData} = useContext(NavigationContext)
    const {TRL_Pack} = useContext(LangContext)
    const {searchedText, updateSearchedText, page, updateCurrentPage} = useFilter()
    const {categoryId} = useParams()
    const history = useHistory()
    const params = useParams()
    const resetPage = () => setFilter(prev => ({...prev, page: 1}))
    const resetFilter = () => setFilter(defaultFilter)
    const toggleFilter = () => setFilter(prev => ({...prev, visible: !prev.visible}))
    const {form, openForm, closeForm} = useForm()
    const [subscribed, setSubscribed] = useState(false)
    const [ean, setEan] = useState(null)
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])
    const [productType, setProductType] = useState(
        sessionStorage.getItem('DB_TYPE') === "mysql" ? "Products" : String(window.location).split('/').at(-1) === "products" ? "Products" : "Recipes")
    const [categoryName, setCategoryName] = useState("")
    const [eans, setEans] = useState([])
    const [recipes, setRecipes] = useState([])

    const [clientId, setClientId] = useState("")

    // const [groupName, setGroupName] = useState("")
    // const [groups, setGroups] = useState([])
    // const [isCoffeeOrSnack,  setIsCoffeeOrSnack] = useState(0)
    const handleSwitchPage = pageNo => () => setFilter(prev => ({...prev, page: pageNo}))

    const getCategories = () => {
        fetchMssqlApi('categories', {}, categories => {
            setCategories(categories)
            if (categoryId) {
                const currentCategory = categories.find(c => c.CategoryId === categoryId)
                if (currentCategory)
                    setHeaderData({
                        text: TRL_Pack.definitions.products,
                        subtext: currentCategory.Name
                    })
                else history.replace('/products')
            }
        })
    }
    const getProducts = () => {
        if (categoryId) {
            fetchMssqlApi(`products/${categoryId}`, {}, (products) => setProducts(products))
        } else fetchMssqlApi('products', {}, (products) => setProducts(products))
    }
    // const getGroups = () => {
    //   if(!groupName) {
    //     fetchMssqlApi('/products/groups', {}, groups => setGroups(groups))
    //   }
    // }
    const submitProduct = productCategories => evt => {
        evt.preventDefault()

        const {ean, name, description, image, vatId, lastPriceNetto} = evt.target.elements

        const formData = new FormData()
        formData.append('Ean', ean.value)
        formData.append('Name', name.value)
        formData.append('Description', description.value)
        formData.append('VATId', vatId.value)
        formData.append('LastPriceNetto', lastPriceNetto.value)

        if (image.files[0]) formData.append('Image', image.files[0])

        let path, method
        if (form !== 'new') {
            path = `product/${form}`
            method = 'PUT'

        } else {
            path = ''
            method = 'POST'
        }

        fetchMssqlApi(path, {method, data: formData}, () => {
            if (ean.value !== '0') {
                productCategories.added.forEach(categoryId =>
                    fetchMssqlApi(`category/${ean.value}`, {
                        method: 'POST',
                        data: {categoryId, "IsSubscribed": 0}
                    })
                )
                productCategories.deleted.forEach(categoryId =>
                    fetchMssqlApi(`category/${ean.value}`, {
                        method: 'DELETE',
                        data: {categoryId}
                    })
                )
            }
            closeForm()
            getProducts()
        })
    }

    const unsubscribeProduct = ean => {
        fetchMssqlApi(`product/${ean}`, {method: 'DELETE'}, getProducts)
    }
    const getRecipes = () => {
        sessionStorage.getItem('DB_TYPE') !== "mysql" && fetchMssqlApi("/recipes", {}, rec => setRecipes(rec))
    }
    const deleteProduct = ean => {
        fetchMssqlApi(`product/${ean}`, {method: 'DELETE'}, getProducts)
    }

    useEffect(() => {
        setHeaderData({text: TRL_Pack.definitions.products})
        getCategories()
        getProducts()
        localStorage.getItem("clientId") !== "console" && getRecipes()
        // getGroups()
        // console.log(getProducts)
        fetchMssqlApi('machines', {}, data => {
            setClientId(data[0].ClientId)
        })

    }, [])
    useEffect(() => {
        setHeaderData(productType === "Products" ? {text: "Produkty"} : {text: "Receptury"})
    }, [productType])

    useEffect(() => {
        if (productType === "Products" || productType === "Ingredients") {
            productType === "Ingredients"
                ? fetchMssqlApi("/ingredients-list", {}, products => setProducts(products))
                : fetchMssqlApi("/products", {}, products => setProducts(products))
        }
    }, [productType])

    let defaultFilter;
    {/* <input type='checkbox' name="showIndexes"></input> */
    }
    if (localStorage.getItem("clientId") !== "console" && sessionStorage.getItem("DB_TYPE") !== "mysql") {
        defaultFilter = {
            showIndexes: false,
            page: 1,
            rowsPerPage: 25,
            rowsPerPageOptions: [10, 25, 50],
            name: "products",
            visible: false,
            sortByColumns: false,
            sortBy: '3 | asc | text',
            activeTags: [],
            disableIndexes: "true",
            columns: [
                {
                    id: 1,
                    name: "EAN",
                    type: "lookup",
                    // type: "text",
                    sortable: true,
                    searchable: true,
                },
                {
                    id: 2,
                    name: "Nazwa",
                    sortable: true,
                    searchable: true,
                    type: 'lookup',
                    // type: 'text',
                    disabled: false,
                    searchbar: ""
                },
                {
                    id: 5,
                    name: "Grupa",
                    sortable: true,
                    searchable: true,
                    type: 'lookup',
                    disabled: false

                },
                {
                    id: 9,
                    name: "Stawka VAT",
                    sortable: true,
                    selectable: true,
                    type: 'lookup',
                    disabled: false
                },
                {
                    id: 11,
                    name: "Składnik",
                    sortable: false,
                    selectable: true,
                    disabled: false,
                    type: "threeStateBoolByActivity",
                    // type: "bool",
                    selectbar: null
                },
                {
                    id: 12,
                    name: "Aktywny",
                    sortable: true,
                    selectable: true,
                    disabled: false,
                    type: "threeStateBoolByActivity",
                    // type: "bool",
                    value: "true",
                    selectbar: "true"
                },
                {
                    id: 14,
                    name: "Ilość składnika",
                    sortable: false,
                    selectable: true,
                    disabled: true,
                    type: "bool",
                },
                {
                    id: 16,
                    name: TRL_Pack.products.filter.isShared,
                    sortable: true,
                    selectable: true,
                    type: 'bool',
                    disabled: true
                },
                {solid: "true"}
            ]
        }
    } else {
        defaultFilter = {
            showIndexes: false,
            page: 1,
            rowsPerPage: 25,
            rowsPerPageOptions: [10, 25, 50],
            visible: false,
            sortByColumns: true,
            sortBy: '3 | asc | text',
            activeTags: [],
            disableIndexes: "true",
            columns: [
                {
                    id: 1,
                    name: TRL_Pack.products.filter.ean,
                    type: "number",
                    sortable: true,
                    searchable: true,
                },
                {
                    id: 2,
                    name: TRL_Pack.products.filter.productName,
                    sortable: true,
                    searchable: true,
                    type: 'text',
                },
                {
                    solid: "true"
                },
                // () => { if (sessionStorage.getItem("DB_TYPE") !== "mysql") return ({ solid: "false" }) },
            ]
        }
    }

    const reportFilter = row =>
        filter.columns
            .filter(col => col.selectbar)
            .every(col => {
                    const x = String(row[Object.keys(row)[col.id - 1]])
                        .toLowerCase() === col.selectbar.toLowerCase()
                    return x
                }
            )

    const reportFilterSecond = row =>
        filter.columns
            .filter(col => col.searchbar ? col.searchbar : col.selectbar)
            .every(col => {
                    const x = String(row[Object.keys(row)[col.id - 1]])
                        .toLowerCase()
                        .includes(col.searchbar ? col.searchbar.toLowerCase() : col.selectbar.toLowerCase())
                    return x
                }
            )

    const sortRows = (a, b) => {
        const [id, order, type] = filter.sortBy.split(' | ')

        const col = Object.keys(products[0])[Number(id) - 1]

        if (a[col] === b[col]) return 0
        else if (a[col] === null) return 1
        else if (b[col] === null) return -1

        let valueA, valueB
        if (type === 'text' || type === 'date') {

            valueA = a[col].toLowerCase()
            valueB = b[col].toLowerCase()
        } else if (type === "bool") {
            valueA = Number(a[col])
            valueB = Number(b[col])
        } else if (type === 'number') {
            valueA = Number(a[col]);
            valueB = Number(b[col]);
        } else if (type === 'price') {
            valueA = Number(a[col].replace(',', ''))
            valueB = Number(b[col].replace(',', ''))
        } else return 0
        if (order === 'asc') return valueA < valueB ? -1 : 1
        else return valueA < valueB ? 1 : -1
    }

    const returnParsedIsShared = (col) => {
        if (typeof col === 'string' || typeof col === 'number') {
            return col
        } else if (typeof col === 'boolean') {
            if (col === true) {
                return <input type='checkbox'
                              checked={true}
                              style={{width: "15px", height: "15px", marginTop: "6px"}}
                              onChange={() => {
                              }}></input>
            } else if (col === false) {
                return <input type='checkbox'
                              checked={false}
                              style={{width: "15px", height: "15px", marginTop: "6px"}}
                              onChange={() => {
                              }}></input>
            }
            // return col
        }
    }

    const handleVatId = (product) => {
        if (product.VATId === 1) {
            return "5%"
        } else if (product.VATId === 2) {
            return "8%"
        } else if (product.VATId === 3) {
            return "23%"
        } else if (product.VATId === 4) {
            return "0%"
        }
    }

    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('productFilter')) {
            return JSON.parse(localStorage.getItem('productFilter'))
        } else return defaultFilter
    })
    useEffect(() => localStorage.setItem('productFilter', JSON.stringify(filter)), [filter])

    const filteredProducts = products.filter(({Name}) => filterItems(searchedText, Name))
    const [filProducts, setFilProducts] = useState([])

    useEffect(() => {
        if (!filProducts.length) setFilProducts(products)
        if (products && recipes) {

            setEans([...products, ...recipes].map(product => product.EAN))
        } else if (products) {
            setEans([...products].map(product => product.EAN))
        } else if (recipes) {
            setEans([...recipes].map(product => product.EAN))
        }
        // setEans(prev => [...prev, [...recipes].map(recipe => recipe.EAN)])
    }, [products, recipes])

    const handleModal = (ean, isSubscribed) => {
        setSubscribed(isSubscribed)
        setEan(ean)
        openForm("acceptModal")()

    }

    const search = () => {
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                col.selectbar = col.value
                return col
            })
        }))
    }

    return (
        <div>
            <ul className='nav nav-tabs machine-tabs mb-3'>
                <li className="nav-item">
                    <Link to='/products'>
                        <button
                            className={`nav-link btn ${productType === "Products" && 'active'}`}
                            onClick={() => setProductType("Products")}
                            tabIndex="0">
                            Produkty
                        </button>
                    </Link>
                </li>

                {sessionStorage.getItem('DB_TYPE') !== "mysql" && (
                    <li className="nav-item">
                        <Link to='/recipes'>
                            <button
                                className={`nav-link btn ${productType === "Recipes" && 'active'}`}
                                onClick={() => setProductType("Recipes")}
                                tabIndex="0">
                                Receptury
                            </button>
                        </Link>
                    </li>
                )}
            </ul>

            {products.length ? (
                <>
                    {/* <SearchInput onSearch={updateSearchedText} /> */}
                    {productType === "Products" && (
                        <>
                            <Pagination
                                totalItems={filteredProducts.length}
                                page={filter.page}
                                rowsPerPage={filter.rowsPerPage}
                                handleSwitchPage={handleSwitchPage}
                                filterVisibility={filter.visible}
                                toggleFilter={toggleFilter}
                                resetFilter={resetFilter}

                            />
                            {filter.visible && localStorage.getItem("clientId") !== "console" && (
                                sessionStorage.getItem("DB_TYPE") !== "mysql" ? (
                                    <FilterEx
                                        filter={filter}
                                        setFilter={setFilter}
                                        columns={filter.columns}
                                        data={products}
                                        resetPage={resetPage}
                                        resetFilter={resetFilter}
                                        search={search}
                                        toggleFilter={toggleFilter}
                                    />) : (
                                    <Filter
                                        {...{
                                            filter,
                                            setFilter,
                                            columns: filter.columns,
                                            data: products,
                                            resetPage,
                                            resetFilter
                                        }}
                                    />
                                ))}
                            {(sessionStorage.getItem('DB_TYPE') === 'mssql' || clientId === "DefaultIMG") && (
                                <div>
                                    <button
                                        className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                                        onClick={openForm()}
                                    >
                                        <i className="fas fa-plus mr-2"/>
                                        {TRL_Pack.products.addProductButton}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {!filteredProducts.length ? (
                        <NoResults buttonText={TRL_Pack.products.addProductButton} onClick={() => {
                        }}/>
                    ) : (
                        <>
                            {productType === "Products" && (
                                sessionStorage.getItem('DB_TYPE') === "mysql" ? (
                                    <table className='table table-striped'>
                                        <thead>
                                        <tr>
                                            {filter.showIndexes && <th className="text-center">#</th>}
                                            {filter.columns
                                                .filter(col => !col.hidden && !col.disabled)
                                                .map((col, idx) => (
                                                    <th key={idx}>{col.name}</th>
                                                ))}
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {filteredProducts
                                            .filter(reportFilterSecond)
                                            .sort(sortRows)
                                            .slice(
                                                (filter.page - 1) * filter.rowsPerPage,
                                                filter.page * filter.rowsPerPage
                                            )
                                            .map((product, idx) => (
                                                <tr key={idx}>
                                                    {Object.keys(product)
                                                        .filter((col, col_idx) =>
                                                            filter.columns
                                                                .filter(col => !col.hidden && !col.disabled)
                                                                .map(col => col.id)
                                                                .includes(col_idx + 1)
                                                        )

                                                        .map((col, col_idx) => (
                                                            <td key={col_idx}
                                                                className={col !== "Składnik" ? "small" : "d-flex justify-content-center"}>
                                                                <button
                                                                    style={{wordBreak: 'break-word'}}
                                                                    className="btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                                                                    onClick={() => {
                                                                        openForm(product.EAN)
                                                                    }}>
                                                                    {sessionStorage.getItem("DB_TYPE") !== "mysql" ? returnParsedIsShared(product[col]) : product[col]}
                                                                </button>
                                                            </td>
                                                        ))}
                                                    <td key={product.VATId} className='small'
                                                        style={{minWidth: "70px"}}>
                                                        <button
                                                            style={{wordBreak: 'break-word'}}
                                                            className="btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                                                            onClick={() => clientId === 'DefaultIMG' && openForm(product.EAN)}>
                                                            {handleVatId(product)}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={`${API_URL}/${product.Image}`}
                                                            onError={evt => (evt.target.src = sampleProduct)}
                                                            alt={product.Name}
                                                            width="48"
                                                            height="48"
                                                        />
                                                    </td>
                                                    <td>
                                                        {!product.IsSubscribed ?
                                                            clientId === 'DefaultIMG' && (
                                                                <button
                                                                    className="btn btn-link"
                                                                    // onClick={deleteProduct(product.EAN)}
                                                                    onClick={() => handleModal(product.EAN, product.IsSubscribed)}
                                                                >
                                                                    <i className="fas fa-trash text-danger"/>
                                                                </button>
                                                            ) : <button
                                                                className="btn btn-link"
                                                                onClick={() => handleModal(product.EAN, product.IsSubscribed)}
                                                                // onClick={unsubscribeProduct(product.EAN)}

                                                            >
                                                                <i className="fas fa-times text-danger"/>
                                                            </button>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <ProductsTable
                                        filter={filter}
                                        filteredProducts={filteredProducts}
                                        handleModal={handleModal}
                                        openForm={openForm}
                                        reportFilter={reportFilter}
                                        sortRows={sortRows}
                                        returnParsedIsShared={returnParsedIsShared}
                                        handleVatId={handleVatId}
                                        API_URL={API_URL}
                                        sampleProduct={sampleProduct}
                                        category={categories.find(category => category.Name === categoryName)?.CategoryId}/>
                                )
                            )}
                            {productType === "Recipes" && (
                                <Recipes products={products}
                                         vat={handleVatId}
                                         category={categoryName && categories.find(category => category.Name === categoryName).CategoryId || null}
                                         setProductType={setProductType}
                                         eans={eans}
                                         data={recipes}
                                />
                            )
                            }
                        </>
                    )}
                </>
            ) : (
                <NoResults buttonText={productType === "Products" ? TRL_Pack.products.addProduct : "Dodaj recepturę"}
                           onClick={openForm()}/>
            )
            }{
            form === "acceptModal" && form && (
                <AcceptForm ean={ean} handleClose={closeForm} deleteProduct={deleteProduct}
                            unsubscribeProduct={unsubscribeProduct} IsSubscribed={subscribed}/>
            )
        }
            {
                form !== "acceptModal" && form && (
                    productType === "Products" ? (sessionStorage.getItem('DB_TYPE') === 'mysql' ? (

                        <ProductForm
                            form={form}
                            productData={
                                form !== 'new' && form !== "acceptModal" && form !== 'newIngredient' ? filteredProducts.find(product => product.EAN === form) : null
                            }
                            getProducts={getProducts}
                            categories={categories}
                            handleSubmit={submitProduct}
                            handleClose={closeForm}
                            isIngredient={form !== 'new'
                                && form !== "acceptModal"
                                && filteredProducts.find(product => product.EAN === form)?.isIngredient}
                            setCategoryName={setCategoryName}
                            deleteProduct={deleteProduct}
                            eans={eans}
                        />
                    ) : (
                        <ProductFormDB
                            form={form}
                            productData={
                                form !== 'new' && form !== "acceptModal" && form !== 'newIngredient' ? filteredProducts.find(product => product.EAN === form) : null
                            }
                            getProducts={getProducts}
                            categories={categories}
                            handleSubmit={submitProduct}
                            handleClose={closeForm}
                            isIngredient={form !== 'new'
                                && form !== "acceptModal"
                                && filteredProducts.find(product => product.EAN === form)?.isIngredient}
                            setCategoryName={setCategoryName}
                            deleteProduct={deleteProduct}
                            eans={eans}
                        />
                    )) : (
                        <RecipeForm
                            handleClose={closeForm}
                            form={form}
                            eans={eans}
                        />
                    )
                )
            }
        </div>
    )
}

export default Products;