import React, { useContext, useEffect, useState } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import { Prompt, useParams, Link, useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import useFetch from '../../hooks/fetchMSSQL-hook' 
import moment from 'moment'
import lodash from 'lodash'
import { NotificationManager } from 'react-notifications'
import INLoader from '../../components/INLoader/INLoader'
import link from "../../assets/images/back_to_list_icon.svg"

export default function IN() {
    //=========================
    const [IN, setIN] = useState({})
    const [initialIN, setInitialIN] = useState({})
    const [initialItems, setInitialItems] = useState([])
    const [items, setItems] = useState([])
    const [warehouses, setWarehouses] = useState([])
    const [changed, setChanged] = useState(false)
    const [avaiableToSave, setAvaiableToSave] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [isLoading ,setIsLoading] = useState(false)
    const params = useParams()
    const { setHeaderData } = useContext(NavigationContext)
    const { fetchMssqlApi } = useFetch()
    const history = useHistory()
    const _ = lodash
    //=========================
    // default constants
    const defaultWarehouseInventory = {
        Comment: "",
        WarehouseId: null,
        WarehouseName: "",
        WarehouseInventoryId: null,
        CreateUserName: "",
        IssuedOn: moment().format('YYYY-MM-DDTHH:MM'),
        IsClosed: false
    }
    //=========================
    const handleChangeWarehouse = (e) => {
        const evt = e.target
        setChanged(true)
        const warehouse = warehouses.find(data => data.Name === evt.value)
        if (!warehouse) {
        evt.className += " invalid-input"
        setIN(prev => ({...prev, WarehouseName: evt.value}))
        } else {
        evt.className = "mt-2 form-control form-control-sm"
        setIN(prev => ({...prev, WarehouseName: evt.value, WarehouseId: warehouse.WarehouseId}))
        }
    }
    const getCurrentState = ({ Incomes, Releases, Loads, Unloads}) => Number(Incomes) - Number(Releases) - Number(Loads) + Number(Unloads)
    const getIn = (whi = params.id) => {
        console.log("ID: ", params.id)
        if (params.id !== "0") {
            try {
                fetchMssqlApi(`in/${whi}`, {}, res => {
                    setInitialIN(res.WarehouseInventory)
                    setInitialItems(res.Items.map(item => item.Quantity > 0 ? item : ({...item, Quantity: 0})))
                })
            } catch (e) {
                console.log("error:", e)
            }
        }
    }
    const getWarehouses = () => {
        fetchMssqlApi('warehouses-list', {}, warehouses => setWarehouses(warehouses))
    }    
    const getWarehouseState = () => {
        if (moment(IN.IssuedOn).format('YYYY-MM-DD HH:MM') > moment().format('YYYY-MM-DD HH:MM')) {
            NotificationManager.error("Wprowadż poprawną datę")
            setIN(prev => ({...prev, IssuedOn: moment().format('YYYY-MM-DD HH:MM')}))
            return
        } 
        fetchMssqlApi('in/warehouse-status', 
        {method: "POST", 
        data: {
            WarehouseId: IN.WarehouseId, 
            CutOfDate: moment(IN.IssuedOn).format('YYYY-MM-DD HH:MM:SS')
        },
        hideNotification: true,
        // showLoader: false
        },
        res =>{setItems(res.map(item => ({...item, Quantity: getCurrentState(item)})))}
        )
    }
    const addWarehouseInventory = () => {
        // if (!IN.WarehouseId || !IN.IssuedOn) return
        const whInvItems = items.map(({ProductId, Quantity}) => ({ProductId, Quantity}))
        const payload = {
            WarehouseId: IN.WarehouseId,
            Comment: IN.Comment,
            WarehouseInventoryItems: JSON.stringify(whInvItems)
        }
        console.log(payload)
        setIsLoading(true)
        fetchMssqlApi('in', {method: "POST", data: payload}, res => {
            setChanged(false)
            fetchMssqlApi(`in/${res.Id}`, {}, res => {
                setInitialIN(res.WarehouseInventory)
                setInitialItems(res.Items.map(item => item.Quantity > 0 ? item : ({...item, Quantity: 0})))
            })
            history.replace(res.Id)
            console.log(res)
            setIsLoading(false)
        })
    }
    const changeItemQuantity = (item, value) => {
        const id = item.ProductCode
        setItems(prev => prev.map(product => product.ProductCode === id ? ({...product, Quantity: value}) : product))
    }
    useEffect(() => {
        setHeaderData({text: "Inwentaryzacja"})
        getIn()
        getWarehouses()
    }, [])
    useEffect(() => {
        _.isEmpty(initialIN) ? setIN(defaultWarehouseInventory) : setIN(initialIN)
    }, [initialIN])
    useEffect(() => {
        !_.isEmpty(initialIN) && setItems(initialItems) 
    }, [initialItems])
    return (
        IN && (
        <>
        {
            isLoading && <INLoader text="Wyliczanie stanów magazynowych trwa. Proszę czekać..."/>
        }
        <div className='container'>
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            {/* <Link to="/ins">
                <button className="btn btn-link mt-0 mb-1 ml-1 text-decoration-none mb-3">
                    <i className="fas fa-arrow-left mr-2" />
                    Wróć
                </button>
            </Link> */}
            <Link to='/ins'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={link}></img>
                    </label>
            </Link>
            {!_.isEmpty(IN) && params.id !== "0" ? (
                <>
                    <div className='card mb-4'>
                        <div className='card-header'>
                            <div className='h5 mt-2'>{IN.IN}</div>
                        </div>
                        <div className='card-body'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr className='text-center'>
                                        <td>Stworzona</td>
                                        <td>Magazyn</td>
                                        <td>Dodatkowe informacje</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='text-center'>
                                        <td>{IN.IssuedOn} ({IN.CreateUserName})</td>
                                        <td>{IN.WarehouseName}</td>
                                        <td>{IN.Comment}</td>
                                    </tr>
                                    <tr >
                                        <td>
                                            <div className='text-center mt-3'>
                                                <Link
                                                to={`/na/${IN.NAId}`}
                                                    className="btn btn-outline-success"
                                                    // disabled
                                                >
                                                    {IN.NA}
                                                </Link>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td>
                                            <div className='text-center mt-3'>
                                                <Link
                                                to={`/br/${IN.RWId}`}
                                                    className="btn btn-outline-danger"
                                                    // disabled
                                                >
                                                    {IN.RW}
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='card'>
                        {/* <div className='card-header'>
                            <div className='h6 mt-2'>Produkty</div>
                        </div> */}
                        <div className='card-body overflow-auto' style={{maxHeight: "41vh"}}>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <td>Produkt</td>
                                        <td>Ilość oczekiwana</td>
                                    </tr>
                                </thead>
                                <tbody>
                                        {items.length ? items.map(item => (
                                            <tr key={item.ProductCode}>
                                                <td >
                                                    {item.ProductName}
                                                </td>
                                                <td >
                                                    {+item.Quantity > 0 ? item.Quantity : 0}
                                                </td>
                                            </tr>
                                        )) : <></>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            ) : (
                <>
                <div className='card mb-4'>
                    {/* <div className='card-header'>
                        <div className='h5 mt-2'>{IN.IN || }</div>
                    </div> */}
                    <div className='card-body'>
                        <table className='table table-striped'>
                            <thead>
                                <tr className='text-center'>
                                    <td>Zakres czasowy</td>
                                    <td>Magazyn</td>
                                    <td>Dodatkowa informacja</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-center'>
                                    <td>
                                        <input
                                            type='datetime-local'
                                            disabled={items.length}
                                            className=  {`mb-2 mt-2 form-control ${!IN.IssuedOn && `invalid-input`} form-control-sm`}
                                            value={IN.IssuedOn || ""}
                                            onChange={evt => {const value = evt.target.value; setIN(prev => ({...prev, IssuedOn: value}))}}
                                        />
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-center'>
                                            <input
                                                value={IN.WarehouseName || ""}
                                                datalist='warehouses'
                                                placeholder='Wybierz...'
                                                disabled={items.length}
                                                className=  {`mb-2 mt-2 form-control ${!IN.WarehouseName && `invalid-input`} form-control-sm`}
                                                list='warehouses'
                                                style={{maxWidth: "30vh"}}
                                                onFocus={() => {setChanged(true); setIN(prev => ({...prev, WarehouseName: "", WarehouseId: null}))}}
                                                onChange={handleChangeWarehouse}
                                            />
                                            <datalist id='warehouses'>
                                            {warehouses && warehouses.map((warehouse, idx) => (
                                                <option value={warehouse.Name} key={idx}/>
                                            ))}
                                            </datalist>
                                        </div>

                                        
                                    </td>
                                    <td>
                                        <textarea
                                            className='form-control'
                                            value={IN.Comment}
                                            onChange={evt => {const value = evt.target.value; setIN(prev => ({...prev, Comment: value}))}}
                                        />
                                        {/* {IN.Comment} */}
                                        </td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <div className='d-flex justify-content-center align-items-center mt-3'>
                                            {items.length ? (
                                                <button 
                                                    className="btn btn-success"
                                                    disabled={!IN.IssuedOn || !IN.WarehouseId}
                                                    onClick={addWarehouseInventory}
                                                >
                                                    Zamknij inwentaryzację
                                                </button>
                                            ) : (
                                                <button 
                                                    className="btn btn-outline-primary"
                                                    disabled={!IN.IssuedOn || !IN.WarehouseId}
                                                    onClick={getWarehouseState}
                                                >
                                                    Wylicz stan
                                                </button>
                                            ) }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {items.length ? (
                <div className='card'>
                    <div className='card-body overflow-auto' style={{maxHeight: "41vh"}}>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <td>Produkt</td>
                                    <td>Ilość</td>
                                </tr>
                            </thead>
                            <tbody>
                                    {items.map(item => (

                                        <tr key={item.ProductCode} 
                                            className= {selectedItem === item.ProductCode ? "bg-warning" : ""} 
                                            onFocus={() => selectedItem === item.ProductCode ? setSelectedItem(null) : setSelectedItem(item.ProductCode)}
                                            onClick={evt => evt.target.name !== "input" && setSelectedItem(null)}
                                        >
                                            <td className='mt-2 mb-2'>
                                            {`${item.ProductName} (${item.ProductCode})`}
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-start'>
                                                    <input
                                                        id={item.ProductCode}
                                                        name="input"
                                                        className='form-control'
                                                        value={item.Quantity}
                                                        // autoFocus={true}
                                                        onChange={evt => {
                                                            const value = evt.target.value.replace(/[^0-9-]/g, '')
                                                            changeItemQuantity(item, value || "")}
                                                        }
                                                        autoFocus={selectedItem === item.ProductCode}
                                                        style={{maxWidth: "5vw", maxHeight: "5vh"}}
                                                        onFocus={() => {
                                                            // selectedItem === item.ProductCode ? setSelectedItem(null) : setSelectedItem(item.ProductCode)
                                                            setItems(prev => prev.map(i => i.ProductCode === item.ProductCode && item.Quantity == 0 ? ({...i, Quantity: ""}) : i))
                                                        }}
                                                        onBlur={() => !item.Quantity && setItems(prev => prev.map(i => i.ProductCode === item.ProductCode ? ({...i, Quantity: 0}) : i))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                    ) : (<></>)}
            </>
            )}
        </div>
        </>
        )
    ) 
}
