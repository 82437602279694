import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import pl from 'javascript-time-ago/locale/pl'

import Dashboard from './dashboard_env/app/App'
import * as serviceWorker from './serviceWorker'
// import { DB_TYPE } from './env'

import LangProvider from './dashboard_env/context/lang-context';
import LoaderProvider from './dashboard_env/context/loader-context';
import ErrorProvider from './dashboard_env/context/error-context';
import NotificationProvider from './dashboard_env/context/notification-context';

import 'react-notifications/lib/notifications.css'

JavascriptTimeAgo.addLocale(en)
JavascriptTimeAgo.addLocale(pl)

// const environment = process.env.CLIENT_ENVIRONMENT || DB_TYPE
// if (process.env.NODE_ENV === 'development') console.log(`Environment: ${environment}`)

render(
  <BrowserRouter basename="dashboard">
    <LangProvider>
      <LoaderProvider>
        <ErrorProvider>
          <NotificationProvider>
            <Dashboard />
          </NotificationProvider>
        </ErrorProvider>
      </LoaderProvider>
    </LangProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
