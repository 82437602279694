import React, { useContext, useState } from 'react'
import { LangContext } from '../../context/lang-context'
import FormSkel from './FormSkel'
import AcceptForm from './AcceptForm'
import { NavigationContext } from '../../context/navigation-context'

export default function LocationForm ({ locationData, handleSubmit, handleClose })  {
  const { TRL_Pack } = useContext(LangContext)
  const [availableToSave, setAvailableToSave] = useState(null) 
  const { customerName } = useContext(NavigationContext)
  const handleFocus = (evt, required = false) => {
    evt.target.value = ""
    if (required && !String(evt.target.className).includes("invalid-input")) evt.target.className += " invalid-input "
  }
  const handleChangeStyle = (evt) => {
    let classes = evt.target.className

    if (evt.target.name === "index") {
      if (regexpPostCode(evt.target.value)) {
        if (String(classes).includes("invalid-input")) evt.target.className = classes.replace("invalid-input", "") 
      } else {
        if (!String(classes).includes("invalid-input")) evt.target.className += " invalid-input "
      }
    } else {
      if (String(classes).includes("invalid-input")) 
        evt.target.className = classes.replace("invalid-input", "") 
    }
  }
  const regexpPostCode = (str) => /^\d{2}-\d{3}$/.test(str)
  const lengthFilter = (evt) => {
      const {name, value} = evt
      const classes = evt.class
      switch (name) {
          case 'town':
          case 'street':
          case 'postDep':
            {
              if(value && String(value).length <= 70) {
                if (String(classes).includes("invalid-input")) evt.class = classes.replace("invalid-input", "") 
                return true
            }
            else {
              if (!String(classes).includes("invalid-input")) evt.class += " invalid-input "
              return false 
            }
          }
          case 'local':
          case 'homeNr':
            {
              if(value && String(value).length <= 15) {
                if (String(classes).includes("invalid-input")) evt.class = classes.replace("invalid-input", "") 
                return true
              }
              else {
                if (!String(classes).includes("invalid-input")) evt.class += " invalid-input "
                return false 
              }
            }
          case 'index':
            {
              if(regexpPostCode(value)) {
                if (String(classes).includes("invalid-input")) evt.class = classes.replace("invalid-input", "") 
                return true
              }
              else {
                if (!String(classes).includes("invalid-input")) evt.class += " invalid-input "
                return false 
              }
            }
          default: 
              return true
      }
    // console.log(obj1)
  }
  const formValidation = () => {
    const available = 
      document.querySelector("form") && 
      Array.from(document.querySelector("form"))
      .filter(el => el.required)
      .map(el => { return el.className && !el.className.includes("invalid-input") ? el.value : false})
      .every(el => !!(el))
      availableToSave !== available && setAvailableToSave(available)
    }
  const debouncedValidator = debounce(formValidation, 100)
  document.addEventListener("click", () => {
    // console.log(document.querySelector("form"))
    debouncedValidator()
  })
  document.addEventListener("keydown", () => {
    debouncedValidator()
  })
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
  }
  return (
    <FormSkel
      headerText={
        locationData
          ? TRL_Pack.locations.editItemHeader   
          : TRL_Pack.locations.newItemHeader
      }
      handleClose={() => {
        document.removeEventListener("click", formValidation)
        document.removeEventListener("keydown", debouncedValidator)
        handleClose(); 
      } }
      data={locationData}
      disableSubmit={!availableToSave}
      // handleSubmit={handleSubmit}
    >
      <form onSubmit={handleSubmit} id="modal-form" autoComplete="off">
        <div className="form-group"> 
          <label className="h6">{TRL_Pack.location.name}</label>
          <input
            onFocus={evt => handleFocus(evt)}
            name="name"
            onKeyDown={evt => {
              if(evt.key === "Enter") evt.preventDefault()
            }}
            onChange={handleChangeStyle}
            minLength={1}
            required={true}
            className={`form-control`}
            defaultValue={locationData && locationData.Name}
          />
        </div>
        {/* <div className="form-group row">
          <div className="col-6">
            <label className="h6">{TRL_Pack.categories.properties.long}</label>
            <input
              name="long"
              type="number"
              min={-180}
              max={180}
              step="any"
              className="form-control"
              defaultValue={locationData && locationData.Long}
              // disabled
            />
          </div>
          <div className="col-6">
            <label className="h6">{TRL_Pack.categories.properties.lat}</label>
            <input
              name="lat"
              type="number"
              min={-180}
              max={180}
              step="any"
              className="form-control"
              defaultValue={locationData && locationData.Lat}
              // disabled
            />
          </div>
        </div> */}
        <div className="form-group">
          <label className="h6">{TRL_Pack.location.monthlyRent}</label>
          <input
            onFocus={handleFocus}
            name="rent"
            type="number"
            // pattern='\d\w+\w.\w+\d'
            onKeyDown={evt => {
              if(evt.key === "Enter") evt.preventDefault()
            }}
            step="any"
            min={0}
            className="form-control"
            defaultValue={locationData && typeof(locationData.MonthlyRentBrutto) === "number" && locationData.MonthlyRentBrutto.toFixed(2)}
          />
        </div>
        <hr/>
          {/* <div className="form-group">
            <label className="h6">Nazwa podatnika</label>
            <input
              onFocus={evt => handleFocus(evt, true)}
              name="customerName"
              onChange={handleChangeStyle}
              // pattern='\d\w+\w.\w+\d'
              onKeyDown={evt => {
                if(evt.key === "Enter") evt.preventDefault()
              }}
              required={true}
              min={0}
              className={`form-control`}
              defaultValue={locationData && locationData.CustomerName || customerName }
            />
          </div> */}
          <div className="form-group">
            <label className="h6">Ulica</label>
            <input
              onFocus={evt => handleFocus(evt)}
              name="street"
              onChange={handleChangeStyle}
              // pattern='\d\w+\w.\w+\d'
              onKeyDown={evt => {
                if(evt.key === "Enter") evt.preventDefault()
              }}
              className={`form-control`}
              defaultValue={locationData && locationData.Street || "" }
            />
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <label className="h6">Nr budynku</label>
              <input
                onFocus={evt => handleFocus(evt)}
                name="homeNr"
                onChange={handleChangeStyle}
                // pattern='\d\w+\w.\w+\d'
                onKeyDown={evt => {
                  if(evt.key === "Enter") evt.preventDefault()
                }}
                className={`form-control`}
                defaultValue={locationData && locationData.HomeNr || "" }
              />
            </div>
            <div>
              <label className="h6">Nr lokalu</label>
              <input
                onFocus={evt => handleFocus(evt)}
                name="local"
                // pattern='\d\w+\w.\w+\d'
                onKeyDown={evt => {
                  if(evt.key === "Enter") evt.preventDefault()
                }}
                className={`form-control `}
                defaultValue={locationData && locationData.Local || "" }
              />
            </div>
          </div>
          <div className="form-group">
            <label className="h6">Kod pocztowy</label>
            <input
              onFocus={evt => handleFocus(evt)}
              name="index"
              onChange={handleChangeStyle}
              // pattern='\d\w+\w.\w+\d'
              onKeyDown={evt => {
                if(evt.key === "Enter") evt.preventDefault()
              }}
              className={`form-control`}
              defaultValue={locationData && locationData.Index || "" }
            />
          </div>
          <div className="form-group">
            <label className="h6">Miejscowość</label>
            <input
              onFocus={evt => handleFocus(evt)}
              name="town"
              onChange={handleChangeStyle}
              // pattern='\d\w+\w.\w+\d'
              onKeyDown={evt => {
                if(evt.key === "Enter") evt.preventDefault()
              }}
              className={`form-control`}
              defaultValue={locationData && locationData.Town || "" }
            />
          </div>
          <div className="form-group">
            <label className="h6">Urząd pocztowy</label>
            <input
              onFocus={evt => handleFocus(evt)}
              name="postDep"
              onChange={handleChangeStyle}
              // pattern='\d\w+\w.\w+\d'
              onKeyDown={evt => {
                if(evt.key === "Enter") evt.preventDefault()
              }}
              className={`form-control`}
              defaultValue={locationData && locationData.PostDep || "" }
            />
          </div>
        <hr/>
        <div>
          <label className="h6">{TRL_Pack.location.comment}</label>
          <textarea
            name="comment"
            min={0}
            className="form-control"
            defaultValue={(locationData && locationData.Comment) || ''}
          />
        </div>
        {/* {!locationData && <div className='pt-3'>
          <label className="h6">{TRL_Pack.location.active}</label>           
          <select
            name="isActive"
            className="form-control"
            defaultValue={locationData && locationData?.IsActive}
          >
              <option value={1}>Tak</option>
              <option value={0}>Nie</option>
          </select>

        </div>} */}
      </form>
    </FormSkel>
  )
}
