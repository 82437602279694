import React, {useState, useEffect, useContext} from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import moment from 'moment'
import 'moment/locale/pl'
import NoResults from '../../../components/NoResults/NoResults'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import PrepaidFilter from './PrepaidFilter'
import Pagination from '../../../components/Pagination/Pagination'

export default function PrepaidGroup() {
    const { setHeaderData } = useContext(NavigationContext),
    { fetchMssqlApi } = fetchHook(),
    [prepaidGroups, setPrepaidGroups] = useState([]),
    [visibleFilter, setVisibleFilter] = useState(false)
    const toggleFilter = () => setVisibleFilter(!visibleFilter)
    // const [filter, setFilter] = useState(() => {
    //     if (localStorage.getItem('prepaidGroupsFilter')) {
    //             return JSON.parse(localStorage.getItem('prepaidGroupsFilter'))
    //         } else return defaultFilter
    //     }, [])
    // const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    // const defaultFilter = localStorage.getItem("clientId") !== "console" ? {
    //     name: "prepaidGroups",
    //     showIndexes: true,
    //     page: 1,
    //     rowsPerPage: 50,
    //     // rowsPerPageOptions: [10, 25, 50],
    //     visible: true,
    //     sortByColumns: false,
    //     sortBy: '3 | asc | text',
    //     activeTags: [],
    //     columns: [

    //     {
    //         id: 2,
    //         name: "Grupa",
    //         sortable: false,
    //         searchable: true,
    //         type: 'lookup',
    //         value: null,
    //         save: null,
    //     },
    //     {
    //         id: 3,
    //         name: "Okres czasowy",
    //         sortable: false,
    //         searchable: true,
    //         type: 'lookup',
    //         value: null,
    //         save: null,
    //     },
    //     {
    //         id: 4,
    //         name: "Aktywna",
    //         type: 'threeStateBool',
    //         disabled: true,
    //         save: null,
    //     },
    //     {
    //         id: 6,
    //         name: "Nadpisywanie środków",
    //         type: 'threeStateBool',
    //         disabled: true,
    //         save: null,
    //     },
    //     {
    //         id: 5,
    //         name: "Włączona",
    //         type: "threeStateBool",
    //         save: null,
    //         value: null}
    //     ]} : {
    //     name: "prepaidGroups",
    //     visible: false,
    //     sortByColumns: false,
    //     sortBy: '3 | asc | text',
    //     rowsPerPage: 50,
    //     activeTags: [],
    //     columns: [

    //         {
    //             id: 2,
    //             name: "Grupa",
    //             sortable: false,
    //             searchable: true,
    //             type: 'lookup',
    //             value: null,
    //             save: null,
    //         },
    //         {
    //             id: 3,
    //             name: "Okres czasowy",
    //             sortable: false,
    //             searchable: true,
    //             type: 'lookup',
    //             value: null,
    //             save: null,
    //         },
    //         {
    //             id: 4,
    //             name: "Aktywna",
    //             type: 'threeStateBool',
    //             disabled: true,
    //             save: null,
    //         },
    //         {
    //             id: 6,
    //             name: "Nadpisywanie środków",
    //             type: 'threeStateBool',
    //             disabled: true,
    //             save: null,
    //         },
    //         {
    //             id: 5,
    //             name: "Włączona",
    //             type: "threeStateBool",
    //             save: null,
    //             value: null}
    //     ]}
    //     const resetFilter = () => setFilter(defaultFilter)
    
    // const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    // useEffect(() => localStorage.setItem("prepaidGroupsFilter", JSON.stringify(filter)), [filter])
    moment.locale('pl')
    const getPrepaidGroups = () => {
        fetchMssqlApi('prepaid-groups-by-params', {method: "POST", data: {PrepaidGroupId: null,
            TimespanId: null,
            Enabled: null,
            IsActive: true,
            OverwriteCredit: null,}, hideNotification: true}, res => {
            setPrepaidGroups(res)
        })
    }
    useEffect(() => {
        getPrepaidGroups()
        // setHeaderData({text: "Karty pracownicze"})
    }, [])
    // const toggleDateVisualisation = (date) => {
    //     let type = "date"
    //     const  toggle = () => {
    //         type === "date" ? type = "words" : type = "date"
    //     }
    //     return {
    //         getDate: function() {
    //             if (type === "date") {
    //                 date 
    //             } else {
    //                 return <>{moment.utc(moment().format('YYYYMMDDTHHMMSS')).to(moment(date).format('YYYYMMDDTHHMMSS'))} </>
    //             }
    //         }(), 

    //         toggleType: function(){toggle()}
    //     } 
    // }
    const search = (data) => {
        fetchMssqlApi('prepaid-groups-by-params', {method: "POST", data, hideNotification: true}, res => {
            setPrepaidGroups(res)
        })
    }
    return (
        <>
        {/* <Pagination
            {...{
            totalItems: prepaidGroups.length,
            page: filter.pagelength,
            handleSwitchPage: handleSwitchPage,
            rowsPerPage: filter.rowsPerPage,
            filterVisibility: toggleFilter,
            resetFilter: resetFilter,

        }}
        />
        {filter.visible && (
            )} */}
            <div className='w-100 d-flex justify-content-center mb-2'>
                <button className="btn ml-2 my-auto my-auto" onClick={toggleFilter}>
                    <i
                    className={`fas fa-filter ${visibleFilter ? 'text-primary' : 'text-secondary'
                        }`}
                    />
                </button>
            </div>
            {/* {visibleFilter && ( */}
                <PrepaidFilter
                    visible={visibleFilter}
                    toggleFilter={toggleFilter}
                    search={search}
                />
            {/* )} */}
        <div className='card'>
            <h5 className="card-header">
                    <div className='d-flex justify-content-between mt-1'>
                        Grupy prepaid
                        <Link to={`/prepaids/group/0`} className="btn text-nowrap btn-sm btn-outline-secondary"><i className="fas fa-plus" /></Link>
                    </div>
                </h5>
            <div className='card-body overflow-auto' style={{maxHeight: "70%"}}>
                {prepaidGroups.length ? (
                    <table className='table table-striped'>
                        <thead>

                            <tr>
                                <th>Nazwa {localStorage.getItem('clientId') === 'console' && (<><br/> Klient</>)}</th>
                                <th className='text-center'>Następne uruchomienie<br/>Częstotliwość zasilania</th>
                                <th className='text-center'>Ilość powiązanych terminali</th>
                                <th className='text-center'>Ilość powiązanych kart</th>
                                <th className='text-center'>Kredyt / Nadpisywanie <br/>Aktywna / Włączona</th>

                            </tr>
                        </thead>
                        <tbody>
                            {prepaidGroups.length && prepaidGroups
                            // .slice(
                            //     (filter.page - 1) * filter.rowsPerPage,
                            //     filter.page * filter.rowsPerPage
                            // )
                            .map((prepaidGroup, idx) => (
                                <tr key={idx}>
                                    <td>
                                        <div>
                                            { prepaidGroup.PrepaidGroupName}
                                        </div>
                                        {
                                            localStorage.getItem('clientId') === 'console' && (
                                                <div>
                                                    {prepaidGroup.CustomerName}
                                                </div>
                                            )
                                        }
                                    </td>
                                    <td className='text-center' >
                                        <div>
                                            {/* {prepaidGroup.LastStart && moment.utc(moment().format('YYYYMMDDTHHMMSS')).to(moment(prepaidGroup.LastStart).format('YYYYMMDDTHHMMSS'))} */}
                                            {/* {moment(prepaidGroup.LastStart).format('YYYY-MM-DD HH:MM')} */}
                                            {/* {console.log(prepaidGroup.PrepaidStatusId)} */}
                                            {/* {toggleDateVisualisation(prepaidGroup.LastStart).getDate} */}
                                            {prepaidGroup.NextStart} 
                                        </div>
                                        <div>
                                            {/* {prepaidGroup.GroupName} */}
                                            <h5><span className="badge badge-light align-middle">{prepaidGroup.PrepaidTimespanName}</span></h5>

                                        </div>
                                    </td>
                                    <td className='text-center'>
                                    <h3><span className={`badge badge-primary align-middle`}>{prepaidGroup.ConectedTerminals}</span></h3>
                                    </td>
                                    <td className='text-center'>
                                    <h3><span className={`badge badge-success align-middle`}>{prepaidGroup.ConnectedPrepaids}</span></h3>
                                    </td>
                                    <td className='text-center'>
                                        <div className='d-flex nowrap justify-content-center'>
                                            <h6><span className={`badge badge-success align-middle`}>{prepaidGroup.PrepaidStatus}</span></h6>
                                        </div>
                                        <div className='d-flex nowrap justify-content-center align-items-center'>

                                            <h6><span className={`badge badge-primary align-middle mr-2`}>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((prepaidGroup && prepaidGroup.CreditValue).toFixed(2)) || "0,00zł"}</span></h6>
                                            {prepaidGroup.OverwriteCredit ? (
                                                <h6><span className={`badge badge-success align-middle`}>Z nadpisywaniem</span></h6>
                                            ) : (
                                                <h6><span className={`badge badge-danger align-middle`}>Bez nadpisywania</span></h6>
                                            )} 
                                        </div>
                                        <div className='d-flex nowrap justify-content-center'>
                                            {prepaidGroup.IsActive ? (
                                                <h6><span className={`mr-2 badge badge-success align-middle`}>Aktywna</span></h6>
                                            ) : (
                                                <h6><span className={`mr-2 badge badge-danger align-middle`}>Nieaktywna</span></h6>
                                            )} 
                                            {prepaidGroup.Enabled ? (
                                                <h6><span className={`badge badge-success align-middle`}>Włączona</span></h6>
                                            ) : (
                                                <h6><span className={`badge badge-warning align-middle`}>Wyłączona</span></h6>
                                            )} 
                                            
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-end border-0'>
                                            <Link className=" mr-4 btn btn-outline-primary btn-sm "
                                                to={`group/${prepaidGroup.PrepaidGroupId}`}
                                                // key={location.id+"btn"}
                                            >
                                                    Edytuj
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody>
                    </table>
                ) : <NoResults/>}
            </div>
        </div>
    </>
    )
}
