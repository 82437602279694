import React, { useEffect, useState, useContext } from "react";
import useFetch from '../../hooks/fetchMSSQL-hook'
// import { LangContext } from '../../context/lang-context';
// import FormSkel from '../../components/Modals/FormSkel'
// import useHttp from '../../hooks/use-http';
// import Modal from '../../components/Modal';
import formHook from "../../hooks/form-hook";
import RoleForm from "../../components/Modals/RoleForm";
import { NotificationManager } from "react-notifications";

// eslint-disable-next-line react/display-name
export default () => {
    const { fetchMssqlApi } = useFetch()
    // const { TRL_Pack } = useContext(LangContext);
    // const { sendRequest } = useHttp();
    const {openForm, form, closeForm} = formHook()
    const [roles, setRoles] = useState([]);
    // const [showModalRole, setShowModalRole] = useState(false);

    // const [showModal, setShowModal] = useState(false);
    // const [currentID, setCurrentID] = useState(null);



    const getHTMLFromBool = ({IsActive}) => (
        <input
            type='checkbox'
            checked={IsActive}
            onChange={() => {}}
        />
    )
    // const hideModalRoleHandler = () => setShowModalRole(false);
    // const showModalRoleHandler = () => setShowModalRole(true);

    // const showModalDeleteHandler = () => setShowModal(true);
    // const handleClose = () => setShowModal(false);

    // const submitRoleHandler = e => {
    //     e.preventDefault();
    //     const { name } = e.target.elements
    //     const data = { Name: name.value }

    //     sendRequest({ url: 'role/add', method: 'POST', data }, response => {
    //         if (response.status === 200) {
    //             hideModalRoleHandler();
    //             getRoles();
    //         }
    //     })
    // }

    // const handleUserDelete = e => {
    //     e.preventDefault();
    //     const data = { ID: currentID }

    //     sendRequest({ url: 'role-permission/delete-all', method: 'POST', data }, response => {
    //         if (response.status === 200) {
    //             sendRequest({ url: 'role/delete', method: 'POST', data }, response => {
    //                 if (response.status === 200) {
    //                     handleClose();
    //                     setCurrentID(null);
    //                     getRoles();
    //                 }
    //             })
    //         }
    //     })
    // }

    const getRoles = () => {
        fetchMssqlApi('roles', {}, roles => {
            setRoles(roles);
        })
    }
    const handleSubmit = (evt, data, setData) => {
        evt.preventDefault()
        const role = roles.find(role => role.RoleId === form)
        const IsActive = form !== "new" && evt.target.elements.isActive.value
        if (form !== "new" && !role) {
            NotificationManager.error("Something went wrong")
            closeForm()
            return 
        }  
        if (roles.filter(role => role.RoleId !== form).map(role =>  String(role.Name).toLowerCase()).indexOf(data.Name.toLowerCase()) !== -1) {
            setData(prev => ({...prev, Name: ""}))
            NotificationManager.error("Taka nazwa już istnieje")
            return
        }
        if (form === "new") {
            fetchMssqlApi('role', {method: "POST", data: {Name: data.Name}}, () => {
                getRoles()
                closeForm()
            })
            return
        }
        if (String(role.IsActive) !== IsActive) {
            fetchMssqlApi(`role/${form}`, {method: "PUT", data: {Name: role.Name, IsActive: !role.IsActive}}, () => {
                getRoles()
                closeForm()
            })
        } else {
            fetchMssqlApi(`role/${form}`, {method: "PUT", data: {Name: data.Name, IsActive: role.IsActive}}, () => {
                getRoles()
                closeForm()
            })
        }
    }
    useEffect(() => {
        getRoles();
    }, [])

    return (

        <>
            <div className="card">
                <div className="card-header">
                    <div className='d-flex justify-content-between'>
                        <div className='h5 mt-2 mb-1'>Role</div>
                        <button onClick={openForm()} className="btn text-nowrap btn-sm btn-outline-secondary mt-1 mb-1"><i className="fas fa-plus" /></button>
                    </div>
                </div>
                <div className="card-body overflow-auto" style={{maxHeight: "65vh"}} >
                    {
                        roles && (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nazwa</th>
                                        <th className="text-center">Aktywna</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roles.map((role, idx )=> (
                                        <tr key={idx} className="mt-2">
                                            <td>{role.Name}</td>
                                            <td className="text-center">{getHTMLFromBool(role)}</td>
                                            <td className='d-flex justify-content-end mb-2 mt-2 border-0'>
                                                <button className="btn btn-outline-primary btn-sm "
                                                    onClick={openForm(role.RoleId)}
                                                >
                                                    Edytuj
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) 
                    }
                </div>
            </div>
            {form && (
                <RoleForm 
                    form={form}
                    handleClose={closeForm}
                    data={roles.find(role => role.RoleId === form)}
                    handleSubmit={handleSubmit}
                />
            )}
        </>
    )
}