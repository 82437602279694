import React, { useState, useEffect, useContext, useMemo, memo } from 'react'
import { NavigationContext } from '../../../context/navigation-context'
// import { LangContext } from '../../context/lang-context'
import useForm from '../../../hooks/form-hook'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useFilter from '../../../hooks/filter-hook'
import { Link } from 'react-router-dom'
import FilterEx from '../../../components/Filter/FilterEx'
import Pagination from '../../../components/Pagination/Pagination'
import SearchInput from '../../../components/SearchInput/SearchInput'
import NoResults from '../../../components/NoResults/NoResults'
import TerminalForm from '../../../components/Modals/TerminalForm'
import filterItems from '../../../util/filterItems'
import tmobileIcon from '../../../assets/images/tmobile_icon.png'
import plusIcon from '../../../assets/images/plus_icon.png'

import { LangContext } from '../../../context/lang-context'
import TrxsFilter from './TrxsFilter'
import setInformationForFilter from './utils/setInformationForFilter'
import FormSkel from '../../../components/Modals/FormSkel'
import ReportBtn from '../../../components/Buttons/ReportsBtn/ReportBtn'
import axios from 'axios'
import moment from 'moment'
import { API_URL } from '../../../config/config'
import { NotificationManager } from 'react-notifications'


const Terminals = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()
  const { setHeaderData } = useContext(NavigationContext)
  const { searchedText, setSearchedText } = useFilter("")

  const { form, openForm, closeForm } = useForm()
  const { form2, openForm2, closeForm2 } = useForm()
  useEffect(() =>  resetFilter() ,[])
  const [machines, setMachines] = useState([])
  const [customers, setCustomers] = useState([])
  const [terminalToDelete, setTerminalToDelete ] = useState(null)
  const [terminalsSummary, setTerminalsSummary ] = useState(null)
  const {state} = useContext(NavigationContext)
  const [hasPermissionToEditTerminal, setHasPermissionToEditTerminal] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("clientId") !== "console") {
      state.permissions && state.permissions.forEach(i => {
        if (i.Name === "VD_UPDATE_TERMINALS") {
          setHasPermissionToEditTerminal(true)
        }
      } )
    } else {
      setHasPermissionToEditTerminal(true)
    }
  }, [state.permissions]) 
  const getMachines = () => {
    if(localStorage.getItem('clientId') === "console") {
      fetchMssqlApi('machine-list', {method: "POST", data: {customerAbbr: "console", customerId: null}, hideNotification: true}, machines => setMachines(machines))

    } else fetchMssqlApi('machines-list', {}, machines => setMachines(machines))
  }

  const [ terminals, setTerminals] = useState([]) // TODO CREATE API TERMINAL ENDPOINT
  const [filteredTerminals, setFilteredTerminals] = useState([])
  
  const getTerminals = (query = {terminalId: null, machineId: null, terminalTypeId: null}) => {
    fetchMssqlApi('terminals', { method: 'POST',  data: {
      customerAbbr: localStorage.getItem('clientId'),
      terminalId: query.terminalId || null,
      machineId: query.machineId || null,
      terminasTypeId: query.terminalTypeId || null
    }, hideNotification:true }, terminals => {
    setTerminals(terminals)
    setFilteredTerminals(terminals)})
  }
  const findCustomerByTerminal = terminalId => {
    if(terminalId) {
      const name = terminals.find(terminal => terminal.TerminalId === terminalId)?.CustomerName || null
      const abbr = customers && customers.find(machine => machine.Name === name)?.Abbreviation || null
      return abbr 
    } else return null
  }
  const getTerminalInfo = () => {
    fetchMssqlApi('/getTerminalsSummary', {}, res => {
      setTerminalsSummary(res.info)})
  }
  const submitTerminal = evt => {
    evt.preventDefault();
    const { useTrxForSales, machine, useVMCTrx, useGenerateMachine } = evt.target.elements;
    const {serialNo, terminalType, clientName} = evt.target.elements
    const toDelete = form !== "new" && form !== "acceptForm" && evt.target.elements.isActive.value === "false"
    if (toDelete) {
      setTerminalToDelete(form)
      openForm("acceptForm")()
      return
    }

    if (form === "acceptForm") {
      fetchMssqlApi(`terminal/${terminalToDelete}`, {method: "DELETE", data: {}}, () => {
        setTerminalToDelete(null)
        getTerminals()
        getTerminalsList()
        closeForm()
      })
      return
    }

    if (form === "new") {
      
      const SerialNo = serialNo.value
      const TerminalType = terminalType.value
      const ClientName = clientName.value

      /**============================== */
      const terminalTypeMap = new Map()
      terminalTypeMap.set("Vendotek", 1)
      terminalTypeMap.set("PAX", 2)
      terminalTypeMap.set("NAYAX", 3)
      terminalTypeMap.set("NEWLAND", 4)
      /**============================== */
      const validation = () =>  {
        if(!SerialNo || !ClientName || !TerminalType) return alert("SerialNo or ClientName or TerminalType is empty")
        const terminal = terminals.find(terminal => terminal.SerialNo === serialNo.value)
        if(terminal) {
          if(terminal.TerminalTypeName === TerminalType && terminal.SerialNo === SerialNo) return false

        }
        else return true
      }
      if (!validation()) return alert("terminal already created")
      
      const getCustomerId = value => {
          const id = customers.find(customer => `${customer.Name} (${customer.Abbreviation})` === value).CustomerId
          return +id || null
        }
        const terminal = terminalTypeMap.get(TerminalType)
        const id = getCustomerId(ClientName)
      if( !terminal || typeof(id) !== "number" ) return alert("you input incorrect data")
      const data = {
        TerminalTypeId: terminal,
        ClientId: id,
        SerialNo
      }
      fetchMssqlApi('terminal', {method: "POST", data}, () => {
        getTerminalsList()
        getTerminals()
        closeForm()
      })
    }




    if(form !== "new" && !machine.value.length) {
      const TerminalId = form
      fetchMssqlApi(
        '/terminal/machine',
        {
          method: 'POST',
          data: {
            TerminalId,
            UseTrxForSales: useTrxForSales.checked,
            UseVMCTrx: useVMCTrx.checked,
            MachineFullName: null,
            MachineSerialNo: null,
            CustomerId: localStorage.getItem("clientId") === "console" ? customers.find(customer => `${customer.Name} (${customer.Abbreviation})` === clientName.value)?.CustomerId : null,
            UseGenerateMachine: useGenerateMachine.checked
          }
        },
        () => {
          closeForm()
          getTerminals()
        }
      )
    }
if (form !== "new" && String(machine.value).length) {

  let machineName ;
  let machineSerialNo = null;
  
  const TerminalId = form;
  const serialNo = prop => prop.split(" ").at(-1).replace(/[^a-z0-9]/gi, '')
  // console.log(machines)
  let machineData = machines.find(m => m.SerialNo === serialNo(machine.value) || m.Name === machine.value)
  
  // console.log(machineData)
  machineName = (localStorage.getItem("clientId") !== "console") ? machineData?.Name : machineData?.MachineName
  machineSerialNo = machineData?.SerialNo
  if(!machineName || !machineSerialNo) return alert("Something went wrong")
    fetchMssqlApi(
      '/terminal/machine',
      {
        method: 'POST',
        data: {
          TerminalId,
          UseTrxForSales: useTrxForSales.checked,
          UseVMCTrx: useVMCTrx.checked,
          MachineFullName: machineName,
          MachineSerialNo: machineSerialNo,
          CustomerId: localStorage.getItem("clientId") === "console" ? customers.find(customer => `${customer.Name} (${customer.Abbreviation})` === clientName.value)?.CustomerId : null,
          UseGenerateMachine: useGenerateMachine.checked
        }
      },
      () => {
        closeForm()
        getTerminals()
        getTerminalsList()
      }
      )
}

  }
  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const defaultFilter = localStorage.getItem("clientId") !== "console" ? {
    name: "terminals",
    showIndexes: true,
    page: 1,
    rowsPerPage: 50,
    // rowsPerPageOptions: [10, 25, 50],
    visible: false,
    sortByColumns: true,
    sortParams: [
      {name: "Najnowsza transakcja",    id: "LastTrxCreateDateTime",  type: "datetime", preferDirection: "desc",  hideArray: true},
      {name: "Najstarsza transakcja",   id: "LastTrxCreateDateTime",  type: "datetime", preferDirection: "asc",   hideArray: true},
      {name: "Nr seryjny terminala",    id: "SerialNo",               type: "number"},
      {name: "Automat",                 id: "MachineFullName",        type: "string"},
      {name: "Suma udanych transakcje", id: "PositiveTrxValue",       type: "number"},
    ],
    sortBy: '3 | asc | text',
    activeTags: [],
    columns: [

      {
        id: 2,
        name: "Automat",
        sortable: true,
        searchable: true,
        type: 'lookup',
        // solid: true,
        value: null,
        save: null,
      },
      {
        id: 3,
        name: "Terminal",
        sortable: true,
        searchable: true,
        type: 'lookup',
        // solid: true,
        value: null,
        save: null,
      },
      {
        id: 4,
        name: "Klient",
        // sortable: false,
        // searchable: true,
        type: 'lookup',
        // solid: true,
        disabled: true,
        save: null,
        save: localStorage.getItem("clientId"),
      },
      {
        id: 5,
        name: "Typ terminala",
        type: "threeStateBool",
        save: null,
        value: null}
    ]} : {
      name: "terminals",
      // showIndexes: true,
      // page: 1,
      // rowsPerPage: 50,
      // rowsPerPageOptions: [10, 25, 50],
      visible: false,
      sortByColumns: false,
      sortParams: [
        {name: "Najnowsza transakcja",    id: "LastTrxCreateDateTime",  type: "datetime", preferDirection: "desc",  hideArray: true},
        {name: "Najstarsza transakcja",   id: "LastTrxCreateDateTime",  type: "datetime", preferDirection: "asc",   hideArray: true},
        {name: "Nr seryjny terminala",    id: "SerialNo",               type: "number"},
        {name: "Automat",                 id: "MachineFullName",        type: "string"},
        {name: "Suma udanych transakcje", id: "PositiveTrxValue",       type: "number"},
        {name: "Klient",                  id: "CustomerName",           type: "string"},
      ],
      sortBy: '3 | asc | text',
      activeTags: [],
      columns: [

        {
          id: 2,
          name: "Automat",
          sortable: true,
          searchable: true,
          type: 'lookup',
          // solid: true,
          value: null,
          save: null,
        },
        {
          id: 3,
          name: "Terminal",
          sortable: true,
          searchable: true,
          type: 'lookup',
          // solid: true,
          value: null,
          save: null,
        },
        {
          id: 4,
          name: "Klient",
          sortable: false,
          searchable: true,
          type: 'lookup',
          disabled: false,
          // solid: true,
          value: null,
          save: null,
        },
        {
          id: 5,
          name: "Typ terminala",
          type: "threeStateBool",
          save: null,
          value: null
        }
      ]}
    const [filter, setFilter] = useState(() => {
      if (localStorage.getItem('terminalsFilter')) {
          return JSON.parse(localStorage.getItem('terminalsFilter'))
      } else return defaultFilter
  })
  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  useEffect(() => localStorage.setItem("terminalsFilter", JSON.stringify(filter)), [filter])
  const resetFilter = () =>{ setFilter(defaultFilter)
  resetPage()
  getTerminals({terminalId: null,
    machineId: null})}


  const getRequestData = (data) => 
    localStorage.getItem("clientId") === "console" ? {
      customerAbbr: localStorage.getItem("clientId"),
      customerId: data.get("Klient") || null,
      machineId: data.get("Automat") || null,
      terminalId: data.get("Terminal") || null,
      terminalTypeId: (data.get("TerminalTypeId") !== "null") ? data.get("TerminalTypeId") : null
    } : {
      customerAbbr: localStorage.getItem("clientId"),
      machineId: data.get("Automat") || null,
      terminalId: data.get("Terminal") || null,
      terminalTypeId: (data.get("TerminalTypeId") !== "null") ? data.get("TerminalTypeId") : null
    }
  const [repEx89, setRepEx89] = useState(null)  
  const exportEx89 = () => {
    setRepEx89(true)
    let data = new Map([
      ["Automat"        , filter.columns[0] !== undefined && filter.columns[0].save   || null],
      ["Terminal"       , filter.columns[1] !== undefined && filter.columns[1].save   || null],
      ["Klient"         , filter.columns[2] !== undefined && filter?.columns[2].save  || null],
      ["TerminalTypeId" , filter.columns[3] !== undefined && filter?.columns[3].save  || null],
    ])

    axios({
        method: "POST",
        url: `${API_URL}/api/reports/terminals`,
        data: getRequestData(data),
        responseType:'blob'
    })
    .then((res) => {
        if (res.status < 300) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `DZ89_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
        setRepEx89(false)
      })
      .catch(err => {
            if (err.response.data.message === "jwt malformed") window.location.reload();
            else NotificationManager.error(err.response?.data || err.toString())
            setRepEx89(false)
      })
  }
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 })) 

  useEffect(() => {
    setHeaderData({ text: TRL_Pack.navigation.terminals })

    getTerminals()
    getMachines()
    getCustomers()
    getTerminalsList()
    localStorage.getItem("clientId") !== "console" && getTerminalInfo()
  }, [])
  const [terminalsList, setTerminalsList] = useState([])
  const getTerminalsList = () => fetchMssqlApi('terminal-list', {method:"POST", data: {customerAbbr: localStorage.getItem("clientId")}, hideNotification: true}, answ => setTerminalsList(answ))

  const getCustomers = () => fetchMssqlApi('clients', {}, clients => setCustomers(clients))
  const search = async () => {
    //  * @value filter.columns[1].value - machine serialNo
    //  * @value filter.columns[2].value - terminal serialNo
    //  */
      const query = () => {
      let data = new Map()
      data.set("Automat",         filter.columns[0] !== undefined && filter.columns[0].save   || null)
      data.set("Terminal",        filter.columns[1] !== undefined && filter.columns[1].save   || null)
      data.set("Klient",          filter.columns[2] !== undefined && filter?.columns[2].save  || null)
      data.set("TerminalTypeId",  filter.columns[3] !== undefined && filter?.columns[3].save  || null)
      const err = filter.columns.find(col => col.value && !col.save)

      if(err) {
        setFilter(prev => ({
          ...prev,
          columns: prev.columns.map(col => {col.value = null
          col.save = null
        return col})
        }))
        return 
      }
      fetchMssqlApi('terminals', { method: 'POST',  data: localStorage.getItem("clientId") === "console" ? {
          customerAbbr:   localStorage.getItem("clientId"),
          customerId:     data.get("Klient") || null,
          machineId:      data.get("Automat") || null,
          terminalId:     data.get("Terminal") || null,
          terminalTypeId: (data.get("TerminalTypeId") !== "null") ? data.get("TerminalTypeId") : null
        } : {
          customerAbbr:   localStorage.getItem("clientId"),
          machineId:      data.get("Automat") || null,
          terminalId:     data.get("Terminal") || null,
          terminalTypeId: (data.get("TerminalTypeId") !== "null") ? data.get("TerminalTypeId") : null
        }, hideNotification:true }, terminals => {
        setTerminals(terminals)  
        setFilteredTerminals(terminals)})
    
    } 

    await setInformationForFilter(setFilter, {machines, terminals}, filter, terminalsList ,customers )

    await query()
  }
  const sortStringAsc       = (columnName) => (a, b) => a[columnName] < b[columnName] ? -1 : 1 || 0 
  const sortStringDesc      = (columnName) => (a, b) => a[columnName] > b[columnName] ? -1 : 1 || 0 
  const sortNumberAsc       = (columnName) => (a, b) => +a[columnName] > +b[columnName] ? -1 : 1 || 0 
  const sortNumberDesc      = (columnName) => (a, b) => +a[columnName] < +b[columnName] ? -1 : 1 || 0 
  const sortDateTimeAsc     = (columnName) => (a, b) => new Date(a[columnName]) < new Date(b[columnName]) ? -1 : 1 || 0 
  const sortDateTimeDesc    = (columnName) => (a, b) => new Date(a[columnName]) > new Date(b[columnName]) ? -1 : -1 || 0 
  const sortWithStateChange = (columnName ,sortMethod, type) => {
    switch (sortMethod) {
      case "asc": {
        switch(type) {
          case "string":
            return sortStringAsc(columnName)
          case "number":
            return sortNumberAsc(columnName)
          case "datetime":
            return sortDateTimeAsc(columnName)
        }
        break;
      }
      case "desc": {
        switch(type) {
          case "string":
            return sortStringDesc(columnName)
          case "number":
            return sortNumberDesc(columnName)
          case "datetime":
            return sortDateTimeDesc(columnName)
        }
        break;
      }
      default:
        return ()=>{}
    }
  }
  const handleChange = (evt) => {
    const [columnName, type, dataType] = evt.target.value.split("|").map(i => String(i).trim())
    setFilteredTerminals(prev => [...prev].sort(sortWithStateChange(columnName, type, dataType)))
  }
  return (
    terminals && (
      <div>
        <div>
            
            
            {/* <input value={searchedText} onChange={e => setSearchedText(e.target.value)}/> */}
        </div>
                          {/* =============== */}
                          {localStorage.getItem("clientId") !== "console" &&
                      <>
                    <nav className="mb-4 d-flex justify-content-center">
                    {toggleFilter && (
                      <button className="btn ml-2 my-auto" onClick={toggleFilter}>
                        <i
                          className={`fas fa-filter ${filter.visible ? 'text-primary' : 'text-secondary'
                            }`}
                        />
                      </button>
                    )}
                    </nav>
                    {/* <Pagination {...{totalItems: filteredTerminals.length,
                    page: filter.page,
                    rowsPerPage: filter.rowsPerPage,
                    filterVisibility: filter.visible,
                    resetFilter,
                    handleSwitchPage,
                    resetPage
                    }}
                    
                    /> */}

                    
                    {/* {filter.visible && terminalsList.length && <FilterEx
                    filter={filter}
                    columns={filter.columns}
                    data={(machines.length && terminals.length) && {machines, terminalsList}}
                    setFilter={setFilter}
                    resetFilter={resetFilter}
                    search={search}
                    resetPage={resetPage}
                    toggleFilter={toggleFilter}
                    />} */}
                    {filter.visible && <TrxsFilter 
                      filter={filter}
                      setFilter={setFilter}
                      machines={machines}
                      // terminals={terminals}
                      resetPage={resetPage}
                      search={search}
                      toggleFilter={toggleFilter}
                      customers={customers}
                      resetFilter={resetFilter}
                      terminalsList={terminalsList}
                      handleChange={handleChange}
                      />}
                    </>
                    
                    
                    
                    
                  }
                  {localStorage.getItem("clientId") === "console" &&
                  <>
                  <nav className="mb-4 d-flex justify-content-center">
                      {toggleFilter && (
                        
                        // <div>
                        <button className="btn ml-2 my-auto my-auto" onClick={toggleFilter}>
                          <i
                            className={`fas fa-filter ${filter.visible ? 'text-primary' : 'text-secondary'
                              }`}
                          />
                        </button>
                        // </div>
                      )}
                    </nav>
                    {filter.visible && <TrxsFilter 
                      filter={filter}
                      setFilter={setFilter}
                      machines={machines}
                      // terminals={terminals}
                      resetPage={resetPage}
                      search={search}
                      toggleFilter={toggleFilter}
                      customers={customers}
                      resetFilter={resetFilter}
                      terminalsList={terminalsList}
                      />}
                      

                      <button
                        className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                        onClick={openForm()}
                      >
                        <i className="fas fa-plus mr-2" /> Dodaj terminal
                      </button> 
                  </>


}
                  {
                    terminalsSummary && (
                      <div className='w-100 card-header d-flex justify-content-between align-items-center'>
                        {terminalsSummary && terminalsSummary.map(i => (
                          <div>
                            {i.Name}: {i.Count}
                          </div>
                        ))}
                        {/*  Nikita: commented until decided could this uncommented before Orange`s tests ended */}

                        
                        {/* <ReportBtn
                        clickFnc={exportEx89}
                        state={repEx89}
                        setState={repEx89}
                        overlayText={(<div>
                            <b>Typ raportu: <br /> Lista terminsli</b><br /><br />Export danych z listy <br /><br />Zawiera dane o wszystkich terminalach <br />
                          </div>)}
                        btnColor='success'
                      /> */}
                      </div>
                    )
                  }
        {terminals.length ? (
          <>
            {/* <SearchInput onSearch={updateSearchedText} /> */}
            {!filteredTerminals.length ? (
              // <NoResults buttonText={TRL_Pack.terminals.addTerminal} onClick={openForm()} />
              <NoResults />
            ) : (
              <div  >
                {/* <div>
                  <button
                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                    onClick={openForm()}
                  >
                    <i className="fas fa-plus mr-2" /> {TRL_Pack.terminals.addTerminal}
                  </button>
                </div> */}

                  {/* =============== */}
                  <table className="table table-striped border" >
                    <thead>
                      <tr>
                        <th className='p-1'><div className='d-flex flex-column justify-content-center align-items-center'>
                          <div className="text-nowrap">{TRL_Pack.terminals.client}</div>
                          <div className="text-nowrap">Typ terminala / {TRL_Pack.terminals.serialNo}</div>
                          {/* <div className="text-nowrap">{TRL_Pack.terminals.addedDate}</div> */}
                          
                          </div></th>
                        <th className='p-1'>
                          <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className="text-nowrap">Ostatnio online / Ostatnia transakcja</div>
                            <div className="text-nowrap">Status ostatniej transakcji / terminala</div>
                            {/* <div className="text-nowrap">Status terminala</div> */}
                          </div>
                        </th>
                        <th className='p-1'><div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className="text-nowrap">Automat</div>
                            <div className="text-nowrap">Sposób rozliczania sprzedaży / gospodarka magazynowa</div>
                          </div></th>
                        <th className='p-1'><div className='d-flex flex-column justify-content-center align-items-center'>
                          <div className="text-nowrap">Jakość (GSM) / Typ połączenia / IP</div>
                          <div className="text-nowrap">Operator GSM / WiFi / SIM Lock / Nr SIM</div>
                          {/* <div className="text-nowrap"> </div> */}
                          {/* <div className="text-nowrap">IMEI</div> */}
                          {/* <div className="text-nowrap">Nr SIM</div> */}
                        </div></th>
                        <th className='p-1'><div className='d-flex flex-column justify-content-center align-items-center'>

                          <div className="text-nowrap">Transakcje (ostatnie 3 dni)</div>
                          <div className="text-nowrap">Razem</div>
                          <div className="text-nowrap">Bezgotówkowe / Gotówkowe</div>
                          <div className="text-nowrap">Zwroty / Odmowy bezgotówkowe</div>
                        </div>
                        </th>
                        {/* <th>{TRL_Pack.machines.properties.delete}</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTerminals
                        // .slice(
                        //   localStorage.getItem("clientId") !== "console" ? 
                        //   ((filter.page - 1) * filter.rowsPerPage,
                        //   filter.page * filter.rowsPerPage) : (0, terminals.length) 
                        // )
                        .map((terminal, idx) => (
                          <tr key={idx}>
                            <td className='p-1'><div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="text-nowrap">
                                {terminal.CustomerName}</div>
                              <div className="text-nowrap">{terminal.TerminalTypeName} {terminal.SerialNo}</div>
                              {/* <div className="text-nowrap">{terminal.CreateDateTime}</div> */}
                            </div>
                            </td>
                            <td className='p-1'>
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                  {(terminal.LastSeenTimeDiff !== "0s" && terminal.LastSeenTimeDiff !== null && terminal.LastSeenTimeDiff.indexOf('s') !== -1 || terminal.LastSeenTimeDiff.indexOf('m') !== -1) && (<h6><div className="badge badge-success align-middle m-1">{terminal.LastSeenTimeDiff}</div></h6>
                                  )}
                                  {(terminal.LastSeenTimeDiff !== null && terminal.LastSeenTimeDiff.indexOf('h') !== -1) && (<h6><div className="badge badge-warning align-middle m-1">{terminal.LastSeenTimeDiff}</div></h6>
                                  )}
                                  {(terminal.LastSeenTimeDiff !== null && terminal.LastSeenTimeDiff.indexOf('d') !== -1) && (<h6><div className="badge badge-danger align-middle m-1">{terminal.LastSeenTimeDiff}</div></h6>
                                  )}
                                  {(terminal.LastTrxTimeDiff !== "0s" && terminal.LastTrxTimeDiff !== null && terminal.LastTrxTimeDiff.indexOf('s') !== -1 || terminal.LastTrxTimeDiff.indexOf('m') !== -1) && (<h6><div className="badge badge-success align-middle m-1">{terminal.LastTrxTimeDiff}</div></h6>
                                  )}
                                  {(terminal.LastTrxTimeDiff !== null && terminal.LastTrxTimeDiff.indexOf('h') !== -1) && (<h6><div className="badge badge-warning align-middle m-1">{terminal.LastTrxTimeDiff}</div></h6>
                                  )}
                                  {(terminal.LastTrxTimeDiff !== null && terminal.LastTrxTimeDiff.indexOf('d') !== -1) && (<h6><div className="badge badge-danger align-middle m-1">{terminal.LastTrxTimeDiff}</div></h6>
                                  )}
                                </div>

                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-middle'>
                                    {(terminal.TerminalTypeName.indexOf('PAX') !== -1 && terminal.LastTrxStatus.indexOf('A') !== -1) && (<h6><div className='fas fa-s fa-coins' /> </h6>)}
                                    {(terminal.TerminalTypeName.indexOf('PAX') !== -1 && terminal.LastTrxStatus.indexOf('A') === -1) && (<h6><div className='fas fa-s fa-credit-card' /> </h6>)}

                                    {(terminal.LastTrxStatus.indexOf('V_S') !== -1 || terminal.LastTrxStatus.indexOf('A') !== -1) && (<h6><div className="badge badge-success align-middle m-1">{terminal.LastTrxPrice.toFixed(2)}zł ({terminal.LastTrxProductNo})</div></h6>)}
                                    {(terminal.LastTrxStatus.indexOf('R_S') !== -1) && (<h6><div className="badge badge-danger align-middle m-1">{terminal.LastTrxPrice.toFixed(2)}zł ({terminal.LastTrxProductNo})</div></h6>)}
                                    {(terminal.LastTrxStatus.indexOf('T_F') !== -1) && (<h6><div className="badge badge-warning align-middle m-1">{terminal.LastTrxPrice.toFixed(2)}zł ({terminal.LastTrxProductNo})</div></h6>)}

                                    {terminal.AppState !== null && terminal.AppState.indexOf('detect') !== -1 && (<h6><div className="badge badge-success align-middle m-1">VMC</div></h6>)}
                                  </div>
                                  {/* <div className="text-nowrap">
                                    {terminal.AppState !== null && terminal.AppState.indexOf('detect') !== -1 && (<h6><div className="badge badge-success align-middle m-1">VMC</div></h6>)} */}
                                    {/* {(terminal.AppState.indexOf('todo') !== -1) && (<h6><div className="badge badge-danger align-middle m-1">{terminal.LastTrxPrice.toFixed(2)}zł ({terminal.LastTrxProductNo})</div></h6>)}
                                    {(terminal.AppState.indexOf('inactive') !== -1) && (<h6><div className="badge badge-warning align-middle m-1">{terminal.LastTrxPrice.toFixed(2)}zł ({terminal.LastTrxProductNo})</div></h6>)} */}
                                  
                                  {/* </div> */}
                                </div>
                                {/* <div className="text-nowrap">{terminal.UptimeOfDevice.replace('Days', 'd').replace('h ', 'h, ').replace('m ', 'm, ').replace('0 d,','').replace('0h,','').replace('0m,','').replace('0s,','')}</div> */}

                              </div>
                            </td>
                            <td className='p-1'>
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="text-wrap">{terminal.MachineFullName}</div>
                                <div className="text-nowrap">{terminal.UseTrxForSales}
                                {(terminal.UseTrxForSales === true) && (<h6><div className="badge badge-primary align-middle m-2">Transakcje</div></h6>)}
                                {(terminal.UseTrxForSales === false) && (<h6><div className="badge badge-info align-middle m-2">EVADTS / manualny</div></h6>)}
                                </div>
                                <div className="text-nowrap">{terminal.UseVMCTrx}
                                {(terminal.UseVMCTrx === true) && (<h6><div className="badge badge-dark align-middle m-1">Rozliczanie sprzedaży przez Transakcje R24 VMC</div></h6>)}
                                {/* {(terminal.UseVMCTrx === false) && (<h6><div className="badge badge-secondary align-middle m-1">Rozliczanie sprzedaży przez Transakcje</div></h6>)} */}
                                </div>
                              </div>
                            </td>
                            <td className='p-1'>
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                  {terminal.GSMLevel !== null && terminal.GSMLevel !== '' && (<h6><div className="badge badge-success align-middle m-1">{terminal.GSMLevel}</div></h6>
                                  )}
                                  {terminal.CurrentNetworkType !== null && terminal.CurrentNetworkType !== '' && (<h6><div className="badge badge-success align-middle m-1">{terminal.CurrentNetworkType}</div></h6>
                                  )}
                                  {terminal.CurrentIP !== null && terminal.CurrentIP !== '' && (<h6><div className="badge badge-success align-middle m-1">{terminal.CurrentIP}</div></h6>
                                  )}
                                </div>
                                <div className="d-flex flex-row align-middle">
                                {terminal.SIMOperator !== null && terminal.SIMOperator === 'Plus' && (<div className="align-middle mr-1"><img src={plusIcon} height="25" /></div>)}
                                {terminal.SIMOperator !== null && terminal.SIMOperator === 'T-Mobile' && (<img src={tmobileIcon} height="25" />)}
                                  {terminal.SIMOperator !== null && terminal.SIMOperator !== 'Plus' && terminal.SIMOperator !== 'T-Mobile' && (<h6><div className="badge badge-success align-middle m-1">{terminal.SIMOperator}</div></h6>
                                  )}
                                  {terminal.IsSIMNetworkLocked !== null && terminal.IsSIMNetworkLocked === 'true' && (<h6><div className="badge badge-warning align-middle m-1">SIM Lock</div></h6>
                                  )}
                                  {terminal.IsWiFiEnabled !== null && terminal.IsWiFiEnabled === 'true' && (<h6><div className="badge badge-success align-middle m-1">WiFi</div></h6>
                                  )}
                                  {terminal.SIMSerial !== null && terminal.SIMSerial !== '' && (<h6><div className="badge badge-info align-middle m-1">{terminal.SIMSerial}</div></h6>)}
                                </div>
                                {/* <div className="d-flex flex-row justify-content-center align-items-center">
                                
                                </div> */}
                                {/* <div className="text-nowrap"><h8>{terminal.SIMSerial}</h8></div> */}
                                {/* <div className="text-nowrap"><h8>{terminal.IMEI}</h8></div> */}
                              </div>
                            </td>
                            <td className='p-1'>
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                  {terminal.PositiveTrxCount !== null && (<h5> <div className="badge badge-primary align-middle ">{+terminal.PositiveTrxValue?.toFixed(2) || 0}zł  <span className="badge badge-light align-middle">{terminal?.PositiveTrxCount}</span></div></h5>
                                  )}
                                </div>
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                  {terminal.PositiveCashlessCount !== null && (<h6><div className='fas fa-xs fa-credit-card' /> <div className="badge badge-success align-middle ">{terminal.PositiveCashlessValue?.toFixed(2) || 0}zł  <span className="badge badge-light align-middle">{terminal?.PositiveCashlessCount}</span></div></h6>
                                  )}
                                  &nbsp;
                                  {/* </div>
                                <div className="text-nowrap"> */}
                                  {terminal.PositiveCashCount !== null && (<h6><div className='fas fa-xs fa-coins' /> <div className="badge badge-success align-middle ">{terminal.PositiveCashValue?.toFixed(2) || 0}zł  <span className="badge badge-light align-middle">{terminal?.PositiveCashCount}</span></div></h6>
                                  )}
                                  </div>
                                <div className='d-flex flex-row justify-content-center align-items-center'>
                                  {terminal.RefundCashlessCount !== null && (<h6><div className='fas fa-xs fa-exclamation-triangle' /> <div className="badge badge-danger align-middle ">{terminal.RefundCashlessValue?.toFixed(2) || 0}zł  <span className="badge badge-light align-middle">{terminal?.RefundCashlessCount}</span></div></h6>
                                )}
                                &nbsp;
                                {//   </div>
                                // <div className="text-nowrap">
                              }
                                  {terminal.FailedCashlessCount !== null && (<h6><div className='fas fa-xs fa-stop-circle' /> <div className="badge badge-warning align-middle ">{terminal.FailedCashlessValue.toFixed(2)}zł  <span className="badge badge-light align-middle">{terminal.FailedCashlessCount}</span></div></h6>
                                  )}</div>
                              </div>
                            </td>
                            <td className='p-1'>
                              {/* Adam: commented out for Orange testing */}
                              {hasPermissionToEditTerminal && (
                                <button className="btn btn-link link-icon" onClick={openForm(terminal.TerminalId)}>
                                  <i className="fas fa-edit" />
                                </button>
                              )}
                            
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
              </div>
            )}
          </>
        ) : (
          // <NoResults buttonText={TRL_Pack.terminals.addTerminal} onClick={openForm()} />
          <NoResults  />
        )}
        {  form &&  form !== "acceptForm" &&  (
          <TerminalForm
            terminalData={
              form !== 'new'
                ? filteredTerminals.find(terminal => terminal.TerminalId === form)
                : null
            }
            machines={machines}
            handleSubmit={submitTerminal}
            handleClose={closeForm}
            abbr = {findCustomerByTerminal(form)}
            customers={customers}
          />
          
        )}
        {
          form && form === "acceptForm" && (
            <FormSkel
              handleClose={closeForm}
              acceptForm={"true"}
            >
              <form onSubmit={submitTerminal} id="modal-form">
                {`Czy na pewno chcesz usunąć terminal? Jego przywrócenie będzie wymagało kontaktu ze Wsparciem Technicznym.`}
              </form>
            </FormSkel>
          )
        }
        {/* {
          form && form === 'new'  && (
            <NewTeriminalForm
              handleClose={handleClose}
            />
          )
        } */}
        {/* {
          form === 'newAutomat' && (
            <NewMachine

            />
          )
        } */}
        {/* {form2 && (
          <TerminalForm
            terminalData={
              form2 !== 'new'
                ? filteredTerminals.find(terminal => terminal.TerminalId === form2)
                : null
            }
            machines={machines}
            handleSubmit={submitTerminalCredit}
            handleClose={closeForm2}
          />
        )} */}
      </div>
    )
  )
}

export default Terminals;