import { useCallback, useContext } from 'react';
import axios from 'axios';
// import { NotificationManager } from 'react-notifications';

import { API_URL } from '../config/config'
import { LangContext } from '../context/lang-context';
import { LoaderContext } from '../context/loader-context';
import { NotificationContext } from '../context/notification-context';

const useHttp = () => {
    // const { TRL_Pack } = useContext(LangContext);
    const loaderCtx = useContext(LoaderContext);
    const notificationCtx = useContext(NotificationContext);

    const errorStatusCodeHandler = error => {
        let _error;

        switch (error.status) {
            case 404:
                _error = '404';
                break;
            default:
                _error = error.data.message;
        }
        return _error;
    }

    const sendRequest = useCallback((requestConfig, applyData) => {
        loaderCtx.showLoader(true);


        axios({
            method: requestConfig.method ? requestConfig.method : 'GET',
            url: `${API_URL}/api/${requestConfig.url}`,
            data: requestConfig.data ? requestConfig.data : null,
            headers: {
            }
        })
            .then(response => {
                loaderCtx.showLoader(false);

                if (requestConfig.method && requestConfig.method !== 'GET')
                    notificationCtx.notification('success', response.data.message);

                // if(!requestConfig.method || requestConfig.method === 'GET')
                applyData(response);
            })
            .catch(error => {
                loaderCtx.showLoader(false);

                let err = null;

                if (error.response)
                    err = errorStatusCodeHandler(error.response);

                if (!err)
                    err = error.message;

                notificationCtx.notification('error', err);
            })
    }, []);

    return {
        sendRequest
    }
};

export default useHttp;