import React, { useContext, useEffect, useState} from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../../context/navigation-context'
import { useParams, Link, Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import link from '../../../assets/images/back_to_list_icon.svg'
import { NotificationManager } from 'react-notifications'

export default function PrepaidUser() {
    const params = useParams(),
    { fetchMssqlApi } = fetchHook(),
    { setHeaderData } = useContext(NavigationContext),
    [initialPrepaid, setInitialPrepaid] = useState({}),
    [prepaid, setPrepaid] = useState({}),
    [balanceControlType, setBalanceControlType] = useState("addWithoutOverwrite"),
    [groupList, setGroupList] = useState([]),
    [statusList, setStatusList] = useState([]),
    [trxs, setTrxs] = useState([]),
    [customers, setCustomers] = useState([]),
    [types, setTypes] = useState([]),
    [prepaidUsers ,setPrepaidUsers] = useState([]),
    [changed, setChanged] = useState(false),
    [avaiableToSave, setAvaiableToSave] = useState(false),
    [variableTrxData, setVariableTrxData] = useState({}),
    [trxFilter, setTrxFilter] = useState({
        StartDateTime: moment().add(-7, "d").format('YYYY-MM-DD 00:00:00'),
        EndDateTime: moment().format('YYYY-MM-DD 23:59:59')
    }),
    history = useHistory()

    const getPrepaid = (id = params.id) => {
        fetchMssqlApi(`prepaid/${id}`, {}, res => {
            if (!res.PrepaidId && params.id !== "0") {
                NotificationManager.error("Takiej karty nie istnieje")
                history.push('/prepaids/cards')
            }
            setInitialPrepaid(res)
        })
    }
    const getPrepaidGroupList = (id) => {
        if(!id) return
        fetchMssqlApi(`prepaid-group-list/${id}`, {}, res => res.length && setGroupList(res))
    }
    const getPrepaidStatusList = () => {
        fetchMssqlApi(`prepaid-status`, {}, res => res.length && setStatusList(res))
    }
    const getPrepaidUsers = (id = null) => {
        fetchMssqlApi(`prepaids/${id ? id : ""}`, {}, res => {
            // prepaidGroup.PrepaidGroupId
            // ? setPrepaidUsers(res.filter(user => !user.PrepaidGroupId || user.PrepaidGroupId === prepaidGroup.prepaidGroupId))
            // : setPrepaidUsers(res.filter(user => !user.PrepaidGroupId))
            res.length && setPrepaidUsers(res)
        })
    }
    const getPrepaidTrx = (id , StartDateTime = moment().add(-7, "d").format('YYYY-MM-DD 00:00:00'), EndDateTime = moment().format('YYYY-MM-DD 23:59:59')) => {
        // if(!code) return
        // fetchMssqlApi(`prepaid-trx/${code}`, {method: "POST", data: {StartDateTime , EndDateTime}, hideNotification: true}, res => {res.TRXs && setTrxs(res.TRXs)
        // res.VariableData && setVariableTrxData(res.VariableData)})
        if(!id) return
        fetchMssqlApi(`prepaid-inp/${id}`, {method: "POST", data: {StartDateTime , EndDateTime}, hideNotification: true}, res => res.length ? setTrxs(res.filter(a => !(!a.Info))) : setTrxs([]))
    }
    const getCustomers = () => {
        if (localStorage.getItem('clientId') === "console" && params.id === "0") {
            fetchMssqlApi('customers', {}, res => res.length && setCustomers(res))
        } 
    }
    const getPrepaidTypes = () => {
        if ( params.id === "0") {
            fetchMssqlApi('prepaid-types', {}, res => res.length && setTypes(res))
        } 
    }

    const getCredit = (value) => {
        const value1 = parseFloat(value)
        if (prepaid.CreditValue && !value) return parseFloat(prepaid.CreditValue).toFixed(2)
        if (Number.isNaN(value1) && value || (!prepaid.CreditValue && !value))  {
            return "0,00"
        } else {
            switch (balanceControlType) {
                case "addWithOverwrite": 
                    return  value1.toFixed(2)
                case "addWithoutOverwrite": 
                    return  (prepaid.CreditValue + value1).toFixed(2)
                case "cleanBalance": 
                    return  0.00
                default: return parseFloat(prepaid.CreditValue).toFixed(2)
            }
        }
    }
    const handleSubmit = () => {
        const isConsole = localStorage.getItem('clientId') === 'console'
        let payload

                payload = {
                    OwnerData: prepaid.OwnerData,
                    AdditionalData: prepaid.AdditionalData,
                    PrepaidGroupId: prepaid.PrepaidGroupId,
                    PrintedCode: prepaid.PrintedCode,
                    Code: prepaid.Code,
                    PrepaidStatusId: prepaid.PrepaidStatusId, 
                    PrepaidTypeId: prepaid.PrepaidTypeId,
                    CreditValue: getCredit(prepaid.BalanceControl), 
                    CustomerId: prepaid.Abbreviation
                }
        //==================================================
        // if (isConsole && !payload.Abbreviation) {
        //     NotificationManager.error("Customer not found")
        //     return
        // }
        if (!payload.OwnerData) {
            NotificationManager.error("Card owner name not found")
            return
        }
        if (!payload.Code || !payload.PrintedCode) {
            NotificationManager.error("Card number not found")
            return
        }
        if (!payload.PrepaidStatusId) {
            NotificationManager.error("Prepaid status not found")
            return
        }
        if (!payload.PrepaidTypeId) {
            NotificationManager.error("Prepaid type not found")
            return
        }
        // if (!payload.CreditValue) {
        //     NotificationManager.error("Credit not found")
        //     return
        // }
        //==================================================
        // if (params.id === "0") {
        //     fetchMssqlApi('prepaid', {method: 'POST', data: payload}, res => {
        //         console.log(res)
        //     })
        // } else {
        //     fetchMssqlApi('prepaid', {method: 'PUT', data: payload}, res => {
        //         console.log(res)
        //     })
        // }
        if (params.id !== "0") {
            // console.log(prepaidUsers.filter(item => item.PrepaidId !== initialPrepaid.PrepaidId).map((item)=> item.PrintedCode).indexOf(prepaid.PrintedCode) )
            if (prepaidUsers.filter(item => item.PrintedCode !== initialPrepaid.PrintedCode).map(({PrintedCode})=> PrintedCode).indexOf(prepaid.PrintedCode) !== -1) {
                setPrepaid(prev => ({...prev, PrintedCode: ""}))
                NotificationManager.error("This printed code already in base")
                return
            }
        }
        if (params.id === "0") {
            if (prepaidUsers.map(({Code})=> Code).indexOf(prepaid.Code) !== -1) {
                setPrepaid(prev => ({...prev, Code: ""}))
                NotificationManager.error("This code already in base")
                return
            }
            if (prepaidUsers.map(({PrintedCode})=> PrintedCode).indexOf(prepaid.PrintedCode) !== -1) {
                setPrepaid(prev => ({...prev, PrintedCode: ""}))
                NotificationManager.error("This printed code already in base")
                return
            }
        }
        if (params.id !== "0") {
            fetchMssqlApi(`prepaid/${params.id}`, {method: 'PUT', data: payload}, res => {
                setInitialPrepaid(res)
                setBalanceControlType("addWithoutOverwrite")
                getPrepaidTrx(params.id, trxFilter.StartDateTime, trxFilter.EndDateTime)
                setChanged(false)
            })
        } else {
            
            fetchMssqlApi('prepaid/new', {method: "POST", data: payload}, res => {
                if (res.id) {
                    setChanged(false)
                    history.replace(res.id)
                    getPrepaid(res.id)
                    getPrepaidTrx(initialPrepaid.PrepaidId, trxFilter.StartDateTime || moment().add(-7, "d").format('YYYY-MM-DD 00:00:00') , trxFilter.EndDateTime ||  moment().format('YYYY-MM-DD 23:59:59'))
                } else {
                    NotificationManager.error(res.message)
                }
            })
        }
        // console.log(payload)
    }
    const handleChangeGroup = (data) => {
        const group = groupList.length && groupList.find(group => String(group.Name).toLocaleLowerCase() === String(data).toLocaleLowerCase())
        if(!group) setPrepaid(prev => ({...prev, PrepaidGroupName: data}))
        else setPrepaid(prev => ({...prev, PrepaidGroupName: data, PrepaidGroupId: group.PrepaidGroupId}))
    }
    const handleChangeStatus = (data) => {
        const status = statusList.find(status => String(status.Name).toLocaleLowerCase() === String(data).toLocaleLowerCase())
        if(!status) setPrepaid(prev => ({...prev, PrepaidStatusName: data}))
        else setPrepaid(prev => ({...prev, PrepaidStatusName: data, PrepaidStatusId: status.PrepaidStatusId}))
    }
    const handleChangeCreditValue = data => {
        // const value = data.replace(/[^0-9,.]/g, '')
        setPrepaid(prev => ({...prev, CreditValue: data}))
    }
    const handleChangeCustomer = (data) => {
        setPrepaid(prev => ({...prev, CustomerName: data}))
        if (!customers.length) return
        const customer  = customers.find(customer => `${customer.Name} (${customer.Abbreviation})` === data)
        if (!customer) return
        getPrepaidGroupList(customer.CustomerId)
        setPrepaid(prev => ({...prev, CustomerId: customer.CustomerId, Abbreviation: customer.Abbreviation}))
    } 
    const handleChangeType = (data) => {
        setPrepaid(prev => ({...prev, PrepaidTypeName: data}))
        if (!types.length) return
        const type  = types.find(type => type.Name === data)
        if (!type) return
        setPrepaid(prev => ({...prev, PrepaidTypeId: type.PrepaidTypeId}))
    } 
    const handleChangeData = (evt) => {
        const {name, value} = evt.target
        if((name === "addWithoutOverwrite" || name === "addWithoutOverwrite") && +value > 10000000) return
        setChanged(true)
        switch (name) {
            case "addWithOverwrite": 
                balanceControlType !== "addWithOverwrite" ? setBalanceControlType("addWithOverwrite") : setBalanceControlType(null) 
                break;
            case "addWithoutOverwrite": 
                balanceControlType !== "addWithoutOverwrite" ? setBalanceControlType("addWithoutOverwrite") : setBalanceControlType(null) 
                break;
            case "cleanBalance": 
                balanceControlType !== "cleanBalance" ? setBalanceControlType("cleanBalance") : setBalanceControlType(null) 
                break;
            case "PrepaidGroupName":
                handleChangeGroup(value)
                break;
            case "PrepaidStatusName":
                handleChangeStatus(value)
                break;
            case "CustomerName":
                handleChangeCustomer(value)
                break;
            case "CreditValue":
                handleChangeCreditValue(value)
                break;
            case "PrepaidTypeName":
                handleChangeType(value)
                break;
            case "BalanceControl":
                setPrepaid({...prepaid, BalanceControl: value || 0})
                break;
            case "PrintedCode":
            case "Code":
                setPrepaid({...prepaid, [name]: value.replace(/[^0-9]/g, "")})
                break
            default: 
                setPrepaid({...prepaid, [name]: value})
                break;
        }
    }
    const getBalanceControlHeader = () => {
        switch (balanceControlType) { 
            case "addWithOverwrite": return "Ustaw nowy kredyt"
            case "addWithoutOverwrite": return "Doładuj środki"
            default: return "Wybierz sposób doładowania"
            // case "cleanBalance": return "Środki zostaną anulowane"
        }
    }
    const handleChangeFocus = (evt) => {
        const {name} = evt.target
        setChanged(true)
        switch (name) {
            case "PrepaidGroupName": 
                setPrepaid({...prepaid, [name]: "", PrepaidGroupId: ""} ) 
                break;
            case "PrepaidStatusName": 
                setPrepaid({...prepaid, [name]: "", PrepaidStatusId: ""} ) 
                break;
            case "PrepaidTypeName": 
                setPrepaid({...prepaid, [name]: "", PrepaidTypeId: ""} ) 
                break;
            case "CustomerName": 
                {
                    setPrepaid({...prepaid, [name]: "", PrepaidGroupId: "", PrepaidGroupName: "" , CustomerId: ""} )
                    setGroupList([])
                }
                break;
            default:
                setPrepaid({...prepaid, [name]: ""})
        }
    }
    useEffect(() => {
        setHeaderData({text: "Pogląd karty prepaid"})
        getPrepaid()
        getPrepaidStatusList()
        localStorage.getItem('clientId') === "console" && getCustomers()
        getPrepaidTypes()
        localStorage.getItem('clientId') !== "console" && getPrepaidGroupList(localStorage.getItem('clientId'))
        getPrepaidUsers()
        getPrepaidTrx(params.id)
    }, [])
    useEffect(() => {
        setPrepaid(initialPrepaid)
        initialPrepaid.CustomerId && getPrepaidGroupList(initialPrepaid.CustomerId)
        // initialPrepaid.Code && getPrepaidTrx(initialPrepaid.Code)
    }, [initialPrepaid])
    useEffect(() => {
        balanceControlType === null && setPrepaid(prev => ({...prev, BalanceControl: 0}))
    }, [balanceControlType])
    useEffect(() => {
        if(prepaid.BalanceControl > 10000000) {
            NotificationManager.warning('Maksymalna ilość dodawania środków - 10000000', "", 600)
        }
        (!prepaid.Code
        || !prepaid.OwnerData
        || !prepaid.PrepaidStatusId 
        || !prepaid.PrepaidStatusName
        || !prepaid.PrepaidTypeName
        || !prepaid.PrepaidTypeId
        || !prepaid.PrintedCode
        || !prepaid.PrepaidGroupId
        || !prepaid.PrepaidGroupName
        || (+prepaid.BalanceControl) > 10000000)
        ? setAvaiableToSave(false) : setAvaiableToSave(true)
    }, [prepaid])
    return (
        <div>
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            <Link to='/prepaids/cards'>
                <label htmlFor='back' className="btn btn-link ml-1 text-decoration-none ml-2 mb-3">
                    <img id='back' src={link}></img>
                </label>
            </Link>
            <div className={`d-flex justify-content-center`} style={{marginBottom: "50px"}}>
                <div name="UserSettings" className='card' style={window.innerWidth > 500 ? {width: "40%"} : {width: "100%"}}>
                    <div className='card-header'>
                        Szczegóły Karty Prepaid
                    </div>
                    <div className='card-body'>
                        <div className='mb-3'>
                            <div className='h-6'>Właściciel karty prepaid</div>
                            <input
                                className={`form-control mt-2 mb-2 ${!prepaid.OwnerData && `invalid-input`}`}
                                type='text'
                                name='OwnerData'
                                value={prepaid.OwnerData || ""}
                                onChange={handleChangeData}
                                onFocus={handleChangeFocus}
                                placeholder='Wpisz właściciela karty prepaid...'
                            />
                        </div>
                        <div className='mb-3'>

                            <label className='h-6'>Dodatkowe informacje</label>
                            <input
                                className='form-control mb-2'
                                type='text'
                                minLength={2}
                                name="AdditionalData"
                                value={prepaid.AdditionalData || ""}
                                onChange={handleChangeData}
                                onFocus={handleChangeFocus}
                                placeholder='Wpisz dodatkową informacje...'
                            />
                        </div>
                        {
                            localStorage.getItem('clientId') === "console" && (
                                <div className='mb-3'>

                            <label className='h-6'>Klient</label>
                            <input
                                className={`form-control mb-2 ${(!prepaid.CustomerId || !prepaid.CustomerName) && `invalid-input`}`}
                                type='text'
                                minLength={2}
                                name="CustomerName"
                                value={prepaid.CustomerName || ""}
                                onChange={handleChangeData}
                                onFocus={handleChangeFocus}
                                placeholder='Wybierz klienta...'
                                list='customerList'
                                disabled={!(params.id === "0")}
                            />
                            <datalist id='customerList'>
                                {customers && customers.map((customer, idx) => (
                                    <option key={idx} value={`${customer.Name} (${customer.Abbreviation})`}/>
                                ))}
                            </datalist>
                            </div>
                            )
                        }
                        <div className='mb-3'>

                            <label className='h-6'>Grupa Prepaid</label>
                            <input
                                className={`form-control mb-2 ${(!prepaid.PrepaidGroupName || !prepaid.PrepaidGroupId) && `invalid-input`}`}
                                type='text'
                                minLength={2}
                                name="PrepaidGroupName"
                                value={prepaid.PrepaidGroupName || ""}
                                onChange={handleChangeData}
                                onFocus={handleChangeFocus}
                                list='PrepaidGroupNameList'
                                placeholder='Wybierz grupę Prepaid...'
                            />
                            <datalist id='PrepaidGroupNameList' >
                                {groupList && groupList.map((group, idx) => (
                                    <option key={idx} value={group.Name}/>
                                ))}
                            </datalist>
                        </div>
                        <div className='mb-3 d-flex justify-content-between align-items-center flex-wrap'>
                            <div className=' flex-fill  mr-3'>
                                <label className='h-6'>Nadrukowany numer karty</label>
                                <input
                                    className={`form-control mb-2 ${!prepaid.PrintedCode && `invalid-input`}`} 
                                    type='text'
                                    minLength={2}
                                    name="PrintedCode"
                                    value={prepaid.PrintedCode || ""}
                                    onChange={handleChangeData}
                                    onFocus={handleChangeFocus}
                                    placeholder='Wpisz nadrukowany numer karty...'
                                />
                            </div>
                            <div className=' flex-fill '>
                                <label className='h-6'>Wewnętrzny numer karty </label>
                                <input
                                    className={`form-control mb-2 ${!prepaid.Code && `invalid-input`}`} 
                                    type='text'
                                    minLength={2}
                                    name="Code"
                                    value={prepaid.Code || ""}
                                    onChange={handleChangeData}
                                    onFocus={handleChangeFocus}
                                    disabled={params.id !== "0"}
                                    placeholder='Wpisz wewnętrzny numer karty...'
                                />
                            </div>
                        </div>
                        <div className='mb-3 d-flex justify-content-between align-items-center flex-wrap'>
                            <div className='flex-fill mr-3'>
                                <label className='h-6'>Status</label>
                                <input
                                    className={`form-control mb-2 ${(!prepaid.PrepaidStatusName || !prepaid.PrepaidStatusId) && `invalid-input`}`} 
                                    type='text'
                                    minLength={2}
                                    name="PrepaidStatusName"
                                    value={prepaid.PrepaidStatusName || ""}
                                    onChange={handleChangeData}
                                    onFocus={handleChangeFocus}
                                    placeholder='Wpisz status...'
                                    list='PrepaidStatusNameList'
                                />
                                <datalist id='PrepaidStatusNameList'>
                                    {statusList && statusList.map((status, idx) => (
                                        <option key={idx} value={status.Name}/>
                                    ) )}
                                </datalist>
                            </div>
                            <div className=' flex-fill '>
                                <label className='h-6'>Typ karty</label>
                                <input
                                    className={`form-control mb-2 ${(!prepaid.PrepaidTypeName || !prepaid.PrepaidTypeName) && `invalid-input`}`} 
                                    type='text'
                                    minLength={2}
                                    name="PrepaidTypeName"
                                    value={prepaid.PrepaidTypeName || ""}
                                    onChange={handleChangeData}
                                    onFocus={handleChangeFocus}
                                    disabled={params.id !== "0"}
                                    list='prepaidTypes'
                                    placeholder='Wpisz typ karty prepaid...'

                                />
                                <datalist id='prepaidTypes'>
                                    {types && types.map((type, idx) => (
                                        <option key={idx} value={type.Name}/>
                                    ))}
                                </datalist>
                            </div>
                        </div>
                        
                        <div className='d-flex flex-wrap'>
                            <div className='flex-fill mr-4 pr-0' style={{maxWidth: "50%"}}>
                                <label className='h-6'>Bieżący Kredyt</label>
                                <input
                                    className='form-control mr-3 mb-2'
                                    type={params.id === "0" ? 'number' : 'text'}
                                    // minLength={2}
                                    name="CreditValue"
                                    value={params.id !== "0" ? Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(prepaid.CreditValue) : prepaid.CreditValue || ""}
                                    onChange={handleChangeData}
                                    onFocus={handleChangeFocus}
                                    disabled={params.id !== "0"}
                                />
                            </div>
                            {params.id !== "0" && (
                                <div className='d-flex align-items-end mb-2 justify-content-center flex-wrap' >
                                    <button
                                        onClick={handleChangeData}
                                        className= {balanceControlType !== "addWithoutOverwrite" ? "btn btn-outline-primary mr-2" : "btn btn-primary mr-2"}
                                        name="addWithoutOverwrite"
                                    >
                                        Doładuj
                                    </button>
                                    <button
                                        onClick={handleChangeData}
                                        className= {balanceControlType !== "addWithOverwrite" ? "btn btn-outline-success mr-2" : "btn btn-success mr-2"}
                                        name="addWithOverwrite"
                                    >
                                        Ustaw Kredyt
                                    </button>
                                    {/* <button
                                        onClick={handleChangeData}
                                        className= {balanceControlType !== "cleanBalance" ? "btn btn-outline-danger mr-2" : "btn btn-danger mr-2"}
                                        name="cleanBalance"
                                    >
                                        Wyzeruj
                                    </button> */}
                                </div>
                            )}
                            </div>
                            {params.id !== "0" && (
                            <div className='d-flex'>
                                <div className='w-100'>
                                    <label className='h-6'>
                                        {getBalanceControlHeader()}
                                    </label>
                                    {/* {balanceControlType !== "cleanBalance" && (/ */}
                                        <input
                                        className={`form-control mb-2`}
                                        type='number'
                                        minLength={2}
                                        name="BalanceControl"
                                        value={prepaid.BalanceControl || ""}
                                        // max={0}
                                        onChange={handleChangeData}
                                        disabled={!balanceControlType}
                                        />
                                    {/* )} */}

                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {
                    params.id !== "0" && (

                            <div name="UserTransactons" className='card ml-3' style={window.innerWidth > 500 ? {width: "60%"} : {width: "100%"}}>
                                <div className='card-header d-flex justify-content-between'>
                                    <div>
                                        Transakcje
                                    </div>
                                    <span>
                                        <b>Wydane środki: { Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trxs.length && trxs.map(trx => trx.Source.split(":")[0] === "Terminal" ? -1 * trx.Price : 0 ).reduce((a, b) =>  a +  b))  || "0,00zł"}</b>
                                    </span>
                                </div>
                                {/* <div> */}
                                {/* {variableTrxData.AverageSpent && variableTrxData.SummarySpent && (
                                    <div className='card-body d-flex justify-content-between p-1 ml-3 mr-3'>
                                        <div>
                                            <h5>Średnia cena</h5>
                                            <h6>{ Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(variableTrxData.AverageSpent / 100) || ""}</h6>
                                        </div>
                                        <div>
                                            <h5>Suma wydań</h5>
                                            <h6>{ Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(variableTrxData.SummarySpent / 100) || ""}</h6>
                                        </div>

                                    </div>
                                )}
                            </div> */}
                                    <div className='d-flex justify-content-between mr-3 ml-3 mb-2 mt-2'>
                                    <div className='d-flex'>
                                        <div className='mr-3'>
                                            <div>
                                                Od
                                            </div>
                                            <input
                                                type='date'
                                                className='form-control'
                                                value={moment(trxFilter.StartDateTime).format('YYYY-MM-DD') || ""}
                                                onChange={evt => {
                                                    const value = evt.target.value
                                                    setTrxFilter(prev => ({...prev, StartDateTime: moment(value).format('YYYY-MM-DDT00:00:00')}))}}
                                            />
                                        </div>
                                        <div>
                                            <div>
                                                Do
                                            </div>
                                            <input
                                                type='date'
                                                className='form-control'
                                                value={moment(trxFilter.EndDateTime).format('YYYY-MM-DD') || ""}
                                                onChange={evt => {
                                                    const value = evt.target.value
                                                    setTrxFilter(prev => ({...prev, EndDateTime: moment(value).format('YYYY-MM-DDT23:59:59')}))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-end'>
                                        <button
                                            className= { `btn h-100 btn-${
                                            !(moment(trxFilter.StartDateTime).format('YYYY-MM-DD') === moment(trxFilter.EndDateTime).format('YYYY-MM-DD')) && (trxFilter.StartDateTime > trxFilter.EndDateTime) 
                                            || !trxFilter.StartDateTime 
                                            || !trxFilter.EndDateTime
                                            || moment(trxFilter.StartDateTime).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
                                            ? `danger` 
                                            : `success`} 
                                            ml-2 mr-2`}
                                            onClick={() => {
                                                if (!trxFilter.StartDateTime || !trxFilter.EndDateTime) {
                                                    NotificationManager.error("Choose date")
                                                    return
                                                }
                                                if(!(moment(trxFilter.StartDateTime).format('YYYY-MM-DD') === moment(trxFilter.EndDateTime).format('YYYY-MM-DD')) && (trxFilter.StartDateTime > trxFilter.EndDateTime)) {
                                                    NotificationManager.error("Start date can`t be bigger than end date", "Choose correct data")
                                                    return
                                                }
                                                if (moment(trxFilter.StartDateTime).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') ) {
                                                    NotificationManager.error("Choose past date, not future", "Choose correct data")
                                                    return
                                                }
                                                getPrepaidTrx(initialPrepaid.PrepaidId, trxFilter.StartDateTime, trxFilter.EndDateTime)
                                            }}
                                        >Szukaj</button>
                                        <button
                                            className= { `btn btn-sm h-100 btn-primary mr-2`}
                                            // onClick={() => {
                                            //     getPrepaidTrx(initialPrepaid.Code, moment().add(-7, "d").format('YYYY-MM-DDT00:00:00'), moment().format('YYYY-MM-DDT23:59:59'))
                                            // }}
                                            onClick={() => setTrxFilter({
                                                StartDateTime:  moment().add(-7, "d").format('YYYY-MM-DDT00:00:00'),
                                                EndDateTime: moment().format('YYYY-MM-DDT23:59:59')
                                            })}
                                        >Przywróć domyślne</button>
                                        <button
                                            className= { "btn btn-sm h-100 btn-primary mr-2"}
                                            onClick={() => setTrxFilter({
                                                StartDateTime: moment().startOf('month').format('YYYY-MM-DDT00:00:00'),
                                                EndDateTime: moment().format('YYYY-MM-DDT23:59:59')
                                            })}
                                        >Bieżący miesiąc</button>
                                        <button
                                            className= { "btn btn-sm h-100 btn-primary mr-2"}
                                            onClick={() => setTrxFilter({
                                                StartDateTime: moment().add(-31, "d").format('YYYY-MM-DDT00:00:00'),
                                                EndDateTime: moment().format('YYYY-MM-DDT23:59:59')
                                            })}
                                        >Poprzedni miesiąc</button>
                                    </div>
                                    </div>
                                <div className='card-body overflow-auto pt-0'  style={{ maxHeight: "500px" }}>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <td>Źródło</td>
                                            <td>Data</td>
                                            <td className='text-center'>Kwota</td>
                                            <td className='text-center'>Kredyt (przed)</td>
                                            <td className='text-center'>Kredyt (po)</td>
                                            <td>Dodatkowe informacje</td>
                                            {/* <td>Czas transakcji</td> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trxs.length ? trxs.map((trx, idx) => (
                                            <tr key={idx}>
                                                <td>{trx.Source}</td>
                                                <td>{trx.CreateDateTime}</td>
                                                <td className='text-center'><h6 className='mt-1'><span className={`badge ${(trx.Source !== "Portal" && trx.Source !== "Dashboard") && `badge-${+trx.Price === 0 && `danger` || +trx.Price < 0 && `success` ||  `secondary`}`}`}>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.Price)  || " "}</span></h6></td>
                                                <td className='text-center'><h6 className='mt-1'><span className='badge badge-secondary'>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.CreditBefore) || " "}</span></h6></td>
                                                <td className='text-center'><h6 className='mt-1'><span className='badge badge-primary'>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(trx.CreditAfter)  || " "}</span></h6></td>
                                                {/* <td>
                                                    {trx.TerminalType === "PAX" && (<h5><span className={`badge badge-${+trx.Price === 0 ? `danger` : `success`} align-middle`}>{`${trx.SerialNo} (${trx.TerminalType})`}</span></h5>)}
                                                    {trx.TerminalType === "NAYAX" && (<h5><span className="badge badge-primary align-middle">{`${trx.SerialNo} (${trx.TerminalType})`}</span></h5>)}
                                                </td> */}
                                                <td>{trx.Info}</td>
                                            </tr>
                                        )) : (<></>)}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                    )
                }
            </div>
            <div className='position-fixed container' style={{bottom: "0px"}}>

                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button disabled={!avaiableToSave || !changed } className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
            </div>
        </div>
    )
}
