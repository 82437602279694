import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'
import { NotificationManager } from 'react-notifications'

const FZS = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)

  const [FZS, setFZS] = useState(null)
  const [contractors, setContractors] = useState([])
  const [warehouses, setWarehouses] = useState([])

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    name: "fzs",
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      // {
      //   id: 1,
      //   name: 'Pokazuj zerowe PA',
      //   searchable: true,
      //   sortable: false,
      //   type: 'threeStateBool',
      //   selectbar: null
      // },
      // {
      //   id: 2,
      //   name: 'Telemetria',
      //   searchable: true,
      //   sortable: false,
      //   type: 'threeStateBool',
      //   selectbar: null
      // },
      // {
      //   id: 3,
      //   name: 'Maszyna',
      //   searchable: true,
      //   sortable: true,
      //   type: 'lookup',
      //   MachineId: null
      // },
      // {
      //   id: 4,
      //   name: 'Serwisant',
      //   searchable: true,
      //   sortable: true,
      //   type: 'lookup',
      //   CreateUserId: null
      // }, 
      { 
        id: 1,
        name: 'Kontrahent',
        searchable: true,
        sortable: false,
        type: 'lookup',
        selectbar: null,
        value: null
      },
      {
        id: 2,
        name: 'Magazyn',
        searchable: true,
        sortable: false,
        type: 'lookup',
        selectbar: null,
        value: null
      },
      {
        id: 5,
        name: 'Zakres czasowy',
        searchable: true,
        sortable: false,
        type: 'dateRange',
        dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DD'),
        dateTimeRangeTo: moment().format('YYYY-MM-DD')
      }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('fzsFilter')) {
      return JSON.parse(localStorage.getItem('fzsFilter'));
    }
    return defaultFilter;
  })

  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);

    //    search();
    getFZS(moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'), moment().format('YYYY-MM-DDT23:59:59'), null, null, null);
  }

  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let contractorId = null;
    // if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
    //   contractorId = columns[2].MachineId;
    const contractor = contractors.find(contractor => contractor.Name === columns[0].searchbar) 
    contractorId = contractor && contractor.ContractorId 
    setFilter(prev => ({...prev, columns: prev.columns.map(col => col.name === 'Contrahent' ? {...col, value: contractorId} : col)}))
    let warehouseId = null;
    // if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
    //   warehouseId = columns[2].MachineId;
    const warehouse = warehouses.find(warehouse => warehouse.Name === columns[1].searchbar)
    warehouseId = warehouse && warehouse.WarehouseId 
    setFilter(prev => ({...prev, columns: prev.columns.map(col => col.name === 'Magazyn' ? {...col, value: warehouseId} : col)}))
    let createUserId = null;
    // if (JSON.parse(columns[3].CreateUserId != '') && JSON.parse(columns[3].CreateUserId != null))
    //   createUserId = columns[3].CreateUserId;

    //set default create from / to date time
    let fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (!moment(filter.columns[2].dateTimeRangeFrom).isValid() || 
    !moment(filter.columns[2].dateTimeRangeTo).isValid()) {
      NotificationManager.error("Wybierz poprawną datę")
      return
    }
    if (JSON.parse(columns[2].dateTimeRangeFrom != '') && JSON.parse(columns[2].dateTimeRangeFrom != null))
      //TODO: parse manually entered(?)
      fromCreateDateTime = moment(columns[2].dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ss') ;

    if (JSON.parse(columns[2].dateTimeRangeTo != '') && JSON.parse(columns[2].dateTimeRangeTo != null))
      //TODO: parse manually entered(?)
      toCreateDateTime = moment(columns[2].dateTimeRangeTo).format('YYYY-MM-DDT23:59:59');

    getFZS(fromCreateDateTime, toCreateDateTime, contractorId, warehouseId, createUserId);
  }
  const getWarehouses = () => {
    fetchMssqlApi('warehouses-list', {}, warehouses => setWarehouses(warehouses))
  }

  const getContractors = () => {
    fetchMssqlApi('contractors-list', {}, contractors => setContractors(contractors))
  }
  const getFZS = (fromCreateDateTime =  moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss')
  , toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59')
  , contractorId = filter.columns[0].value || null
  , warehouseId = filter.columns[1].value || null
  , createUserId = null) => {
    fetchMssqlApi('fz-fzs', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        fromCreateDateTime: fromCreateDateTime,
        toCreateDateTime: toCreateDateTime,
        contractorId: contractorId,
        warehouseId: warehouseId,
        createUserId: createUserId
      }
    }, response => {
      setFZS(response);
      // console.log(response)
    })
  }


  useEffect(() => {
    getFZS()
    setHeaderData({
      text: TRL_Pack.fz.fzs
    });
    getWarehouses()
    getContractors()
  }, [])

  useEffect(() => localStorage.setItem('fzsFilter', JSON.stringify(filter)), [
    filter
  ])


  const sortRows = (a, b) => {
    /*const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(salesdoc[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'text' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col].replace(',', ''))
      valueB = Number(b[col].replace(',', ''))

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    */
  }

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }

  const getUI = () => {
    if (FZS != null) {
      let temp = JSON.parse(JSON.stringify(FZS));

      return (
        <div>
          <div className='container-fluid'>
            <Pagination
              {...{

                totalItems: FZS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: {warehouses: warehouses, contractors: contractors},
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: false
                  }}
                />
              </>
            )}
            <div className="card">
              <h5 className="card-header">
                <div className='d-flex justify-content-between'>
                  {TRL_Pack.fz.fzs}
                  <Link to={`/fz/0`} className="btn text-nowrap btn-sm btn-outline-secondary"><i className="fas fa-plus" /></Link>
                </div>
              </h5>
              <div className="card-body overflow-auto" style={{ maxHeight: 500 }}>

                {FZS.length ? (
                  <table className="table table-striped">
                    <thead>
                      <tr className="justify-content-center">
                        <th> <div className='d-flex justify-content-center'>Nr faktury <br />Czas zapisu, Źródło</div> </th>
                        <th> <div className='d-flex justify-content-center'>Nr oryginalny, Wystawiony </div></th>
                        <th> <div className='d-flex justify-content-center'>Kontrahent </div></th>
                        <th> <div className='d-flex justify-content-center'>Magazyn<br />PZ </div></th>
                        <th> <div className='d-flex justify-content-center'>Brutto, VAT<br />Netto </div></th>
                        <th> <div className='d-flex justify-content-center'>Zapłacona<br />Data platności </div></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {FZS
                        .sort(sortRows)
                        // .filter(reportFilter)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Link to={`/fz/${entry.WarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{entry.WarehouseOperationFullName}</Link>
                                <div className="">{entry && moment(entry.Created).format("YYYY.MM.DD")} {entry.Created?.split(" ").at(-1) || ""}</div>
                              </div>
                            </td>
                            <td >
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-nowrap">{entry.OriginalDocNumber}</div><div className="text-nowrap">{moment(entry.IssuedOn).format("YYYY.MM.DD")}</div>
                              </div>
                              </td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              {entry.ContractorId !== '' && (<Link to={`/contractor/${entry.ContractorId}`} className="btn text-nowrap btn-sm btn-outline-info">{entry.ContractorName}</Link>)}
                            </div>
                            </td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center '>
                              <div className="text-nowrap">{entry.WarehouseFullName}</div>

                              <div className="text-nowrap">{entry.RelatedDoc !== '' && (<Link to={`/pz/${entry.ToWarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-danger">{entry.RelatedDoc}</Link>)}
                              </div>
                            </div></td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="text-nowrap">{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(entry.TotalBrutto?.toFixed(2) || 0) }</div>
                              <div className="text-nowrap">{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(entry.TotalVAT?.toFixed(2) || 0) }</div>
                              <div className="text-nowrap">{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(entry.TotalNetto?.toFixed(2) || 0) }</div>
                            </div></td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="text-nowrap"><input
                                  type='checkbox'
                                  checked={entry.IsPaid}
                                  onClick={() => {}}/></div>
                            </div>
                              <div className="text-nowrap d-flex justify-content-center">{moment(entry.PaymentDate).format("YYYY.MM.DD") === "Invalid date" ? "" : moment(entry.PaymentDate).format("YYYY.MM.DD")}</div>
                            </td>
                            <td>
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Link to={`/fz/${entry.WarehouseOperationId}`} className="btn btn-outline-primary btn-sm">
                                  Edytuj
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )
}

export default FZS;