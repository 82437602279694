export const validateEmail = (email) => {
    var mailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailRegex)) {
        return true;
    }
    else {
        return false;
    }
}

export const validateLogin = (login) => {
    var loginRegex = /^[a-zA-Z0-9]+$/;
    if (login.match(loginRegex)) {
        return true;
    }
    else {
        return false;
    }
}
