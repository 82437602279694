import React, { useState, useEffect, useContext } from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import useForm from '../../../hooks/form-hook'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useFilter from '../../../hooks/filter-hook'
import NoResults from '../../../components/NoResults/NoResults'
import ClientForm from '../../../components/Modals/ClientForm'
import filterItems from '../../../util/filterItems'
import { LangContext } from '../../../context/lang-context'

const ClientPersonalisation = () => {
  const { fetchMssqlApi } = useFetch()
  const { TRL_Pack } = useContext(LangContext)
  const { setHeaderData } = useContext(NavigationContext)

  const { searchedText, updateSearchedText } = useFilter()

  const { form, openForm, closeForm } = useForm()

  const [clients, setClients] = useState(null)
  const getClients = () => {
    fetchMssqlApi('clients', {}, clients => setClients(clients))
  }

  const submitClient = evt => {
    evt.preventDefault()

    const { name, abbreviation } = evt.target.elements

    let path, method
    if (form !== 'new') {
      path = `client/${form}`
      method = 'PUT'
    } else {
      path = 'client'
      method = 'POST'
    }

    fetchMssqlApi(
      path,
      { method, data: { Name: name.value, Abbreviation: abbreviation.value || 'brak' } },
      () => {
        closeForm()
        getClients()
      }
    )
  }

  useEffect(() => {
    // setHeaderData({ text: TRL_Pack.navigation.clients })

    getClients()
  }, [])

  const filteredClients =
    clients && clients.filter(({ Name }) => filterItems(searchedText, Name))

  return (
    <div className='container mt-5 ml-0 p-0'>
      {clients && (
        <>
          {clients.length ? (
            <>
              {!filteredClients.length ? (
                <NoResults buttonText="Dodaj klienta" onClick={openForm()} />
              ) : (
                <>
                  <div>
                  </div>
                  <div className="overflow-auto">
                    <table className="table table-striped border">
                      <thead>
                        <tr>
                          <th>{TRL_Pack.clients.name}</th>
                          <th>{TRL_Pack.clients.edit}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredClients.map((client, idx) => (
                          <tr key={idx}>
                            <td>
                              <button
                                className="btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                                onClick={openForm(client.CustomerId)}
                              >
                                {client.Name}
                              </button>
                            </td>
                            <td>
                              <button className="btn btn-link link-icon" onClick={openForm(client.CustomerId)}>
                                <i className="fas fa-edit text-success" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          ) : (
            <NoResults buttonText={TRL_Pack.clients.addClient} onClick={openForm()} />
          )}
          {form && (
            <ClientForm
              clientData={
                form !== 'new'
                  ? filteredClients.find(client => client.CustomerId === form)
                  : null
              }
              handleSubmit={submitClient}
              handleClose={closeForm}
            />
          )}
        </>
      )}
    </div>
  )
};

export default ClientPersonalisation;