import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function ProductsTable({filter, filteredProducts, handleModal, openForm, reportFilter, sortRows, returnParsedIsShared, handleVatId, API_URL, sampleProduct, category}) {
    

return (
    <div className="overflow-auto">
        <table className="table table-striped border">
        <thead>
            <tr>
            {/* {filter.showIndexes && <th className="text-center">#</th>}
            {filter.columns
                .filter(col => !col.hidden && !col.disabled)
                .map((col, idx) => (
                <th key={idx}>{col.name}</th>
                ))}
            <th /> */}
            <th>EAN</th>
            <th>Nazwa</th>
            <th className='text-center'>Aktywny</th>
            {window.innerWidth > 550 && (
                <>
                    <th className='text-center'>Składnik</th>
                    <th>Ilość składnika</th>
                    <th>Stawka VAT</th>
                    <th>Grupa</th>
                </>
            )}
            {/* <th>Grupa</th> */}
            </tr>
        </thead>
        <tbody>
            {filteredProducts && filteredProducts
            .filter(reportFilter)
            // .sort(sortRows)  
            .slice(
                (filter.page - 1) * filter.rowsPerPage,
                filter.page * filter.rowsPerPage
            )
                .map((product, idx) => (
                    <tr key={idx}>
                        <td style={{ wordBreak: 'break-word' }}>
                                {product.EAN}
                        </td>
                        <td style={{ wordBreak: 'break-word' }}>
                            {product.Name}
                        </td>
                        <td style={{ wordBreak: 'break-word' }}>
                            <div className='d-flex align-items-center justify-content-center'>
                                <input type='checkbox'
                                onChange={() => {}}
                                checked={product.IsActive}/>
                            </div>
                        </td>
                        {window.innerWidth > 550 && (
                        <>
                        {/* <td style={{ wordBreak: 'break-word' }}>{product?.GroupName || ""}</td> */}
                        <td style={{ wordBreak: 'break-word' }} >
                            <div className='d-flex align-items-center justify-content-center'>
                                <input type='checkbox'
                                onChange={() => {}}
                                checked={product.IsIngredient}/>
                            </div>
                        </td>
                        <td>{product?.IngredientQuantity || ""}</td>
                        <td>{handleVatId(product)}</td>
                        <td>{product.ProductGroupName || ""}</td>
                        <td>
                            {/* <div style={{width: "48px", height: "48px"}}> */}
                                <img
                                    src={`${API_URL}/${product.Image}`}
                                    onError={evt => (evt.target.src = sampleProduct)}
                                    alt={product.Name}
                                    width="48px"
                                    height="48px"
                                />
                            {/* </div> */}
                        </td>
                        </>
                        )}
                        <td>
                            {/* {!product.IsSubscribed ?
                            <button
                                className="btn btn-link"
                                // onClick={deleteProduct(product.EAN)}
                                onClick={() => handleModal(product.EAN, product.IsSubscribed)}

                            >
                                <i className="fas fa-trash text-danger" />
                            </button>
                            : <button
                                className="btn btn-link"
                                onClick={() => handleModal(product.EAN, product.IsSubscribed)}
                            // onClick={unsubscribeProduct(product.EAN)}

                            >
                                <i className="fas fa-times text-danger" />
                            </button>} */}
                            {/* <td className='d-flex justify-content-end'>
                            <button className="btn btn-outline-primary btn-sm "
                                onClick={openForm(product.EAN)}
                                // key={location.id+"btn"}
                            >
                                    Edytuj
                                </button>
                            </td> */}
                            <div className='d-flex justify-content-end border-0'>
                            <Link className="btn btn-outline-primary btn-sm "
                                to={`product/${product.EAN}`}
                                // key={location.id+"btn"}
                            >
                                    Edytuj
                            </Link>
                            </div>
                        </td>
                    </tr>
                ))}
        </tbody>
        {/* <tbody>
            {filteredProducts
            .filter(reportFilter)
            .sort(sortRows)
            .slice(
                (filter.page - 1) * filter.rowsPerPage,
                filter.page * filter.rowsPerPage
            )
            .map((product, idx) => (
                <tr key={idx}>
                {Object.keys(product)
                    .filter((col, col_idx) =>
                    filter.columns
                        .filter(col => !col.hidden && !col.disabled)
                        .map(col => col.id)
                        .includes(col_idx + 1)
                    )

                    .map((col, col_idx) => (
                    <td key={col_idx} className={col !== "Składnik" ? "small" : "d-flex justify-content-center"}>
                        <button
                        style={{ wordBreak: 'break-word' }}
                        className= "btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                        onClick={openForm(product.EAN)}>
                        {sessionStorage.getItem("DB_TYPE") !== "mysql" ? returnParsedIsShared(product[col]) : product[col] }
                        </button>
                    </td>
                    ))}
                <td key={product.VATId} className='small' style={{ minWidth: "70px" }}>
                    <button
                    style={{ wordBreak: 'break-word' }}
                    className="btn btn-link font-size-inherit text-reset text-decoration-none p-1"
                    onClick={openForm(product.EAN)}>
                    {handleVatId(product)}
                    </button>
                </td>
                <td>
                    <img
                    src={`${API_URL}/${product.Image}`}
                    onError={evt => (evt.target.src = sampleProduct)}
                    alt={product.Name}
                    width="48"
                    height="48"
                    />
                </td>
                <td>
                    {!product.IsSubscribed ?
                    <button
                        className="btn btn-link"
                        // onClick={deleteProduct(product.EAN)}
                        onClick={() => handleModal(product.EAN, product.IsSubscribed)}

                    >
                        <i className="fas fa-trash text-danger" />
                    </button>
                    : <button
                        className="btn btn-link"
                        onClick={() => handleModal(product.EAN, product.IsSubscribed)}
                    // onClick={unsubscribeProduct(product.EAN)}

                    >
                        <i className="fas fa-times text-danger" />
                    </button>}
                </td>
                </tr>
            ))}
        </tbody> */}
        </table>
        </div>
    )
}
