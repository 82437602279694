import React, {useEffect, useContext, useState} from 'react'
import useFetch from '../../hooks/fetchMSSQL-hook'
import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min'
import { NavigationContext } from '../../context/navigation-context'
import link from '../../assets/images/back_to_list_icon.svg'


export default function RO() {
    const [RO, setRO] = useState([])
    const [items, setItems] = useState([])
    const [initialItems, setInitialItems] = useState([])
    const { setHeaderData } = useContext(NavigationContext)
    const {fetchMssqlApi} = useFetch()
    const param = useParams()
    // console.log(param)
    const getRo = () => fetchMssqlApi(`ros/${param.id}`, {}, res => {
        setRO(res[0][0])
        setInitialItems(res[1])
    })
    // const getItems = () => fetchMssqlApi(`pa-pas/items/${param.id}`, {}, res => setInitialItems(res))
    useEffect(() => {
        setHeaderData({text: "Szczegóły rozładowania"})
        getRo()
        // getItems()
    }, [])
    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])


    return (
        RO && (
        <div>
            <div className='container-xl'>
            {/* <Link to='/ros'>
                <button onClick={() => {window.history.back()}} className="btn btn-link ml-1 text-decoration-none mb-3">
                    <i className="fas fa-arrow-left mr-2" />
                    Wróć
                </button>
            </Link> */}
             <Link to='/ros'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={link}></img>
                    </label>
            </Link>
            <div className='card mb-4'>
                <div className='card-header d-flex justify-content-between'>
                    <h5 className='h5 mb-0'>
                        {RO.RO}
                    </h5 >
                </div>
                <div className='card-body'>
                    <table className="table table-striped">
                        <tbody>
                            <tr className='justify-content-center'>
                                <td className='text-center'>Stworzone</td>
                                <td className='text-center'>Automat</td>
                                <td className='text-center'>Ilość rozładowania</td>
                            </tr>
                            <tr className='justify-content-center'>
                                <td>
                                    <div className='d-flex justify-content-center mb-1 mt-1'>
                                        {RO.Created}
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center mb-1 mt-1'>
                                        {RO.MachineFullName}
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-center mb-1 mt-1'>
                                        {RO.UnloadQuantity}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div className='card-body'> */}
                </div>
                <div className='card'>
                    {items.length ?
                ( <table className="table table-striped ">
                    <thead>
                        <tr>
                            <th>Produkt</th>
                            <th>Nr sprężyny</th>
                            <th>Ilość</th>
                        </tr>   
                    </thead>
                    <tbody>
                        {items.map((item, idx) => 
                        !item.deleted && 
                        (
                            <tr key={idx}>
                                <td>{item.Name}</td>
                                <td>{item.MachineFeederNo}</td>
                                <td>{item.Quantity}</td>
                            </tr>
                        ))}
                        
                    </tbody>
                    </table>)
                    : 
                    <div className='card'>
                    </div>
                }
                </div>
                
            </div>
        </div>
        )
    )
}
