import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { LangContext } from "../../../context/lang-context";
import useHttp from "../../../hooks/use-http";

import ConfirmationForm from "../../../components/Modals/ConfirmationForm";
import FormSkel from "../../../components/Modals/FormSkel";
import Switch from "../../../components/Buttons/Switch";
import fetchMSSQLHook from "../../../hooks/fetchMSSQL-hook";

export default function HardwareComponent() {
    const { sendRequest } = useHttp();
    const { TRL_Pack } = useContext(LangContext);
    const { fetchMssqlApi } = fetchMSSQLHook();

    const [showModal, setShowModal] = useState({
        synchronize: false,
        statusGSM: false
    });
    const [text, setText] = useState("");
    const [simpleView, setSimpleView] = useState(0);

    const getSimpleView = () => {
        fetchMssqlApi("advanced-settings/simple-view", {}, (simpleView) => {
            setSimpleView(simpleView.simpleView[0].ProductDetailsSimpleView);
        })
    }

    useEffect(() => {
        getSimpleView();
    }, []);

    const handleClick = (e) => {
        e.persist();
        const { id } = e.target;
        try {
            switch (id) {
                case "synchronize":
                    setShowModal({ ...showModal, synchronize: true });
                    break;
                case "unlock":
                    sendRequest({ url: "advanced-settings/unlock", method: "POST" }, response => {
                        if(response.status === 200) {
                            NotificationManager.success(
                                TRL_Pack.hardware.selectionsUnlocked,
                                null,
                                4000
                            );
                        }
                    });
                    break;
                case "initPayment":
                    sendRequest({ url: "advanced-settings/init-payment", method: "POST" }, response => {
                        if (response.status === 200) {
                            NotificationManager.success(
                                TRL_Pack.hardware.paymentInitialized,
                                null,
                                4000
                            );
                        }
                    });
                    break;
                case "updateCpu":
                    sendRequest({ url: "advanced-settings/hex-update", method: "POST" }, response => {
                        if (response.status === 200) {
                            NotificationManager.success(
                                TRL_Pack.hardware.cpuUpdated,
                                null,
                                4000
                            );
                        }
                    });
                    break;
                case "gsm":
                    sendRequest({ url: "advanced-settings/status-gsm", method: "POST" }, response => {
                        if (response.status === 200) {
                            setText(response.data.stdout);
                            setShowModal({ ...showModal, statusGSM: true });
                        }
                    });
                    break;
                default:
                    NotificationManager.error(
                        TRL_Pack.hardware.functionNotAvailable,
                        null,
                        4000
                    );
            }
        } catch (error) {
            NotificationManager.error(
                TRL_Pack.hardware.requestFailed,
                null,
                4000
            );
        }
    };


    const StatusGSMForm = () => {
        const formatText = text.split(" ,");
        return (
            showModal.statusGSM && (
                <FormSkel
                    headerText={TRL_Pack.hardware.gsmStatus}
                    informationForm={true}
                    handleClose={() => setShowModal({ ...showModal, statusGSM: false })}
                >
                    <form id="modal-form" autoComplete="off">
                        <div className="form-group">
                            <h5>{TRL_Pack.hardware.operator}: {formatText[0]}</h5>
                        </div>
                        <div className="form-group">
                            <h5>{TRL_Pack.hardware.network}: {formatText[1]}</h5>
                        </div>
                        <div className="form-group">
                            <h5>{TRL_Pack.hardware.signal}: {formatText[2]} (-1 oznacza brak sygnalu)</h5>
                        </div>
                    </form>
                </FormSkel>
            )
        );
    };

    const SynchronizeForm = () => (
        showModal.synchronize && (
            <ConfirmationForm
                text={TRL_Pack.hardware.synchronizeText}
                handleClose={() => setShowModal({ ...showModal, synchronize: false })}
                handleSubmit={async () => {
                    setShowModal({ ...showModal, synchronize: false });
                    sendRequest(
                        { url: "advanced-settings/synchronize", method: "POST" }, response => {
                            if (response.status === 200) {
                                NotificationManager.success(
                                    TRL_Pack.hardware.synchronized,
                                    null,
                                    4000
                                );
                            }
                        }
                    );
                }}
            />
        )
    );

    const handleSwitchChange = (e) => {
        const { checked } = e.target;

        sendRequest({ url: "advanced-settings/simple-view", method: "POST", data: { enabled: Number(checked) } }, (response) => {
            getSimpleView();
        });
    }

    const HardwareUI = () => (
        <div>
            <div className="row">
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="softwareUpdate" className="form-label">
                            {TRL_Pack.hardware.softwareUpdate}
                        </label>
                        <input
                            type="button"
                            onClick={handleClick}
                            className="form-control"
                            defaultValue={TRL_Pack.hardware.update}
                            id="softwareUpdate"
                        />
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="CheckUpdate" className="form-label">
                            {TRL_Pack.hardware.checkUpdate}
                        </label>
                        <input
                            type="button"
                            onClick={handleClick}
                            className="form-control"
                            defaultValue={TRL_Pack.hardware.checkUpdate}
                            id="CheckUpdate"
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="synchronize" className="form-label">
                            {TRL_Pack.hardware.synchronize}
                        </label>
                        <input
                            type="button"
                            onClick={handleClick}
                            className="form-control"
                            defaultValue={TRL_Pack.hardware.synchronize}
                            id="synchronize"
                        />
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="unlock" className="form-label">
                            {TRL_Pack.hardware.unlockSelections}
                        </label>
                        <button id="unlock" onClick={handleClick} className="form-control">
                            {TRL_Pack.hardware.unlock}
                        </button>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="initPayment" className="form-label">
                            {TRL_Pack.hardware.paymentInit}
                        </label>
                        <button id="initPayment" onClick={handleClick} className="form-control">
                            {TRL_Pack.hardware.init}
                        </button>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="simpleView" className="form-label">
                            {TRL_Pack.hardware.simpleView}
                        </label>
                        <Switch
                            id="simpleView"
                            checked={simpleView}
                            onChange={handleSwitchChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="updateCpu" className="form-label">
                            {TRL_Pack.hardware.updateCpu}
                        </label>
                        <button id="updateCpu" onClick={handleClick} className="form-control">
                            {TRL_Pack.hardware.update}
                        </button>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="gsm" className="form-label">
                            {TRL_Pack.hardware.gsmState}
                        </label>
                        <button id="gsm" onClick={handleClick} className="form-control">
                            {TRL_Pack.hardware.check}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );

    return (
        <div className="row">
            <div className="col-12 mb-2">
                <SynchronizeForm />
                <StatusGSMForm />
                <HardwareUI />
            </div>
        </div>
    );
}
