import React, {useContext, useEffect, useState} from 'react';

// Context
import {NavigationContext} from '../../context/navigation-context';
import {LangContext} from '../../context/lang-context';
import {NotificationContext} from '../../context/notification-context';

// Custom hooks
import useHttp from '../../hooks/use-http';
import useForm from '../../hooks/form-hook'

// Components
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import User from '../../components/Definitions/User';
import Pagination from '../../components/Pagination/Pagination';
import FilterEx from '../../components/Filter/FilterEx';
// Util
import {validateEmail, validateLogin} from '../../util/dataValidation';
import fetchMSSQLHook from '../../hooks/fetchMSSQL-hook';
import UsersTable from './components/UsersTable.js'
import UsersForm from './components/UsersForm';

const Users = () => {
    const navigationCtx = useContext(NavigationContext);
    const notificationCtx = useContext(NotificationContext);
    const {TRL_Pack} = useContext(LangContext);

    const {sendRequest} = useHttp();
    const {fetchMssqlApi} = fetchMSSQLHook()
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalUser, setShowModalUser] = useState(false);
    const [modifiedUserId, setModifiedUserId] = useState(null);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouse, setWarehouse] = useState([]);
    const [all, setAll] = useState([])
    const {form, openForm, closeForm} = useForm()
    const [warning, setWarning] = useState({status: false, message: ""})


    const [activeUserFilter, setActiveUserFilter] = useState(false);


    const handleSwitchPage = pageNo => () => setFilter(prev => ({...prev, page: pageNo}))
    const resetFilter = () => {
        setFilter(defaultFilter)
        getAll({})
    }

    const toggleFilter = () => setFilter(prev => ({...prev, visible: !prev.visible}))
    const resetPage = () => setFilter(prev => ({...prev, page: 1}))
    const handleChange = (e) => {
        setActiveUserFilter(prev => (!prev))
        getUsers();
    }

    // const getUsers = () => {
    //     if(activeUserFilter) {
    //         sendRequest({ url: 'users' }, users => {
    //             setUsers(users.data)
    //         });
    //     } else {
    //         sendRequest({ url: 'users/active' }, users => {
    //             setUsers(users.data)
    //         });
    //     }
    // }
    // const getAll = () => {fetchMssqlApi('/users/users-list', {}, answer => setAll(answer))}

    const getWarehouses = () => {
        (sessionStorage.getItem("DB_TYPE") !== "mysql")
            ? fetchMssqlApi('/warehouses-list', {}, warehouses => setWarehouses(warehouses))
            : sendRequest({url: 'warehouse'}, warehouse => {
                setWarehouse(warehouse.data)
            });
    }
    const getRoles = () => {
        (sessionStorage.getItem("DB_TYPE") !== "mysql")
            ? fetchMssqlApi('/roles', {}, roles => setRoles(roles))
            : sendRequest({url: 'roles'}, roles => {
                setRoles(roles.data)
            });
    }
    const getUsers = () => {
        if (sessionStorage.getItem("DB_TYPE") !== "mysql") fetchMssqlApi('users-list', {}, users => setUsers(users))
        else {
            sendRequest({url: 'users'}, users => {
                setUsers(users.data)
            });
        }

    }
    useEffect(() => {
        setWarning({status: false, message: ""})
    }, [form])
    // const getRoles = () => {
    //     sendRequest({ url: 'roles' }, roles => {
    //         setRoles(roles.data)
    //     });
    // }

    // const getWarehouse = () => {
    //     sendRequest({ url: 'warehouse' }, warehouse => {
    //         setWarehouse(warehouse.data)
    //     });
    // }
    let defaultFilter = {}
    if (localStorage.getItem("clientId") !== "console" && sessionStorage.getItem("DB_TYPE") !== "mysql") {
        defaultFilter = {
            btnHiden: true,
            name: "users",
            showIndexes: false,
            page: 1,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 25, 50],
            // need to disable after end of work with filters
            visible: false,
            sortByColumns: true,
            sortBy: '8 | asc | text',
            activeTags: [],
            disableIndexes: "true",
            columns: [
                {
                    id: 2,
                    name: "Nazwa",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null,
                    value: null
                },
                {
                    id: 3,
                    name: "Email",
                    sortable: false,
                    searchable: false,
                    type: "lookup",
                },
                {
                    id: 4,
                    name: "Aktywny",
                    sortable: true,
                    type: "threeStateBool",
                    selectbar: "true",
                    value: true
                },
                {
                    id: 6,
                    name: "Magazyn",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null,
                    value: null
                },
                {
                    id: 7,
                    name: "Rola",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null,
                    value: null
                },

            ],
        }

    } else {
        defaultFilter = {
            btnHiden: true,
            name: "users",
            showIndexes: false,
            page: 1,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 25, 50],
            // need to disable after end of work with filters
            visible: false,
            sortByColumns: true,
            sortBy: '9 | asc | text',
            activeTags: [],
            disableIndexes: "true",
            columns: [
                {
                    id: 1,
                    name: "Imię",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null
                },
                {
                    id: 2,
                    name: "Email",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null
                },
                {
                    id: 3,
                    name: "Magazyn",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null
                },
                {
                    id: 4,
                    name: "Rola",
                    sortable: false,
                    searchable: true,
                    type: "lookup",
                    searchbar: null
                },
                {
                    id: 5,
                    name: "Aktywny",
                    sortable: true,
                    type: "threeStateBool",
                    searchbar: null
                }
            ],
        }
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('usersFilter')) {
            return JSON.parse(localStorage.getItem('usersFilter'))
        } else return defaultFilter
    })
    const returnFilterWithoutVisibleParams = (obj) => {
        const {visible, page, ...other} = obj
        return other
    }
    const [resetBtnVis, setResetBtnVis] = useState(false)
    useEffect(() => {
        (sessionStorage.getItem("DB_TYPE") !== "mysql") &&
        (JSON.stringify(returnFilterWithoutVisibleParams(filter)) !== JSON.stringify(returnFilterWithoutVisibleParams(defaultFilter)))
            ? setResetBtnVis(true)
            : setResetBtnVis(false)
    }, [filter])

    useEffect(() => {
        if (sessionStorage.getItem("DB_TYPE") !== "mysql") {
            navigationCtx.setHeaderData({text: TRL_Pack.navbar.users})
            getUsers()
            getWarehouses()
            getRoles()
            getAll()
        } else {
            navigationCtx.setHeaderData({text: TRL_Pack.navbar.users})
            getUsers(false)
            getRoles()
        }
    }, []);
    const payload = {
        userId: filter.columns[0].value,
        roleId: filter.columns[4].value,
        warehouseId: filter.columns[3].value,
        isActive: filter.columns[2].selectbar
    }
    const getAll = (data = payload) => {
        fetchMssqlApi('users/users-list', {method: "POST", data, hideNotification: true}, answer => setAll(answer))
    }
    const search = () => {

        const columns = filter.columns
        columns.filter(filter => filter.searchable).map(column => {
            switch (column.name) {
                case ("Nazwa"): {
                    const data = users.filter(user => user.Name === column.searchbar)[0]?.UserId || null
                    setFilter(prev => ({
                        ...prev,
                        columns: prev.columns.map(column => {
                            if (column.name === "Nazwa") column.value = data
                            return column
                        })
                    }))
                    filter.columns[0].value = data
                    break
                }
                case ("Magazyn"): {
                    const data = warehouses.filter(wh => wh.Name === column.searchbar)[0]?.WarehouseId || null
                    filter.columns[3].value = data
                    setFilter(prev => ({
                        ...prev,
                        columns: prev.columns.map(column => {
                            if (column.name === "Magazyn") column.value = data
                            return column
                        })
                    }))
                    break
                }
                case ("Rola"): {
                    const data = roles.filter(role => role.Name === column.searchbar)[0]?.RoleId || null
                    filter.columns[4].value = data
                    setFilter(prev => ({
                        ...prev,
                        columns: prev.columns.map(column => {
                            if (column.name === "Rola") column.value = data
                            return column
                        })
                    }))
                    break
                }
            }
        })

        const data = {
            userId: (filter.columns[0].value) ? +filter.columns[0].value : null,
            roleId: (filter.columns[4].value) ? +filter.columns[4].value : null,
            warehouseId: (filter.columns[3].value) ? +filter.columns[3].value : null,
            isActive: filter.columns[2].selectbar
        }
        fetchMssqlApi('users/users-list', {method: "POST", data, hideNotification: true}, res => setAll(res))
    }
    const sortRows = (a, b) => {
        const [id, order, type] = filter.sortBy.split(' | ')

        const col = Object.keys(all[0])[Number(id) - 1]

        if (a[col] === b[col]) return 0
        else if (a[col] === null) return 1
        else if (b[col] === null) return -1

        let valueA, valueB
        if (type === 'text' || type === 'date' || type === "lookup") {
            valueA = a[col]
            valueB = b[col]
        } else if (type === "bool" || type === "threeStateBool") {
            valueA = Number(a[col])
            valueB = Number(b[col])
        } else if (type === 'price') {
            valueA = Number(a[col].replace(',', ''))
            valueB = Number(b[col].replace(',', ''))
        } else return 0
        if (order === 'asc') return valueA < valueB ? -1 : 1
        else return valueA < valueB ? 1 : -1
    }
    const showModalHandler = id => {
        setModifiedUserId(id);
        setShowModal(true);
    };


    const hideModalHandler = () => setShowModal(false);

    const showModalUserHandler = () => setShowModalUser(true)

    const hideModalUserHandler = () => setShowModalUser(false)


    const submitUserHandler = e => {
        e.preventDefault()

        // if (sessionStorage.getItem('DB_TYPE') === "mssql") {
        //     const { name, loginEmail, newPassword, repeatNewPassword, role, warehouses } = e.target.elements

        //     if (newPassword.value.trim().length < 6) {
        //         notificationCtx.notification('error', TRL_Pack.errors.passwordIsTooShort);
        //         return;
        //     }

        //     if (newPassword.value != repeatNewPassword.value.trim()) {
        //         notificationCtx.notification('error', TRL_Pack.errors.passwordNotMatch);
        //         return;
        //     }

        //     const getWarehouseItem = (warehouseName) => {
        //         let warehouseItem
        //         warehouse.map(item => {
        //             if (item.Name == warehouseName) {
        //                 warehouseItem = item.WarehouseId
        //             }
        //         })
        //         return warehouseItem
        //     }
        //     const data = {
        //         Name: name.value,
        //         EmailLogin: loginEmail.value,
        //         Password: newPassword.value,
        //         Role: role.value,
        //         Warehouse: getWarehouseItem(warehouses.value)
        //     }
        //     if (!validateEmail(data.EmailLogin) || !validateLogin(data.Name)) {
        //         notificationCtx.notification('error', TRL_Pack.errors.invalidInput);
        //         return;
        //     }

        //     sendRequest({ url: 'user/add', method: 'POST', data }, response => {
        //         if (response.status === 201) {
        //             hideModalUserHandler();
        //             getUsers()
        //         }
        //     })
        // }
        // else 
        if (sessionStorage.getItem('DB_TYPE') !== "mssql") {
            const {name, loginEmail, notifyEmail, newPassword, repeatNewPassword, role} = e.target.elements

            if (newPassword.value.trim().length < 6) {
                notificationCtx.notification('error', TRL_Pack.errors.passwordIsTooShort);
                return;
            }

            if (newPassword.value != repeatNewPassword.value.trim()) {
                notificationCtx.notification('error', TRL_Pack.errors.passwordNotMatch);
                return;
            }

            const data = {
                Name: name.value,
                EmailLogin: loginEmail.value,
                Password: newPassword.value,
                Role: role.value
            }

            if (!validateEmail(data.EmailLogin) || !validateLogin(data.Name)) {
                notificationCtx.notification('error', TRL_Pack.errors.invalidInput);
                return;
            }

            sendRequest({url: 'user/add', method: 'POST', data}, response => {
                if (response.status === 201) {
                    hideModalUserHandler();
                    getUsers()
                }
            })
        }
    }

    const submitHandler = e => {
        e.preventDefault();

        const {password, newPassword, repeatNewPassword} = e.target.elements;
        // Validation
        if (password.value.trim().length < 6 || newPassword.value.trim().length < 6 || repeatNewPassword.value.trim().length < 6 || newPassword.value !== repeatNewPassword.value) {
            notificationCtx.notification('error', TRL_Pack.errors.invalidInput);
            return;
        }

        // Send change password request
        const data = {
            CurrentPassword: password.value,
            Password: newPassword.value
        };

        const url = `auth/change-password/${modifiedUserId}`;

        sendRequest({
            url,
            method: 'PUT',
            data
        }, response => {
            if (response.status === 201)
                hideModalHandler();
        });
    }
    const handleSubmit = evt => {
        evt.preventDefault()
        const {name, password, email, warehouse, role} = evt.target.elements
        const roleId = roles.filter(odjRole => odjRole.Name === role.value)[0]?.RoleId || null
        const warehouseId = warehouses.filter(wh => wh.Name === warehouse.value)[0]?.WarehouseId || null
        const payload = {
            userName: name.value,
            password: password.value,
            roleId: roleId,
            warehouseId: warehouseId,
            email: email.value,
        }
        if (Object.values(payload).includes(null)) return setWarning({status: true, message: "write correct names"})
        else {
            setWarning({status: false, message: ""})
            fetchMssqlApi(`/users`, {method: "POST", data: payload}, res => {
                closeForm()
                getUsers()
                // getWarehouses()
                // getRoles()
                // getAll()
            })
        }
    }

    useEffect(() => localStorage.setItem("usersFilter", JSON.stringify(filter)), [filter])
    return (
        <div className='p-20'>
            {form && <UsersForm
                handleClose={closeForm}
                form={form}
                handleSubmit={handleSubmit}
                warning={warning}
            />}
            <>

                {sessionStorage.getItem("DB_TYPE") !== "mysql" && (
                    <nav className="mb-4 d-flex justify-content-center">
                        <Pagination
                            totalItems={all.length}
                            page={filter.page || 1}
                            rowsPerPage={filter.rowsPerPage}
                            filterVisibility={filter.filterVisibility}
                            handleSwitchPage={handleSwitchPage}
                            toggleFilter={toggleFilter}
                            resetFilter={resetFilter}
                            resetBtnVis={resetBtnVis}
                        />
                    </nav>
                )}

                {filter.visible && sessionStorage.getItem("DB_TYPE") !== "mysql" &&
                    <FilterEx
                        {...{
                            filter,
                            setFilter,
                            columns: filter.columns,
                            data: all,
                            resetFilter,
                            toggleFilter,
                            resetPage,
                            search,
                        }}
                    />}
                {/* <button onClick={openForm((all[1]).UserId)}>click</button> */}
                {(sessionStorage.getItem("DB_TYPE") === "mysql") ? (
                    <div>
                        <div>
                            {(sessionStorage.getItem("DB_TYPE") === "mysql") ? (<button
                                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                                    onClick={() => showModalUserHandler()}>
                                    <i className="fas fa-plus mr-2"/> {TRL_Pack.buttons.addUser}
                                </button>)
                                :
                                (<button
                                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                                    onClick={openForm()}
                                >
                                    <i className="fas fa-plus mr-2"/>
                                    {TRL_Pack.locations.addProductButton}
                                </button>)}

                        </div>
                        <Table>
                            <thead>
                            <tr>
                                <th>{TRL_Pack.users.name}</th>
                                <th>{TRL_Pack.users.email}</th>
                                <th>{TRL_Pack.users.role}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map(user => <User
                                key={user.UserId}
                                id={user.UserId}
                                name={user.Name}
                                email={user.Email}
                                isActive={user.IsActive}
                                role={roles.map(role => {
                                    if (user.RoleId == role.RoleId) {
                                        return role.Name;
                                    }
                                })}
                                users={getUsers}
                                showModalHandler={showModalHandler}/>)}
                            </tbody>
                        </Table>
                    </div>

                ) : (
                    <div>

                        {/* <button
                        className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                        onClick={() => showModalUserHandler()}>
                        <i className="fas fa-plus mr-2" /> {TRL_Pack.buttons.addUser}
                    </button> */}
                        <button
                            className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                            onClick={openForm()}>
                            <i className="fas fa-plus mr-2"/>
                            {TRL_Pack.locations.addProductButton}
                        </button>
                        <UsersTable sortRows={sortRows}
                                    IsActive="Aktywna"
                                    data={all}
                                    filter={filter}
                                    id="UserId"
                                    page={filter.page}
                                    roles={roles}
                                    warehouses={warehouses}
                                    setData={getAll}/>
                    </div>
                )}
            </>


            {showModal && (sessionStorage.getItem("DB_TYPE") === "mysql") && <Modal
                hideModalHandler={hideModalHandler}
                header={'Zmień hasło'}
            >
                <form onSubmit={submitHandler} id='modal-form'>
                    <div className='form-group'>
                        <label className='h6'>{TRL_Pack.users.currentPassword}</label>
                        <input
                            type='password'
                            name='password'
                            className='form-control'
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label className='h6'>{TRL_Pack.users.newPassword}</label>
                        <input
                            type='password'
                            name='newPassword'
                            className='form-control'
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label className='h6'>{TRL_Pack.users.repeatNewPassword}</label>
                        <input
                            type='password'
                            name='repeatNewPassword'
                            className='form-control'
                            required
                        />
                    </div>
                </form>
            </Modal>}
            {sessionStorage.getItem('DB_TYPE') === "mssql" ?
                showModalUser && <Modal
                    hideModalHandler={hideModalUserHandler}
                    header={TRL_Pack.users.newUser}
                >
                    <form onSubmit={submitUserHandler} autoComplete="off" id='modal-form'>
                        <div className='form-group'>
                            <label className='h6'>{TRL_Pack.users.name}</label>
                            <input
                                type='text'
                                name='name'
                                className='form-control'
                                maxLength={128}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label className='h6'>{TRL_Pack.users.loginEmail}</label>
                            <input
                                type='text'
                                name='loginEmail'
                                className='form-control'
                                maxLength={255}
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label className='h6'>{TRL_Pack.users.notifyEmail}</label>
                            <input
                                type='text'
                                name='notifyEmail'
                                className='form-control'
                                maxLength={255}
                            />
                        </div>
                        <div className='form-group'>
                            <label className='h6'>{TRL_Pack.users.newPassword}</label>
                            <input
                                type='password'
                                name='newPassword'
                                className='form-control'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label className='h6'>{TRL_Pack.users.repeatNewPassword}</label>
                            <input
                                type='password'
                                name='repeatNewPassword'
                                className='form-control'
                                required
                            />
                        </div>
                        <div className='form-group'>
                            <label className='h6'>{TRL_Pack.users.role}</label>
                            <select className="form-control" name="role">
                                {roles.map(role => <option key={role.RoleId} value={role.RoleId}>{role.Name}</option>)}
                            </select>
                        </div>
                    </form>
                </Modal>
                : (
                    showModalUser && <Modal
                        hideModalHandler={hideModalUserHandler}
                        header={TRL_Pack.users.newUser}
                    >
                        <form onSubmit={submitUserHandler} autoComplete="off" id='modal-form'>
                            <div className='form-group'>
                                <label className='h6'>{TRL_Pack.users.name}</label>
                                <input
                                    type='text'
                                    name='name'
                                    className='form-control'
                                    maxLength={128}
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <label className='h6'>{TRL_Pack.users.loginEmail}</label>
                                <input
                                    type='text'
                                    name='loginEmail'
                                    className='form-control'
                                    maxLength={255}
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <label className='h6'>{TRL_Pack.users.newPassword}</label>
                                <input
                                    type='password'
                                    name='newPassword'
                                    className='form-control'
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <label className='h6'>{TRL_Pack.users.repeatNewPassword}</label>
                                <input
                                    type='password'
                                    name='repeatNewPassword'
                                    className='form-control'
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <label className='h6'>{TRL_Pack.users.role}</label>
                                <select className="form-control" name="role">
                                    {roles.map(role => <option key={role.RoleId}
                                                               value={role.RoleId}>{role.Name}</option>)}
                                </select>
                            </div>
                        </form>
                    </Modal>
                )}
        </div>);
};

export default Users;