import React, {useState} from 'react'
import { Button, Toast } from 'react-bootstrap';


export default function InfoToast({text, delay=3000, btnSize, leftDistance}) {
    const [showToast, setShowToast] = useState(false)
    return (
        <>
        {/* <div style={{direction: "ltr"}}> */}
            <div onBlur={() => setShowToast(false)} onFocus={() => setShowToast(true)} className=' mb-0 ' >
                    <Button  onClick={() => setShowToast(true)} className={btnSize && `btn btn-${btnSize}`}>
                    <i className="fas fa-info"></i>
                    </Button>
            </div>
                    <Toast  style={{position: 'absolute', zIndex: 999, left: leftDistance}} delay={delay} autohide show={showToast} onClose={() => setShowToast(false)} >
                        <Toast.Body>
                            {text}
                        </Toast.Body>
                    </Toast>
        {/* </div> */}
        </>
    )
}
