import React from 'react'
import { OverlayTrigger, Button, Spinner, Tooltip } from 'react-bootstrap'

export default function ReportBtnForArr({
    clickFnc = (() => {}) // click handler function (in params toggle state)
    , state = {Id: 0, Report: 0}
    , setState // toggle function
    , overlayText
    , placement = "left"
    , btnColor = "primary" // button color. By default is blue
    , size = "sm"
    , value = {Id: 0, Report: 0}
    , disabled = false
}) {
    return (
        state.Id === value.Id && state.Report === value.Report ? (
            <Button
            className={`d-flex justify-content-center align-items-center m-1 btn btn-${btnColor} float-right btn `}>
            <Spinner
                as="span"
                animation="border"
                size={size}
                role="status"
                aria-hidden="true"
                variant='light'
                className='b-0 pr-0 pl-0 '
                style={{maxHeight: "11px", maxWidth: "11px"}}
            />
            </Button>
        ) : (
            overlayText ? (
            <OverlayTrigger
                // id='DZ87'
                placement={placement}
                overlay={<Tooltip>{overlayText}</Tooltip>}
            >
                <Button
                    variant='primary'
                    className={`d-inline m-1 btn btn-${btnColor} float-right fas fa-xs fa-download`}
                    onClick={() => clickFnc(setState)}
                    disabled={disabled}
                ></Button>
            </OverlayTrigger>
            ) : (
                <Button
                    variant='primary'
                    className={`d-inline m-1 btn btn-${btnColor} float-right fas fa-xs fa-download`}
                    onClick={() => clickFnc(setState)}
                    disabled={disabled}
                ></Button>
            )
        )
    )
}