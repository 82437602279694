import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../../context/lang-context'
import { NavigationContext } from '../../../context/navigation-context'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import Pagination from '../../../components/Pagination/Pagination'
import Filter from '../../../components/Filter/Filter'
import FilterEx from '../../../components/Filter/FilterEx'
import NoResults from '../../../components/NoResults/NoResults'
import MachinesImages from '../../../components/Machine/MachinesImages';
import GetId from '../../../components/Filter/filterFunctions/queryGenerator'
import ReportBtnForArr from '../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import { NotificationManager } from 'react-notifications'
import { API_URL } from '../../../config/config'
import axios from 'axios'
import moment from 'moment'
import ReportBtn from '../../../components/Buttons/ReportsBtn/ReportBtn'


const Machines = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()
  const { setHeaderData } = useContext(NavigationContext)

  const [tags, setTags] = useState([])
  const [machines, setMachines] = useState([])
  const [users, setUsers] = useState([])
  const [locations, setLocations] = useState([])
  const [machineTypes, setMachineTypes] = useState([])
  const [maintenances, setMaintenances] = useState([])
  const [machinesRes, setMachinesRes] = useState([])
  const [rep72, setRep72] = useState(null)
  const [rep73, setRep73] = useState(null)
  const [rep73machine, setRep73machine] = useState({Report: null, Id: null})
  const [rep72machine, setRep72machine] = useState({Report: null, Id: null})
  const [choosedMachines, setChoosedMachines] = useState([])
  const [showToast, setShowToast] = useState(false)
  useEffect(() => {
    getTags()
    getUsers()
    getMachines()
    getLocations()
    getMachinetypes()
    getMaintenances()
    /**@getMachinelist the same to @getmachines but this function take "clear" answer without any params */
    getMachinesList()
  }, [])


  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))   

  const defaultFilter = {
    name: "machines",
    showIndexes: true,
    page: 1,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 25, 50],
    visible: false,
    sortByColumns: true,
    sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'ID',
        disabled: true
      },
      {
        id: 2,
        name: TRL_Pack.machines.properties.machineName,
        sortable: true,
        searchable: true,
        type: 'lookup',
        // solid: true,
        value: null,
        save: null,
      },
      {
        id: 3,
        name: TRL_Pack.machines.properties.serialNo,
        searchable: false,
        sortable: true,
        type: 'lookup',
        value: null,
        save: null,
      },
      {
        id: 4,
        name: TRL_Pack.machines.properties.location,
        sortable: true,
        searchable: true,
        type: 'lookup',
        value: null,
        save: null,
      },
      {
        id: 5,
        name: TRL_Pack.machines.properties.machineType,
        sortable: true,
        searchable: true,
        type: 'lookup',
        value: null,
        save: null,
      },
      {
        id: 6,
        name: TRL_Pack.machines.properties.maintenance,
        sortable: true,
        searchable: true,
        type: 'lookup',
        value: null,
        save: null,
      },
      {
        id: 9,
        name: "Aktywny",
        sortable: true,
        searchable: true,
        type: "threeStateBool",
        selectbar: "true"

      }
      //   id: 1,
      //   name: 'ID',
      //   disabled: true
      // },
      // {
      //   id: 2,
      //   name: TRL_Pack.machines.properties.machineName,
      //   sortable: true,
      //   searchable: true,
      //   type: 'text',
      //   solid: true
      // },
      // {
      //   id: 3,
      //   name: TRL_Pack.machines.properties.serialNo,
      //   searchable: true,
      //   sortable: true,
      //   type: 'text'
      // },
      // {
      //   id: 4,
      //   name: TRL_Pack.machines.properties.location,
      //   sortable: true,
      //   searchable: true,
      //   type: 'text'
      // },
      // {
      //   id: 5,
      //   name: TRL_Pack.machines.properties.machineType,
      //   sortable: true,
      //   searchable: true,
      //   type: 'text'
      // },
      // {
      //   id: 6,
      //   name: TRL_Pack.machines.properties.maintenance,
      //   sortable: true,
      //   searchable: true,
      //   type: 'text'
      // }
    ]
  }


  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('machinesFilter')) {
      return JSON.parse(localStorage.getItem('machinesFilter'))
    } else return defaultFilter
  })
    const returnFilterWithoutVisibleParams = (obj) => {
        const {visible, page, ...other} = obj
        return other
    }
    const [resetBtnVis, setResetBtnVis] = useState(false)
        useEffect(() => {
          (JSON.stringify(returnFilterWithoutVisibleParams(filter)) !== JSON.stringify(returnFilterWithoutVisibleParams(defaultFilter)))
            ? setResetBtnVis(true) 
            : setResetBtnVis(false)
    }, [filter])
  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {setFilter(defaultFilter)
    getMachines({})
    setChoosedMachines([])
  }

  function getMachines (data = {
    machineId: filter.columns[1].save ,
    machineTypeId: filter.columns[4].save ,
    isActive: filter.columns[6].selectbar === "true" && true ||
              filter.columns[6].selectbar === "false" && false || 
              filter.columns[6].selectbar === "null" || filter.columns[6].selectbar === null && null ,
    maintenanceTypeId: filter.columns[5].save ,
    locationId: filter.columns[3].save 
})  {
    // console.log(data)
    fetchMssqlApi('machines', {method: "POST", data, hideNotification: true}, machines => setMachines(machines))
  }
  const getMachinesList = () => fetchMssqlApi('machines', {}, machines => setMachinesRes(machines))
  const getTags = () => {
    fetchMssqlApi('tags', {}, tags =>
      setTags(tags.machine.filter(tag => tag.options.length > 0))
    )
  }
  const getUsers = () => {
    fetchMssqlApi('users-list', {}, users => setUsers(users))
  }
  const getLocations = () => {
    fetchMssqlApi('locations', {}, locations => setLocations(locations))
  }
  const getMaintenances = () => {fetchMssqlApi('/maintenance', {}, maintenance => setMaintenances(maintenance) )}
  const getMachinetypes = () => {
    fetchMssqlApi('/machine-types', {}, machineTypes => setMachineTypes(machineTypes))}
  //

  useEffect(() => {
    setHeaderData({ text: TRL_Pack.machines.header })
    //getMachines()
    // getMachines()
    getTags()
  }, [])

  useEffect(() => localStorage.setItem('machinesFilter', JSON.stringify(filter)), [
    filter
  ])




  const sortRows = (a, b) => {
    const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(machines[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'text' || type === 'date' || type === "lookup") {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col].replace(',', ''))
      valueB = Number(b[col].replace(',', ''))

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
  }
    // const machinesProps = Object.values(TRL_Pack.machines.properties).map(prop => prop.replaceAll(" ", "").replace("Name", "").toLowerCase())
    const namePropsMap = new Map()
    for (const prop in TRL_Pack.machines.properties) {
      let word 
      if(prop === "maintenance" || prop === "location") word = prop + "Name"
      else word = prop
      // else if (prop === TRL_Pack.machines.properties.location) word = "LocationName"
      namePropsMap.set( TRL_Pack.machines.properties[prop], word)
    }
  
    const search =  () => {
      const  getMachineIdByName = (machineName) => machinesRes.filter(machine => machine.MachineName === machineName)[0]?.MachineId 
      const  getLocationId =(location) =>  locations.filter(locationn => locationn.Name === location)[0]?.LocationId
      const  getMachineIdBySerialNo =(serialNo) =>  machinesRes.filter(machine => machine.SerialNo === serialNo)[0]?.MachineId
      const  getMaintenanceId =(maintenanceName) =>  maintenances.filter(user => user.Name === maintenanceName)[0]?.MaintenanceTypeId
      const  getMachineTypesId =(machineType) =>  machineTypes.filter(user => user.Name === machineType)[0]?.MachineTypeId
    function getIsActive() {
      const filter = localStorage.getItem("machinesFilter")
      if(filter ) {
          if(JSON.parse(filter).columns !== null && JSON.parse(filter).columns.length > 6  && JSON.parse(filter).columns[6] !== null && JSON.parse(filter).columns[6].selectbar !== null)
          {
              const value = JSON.parse(filter).columns[6].selectbar
              if(value === "null") return null
              if(value === "true") return true
              if(value === "false") return false
          }
          else return null
      }
      return null
  }
  const findId = () => {
    
    let id = getMachineIdByName(filter.columns[1].value) 
    let id2 = getMachineIdBySerialNo(filter.columns[2].value)
    // console.log("id: ", id, "id2: ", id2)
    // console.log("localStorage: ", filter.columns[2].value, filter.columns[1].value)
    return id || id2
  }
    // const getMachine = (name, serialNo) => {
    //   const byName = getMachineIdByName(name)
    //   const bySerialNo = getMachineIdBySerialNo(serialNo)
    //   if(!byName && !bySerialNo) return null
    //   else if(byName) return byName
    //   else if(bySerialNo) return bySerialNo
    // }
        const data = {
          machineId:findId()  ,
          machineTypeId: getMachineTypesId(filter.columns[4].value),
          isActive: getIsActive(),
          maintenanceTypeId: getMaintenanceId(filter.columns[5].value),
          locationId: getLocationId(filter.columns[3].value)
        }
        
        // filter.columns.forEach(filter => console.log(filter?.value))
        fetchMssqlApi('/machines', {method: "POST", data, hideNotification: true}, response => {
          setChoosedMachines([])
          setMachines(response)}
          )
        setFilter(prev => ({
          ...prev,
          columns: prev.columns.map(column => {
            if(column.name === TRL_Pack.machines.properties.machineName) column.save =  data.machineId
            else if (column.name === TRL_Pack.machines.properties.serialNo) column.save = data.machineId
            else if (column.name === TRL_Pack.machines.properties.location) column.save = data.locationId
            else if (column.name === TRL_Pack.machines.properties.machineType) column.save = data.machineTypeId
            else if (column.name === TRL_Pack.machines.properties.maintenance) column.save = data.maintenanceTypeId
            else if (column.name === "Aktywna") column.save = data.isActive
            return column
          })
        }))
        // setFilter(prev => ({
        //   ...prev,
        //   columns
        // }))
        // console.log(data)
    } 

  const reportFilter = row =>
    filter.columns
      .filter(col => col.searchbar)
      .every(col =>
        row[Object.keys(row)[col.id - 1]]
          .toLowerCase()
          .includes(col.searchbar.toLowerCase())
      )

  const tagFilter = machine => {
    return (
      tags
        .filter(
          label =>
            label.others &&
            label.options
              .map(tag => tag.tagId)
              .some(tag => filter.activeTags.includes(tag))
        )
        .map(label =>
          label.options
            .map(opt => opt.tagId)
            .filter(tagId => filter.activeTags.includes(tagId))
        )
        .every(label =>
          label.every(opt => machine.MachineTags.split(', ').includes(opt))
        ) &&
      tags
        .filter(
          label =>
            !label.others &&
            label.options
              .map(tag => tag.tagId)
              .some(tag => filter.activeTags.includes(tag))
        )
        .map(label =>
          label.options
            .map(opt => opt.tagId)
            .filter(tagId => filter.activeTags.includes(tagId))
        )
        .every(label => label.some(opt => machine.MachineTags.split(', ').includes(opt)))
    )
  }
  const toggleToast = () => setShowToast(false)
  const  getMachineIdByName = (machineName = filter.columns[1]?.value ? filter.columns[1]?.value : null ) => machinesRes.filter(machine => machine.MachineName === machineName)[0]?.MachineId || null
  const  getLocationId =(location = filter.columns[3]?.value ? filter.columns[3]?.value : null) =>  locations.filter(locationn => locationn.Name === location)[0]?.LocationId || null
  const  getMachineIdBySerialNo =(serialNo = filter.columns[2]?.value ? filter.columns[2]?.value : null) =>  machinesRes.filter(machine => machine.SerialNo === serialNo)[0]?.MachineId || null
  const  getMaintenanceId =(maintenanceName = filter.columns[5]?.value ? filter.columns[5]?.value : null) =>  maintenances.filter(user => user.Name === maintenanceName)[0]?.MaintenanceTypeId || null
  const  getMachineTypesId =(machineType = filter.columns[4]?.value ? filter.columns[4]?.value : null) =>  machineTypes.filter(user => user.Name === machineType)[0]?.MachineTypeId || null
  function getIsActive() {
    const filter = localStorage.getItem("machinesFilter")
    if(filter ) {
        if(JSON.parse(filter).columns !== null && JSON.parse(filter).columns.length > 6  && JSON.parse(filter).columns[6] !== null && JSON.parse(filter).columns[6].selectbar !== null)
        {
            const value = JSON.parse(filter).columns[6].selectbar
            if(value === "null") return null
            if(value === "true") return true
            if(value === "false") return false
        }
        else return null
    }
  }
  const getMachineIds = (machineId) => {
    if (machineId) {
      console.log("machineId")
      return null
    }
    if (choosedMachines.length ) {
      console.log("choosedMachines")
      return choosedMachines
    } 
    if (filter.activeTags.length) {
      return [...machines].filter(tagFilter).map(machine => machine.MachineId).join(",") 
    }
    return null 
    // [...machines].map(machine => machine.MachineId).join(",")
  }
  const export72 = (machineId) => {
    let isDefaultMachineId = !(machineId == null)
    // const controller = reportStateChanger(
    //   isDefaultMachineId,
    //   () => setRep72machine(() => ({Report: 72, Id: machineId})),
    //   () => setRep72(true),
    //   {Report: null, Id: null},
    //   () => setRep72machine(() => ({Report: null, Id: null})),
    // )
    if (isDefaultMachineId) {
      setRep72machine(() => ({Report: 72, Id: machineId}))
    } else {
      setRep72(true)
    }
    // console.log("tagFilter:", [...machines].filter(tagFilter).map(machine => machine.MachineId).join(","))
    machineId = machineId || getMachineIdByName() || getMachineIdBySerialNo()
    axios({
      method: "POST",
      url: `${API_URL}/api/reports/machine`,
      data: { 
        reportId: parseInt(72),
        machineId: machineId,
        isActive: getIsActive(),
        machineIds: getMachineIds(machineId),
        locationId: getLocationId(),
        maintenanceId: getMaintenanceId(),
        machineType: getMachineTypesId()
      },
      responseType:'blob'
  }).then((res) => {
      if (isDefaultMachineId) {
        setRep72machine(() => ({Report: null, Id: null}))
      } else {
        setRep72(false)
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DZ72_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
      document.body.appendChild(link);
      link.click();
  }).catch(err => {
      if (err.response.data.message === "jwt malformed") window.location.reload();
      else NotificationManager.error(err.response?.data || err.toString())
      if (isDefaultMachineId) {
        setRep72machine(() => ({Report: null, Id: null}))
      } else {
        setRep72(false)
      }
  })
  }



  const export73 = (machineId) => {
    let isDefaultMachineId = !(machineId == null)
    if (isDefaultMachineId) {
      setRep73machine(() => ({Report: 73, Id: machineId}))
    } else {
      setRep73(true)
    }
    machineId = machineId ||  getMachineIdByName() || getMachineIdBySerialNo()
    axios({ 
      method: "POST",
      url: `${API_URL}/api/reports/machine`,
      data: { 
        reportId: parseInt(73),
        machineId: machineId,
        isActive: getIsActive(),
        machineIds: getMachineIds(machineId),
        locationId: getLocationId(),
        maintenanceId: getMaintenanceId(),
        machineType: getMachineTypesId()
      },
      responseType:'blob'
  }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DZ73_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (isDefaultMachineId) {
        setRep73machine(() => ({Report: null, Id: null}))
      } else {
        setRep73(null)
      }
  }).catch(err => {
      if (err.response.data.message === "jwt malformed") window.location.reload();
      else NotificationManager.error(err.response?.data || err.toString())
      if (isDefaultMachineId) {
        setRep73machine(() => ({Report: null, Id: null}))
      } else {
        setRep73(null)
      }
  })

  }


  const getMachineIsChoosed = ({MachineId}) => {
    if (choosedMachines.includes(MachineId)) {
      return true
    } else {
      return false
    }
  }
  const deleteMachineFromChoosed = ({MachineId}) => {setChoosedMachines(prev => prev.filter(i => i !== MachineId))}
  const addMachineToChoosed = ({MachineId}) => {
    if (getMachineIsChoosed({MachineId})) {
      deleteMachineFromChoosed({MachineId})
    } else {
      setChoosedMachines(prev => [...prev, MachineId])
    }
  }
  return (
    <div className='container-fluid'>
      <Pagination
        {...{
          totalItems: machines.filter(reportFilter).filter(tagFilter).length,
          page: filter.page,
          handleSwitchPage,
          rowsPerPage: filter.rowsPerPage,
          toggleFilter,
          resetFilter,
          filterVisibility: filter.visible,
          resetBtnVis
        }}
      />
      {filter.visible && (
        <>
          {/* <Filter
            {...{
              filter,
              setFilter,
              columns: filter.columns,
              data: machines,
              resetPage,
              tags,
              resetFilter
            }}
          /> */}
          <FilterEx
            {...{
              filter,
              setFilter,
              columns: filter.columns,
              data: machines,
              resetPage,
              tags,
              resetFilter,
              search,
              toggleFilter,
              enableMachineTags: true
            }}
          />
        </>
      )}
        <div className='d-flex justify-content-end align-items-center'>
          {/* <div>
            <InformationBtn
              text={`Raport będzie zawierał informacje o ${choosedMachines.length}`}
              // aditionalStyles = {!choosedMachines.length && `d-none mb-3`}
              showToast={showToast}
              setShowToast={setShowToast}
              toggleToast={toggleToast}
              iconStyle='fa-sm'
            />
          </div> */}
          <div className='d-flex'>
            <div>
              <ReportBtn
                  placement='top'
                  clickFnc={() => export72()}
                  state={rep72}
                  setState={setRep72}
                  overlayText={(<div>
                      <b>Typ raportu: <br/> Konfiguracja<br/> [DZ72]</b>
                    </div>)}
                  btnColor='success'
              />
            </div>
            <div>
              <ReportBtn
                  placement='top'
                  clickFnc={() => export73()}
                  state={rep73}
                  setState={setRep73}
                  overlayText={(<div>
                      <b>Typ raportu: <br/> Stan bieżący<br/> [DZ73]</b>
                    </div>)}
                  btnColor='primary'
              />
            </div>
          </div>
            
        </div>
      <div className="card">
        {/* <h5 className="card-header"> */}
          {/* Automaty */}
          {/* TO DO: Add translations*/}
        {/* </h5> */}
        <div className='card-header'>
            <div className='d-flex justify-content-between'>
              <div className='mt-1'>
                Automaty
              </div>
              <div>
                <Link
                    className="btn text-nowrap btn-sm btn-outline-secondary"
                    to='/machine/0'
                >
                    <i className="fas fa-plus" />
                </Link>
              </div>
            </div>
        </div>
        <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>
          {machines.filter(reportFilter).filter(tagFilter).length ? (
            <table className="table table-striped">
              <thead >
              <tr>
                
                {/* {filter.columns
                  .filter(col => !col.hidden && !col.disabled)
                  .map((col, idx) => (
                  <th key={idx} 
                  className={(col.name === "Aktywny") && "text-center" || ""}>
                    {col.name}
                  </th>))}
                <th /> */}
                <th></th>
                <th>Nazwa <br/> Nr seryjny </th>
                <th>Lokalizacja</th>
                <th>Typ <br/> Tryb obsługi</th>
                <th className='text-center'>Aktywny</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                { 
                machines
                  .filter(reportFilter)
                  .filter(tagFilter)
                  .sort(sortRows)
                  .slice(
                    (filter.page - 1) * filter.rowsPerPage,
                    filter.page * filter.rowsPerPage
                  )
                  .map((machine, idx) => (
                    <tr key={idx} >
                      {/* {filter.showIndexes && (
                        <td className="text-center small font-weight-bold">
                          {(filter.page - 1) * filter.rowsPerPage + idx + 1}
                        </td>
                      )} */}
                      
                      {/* {Object.keys(machine)
                        .filter((col, col_idx) =>
                          filter.columns
                            .filter(col => !col.hidden && !col.disabled)
                            .map(col => col.id)
                            .includes(col_idx + 1)
                        )
                        .map((col, col_idx) => (
                          <td key={col_idx} className={(col !==  "IsActive") ? "small" : ""}>
                            <div className={(col !==  "IsActive") ?  "btn btn-link font-size-inherit text-reset text-decoration-none p-1" : "d-flex w-100 h-100 justify-content-center align-items-center"}>
                            {(typeof(machine[col])  !== "boolean" )
                              ? machine[col]
                              :
                                <input type='checkbox'
                                  style={{width: "22px", height: "22px"}}
                                  checked={machine[col]} onChange={() => {}}/>
                              }
                            </div>
                        </td>
                        ))} */}
                        <td>
                          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                            <input type='checkbox'
                            style={{width: "22px", height: "22px"}}
                            checked={getMachineIsChoosed(machine)} 
                            onChange={() => addMachineToChoosed(machine)}/>
                          </div>
                        </td>
                        <td className='small'>
                          <div>
                            <div>{machine.MachineName}</div>
                            <div>{machine.SerialNo}</div>
                          </div>
                        </td>
                        <td className='small'>
                          {machine.LocationName}
                        </td>
                        <td className='small'>
                          <div>{machine.MachineTypeName}</div>
                          <div>{machine.MaintenanceName}</div>
                        </td>
                        <td>
                          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
                            <input type='checkbox'
                            style={{width: "22px", height: "22px"}}
                            checked={machine.IsActive} 
                            onChange={() => {}}/>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-center'>
                            <ReportBtnForArr
                              placement='top'
                              clickFnc={() => export72(machine.MachineId)}
                              state={rep72machine}
                              setState={setRep72machine}
                              value={{Report: 72, Id: machine.MachineId}}
                              overlayText={(<div>
                                <b> Typ raportu: <br/> Konfiguracja <br/> [DZ72] </b> 
                                </div>)}
                              btnColor='success'
                              />
                            {/* <ReportBtnForArr
                              clickFnc={() => export73(machine.MachineId)}
                              state={rep73}
                              setState={setRep73}
                              value={{Report: 73, Id: machine.MachineId}}
                              // overlayText={(<div>
                              //     <b>Typ raportu:<br />Transakcje - sprzedaż [DZ76]</b><br /><br />Raport zawiera transakcje gotówkowe oraz bezgotówkowe (w tym BLIK) zakończone poprawnym wydaniem.
                              //   </div>)}
                              btnColor='primary'
                              /> */}
                              <ReportBtnForArr
                              placement='top'
                              clickFnc={() => export73(machine.MachineId)}
                              state={rep73machine}
                              setState={setRep73machine}
                              value={{Report: 73, Id: machine.MachineId}}
                              overlayText={(<div>
                                <b> Typ raportu: <br/> Stan bieżący <br/> [DZ73] </b> 
                                </div>)}
                              btnColor='primary'
                              />
                            </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end'>

                            <Link
                              to={`/machine/${machine.MachineId}`}
                              // className="btn btn-link link-icon"
                              className="btn btn-sm btn-outline-primary btn-sm mr-1 p-0.5"
                            >
                              {/* <i className="far fa-edit" /> */}
                              Edytuj
                            </Link>
                          </div>

                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <NoResults />
          )
          }
        </div>
      </div>
      {/* <div className="card-footer text-center">
          <button className="btn btn-success btn-sm">{TRL_Pack.buttons.save}</button>
      </div> */}
      {/* <MachinesImages /> */}
    </div>
  )
}

export default Machines;    