import React, { useContext } from 'react'
import { LangContext } from '../../context/lang-context'
import FormSkel from './FormSkel'
import { useState } from 'react'

export default function MachineTypeForm ({ machineTypeData, handleSubmit, handleClose, manufacturers }) {
  const { TRL_Pack } = useContext(LangContext)
  const [data, setData] = useState(machineTypeData || {Name: "", ManufacturerName: ""})
  return (
    <FormSkel
      headerText={
        machineTypeData
          ? "Edytuj typ automatów"
          : "Nowy typ automatów"
      }
      handleClose={handleClose}
      data={machineTypeData}
      disableSubmit={!data.Name}
    >
      <form onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()} onSubmit={evt => handleSubmit(evt, setData)} id="modal-form" autoComplete="off">
        <div>
          <label className="h6">Nazwa typu automatu</label>
          <input
            minLength={1}
            onFocus={() => setData({...data, Name: ""})}
            name="name"
            className={`form-control ${!data.Name && `invalid-input`}`}
            value={data.Name || ""}
            onChange={evt => setData({...data, Name: evt.target.value})}
          />
        </div>
        <div>
          <label className="h6">Producent</label>
          <input  
            onFocus={() => setData({...data, ManufacturerName: ""})}
            name="manufacturer"
            className={`form-control`}
            value={data.ManufacturerName || ""}
            onChange={evt => setData({...data, ManufacturerName: evt.target.value})}
            list='manufacturers'
          />
          <datalist id='manufacturers'>
            {manufacturers && manufacturers.map((manufacturer, idx) => (
              <option value={manufacturer.Name} key={idx}/>
            ))}
          </datalist>
        </div>
      </form>
    </FormSkel>
  )
}
