import React from 'react';

const Switch = ( {id, onChange, defaultChecked, checked } )=> {
    return (
        <>
            <div style={{ top: 2, left: "1.4em" }} className="custom-control custom-switch">
                <input type="checkbox" style={{ width: 15, transform: "scale(1.5)" }} checked={checked} defaultChecked={defaultChecked} className="form-control custom-control-input" onChange={onChange} id={id}></input>
                <label className="form-label custom-control-label" style={{ width: 15, transform: "scale(1.5)" }} htmlFor={id}></label>
            </div>
        </>
    )
}

export default Switch;