import React, { useContext, useEffect, useState } from "react";
import useForm from "../../hooks/form-hook";
import useForm2 from "../../hooks/form-hook2";
import useForm3 from "../../hooks/form-hook3";

import { LangContext } from "../../context/lang-context";

import useFetch from "../../hooks/fetchMSSQL-hook";
import LiftForm from "../../components/Modals/LiftForm";
import Feeder from "./Feeder";

// import MergeFeeder from './MergeFeeder';
const Lift = () => {
  const { TRL_Pack } = useContext(LangContext);
  const { fetchMssqlApi } = useFetch();
  const { form, openForm, closeForm } = useForm();
  const { form2, openForm2, closeForm2 } = useForm2();
  const { form3, openForm3, closeForm3 } = useForm3();

  const [machineType, setMachineType] = useState("-");
  const [machineSlaveType, setMachineSlaveType] = useState("-");
  const [machineSecondSlaveType, setMachineSecondSlaveType] = useState("-");

  const getMachineType = () => {
    fetchMssqlApi("machines", {}, (machines) => {
      let types = machines[0].Type;
      setMachineType(types.split("_")[0]);
      if (types.split("_")[1] !== undefined)
        setMachineSlaveType(types.split("_")[1]);
      if (types.split("_")[2] !== undefined)
        setMachineSecondSlaveType(types.split("_")[2]);
    });
  };

  useEffect(() => {
    getMachineType();
  }, []);

  const resetLift = () => {
    fetchMssqlApi(
      `shop/lift/move`,
      { method: "POST", data: { Floor: 0, Number: 1 } },
      closeForm
    );
  };

  const moveLift = (floor) => () => {
    fetchMssqlApi(
      "shop/lift/move",
      { method: "POST", data: { Floor: floor, Number: 1 } },
      closeForm
    );
  };

  const resetLiftSlave = () => {
    fetchMssqlApi(
      `shop/lift/move`,
      { method: "POST", data: { Floor: 0, Number: 2 } },
      closeForm2
    );
  };

  const moveLiftSlave = (floor) => () => {
    fetchMssqlApi(
      `shop/lift/move`,
      { method: "POST", data: { Floor: floor, Number: 2 } },
      closeForm2
    );
  };

  const resetLiftSlave2 = () => {
    fetchMssqlApi(
      `shop/lift/move`,
      { method: "POST", data: { Floor: 0, Number: 3 } },
      closeForm3
    );
  };

  const moveLiftSlave2 = (floor) => () => {
    fetchMssqlApi(
      `shop/lift/move`,
      { method: "POST", data: { Floor: floor, Number: 3 } },
      closeForm3
    );
  };

  return (
    <>
      <Feeder />
      {(machineType !== "-") & (machineType !== "LOCKER") & (machineType !== "NECTA") & (machineType !== "V10") ? (
        <>
          <div style={{ paddingBottom: 50 }}></div>
          <h4>{machineType} [Master]</h4>
          <div className="row">
            <div className="col-12 mt-4 mb-2">
              <h5>{TRL_Pack.lift.elevatorSettings}</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-2">
              <h6>Rest</h6>
              <button
                className="btn list-group-item list-group-item-action"
                onClick={resetLift}
              >
                <div className="text-center">
                  <i className="fas fa-chevron-up text-info" />
                </div>
              </button>
            </div>
            {[1, 2, 3, 4, 5, 6, 7].map((floor) => (
              <div
                key={floor}
                className="col-12 col-md-6 col-lg-4 mb-2 position-relative"
              >
                <h6>{TRL_Pack.lift.shelf} {floor}</h6>
                <div className="row">
                  <div className="col-sm">
                    <button
                      className="btn list-group-item list-group-item-action"
                      onClick={openForm(floor)}
                    >
                      <div className="text-center">
                        <i className="fas fa-cog text-info" />
                      </div>
                    </button>
                  </div>

                  <div className="col-sm">
                    <button
                      type="button"
                      className="btn list-group-item list-group-item-action"
                      onClick={moveLift(floor)}
                    >
                      <div className="text-center">
                        <i className="fas fa-chevron-up text-info" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {form && (
            <LiftForm floor={form} type={"master"} handleClose={closeForm} />
          )}
        </>
      ) : null}

      {(machineSlaveType !== "-") & (machineSlaveType !== "LOCKER") & (machineSlaveType !== "NECTA") & (machineSlaveType !== "V10") ? (
        <>
          <div style={{ paddingTop: 50 }}></div>
          <h4>{machineSlaveType} [Slave 1]</h4>
          <div className="row">
            <div className="col-12 mt-4 mb-2">
              <h5>{TRL_Pack.lift.elevatorSettings}</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-2">
              <button
                className="btn list-group-item list-group-item-action"
                onClick={resetLiftSlave}
              >
                {TRL_Pack.lift.elevatorReset}
              </button>
            </div>
            {[1, 2, 3, 4, 5, 6, 7].map((floor) => (
              <div
                key={floor}
                className="col-12 col-md-6 col-lg-4 mb-2 position-relative"
              >
                <h6>{TRL_Pack.lift.shelf} {floor}</h6>
                <div className="row">
                  <div className="col-sm">
                    <button
                      className="btn list-group-item list-group-item-action"
                      onClick={openForm2(floor)}
                    >
                      <div className="text-center">
                        <i className="fas fa-cog text-info" />
                      </div>
                    </button>
                  </div>

                  <div className="col-sm">
                    <button
                      type="button"
                      className="btn list-group-item list-group-item-action"
                      onClick={moveLiftSlave(floor)}
                    >
                      <div className="text-center">
                        <i className="fas fa-chevron-up text-info" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {form2 && (
            <LiftForm floor={form2} type={"slave"} handleClose={closeForm2} />
          )}
        </>
      ) : null}
      <div style={{ paddingTop: 50 }}></div>
      {(machineSecondSlaveType !== "-") & (machineSecondSlaveType !== "LOCKER") & (machineSecondSlaveType !== "V10") & (machineSecondSlaveType !== "NECTA") ? (
        <>
          <h4>{machineSecondSlaveType} [Slave 2]</h4>
          <div className="row">
            <div className="col-12 mt-4 mb-2">
              <h5>{TRL_Pack.lift.elevatorSettings}</h5>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-2">
              <button
                className="btn list-group-item list-group-item-action"
                onClick={resetLiftSlave2}
              >
                {TRL_Pack.lift.elevatorReset}
              </button>
            </div>
            {[1, 2, 3, 4, 5, 6, 7].map((floor) => (
              <div
                key={floor}
                className="col-12 col-md-6 col-lg-4 mb-2 position-relative"
              >
                <h6>{TRL_Pack.lift.shelf} {floor}</h6>
                <div className="row">
                  <div className="col-sm">
                    <button
                      className="btn list-group-item list-group-item-action"
                      onClick={openForm3(floor)}
                    >
                      <div className="text-center">
                        <i className="fas fa-cog text-info" />
                      </div>
                    </button>
                  </div>

                  <div className="col-sm">
                    <button
                      type="button"
                      className="btn list-group-item list-group-item-action"
                      onClick={moveLiftSlave2(floor)}
                    >
                      <div className="text-center">
                        <i className="fas fa-chevron-up text-info" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {form3 && (
            <LiftForm floor={form3} type={"slave2"} handleClose={closeForm3} />
          )}
        </>
      ) : null}
    </>
  );
};

export default Lift;