import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { LangContext } from '../../../context/lang-context'
import { NavigationContext } from '../../../context/navigation-context'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import { Button } from 'react-bootstrap'
import NoResults from '../../../components/NoResults/NoResults'
import { getSheet } from '../../../util/getSheets'
import ReportBtn from '../../../components/Buttons/ReportsBtn/ReportBtn'
import moment from 'moment'
import { API_URL } from '../../../config/config'
import Pagination from '../../../components/Pagination/Pagination'
import FilterEx from '../../../components/Filter/FilterEx'
import { NotificationManager } from 'react-notifications'

const changeTypes = new Map(
    [
        ["[AUTOMAT] Zmiana nr wyboru", "A"],
        ["[AUTOMAT] Zmiana ceny", "B"],
        ["[AUTOMAT] Nowy wybór", "C"],
        ["[AUTOMAT] Usunięcie wyboru", "D"],
        ["[AUTOMAT] Zmiana stanu", "E"],
        ["[AUTOMAT] Zmiana produktu", "F"],
        ["[AUTOMAT] Zmiana pojemności", "G"],
        ["[AUTOMAT] Wybór zablokowany / Wybór odblokowany", "H"],
        ["[VD] Zmiana nr wyboru", "I"],
        ["[VD] Zmiana ceny", "J"],
        ["[VD] Nowy wybór", "K"],
        ["[VD] Usunięcie wyboru", "L"],
        ["[VD] Zmiana stanu", "M"],
        ["[VD] Zmiana produktu", "N"],
        ["[VD] Zmiana pojemności", "O"],
        ["[TRX] Zmiana ceny", "P"],
        ["[TRX] Zmiana stanu", "R"],
        ["[VM] Zmiana nr wyboru", "S"],
        ["[VM] Zmiana ceny", "T"],
        ["[VM] Nowy wybór", "U"],
        ["[VM] Usunięcie wyboru", "W"],
        ["[VM] Zmiana stanu", "X"],
        ["[VM] Zmiana produktu", "Y"],
        ["[VM] Zmiana pojemności", "Z"],
        ["[VM] Pobranie danych stanu automatu", "1"],
        ["[VM] Zapisanie wizyty (start)", "2"],
        ["[VM] Zapisanie wizyty (koniec)", "3"],
        ["[VD] Pobranie danych do synchronizacji przez automat", "4"],
    ]
)

const sources = new Map([
    ["[AUTOMAT]", ["A","B","C","D","E","F","G","H",]],
    ["[VD]", ["I","J","K","L","M","N","O", "4"]],
    ["[TRX]", ["P", "R"]],
    ["[VM]", ["S","T","U","W","X","Y","Z","1","2","3",]],
])

export default function MachineLogs({machineData}) {

    const defaultFilter = {
        showIndexes: false,
        page: 1,
        disableIndexes: true,
        rowsPerPage: 1000,
        visible: false,
        sortByColumns: false,
        activeTags: [],
        columns: [
            {
                id: 1,
                name: 'Zakres czasowy',
                searchable: true,
                sortable: false,
                type: 'datetimeRange',
                dateTimeRangeFrom: moment().format('YYYY-MM-DDT00:00:00'),
                dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
            },
            {
                id: 2,
                name: 'Nr wyboru',
                searchable: true,
                sortable: true,
                type: 'lookup',
                ProductNo: null,
                value: null
            },
            {
                id: 3,
                name: 'Typ zmiany',
                searchable: true,
                sortable: true,
                type: 'lookup',
                ProductNo: null,
                value: null
            },
            {
                id: 4,
                name: 'Żródło zmiany',
                searchable: true,
                sortable: true,
                type: 'lookup',
                ProductNo: null,
                value: null
            },
        ]
    }
    
    const {fetchMssqlApi} = useFetch()
    const {setHeaderData} = useContext(NavigationContext)
    const [machineLogs, setMachineLogs] = useState(null)
    const [rep96, setRep96] = useState(null)


    const getReqBody = (returnDefault = false ) => {
        if(returnDefault) return {
            CreateDateTimeFrom: defaultFilter.columns[0].dateTimeRangeFrom,
            CreateDateTimeTo: defaultFilter.columns[0].dateTimeRangeTo,
            MachineId: machineData.MachineId,
            MachineFeederNo: null
        }
        const searchFilter = {}
        searchFilter.MachineId = machineData.MachineId
        try {
            searchFilter.CreateDateTimeFrom = filter.columns[0].dateTimeRangeFrom
        } catch(e) {
            searchFilter.CreateDateTimeFrom = moment().format('YYYY-MM-DDT00:00:00')
        }
        try {
            searchFilter.CreateDateTimeTo = filter.columns[0].dateTimeRangeTo
        } catch(e) {
            searchFilter.CreateDateTimeTo = moment().format('YYYY-MM-DDT23:59:59')
        }
        if (!moment(filter.columns[0]?.dateTimeRangeFrom).isValid() || 
        !moment(filter.columns[0]?.dateTimeRangeTo).isValid()) {
            NotificationManager.error("Wybierz poprawną datę")
            searchFilter.CreateDateTimeFrom = moment().format('YYYY-MM-DDT00:00:00')
            searchFilter.CreateDateTimeTo = moment().format('YYYY-MM-DDT23:59:59')
            return null
        }
        if(filter.columns[1].value && !Number.isNaN(filter.columns[1].value)) searchFilter.MachineFeederNo = filter.columns[1].value  
        if(filter.columns[2].value) searchFilter.ChangeTypes = changeTypes.get(filter.columns[2].value)
        if(filter.columns[3].value) searchFilter.ChangeTypes = sources.get(filter.columns[3].value).join(",")
        return searchFilter
    }

    const getMachineLogs = (returnDefault = true) => {
        fetchMssqlApi(`machine-logs`, {method: "POST", data: getReqBody(returnDefault), hideNotification: true}, res => {
            setMachineLogs(res)
        })
    }
    const getRep = () => {
            const f = getReqBody(false)
            getSheet({
                callbackToChangeState: setRep96,
                url: `${API_URL}/api/reports/machine-logs`,  
                name: `DZ96_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
                body: {
                reportId: 96, 
                ...f
                },
                spinnerTriggerState: true
            })
    } 

    const [filter, setFilter] = useState(() => {
        return defaultFilter;
    })
    
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);
        getMachineLogs()
    }
    useEffect(() => {
        setHeaderData({text: "Historia zmian stanów automatu"})
        getMachineLogs()
    }, [])
    return (
        <div>
            <Pagination
                totalItems={machineLogs && machineLogs.length}
                page={filter.page}
                rowsPerPage={filter.rowsPerPage}
                filterVisibility={filter.visible}
                toggleFilter={toggleFilter}
                resetFilter={resetFilter}
            />
            {filter.visible && (
                <FilterEx
                    {...{ filter, setFilter,columns: filter.columns, resetFilter, search: () => getMachineLogs(false), toggleFilter, resetPage: () => {}}}
                />
            )}
            <div className="card">
                <div className='card-header d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        {`${machineData.MachineName} (${machineData.SerialNo})` || "Historia zmian"}
                    </div>
                    <div>
                        <ReportBtn
                            clickFnc={getRep}
                            state={rep96}
                            setState={setRep96}
                            overlayText={(<div>
                                <b>Typ raportu:<br />Błędy utomatów [DZ96]</b><br /><br />Raport zawiera inofrmacje o zmianach stanu automatu.
                            </div>)}
                            btnColor='secondary'
                            permissionName={"VD_REPORT_91"}
                        />

                    </div>
                </div>

                <div className='card-body'>
                    <section className="card-body overflow-auto p-0" style={{maxHeight: "80vh"}}>

                        {machineLogs && machineLogs.length ? (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    {/* <th>Nazwa automatu</th> */}
                                    {/* <th>Nr seryjny automatu</th> */}
                                    <th>Nr wyboru</th>
                                    <th>Data / Czas</th>
                                    <th>Zmiana</th>
                                    {/* <th>Zmiana</th> */}
                                    {/* <th>Wartośc przed</th>
                                    <th>Wartośc po</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {machineLogs.map(log =>(
                                    <tr>
                                        {/* <td>{log?.MachineName}</td> */}
                                        {/* <td>{log?.SerialNo}</td> */}
                                        <td>{log?.MachineFeederNo}</td>
                                        <td>{log?.ChangeDateTime}</td>
                                        <td>{log?.ChangeType}</td>
                                        {/* <td>{log?.ChangeDesc}</td> */}
                                        {/* <td>{log?.BeforeValue}</td>
                                        <td>{log?.AfterValue}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        ) : <NoResults/>}
                    </section>
                </div>
            </div>
        </div>
    )
}
