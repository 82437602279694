import React from 'react'
import { useState } from 'react'
import ReportBtn from '../../../components/Buttons/ReportsBtn/ReportBtn'

export default function PrepaidReports({export86, export87, export88, type}) {
    const [r86, set86] = useState(false),
    [r87, set87] = useState(false),
    [r88, set88] = useState(false)
    return (
        type === "transactions" && (
        <div className='w-100 mr-10 d-flex justify-content-end'>
            <ReportBtn
                clickFnc={export87} 
                state={r87}
                setState={set87}
                overlayText={(<div><b>Typ raportu: Transakcje prepaid [DZ87]</b><br /><br />Export danych z listy. Zawiera dane dotyczące stawek VAT produktów.</div> )}
                btnColor='primary'
            />
            <ReportBtn
                clickFnc={export86} 
                state={r86}
                setState={set86}
                overlayText={(<div><b>Typ raportu: Transakcje prepaid pogrupowane po karcie [DZ86]</b><br /><br />Export danych z listy pogrupownych wg kart prepaid. Zawiera dane dotyczące stawek VAT produktów. </div> )}
                btnColor='danger'
            />
            <ReportBtn
                clickFnc={export88} 
                state={r88}
                setState={set88}
                overlayText={(<div><b>Typ raportu: Transakcje prepaid pogrupowane po karcie [DZ88]</b><br /><br />Export danych z listy pogrupownych wg terminali. Zawiera dane dotyczące stawek VAT produktów.</div> )}
                btnColor='success'
            />
        </div>
        )
    )
}
