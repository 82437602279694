//PROD
export const API_URL = `${window.location.protocol}//${window.location.hostname}:10443`

//DEV / MASZYNA 
// export const API_URL = `${window.location.protocol}//${window.location.hostname}:3000`

//export const API_URL = `http://portal.retail24.pl:10443`


export const SHOP_URL = `${window.location.protocol}//${window.location.hostname}:8080/shop`
// export const API_URL = `${window.location.protocol}//${window.location.hostname}:5000`
// export const API_URL = `http://46.41.151.18:5000`

// export const VD_VERSION

export const LOCAL_CLOUD = `${API_URL}/images/${localStorage.getItem('clientId')}`
export const CONSOLE_CLOUD = `${API_URL}/images/console`
// export const WKF_BASE_URL = `https://portal.retail24.pl:9999`
export const WKF_API_BASE_URL = `https://portal.retail24.pl:10443`
export const WKF_BASE_URL = `https://portal.retail24.pl`



