import React, { useEffect, useState, useContext } from 'react'
import useFetch from '../../hooks/fetchMSSQL-hook'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { NavigationContext } from '../../context/navigation-context'
import link from "../../assets/images/back_to_list_icon.svg"

export default function WZ() {
const [WZ, setWZ] = useState({})
const [items, setItems] = useState([])
const { setHeaderData } = useContext(NavigationContext)
const {fetchMssqlApi} = useFetch()
const param = useParams()
const history = useHistory()


const getWZ = () => {
    fetchMssqlApi(`wz/${param.id}`, {}, res => {
        setItems(res.Items.length ? res.Items : [])
        delete res.Items
        setWZ({...res, IssuedOn: moment(res.IssuedOn).format('YYYY-MM-DD')})
    })
} 
useEffect(() => {
    setHeaderData({text: "Wydainie zewnętrzne"})
    getWZ()
}, [])
    return (
        <div>
        {/* <div>
            <button onClick={() => history.goBack()} className="btn btn-link ml-1 text-decoration-none mb-3">
                <i className="fas fa-arrow-left mr-2" />
                Wróć
            </button>
        </div> */}
        <Link to='/wzs'>
                <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                <img id='back' src={link} ></img>
            </label>
        </Link>
    <div className='card'>
        <div className='card-header'>
            <div className='h5 mt-2'>
                {WZ.WarehouseOperationFullName}
            </div>
        </div>    
            <table className="table table-striped">
                <tbody>
                <tr className='justify-content-center'>
                <td>Stworzony</td>
                <td className='text-center'>Data wystawienia</td>
                <td>Magazyn</td>
                </tr>


                <tr >
                <td>{WZ.Created && `${moment(WZ.Created.split(" ")[0]).format("DD.MM.YYYY")} ${WZ.Created.split(" ").at(-1)}`}</td>
                <td>
                    <div className='d-flex justify-content-center mt-1 mb-1'>
                        {WZ.IssuedOn}
                    </div>
                </td>
                <td>
                        {WZ.WarehouseFullName}
                </td>
                </tr>


                <tr className='justify-content-center mt-1 mb-1'>
                <td></td>
                <td className='text-center' colSpan={1}>Komentarz / dodatkowe informacje</td>
                <td></td>
                </tr>


                <tr className='justify-content-center'>
                <td>{WZ.ToWarehouseOperationFullName}</td>
                    <td colSpan={1}>
                        <div className='d-flex justify-content-center'>
                            <textarea
                                disabled={true}
                                value={WZ.Comment || ""}
                                cols={70}
                                rows={5}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

        <div className='card' style={{marginBottom: "40px", marginTop: "40px"}}>
        {items.length ?
        ( <table className="table table-striped ">
            <thead>
                <tr>
                    <th>Produkt</th>
                    <th>Ilość</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, idx) => 
                !item.deleted && 
                (
                    <tr key={idx}>
                        <td>
                            <input
                            disabled={true}
                                style={{maxWidth: "70vh"}}
                                className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                value={item.Name}
                            />

                        </td>
                        <td>
                            <input
                            disabled={true}
                                type='text'
                                className={`form-control form-control-sm mx-auto mx-lg-0`}
                                value={item.Quantity}
                            />
                        </td>
                        <td></td>
                    </tr>
                ))}
                
            </tbody>
            </table>)
            : 
            <div className='card'>
                <div className='d-flex justify-content-center align-items-center card-header'>
                    Dokument nie ma produktów
                </div>
            </div>
        }
        </div>
    </div>
    )
}
