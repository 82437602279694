import React, {useEffect, useContext, useState} from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../../context/navigation-context'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import NoResults from '../../../components/NoResults/NoResults'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../../assets/images/back_to_list_icon.svg'
import { NotificationManager } from 'react-notifications'
import CashRegisterCurrentState from './components/CashRegisterCurrentState'
import PaginationEx from '../../../components/Pagination/PaginationExSm'
import NewCashRegister from '../CashRegister'
import moment from 'moment'

export default function CashRegister() {
    const [defaultCashRegister, setDefaultCashRegister] = useState([]),
    [cashRegister, setCashRegister] = useState([]),
    [machinesList, setMachinesList] = useState([]),
    [cashRegisterIN, setCashRegisterIN] = useState([]),
    [changed, setChanged] = useState(false),
    [availableToSave, setAvailableToSave] = useState(true),
    { setHeaderData } = useContext(NavigationContext),
    { fetchMssqlApi } = fetchHook(),
    params = useParams(),
    history = useHistory()
    const getCashRegister = (id = params.id) => {
        id !== "0" && fetchMssqlApi(`cashRegister/${id}`, {}, res => setDefaultCashRegister(res))
    }

    // const getMachinesList = () => {
    //     fetchMssqlApi(`machines-list`, {}, res => setMachinesList(res))
    // }


    const handleChangeMachine = (machineName) => {
        const machine = machinesList && machinesList.find(machine => machine.Name === machineName)
        if (!machine) {
            setCashRegister(prev => ({...prev, MachineName: machineName}))
        } else {
            setCashRegister(prev => ({...prev, MachineName: machineName, MachineId: machine.MachineId}))
        }
    }
    const handleChangeState = (evt) => {
        const {name, value} = evt.target
        setChanged(true)

        switch(name) {
            case "TSN":
            case "NrEwid":
            case "NrUnik":
            case "TypeOfWork":
                setCashRegister(prev => ({...prev, [name]: value}))
                break;
            case "MachineName":
                handleChangeMachine(value)
                break; 
        }
    }
    const handleFocus = (evt) => {
        const {name} = evt.target

        switch(name) {
            case "TSN":
            case "NrEwid":
            case "NrUnik":
                setCashRegister(prev => ({...prev, [name]: ""}))
                break;
            case "MachineName":
                setCashRegister(prev => ({...prev, [name]: "", MachineId: null}))
                break; 
        }
    }
    const handleSubmit = () => {
        fetchMssqlApi(`cashRegister`, {method: "POST", data: cashRegister}, res => {
            if (res.Id) {
                history.replace(res.Id)
                getCashRegister(res.Id)
            } else {
                NotificationManager.error("Cash register doesn`t save")
            }
        })
    }

    const getTypeOfWork = (type) => {
        switch (type) {
            case "Tylko do odczytu":
                return (
                    <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                        <div className={`p-2 badge badge-warning`}>
                                Tryb pracy: {cashRegister.TypeOfWork}
                        </div>
                    </h5>
                )
            case "Fiskalny (środowisko testowe)":
                return (
                    <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                        <div className={`p-2 badge badge-primary`}>
                                Tryb pracy: {cashRegister.TypeOfWork}
                        </div>
                    </h5>
                )
            case "Fiskalny":
                return (
                    <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                        <div className={`p-2 badge badge-success`}>
                                Tryb pracy: {cashRegister.TypeOfWork}
                        </div>
                    </h5>
                )
            case "Obsługi":
                return (
                    <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                        <div className={`p-2 badge badge-secondary`}>
                                Tryb pracy: {cashRegister.TypeOfWork}
                        </div>
                    </h5>
                )
            default:
                return (
                    <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                        <div className={`p-2 badge badge-danger`}>
                                Tryb pracy: nie rozpoznano
                        </div>
                    </h5>
                )
        }
    }



    const defaultFilter = {
        fromDate: moment().add(-1, 'd').format('YYYY-MM-DD')
        , toDate: moment().format('YYYY-MM-DD')
        , docType: "Paragon (PR)"
        , page: 1
        , rowsPerPage: 100
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [fiscalDocsLength, setFiscalDocsLength] = useState(null)
    const handleSwitchPage = (page) => setFilter(prev => ({...prev, page}))
    const resetFilter = () => setFilter(defaultFilter) 
    useEffect(() => {
        setHeaderData({text: "Kasa"})
        getCashRegister()
        // getMachinesList()
    }, [])
    useEffect(() => {
        setCashRegister(defaultCashRegister)
    }, [defaultCashRegister])
    useEffect(() => {
        (cashRegister.TypeOfWork === "null") ? setAvailableToSave(false) : setAvailableToSave(true) 
    }, [cashRegister])
    /**
        @TSN
        @MachineName
        @NrEwid
        @NrUnik
     */
    return (
        params.id !== "0" && (

            <div>
                <div className='d-flex'>
                        <Link  to='/wkfs/cashregisters'>
                            <label htmlFor='back' className="btn btn-link ml-1 text-decoration-none ml-2 mb-3 pb-0">
                                <img id='back' src={link}></img>
                            </label>
                        </Link>
                        <div className='d-flex w-100 justify-content-around align-items-center flex-wrap'>
                            <div>
                                <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                                    <div className={`p-2 badge ${cashRegister.TSN ? `badge-success` : `badge-danger`}`}>
                                        Numer fabryczny: {cashRegister.TSN} 
                                    </div>
                                </h5>
                            </div>
                            <div>
                                <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                                    <div className={`p-2 badge ${cashRegister.NrEwid ? `badge-success` : `badge-danger`}`}>
                                        Numer ewidencyjny: {cashRegister.NrEwid}
                                    </div>
                                </h5>
                            </div>
                            <div>
                                <h5 className='mb-0 p-2' style={{fontSize: "1.3rem"}}>
                                    <div className={`p-2 badge ${cashRegister.NrUnik ? `badge-success` : `badge-danger`}`}>
                                        Numer unikatowy: {cashRegister.NrUnik}
                                    </div>
                                </h5>
                            </div>
                            <div>
                                {getTypeOfWork(cashRegister.TypeOfWork)}
                            </div>
                        </div>
                </div>
                {/* {window.innerWidth > 500 && ( */}
                {/* )} */}
                <div className='container-fluid mb-5'>
    
                        {/* <div className={window.innerWidth > 500 ? 'col-4 ' : 'col-12'} name="Information">
                            <div className='card' >
                                <div className='card-header'>
                                    Ustawnienia
                                </div>
                                <div className='card-body'>
                                    <div className='mb-3'>
                                        <label>Numer fabryczny</label>
                                        <input
                                            className={`form-control ${!cashRegister.TSN && `invalid-input`}`}
                                            value={cashRegister.TSN || ""}
                                            onChange={handleChangeState}
                                            onFocus={handleFocus}
                                            disabled={params.id !== "0"}
                                            name='TSN'
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Numer ewidencyjny</label>
                                        <input
                                            className={`form-control ${!cashRegister.NrEwid && `invalid-input`}`}
                                            value={cashRegister.NrEwid || ""}
                                            onChange={handleChangeState}
                                            onFocus={handleFocus}
                                            disabled={params.id !== "0"}
                                            name='NrEwid'
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Numer unikatowy</label>
                                        <input
                                            className={`form-control ${!cashRegister.NrUnik && `invalid-input`}`}
                                            value={cashRegister.NrUnik || ""}
                                            onChange={handleChangeState}
                                            onFocus={handleFocus}
                                            disabled={params.id !== "0"}
                                            name='NrUnik'
                                        />
                                    </div> */}
                                    {/* <div className='mb-3'>
                                        <label>Przypisany automat</label>
                                        <input
                                            className={`form-control`}
                                            value={cashRegister.MachineName || ""}
                                            onChange={handleChangeState}
                                            onFocus={handleFocus}
                                            name='MachineName'
                                            list='machiensList'
                                            disabled={params.id !== "0"}
                                        />
                                        <datalist id='machiensList'>
                                            {machinesList && machinesList.map((machine, idx) => (
                                                <option key={idx} value={machine.Name}/>
                                            ))}
                                        </datalist>
                                    </div> */}
                                    {/* <div className='mb-3'>
                                        <label>Tryb pracy</label>
                                        <select
                                            className={`form-control`}
                                            value={cashRegister.TypeOfWork || ""}
                                            onChange={handleChangeState}
                                            onFocus={handleFocus}
                                            name='TypeOfWork'
                                            disabled="true"
                                        >
                                            <option value={"null"}>Wybierz</option>
                                            <option value={"test"} >Test</option>
                                            <option value={"production"} >Produkcja</option>
                                        </select>
                                        
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='col-6 ' name="Operations"> */}
                            <div className='card p-0'>
                                <div className='card-header d-flex justify-content-between h-100 align-items-center'>
                                    <div>
                                        Dokumenty
                                    </div>
                                    <div>
                                        <PaginationEx
                                            rowsPerPage={filter.rowsPerPage}
                                            page={filter.page}
                                            rowsCount={fiscalDocsLength}
                                            resetBtnVis={false}
                                            resetFilter={resetFilter}
                                            handleSwitchPage={handleSwitchPage}
                                        />
                                    </div>
                                </div>
                                <section className='card-body overflow-auto' style={{ maxHeight: "72vh" }}>
                                    {/* {!cashRegisterIN.length ? (
                                        <NoResults/>
                                    ) : (
                                        <></>
                                    )} */}
                                    <CashRegisterCurrentState CashRegisterId={params.id} setFilter={setFilter} filter={filter} setFiscalDocsLength={setFiscalDocsLength}/>
                                </section>
                                {
                                    window.innerWidth < 500 && (
                                        <div style={{minHeight: "5vh"}}>
    
                                        </div>
                                    )
                                }
                            </div>
                        {/* </div> */}
                    {/* <div className={`position-fixed container`} style={{bottom: "0px", paddingTop: "50px"}}>
                            <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                                <button disabled={!changed || !availableToSave} className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                                    Zapisz
                                </button>
                            </div>
                    </div> */}
                </div>
            </div>
        ) || (
            <NewCashRegister/>
        )
        )
}
