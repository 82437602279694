import React, { useEffect, useState } from 'react'
import useFetch from '../../../../hooks/fetchMSSQL-hook'
import FormSkel from '../../../../components/Modals/FormSkel'
import IngredientForm from './IngredientForm'
import clearInput from '../../../../util/cleanInput'

export default function RecipeForm({handleClose, ingredients, form, recipeData, setRecipes, eans }) {
const {fetchMssqlApi} = useFetch()
const [allVat, setallVat] = useState([])
const [recipeIngredients, setRecipeIngredients] = useState([])
const [data, setData] = useState([])
const disabled = !!recipeData
const getVAT = () => {
    fetchMssqlApi("/vats", {}, vats => setallVat(vats))
}
const getRecipes = () => {
    fetchMssqlApi("/recipes", {}, recipes => setRecipes(recipes))
}
const id = form
const getRecipeData = () => {
    form !== "new" && fetchMssqlApi( `recipe-products/${id}`, {}, ingredients => ingredients ? setRecipeIngredients(ingredients) : setRecipeIngredients([]))
}
useEffect(() => {
    getVAT()
    getRecipeData()
}, [])
const getVATCode = (vatId) => {
    let code = null;

    if (vatId !== '' && allVat.find(obj => obj.VATId === vatId) !== undefined)
      code = allVat.find(obj => obj.VATId === vatId).Code

    return code;

}
const getVATId = (code) => {
    let vatId = null;

    if (code !== '' && allVat.find(obj => obj.Code === code) !== undefined)
      vatId = allVat.find(obj => obj.Code === code).VATId

    return vatId;

  }

// const getVATId = (value) => {
//     value = value.replace("%", "")
//     console.log(value)
//     console.log(allVat)
//     return allVat.find(vat => vat.Value == value).VATId
// }
const submitForm = evt => {
    evt.preventDefault()
    const {Ean, Name, vatId} = evt.target.elements
    const vat = vatId.value
    const formData = new FormData()
    formData.append("EAN", Ean.value)
    formData.append("Name", Name.value)
    formData.append("VatId", allVat.find(a => a.Code === vat.replace("%", "")).VATId)
    // console.log(Ean.value, Name.value, getVATId(vat))
    const payload = {
        EAN: Ean.value,
        Name: Name.value,
        VatId: getVATId(vat)
    }
    if(eans.indexOf(Ean.value) !== -1) {
        Ean.value = ""
        alert ("EAN musi być unikalny")
        return
    }
    form === "new" 
    ? fetchMssqlApi("/recipe", {method: "POST", data: payload}, res => console.log(res))
    : fetchMssqlApi(`/recipe/${form}`, {method: "PUT", data: payload}, res => console.log(res))
    handleClose()
    getRecipes()    
}


    return (
        <FormSkel headerText={recipeData ? "Zmień recepturę" : "Dodaj recepturę"}
        handleClose={handleClose}>

        <form onSubmit={submitForm} id="modal-form" autoComplete='off'>

        <div className='form-group'>
            <label className='h6'>EAN</label>
            <input
            name="Ean"
            className="form-control"
            disabled={form !== "new" }
            onFocus={clearInput}
            onChange={evt => evt.target.value = evt.target.value.replace(/\D/g, '')}
            required/>
        </div>
        <div className='form-group'>
            <label className='h6'>Nazwa</label>
            <input type="text"
            name="Name"
            className="form-control"
            onFocus={clearInput}
            required/>
        </div>
        <div className='form-group'>
        <label className="h6">Stawka VAT</label><br></br>
           <select
            required
            name="vatId"
            className="form-control"
            onChange={val => { const value = getVATId(val.target.value); setData(value) }}

            
          >
            {allVat.map(e => (
              <option key={e.VATId} value={getVATCode(e.VATId)} selected={+e.Code === recipeData?.VAT}>{e.Code + "%"}</option>
            ))}
          </select> 
        </div>
        </form>
        {
            form !== "new" && (
                <>
                {Object.keys(recipeIngredients).length && ( <label className='h6'>Składniki</label>) || ""}
                {recipeIngredients && recipeIngredients.map((ingredient, idx) => (
                        <IngredientForm key={idx} ingredient={ingredient} ingredientsList={ingredients} recipeId={recipeData?.RecipeId || "new"} closeForm={handleClose}/>
                    ))}
                    <button onClick={() => setRecipeIngredients(prev => [...prev, {}])} className='btn btn-success'>Dodaj składnik</button>
                </>
            )
        }
        </FormSkel>
    )   
}
