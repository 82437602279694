import React, { useContext, useState } from "react";
import { NotificationManager } from "react-notifications";
import { LangContext } from "../../../context/lang-context";
import useHttp from "../../../hooks/use-http";

import FormSkel from "../../../components/Modals/FormSkel";

const Other = () => {
    const { sendRequest } = useHttp();
    const { TRL_Pack } = useContext(LangContext);

    const [networkTestSpeedInfo, setNetworkTestSpeedInfo] = useState("TRL_Pack.other.waitForResponse");
    const [zerotierResetInfo, setZerotierResetInfo] = useState(null);
    const [volume, setVolume] = useState(50);

    const [showModal, setShowModal] = useState({
        networkTestSpeed: false,
        changeAllOfferPrices: false,
        zertoTierReset: false,
        zeroTierResponse: false
    });

    const handleClick = (e) => {
        e.persist();
        switch (e.target.id) {
            case "networkTest":
                sendRequest(
                    { url: "advanced-settings/test-network-speed", method: "GET" },
                    (response) => {
                        if (response.status === 200) {
                            setNetworkTestSpeedInfo(response.data.stdout[0]);
                            setShowModal({ ...showModal, networkTestSpeed: true });
                        }
                    }
                );
                break;
            case "deviceVolume":
                setVolume(e.target.value);
                break;
            case "deleteAllOfferPrices":
                sendRequest({ url: "machine-product/delete-all/discounted-price", method: "POST" }, (response) => {
                });
                break;
            case "changeAllOfferPrices":
                setShowModal({ ...showModal, changeAllOfferPrices: true });
                break;
            case "zeroTierReset":
                setShowModal({ ...showModal, zertoTierReset: true });
                break;
            case "forceSync":
                sendRequest({ url: "advanced-settings/force-sync", method: "POST" }, (response) => {
                    if (response.status === 200) {
                        console.log(response.ok);
                    }
                });
                break;
            default:
                NotificationManager.error(
                    TRL_Pack.advancedConfig.functionNotAvaiable,
                    null,
                    4000
                );
        }
    };

    const handleChange = (e) => {
        e.persist();

        switch (e.target.id) {
            case "deviceVolume":
                setVolume(e.target.value);
                sendRequest({ url: "volume", data: { volume: e.target.value }, method: "POST" }, (response) => {
                });
                break;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const { price } = e.target.elements;

        sendRequest({
            url: "machine-product/change-all/discounted-price",
            method: "POST",
            data: { Price: price.value }
        }, (response) => {
            if (response.status === 200) {
                setShowModal({ ...showModal, changeAllOfferPrices: false });
            }
        });
    };

    const ZeroTierResetModal = () => {
        const onSubmit = (e) => {
            e.persist();

            setShowModal({ ...showModal, zeroTierResponse: true, zertoTierReset: false });
            sendRequest({ url: "advanced-settings/reset-zerotier", method: "POST" }, (response) => {
                if (response.status === 200) {
                    setZerotierResetInfo(response.data.stdout);
                }
            });
        };

        return (
            showModal.zertoTierReset && (
                <FormSkel
                    headerText={TRL_Pack.other.vpnReset}
                    acceptForm={true}
                    handleClose={() => setShowModal({ ...showModal, zertoTierReset: false })}>
                    <div>
                        <form id="modal-form" onSubmit={onSubmit}>
                            <div>
                                <label>
                                    {TRL_Pack.other.vpnModalConfirmation}
                                    <label style={{ fontWeight: 'bold' }}>{TRL_Pack.other.vpnWarning}</label>
                                </label>
                            </div>
                        </form>
                    </div>
                </FormSkel>
            )
        );
    };

    const ZeroTierResponseModal = () => (
        showModal.zeroTierResponse && (
            <FormSkel
                headerText={TRL_Pack.other.vpnResponse}
                informationForm={true}
                handleClose={() => setShowModal({ ...showModal, zeroTierResponse: false })}>
                <form id="modal-form">
                    {zerotierResetInfo}
                </form>
            </FormSkel>
        )
    );

    const ChangeAllOfferPricesModal = () => (
        showModal.changeAllOfferPrices && (
            <FormSkel
                headerText={TRL_Pack.other.changeAllPricesToDiscount}
                handleClose={() => setShowModal({ ...showModal, changeAllOfferPrices: false })}
            >
                <div>
                    <form onSubmit={onSubmit} id="modal-form">
                        <div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm">
                                        <label htmlFor="price" className="form-label">
                                            {TRL_Pack.other.giveDiscountPrice}
                                        </label>
                                        <input type="number" step={0.01} defaultValue={0.20} id="price"
                                               className="form-control"></input>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </FormSkel>)
    );

    const NetworkTestSpeedModal = () => (
        showModal.networkTestSpeed && (
            <FormSkel
                headerText={TRL_Pack.other.networkSpeedTestSuccess}
                handleClose={() => setShowModal({ ...showModal, networkTestSpeed: false })}
                informationForm="true"
            >
                <div>
                    <form id="modal-form">
                        <div>
                            <label>
                                {TRL_Pack.other.downloadSpeed}: {networkTestSpeedInfo.split("_")[0]}{" "}
                                Mbps
                            </label>
                            <br/>
                            <label>
                                {TRL_Pack.other.uploadSpeed}: {networkTestSpeedInfo.split("_")[1]}{" "}
                                Mbps
                            </label>
                            <br/>
                            <label>
                                Ping: {networkTestSpeedInfo.split("_")[2]} ms
                            </label>
                        </div>
                    </form>
                </div>
            </FormSkel>
        )
    );

    const OtherUI = () => (
        <div>
            <div className="row">
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="networkTest" className="form-label">
                            {TRL_Pack.other.networkTest}
                        </label>
                        <input
                            type="button"
                            onClick={handleClick}
                            className="form-control"
                            defaultValue={TRL_Pack.other.testButton}
                            id="networkTest"
                        ></input>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <form>
                            <div className="form-group">
                                <label htmlFor="deviceVolume">
                                    {TRL_Pack.other.deviceVolume} [{volume}%]
                                </label>
                                <input
                                    type="range"
                                    value={volume}
                                    onChange={handleChange}
                                    className="form-control-range"
                                    step="10"
                                    id="deviceVolume"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="row">
                        <div className="col-sm">
                            <label className="form-label">
                                {TRL_Pack.other.changeAllPricesToDiscount}
                            </label>
                            <input
                                type="button"
                                id="changeAllOfferPrices"
                                className="form-control"
                                defaultChecked={false}
                                onClick={handleClick}
                                defaultValue={TRL_Pack.other.change}
                            />
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label className="form-label">
                                    {TRL_Pack.other.deleteAllDiscountPrices}
                                </label>
                                <input
                                    type="button"
                                    id="deleteAllOfferPrices"
                                    className="form-control"
                                    defaultChecked={false}
                                    onClick={handleClick}
                                    defaultValue={TRL_Pack.other.delete}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="rs232Test" className="form-label">
                            SELF-TEST
                        </label>
                        <input
                            type="button"
                            onClick={handleClick}
                            className="form-control"
                            defaultValue={TRL_Pack.other.testButton}
                            id="rs232Test"
                        ></input>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="form-group">
                        <label htmlFor="zeroTierReset" className="form-label">
                            {TRL_Pack.other.vpnReset}
                        </label>
                        <input
                            type="button"
                            onClick={handleClick}
                            className="form-control"
                            defaultValue={TRL_Pack.other.resetButton}
                            id="zeroTierReset"
                        ></input>
                    </div>
                </div>
                <div className="col-sm"></div>
            </div>
        </div>
    );

    return (
        <div className="row">
            <div className="col-12 mb-2">
                <ZeroTierResetModal />
                <ZeroTierResponseModal />
                <ChangeAllOfferPricesModal />
                <NetworkTestSpeedModal />
                <OtherUI />
            </div>
        </div>
    );
};

export default Other;