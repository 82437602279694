import React from 'react'
import FormSkel from './FormSkel'
import { useState } from 'react'

export default function WarehouseForm({data, handleClose, handleSubmit }) {
    const [name, setName] = useState(data ? data.Name : "")
    return (
        <FormSkel deleteDisable={data && data.WarehouseUsers} disableSubmit={!name} onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()} headerText={data ? "Edytuj magazyn" : "Nowy magazyn"} data={data} handleClose={handleClose}>
            <form autoComplete='off' id='modal-form' onSubmit={handleSubmit}>
            <div className='form-group'>
                    <label className='h6'>Nazwa</label>
                    <input
                        name = "name"
                        min={1}
                        onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        onFocus={() => setName("")}
                        className="form-control"
                        onChange={evt => setName(evt.target.value)}
                        value={name}/>

                </div>
            </form>
        </FormSkel>
    )
}
