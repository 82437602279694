import React, { useEffect, useState, useContext } from 'react'
import useFetch from '../../hooks/fetchMSSQL-hook'
import { Link, Prompt, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { NavigationContext } from '../../context/navigation-context'
import link from "../../assets/images/back_to_list_icon.svg"


export default function ST() {
    const [ST, setST] = useState({})
    const [initialST, setInitialZW] = useState({})
    const [items, setItems] = useState([])
    const [initialItems, setinitialItems] = useState([])
    const [warehouses, setWarehouses] = useState([])
    const [products, setProducts] = useState([])
    const [changed, setChanged] = useState(false)
    const { setHeaderData } = useContext(NavigationContext)
    const [availableToSave, setAvailableToSave] = useState(false)
    const {fetchMssqlApi} = useFetch()
    const param = useParams()
    const history = useHistory()
    const [closed, setClosed] = useState(false)

    const getWarehouses = () => {
        fetchMssqlApi('warehouses-list', {}, warehouses => setWarehouses(warehouses))
    }    
    const getProducts = () => {
        fetchMssqlApi('products-list', {}, products => setProducts(products))
    }
    const deleteItem = (id) => {
        const item = items.find(item => item.WarehouseOperationItemId === id)
        item.new ? setItems(prev => prev.filter(item => item.WarehouseOperationItemId !== id)) 
        : setItems(prev => prev.map(a => a.WarehouseOperationItemId === id ? ({...a, deleted: true}) : (a)))
    }
    const handleChangeItem = (id, evt) => {
        const name = evt.target.value
        setChanged(true)
        const product = products.find(pr => pr.Name === name)
        product ? evt.target.className = "form-control form-control-sm mx-auto mx-lg-0" : evt.target.className += " invalid-input"
        product
        ? setItems(prev => prev.map(item => {
            if(item.WarehouseOperationItemId === id) {return {
                ...item,
                Name: product.Name,
                ProductId: product.ProductId,
                Quantity: item.Quantity,
            }} else return item
            }))
            : setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...item, Name: name}) : (item)))
    }
    const handleChangeQuantity = (id, quantity) => {
        setChanged(true)
        setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({
            ...item,
            Quantity: quantity.replace(/[^0-9]/, ""),
        }) : item))
    }
    const defaultST = {
        WarehouseOperationId: null,
        ToWarehouseOperationId: null,
        WarehouseFullName: "",
        Comment: "",
        WarehouseOperationFullName: "",
        ToWarehouseOperationFullName: "",
        IssuedOn: moment().format('YYYY-MM-DD'),
        Created: ""
    }
    const getST = (id = param.id) => {
        id !== "0" ?
        fetchMssqlApi(`st/${id}`, {}, res => {
            res.WarehouseInventoryId ? setClosed(true) : setClosed(false)
            setinitialItems(res.Items)
            delete res.Items
            setInitialZW({...res, IssuedOn: moment(res.IssuedOn).format('YYYY-MM-DD')})
        }) : setInitialZW(defaultST)
    } 
    const random = () => { 
        const set = new Set(items.map(item => +item.WarehouseOperationItemId))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : String(a)
    }
    const newZWItem = {
        WarehouseOperationItemId: random(),
        Quantity: 0,
        Name: "",
        ProductId: null,
        new: true
    }
    const handleChangeWarehouse = (e) => {
        const evt = e.target
        setChanged(true)
        const warehouse = warehouses.find(data => data.Name === evt.value)
        if (!warehouse) {
        evt.className += " invalid-input"
        setST(prev => ({...prev, WarehouseFullName: evt.value}))
        } else {
        evt.className = "mt-2 form-control form-control-sm"
        setST(prev => ({...prev, WarehouseFullName: evt.value, WarehouseId: warehouse.WarehouseId}))
        }
    }
    const handleSubmit = () => {
        // console.log("ST: ", ST, "items: ", {...items, IssuedOn: moment(items.IssuedOn).format('YYYY-MM-DD HH:MM:SS:SSS')})
        const payload = {
            ...{...ST, IssuedOn: moment(ST.IssuedOn).format('YYYY-MM-DD HH:MM:SS.SSS')},
            Items: items.filter(item => !(item.deleted && item.new)).map(item => ({...item, new: item.new ? 1 : 0, deleted: item.deleted ? 1 : 0}))
        }
        if (param.id !== "0") {
            fetchMssqlApi(`st/${param.id}`, {method: "PUT", data: payload}, res => {
                setChanged(false)
                setinitialItems(res.Items)
                delete res.Items
                setInitialZW({...res, IssuedOn: moment(res.IssuedOn).format('YYYY-MM-DD')})
            })
        } else {
            fetchMssqlApi('st', {method: "POST", data: payload}, res => {
                setChanged(false)
                history.replace(res.id)
                getST(res.id) 
                
            })
        }
    }
    useEffect(() => {
        setHeaderData({text: "Strata"})
        getST()
        getWarehouses()
        getProducts()
    }, [])
    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])
    useEffect(() => {
        setST(initialST)
    }, [initialST])
    useEffect(() => {
        items.length
        && items.every(item => item.Name && item.ProductId)
        && ST.WarehouseId
        && ST.IssuedOn
        ? setAvailableToSave(true)
        : setAvailableToSave(false)
    }, [ST, items])
    return (
        param.id !== "0" ?  (
            <div>
                <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
                <div className='container-xl'>
                {/* <Link to='/sts'>
                    <button className="btn btn-link ml-1 text-decoration-none mb-3">
                        <i className="fas fa-arrow-left mr-2" />
                        Wróć
                    </button>
                </Link> */}
                <Link to='/sts'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={link}></img>
                    </label>
                </Link>
                <div className='card'>
                    <div className='card-header'>
                        <div className='h5 mt-2'>
                            {ST.WarehouseOperationFullName}
                        </div>
                    </div>    
                        <table className="table table-striped">
                            <tbody>
                            <tr className='justify-content-center'>
                            <td className='text-center'>Stworzony</td>
                            <td className='text-center'>Data wystawienia</td>
                            <td className='text-center'>Magazyn</td>
                            </tr>
                            <tr >
                            <td>
                                <div className='d-flex justify-content-center'>
                                    {ST.Created && `${moment(ST.Created.split(" ")[0]).format("DD.MM.YYYY")} ${ST.Created.split(" ").at(-1)}`}
                                </div>
                            </td>
                            
                            <td>
                                <div className='d-flex justify-content-center'>
                                    <input
                                        className={`mt-2 form-control form-control-sm ${!ST.IssuedOn && `invalid-input`}`}
                                        type='date'
                                        value={ST.IssuedOn}
                                        style={{maxWidth: "30vh"}}
                                        onChange={evt => {setChanged(true); const value = evt.target.value ;setST(prev => ({...prev, IssuedOn: value || ""}))}}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='d-flex justify-content-center'>
                                    <input
                                        value={ST.WarehouseFullName}
                                        datalist='warehouses'
                                        placeholder='Wybierz...'
                                        className=  {`mt-2 form-control ${!ST.WarehouseFullName && `invalid-input`} form-control-sm`}
                                        min={1}
                                        style={{maxWidth: "30vh"}}
                                        list='warehouses'
                                        onFocus={() => {setChanged(true); setST(prev => ({...prev, WarehouseFullName: "", WarehouseId: null}))}}
                                        onChange={handleChangeWarehouse}
                                    />
                                    <datalist id='warehouses'>
                                    {warehouses && warehouses.map((warehouse, idx) => (
                                        <option value={warehouse.Name} key={idx}/>
                                    ))}
                                </datalist>
                                </div>
                            </td>
                            </tr>
    
    
                            <tr className='justify-content-center'>
                            <td className='text-center' colSpan={2}>Komentarz / dodatkowe informacje</td>
                            <td className='text-center'>Dokument powiązany</td>
                            </tr>
    
    
                            <tr className='justify-content-center'>
                            <td colSpan={2}>
                                <div className='d-flex justify-content-center'>
                                    <textarea
                                        value={ST.Comment || ""}
                                        cols={70}
                                        rows={3}
                                        onChange={evt => {setChanged(true); const value = evt.target.value; setST(prev => ({...prev, Comment: value}))}}
                                    />
                                </div>
                            </td>
                            <td className='text-center'>
                                {ST.ToWarehouseOperationId &&  <div className="text-nowrap"><Link to={`/wz/${ST.ToWarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{ST.ToWarehouseOperationFullName}</Link></div>}
                            </td>
                            </tr>
                            {closed && (
                                <tr>
                                    <td colSpan={4} className='text-center'>

                                    Inwentaryzacja
                                    </td>
                                </tr>
                            )}
                            {closed && (
                                <tr>
                                <td colSpan={4}>
                                    <div className='text-center mt-1'>
                                        <Link
                                        to={`/in/${ST.WarehouseInventoryId}`}
                                            className="btn btn-outline-success"
                                            // disabled
                                        >
                                            {ST.WarehouseInventory}
                                        </Link>
                                    </div>
                                </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                </div>
    
                    <div className='card' style={{marginBottom: "40px", marginTop: "40px"}}>
                    {items.length ?
                    ( <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th>Produkt</th>
                                <th>Ilość</th>
                                <th>
                                    {!closed && (
                                        <div>
                                            <button
                                                className="d-block btn btn-link text-decoration-none ml-auto"
                                                onClick={() => {setChanged(true); setItems(prev => [{...newZWItem, Name: ""}, ...prev]);}}
                                            >
                                                <i className="fas fa-plus fa-sm" />
                                            </button>
                                        </div>
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, idx) => 
                            !item.deleted && 
                            (
                                <tr key={idx}>
                                    <td>
                                        <input
                                            disabled={closed}
                                            style={{maxWidth: "70vh"}}
                                            className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                            value={item.Name}
                                            onChange={evt => {setChanged(true); handleChangeItem(item.WarehouseOperationItemId, evt)}}
                                            list='products'
                                            onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Name: "", ProductId: ""}) : pr))}}
                                        />
                                        <datalist id='products'>
                                        {products && products.map((product, idx) => (<option key={idx} value={product.Name} />))}
                                        </datalist>
                                    </td>
                                    <td>
                                        <input
                                            disabled={closed}
                                            type='text'
                                            className={`form-control ${(!item.Quantity || item.Quantity == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                            value={item.Quantity}
                                            onChange={evt => {setChanged(true); handleChangeQuantity(item.WarehouseOperationItemId, evt.target.value)}}
                                            onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Quantity: ""}) : pr))}}
                                        />
                                    </td>
                                    <td>
                                        {!closed && (
                                            <div className='d-flex justify-content-end'>
                                                
                                            <button className="btn btn-link btn-sm"
                                                onClick={() => {setChanged(true); deleteItem(item.WarehouseOperationItemId)}} 
                                            >
                                            <i className="fas fa-trash" />                                
                                            </button>
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody>
                        </table>)
                        :
                        <>
                        <div>
                            {!closed && (
                            <button
                                className="d-block btn btn-link text-decoration-none ml-auto"
                                onClick={() => {setChanged(true); setItems(prev => [{...newZWItem, Name: ""}, ...prev]);}}
                            >
                                <i className="fas fa-plus fa-sm" />
                            </button>
                            )}
                        </div>
                        <div className='card'>
                            <div className='d-inline-flex justify-content-center align-items-center card-header'>
                                Dokument nie ma produktów
                            </div>
                        </div>
                        </>
                    }
                    </div>
                    
                </div>
                <div className='position-fixed container' style={{bottom: "0px"}}>
    
                        <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                            <button disabled={!changed || !availableToSave} className={`btn btn-primary w-20  btn-sm`} 
                                onClick={handleSubmit}
                                >
                                Zapisz
                            </button>
                        </div>
                    </div>
            </div>
    
            ) : (
                <div>
                <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
                <div className='container-xl'>
                <Link to='/zws'>
                    <button className="btn btn-link ml-1 text-decoration-none mb-3">
                        <i className="fas fa-arrow-left mr-2" />
                        Wróć
                    </button>
                </Link>
                <div className='card'>
                    <div className='card-header'>
                        <div className='h5 mt-2'>
                            Nowa strata
                        </div>
                    </div>    
                        <table className="table table-striped">
                            <tbody>
                            <tr className='justify-content-center'>
                            <td className='text-center'>Data wystawienia</td>
                            <td>Magazyn</td>
                            <td className='text-center' colSpan={1}>Komentarz / dodatkowe informacje</td>
                            </tr>
    
    
                            <tr >
                            <td>
                                <div className='d-flex justify-content-center'>
                                    <input
                                        className={`mt-2 form-control form-control-sm ${!ST.IssuedOn && `invalid-input`}`}
                                        type='date'
                                        value={ST.IssuedOn}
                                        style={{maxWidth: "30vh"}}
                                        onChange={evt => {setChanged(true); const value = evt.target.value ;setST(prev => ({...prev, IssuedOn: value || ""}))}}
                                    />
                                </div>
                            </td>
                            <td>
                                <input
                                value={ST.WarehouseFullName}
                                datalist='warehouses'
                                placeholder='Wybierz...'
                                className=  {`mt-2 form-control ${!ST.WarehouseFullName && `invalid-input`} form-control-sm`}
                                min={1}
                                list='warehouses'
                                onFocus={() => {setChanged(true); setST(prev => ({...prev, WarehouseFullName: "", WarehouseId: null}))}}
                                onChange={handleChangeWarehouse}
                                />
                                <datalist id='warehouses'>
                                {warehouses && warehouses.map((warehouse, idx) => (
                                    <option value={warehouse.Name} key={idx}/>
                                ))}
                                </datalist>
                            </td>
                            <td colSpan={1}>
                                <div className='d-flex justify-content-center'>
                                    <textarea
                                        value={ST.Comment || ""}
                                        cols={70}
                                        rows={5}
                                        onChange={evt => {setChanged(true); const value = evt.target.value; setST(prev => ({...prev, Comment: value}))}}
                                    />
                                </div>
                            </td>
                            </tr>
    
    

                            </tbody>
                        </table>
                </div>
    
                    <div className='card' style={{marginBottom: "40px", marginTop: "40px"}}>
                    {items.length ?
                    ( <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th>Produkt</th>
                                <th>Ilość</th>
                                <th>
                                    <div>
                                        <button
                                            className="d-block btn btn-link text-decoration-none ml-auto"
                                            onClick={() => {setChanged(true); setItems(prev => [{...newZWItem, Name: ""}, ...prev]);}}
                                        >
                                            <i className="fas fa-plus fa-sm" />
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, idx) => 
                            !item.deleted && 
                            (
                                <tr key={idx}>
                                    <td>
                                        <input
                                            style={{maxWidth: "70vh"}}
                                            className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                            value={item.Name}
                                            onChange={evt => {setChanged(true); handleChangeItem(item.WarehouseOperationItemId, evt)}}
                                            list='products'
                                            onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Name: "", ProductId: ""}) : pr))}}
                                        />
                                        <datalist id='products'>
                                        {products && products.map((product, idx) => (<option key={idx} value={product.Name} />))}
                                        </datalist>
                                    </td>
                                    <td>
                                        <input
                                            type='text'
                                            className={`form-control ${(!item.Quantity || item.Quantity == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                            value={item.Quantity}
                                            onChange={evt => {setChanged(true); handleChangeQuantity(item.WarehouseOperationItemId, evt.target.value)}}
                                            onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Quantity: ""}) : pr))}}
                                        />
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-end'>
                                            
                                        <button className="btn btn-link btn-sm" 
                                            onClick={() => {setChanged(true); deleteItem(item.WarehouseOperationItemId)}}
                                        >
                                        <i className="fas fa-trash" />                                
                                        </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody>
                        </table>)
                        :
                        <>
                        <div>
                            <button
                                className="d-block btn btn-link text-decoration-none ml-auto"
                                onClick={() => {setChanged(true); setItems(prev => [{...newZWItem, Name: ""}, ...prev]);}}
                            >
                                <i className="fas fa-plus fa-sm" />
                            </button>
                        </div>
                        <div className='card'>
                            <div className='d-inline-flex justify-content-center align-items-center card-header'>
                                Dokument nie ma produktów
                            </div>
                        </div>
                        </>
                    }
                    </div>
                    
                </div>
                <div className='position-fixed container' style={{bottom: "0px"}}>
    
                        <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                            <button disabled={!changed || !availableToSave} className={`btn btn-primary w-20  btn-sm`} 
                                onClick={handleSubmit}
                                >
                                Zapisz
                            </button>
                        </div>
                    </div>
            </div>
            )
    )
}
