import React from 'react';
import PropTypes from 'prop-types';

const Table = ({children, className}) => {
    return (
        <div className="overflow-auto">
            <table className={`table table-striped ${className}`}>
                {children}
            </table>
        </div>
    );
}

Table.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

export default Table;