import React, { useState, useContext,useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import moment from 'moment'
import { LangContext } from '../../../context/lang-context'
import PrepaidReports from './PrepaidReports'
import axios from 'axios'
import { API_URL } from '../../../config/config'
import { NotificationManager } from 'react-notifications'


export default function PrepaidFilter({search, toggleFilter, visible = true}) {
    const {type} = useParams(),
    { TRL_Pack } = useContext(LangContext),
    { fetchMssqlApi } = fetchHook(),
    [prepaidGroups, setPrepaidGroups] = useState([]),
    [prepaids, setPrepaids] = useState([]),
    [types, setTypes] = useState([]),
    [statuses, setStatuses] = useState([]),
    [timespans, setTimespans] = useState([]),
    [customers, setCustomers] = useState([]),
    [terminalList, setTerminalList] = useState([]),
    [machineList, setMachineList] = useState([]),

    defaultFilter = 
        type === "cards" && {
            PrepaidName: "",
            PrintedCode: "",
            PrepaidCode: "",
            GroupName: "",
            StatusName: "",
            CardType: "",
        } ||
        type === "groups" && {
            GroupName: "",
            Timespan: "",
            Enabled: "null",
            IsActive: "true",
            OverwriteCredit: "null"
        } ||
        type === "transactions" && {
            Terminal: "",
            Automat: "",
            IsSuccess: "null",
            Price: "",
            ProductNo: "",
            StartDateTime: moment().add(-7, "d").format("YYYY-MM-DD 00:00:00.000"),
            EndDateTime: moment().format('YYYY-MM-DD 23:59:59.000'),
            PrepaidName: "",
            GroupName: "",
            TerminalId: null,
            MachineId: null,
            PrepaidId: null
        },
    [query, setQuery] = useState(defaultFilter)
    // useEffect(() => {
    //     localStorage.getItem('prepaidsFilter') && localStorage.removeItem('prepaidsFilter')  
    // }, [])
    // useEffect(() => {
    //     localStorage.setItem('prepaidsFilter', JSON.stringify(query))
    // }, [query])

    // ==========================================

    const getPrepaids = () => {
        fetchMssqlApi('prepaids', {}, res => res.length && setPrepaids(res))
    }
    const getPrepaidGroups = () => {
        fetchMssqlApi('prepaid-groups', {}, res => res.length && setPrepaidGroups(res.sort((a, b) => {
            if (String(a.PrepaidGroupName).toLowerCase() < String(b.PrepaidGroupName).toLowerCase()) {
                return -1;
            }
            if (String(a.PrepaidGroupName).toLowerCase() > String(b.PrepaidGroupName).toLowerCase()) {
                return 1;
            }
            return 0;
        })))
    }
    const getPrepaidTypes = () => {
        fetchMssqlApi('prepaid-types', {}, res => res.length && setTypes(res))
    }
    const getPrepaidStatuses = () => {
        fetchMssqlApi('prepaid-status', {}, res => res.length && setStatuses(res))
    }
    const getTimespans = () => {
        fetchMssqlApi('prepaid-timespans', {}, res => res.length && setTimespans(res))
    }
    const getMachines = () => {
        fetchMssqlApi('machine-list', {
            method: 'POST',
            hideNotification: true,
            data: {
                customerAbbr: localStorage.getItem('clientId'),
            }
        }, machines => setMachineList(machines))
    }
    const getCustomers = () => {
        localStorage.getItem("clientId") === "console" && fetchMssqlApi('customers', {}, res => res.length && setCustomers(res))
    }
    const getTerminals = () => {
        fetchMssqlApi('terminal-list', {method: "POST", data: {customerAbbr: localStorage.getItem('clientId')}, hideNotification: true}, terminals => setTerminalList(terminals))
    }
    useEffect(() => {
        getCustomers()
        getPrepaidGroups()
        switch (type) {
            case "cards":
                getPrepaids()
                getPrepaidTypes()
                getPrepaidStatuses()
                break;
            case "groups":
                getTimespans()
                break;
            case "transactions":
                getPrepaids()
                getTerminals()
                getMachines()
                break;
            default:
                break;
        }
    }, [])
    // ==========================================

    // ==========================================
    const handleChangePrepaid = (name) => {
        const prepaid = prepaids && prepaids.find(prepaid => prepaid.OwnerData && prepaid.OwnerData === name)
        if (prepaid) {
            return prepaid.PrepaidId
        } 
    }
    const handleChangePrepaidByPrintedCode = (name) => {
        const prepaid = prepaids && prepaids.find(prepaid => prepaid.PrintedCode === name)
        if (prepaid) {
            return prepaid.PrepaidId
        } 
    }
    const handleChangePrepaidByCode = (code) => {
        const prepaid = prepaids && prepaids.find(prepaid => prepaid.Code === code)
        if (prepaid) {
            return prepaid.PrepaidId
        } 
    }

    const handleChangePrepaidPrintedCode = (printedCode) => {
        const prepaid = prepaids && prepaids.find(prepaid => prepaid.PrintedCode === printedCode)
        if (prepaid) {
            return prepaid.PrepaidId
        } 
    }
    const handleChangePrepaidCode = (code) => {
        const prepaid = prepaids && prepaids.find(prepaid => prepaid.Code === code)
        if (prepaid) {
            return prepaid.PrepaidId
        } 
    }
    const handleChangePrepaidGroup = (group) => {
        const prepaidGroup = prepaidGroups && prepaidGroups.find(prepaidGroup => prepaidGroup.PrepaidGroupName === group)
        if (prepaidGroup) {
            return prepaidGroup.PrepaidGroupId
        } 
    }
    const handleChangePrepaidStatus = (status) => {
        const prepaidStatus = statuses && statuses.find(prepaidStatus => prepaidStatus.Name === status)
        if (prepaidStatus) {
            return prepaidStatus.PrepaidStatusId
        } 
    }
    const handleChangePrepaidType = (type) => {
        const prepaidType = types && types.find(prepaidGroup => prepaidGroup.Name === type)
        if (prepaidType) {
            return prepaidType.PrepaidTypeId
        } 
    }
    const handleChangeTimespan = (data) => {
        const timespan = timespans && timespans.find(timespan => timespan.Name === data)
        if (timespan) {
            return timespan.PrepaidTimespanId
        } 
    }
    const handleChangeCustomer = (data) => {
        const customer = customers && customers.find(customer => `${customer.Name} (${customer.Abbreviation})` === data)
        if (customer) {
            return customer.Abbreviation
        } 
    }
    const handleChangeTerminal = (data) => {
        const terminal = terminalList && terminalList.find(terminal => terminal.Name === data)
        if (terminal) {
            // localStorage.setItem('prepaidsFilter',JSON.stringify({...JSON.parse(localStorage.getItem('prepaidsFilter')), TerminalId: terminal.TerminalId}))
            return terminal.TerminalId
        } 
    }
    const handleChangeMachine = (data) => {
        const machine = machineList && machineList.find(machine => `${machine.MachineName} (${machine.SerialNo})` === data)
        if (machine) {
            // localStorage.setItem('prepaidsFilter',JSON.stringify({...JSON.parse(localStorage.getItem('prepaidsFilter')), MachineId: machine.MachineId}))
            return machine.MachineId
        } 
    }
    const handleFocus = (evt) => {
        const {name} = evt.target
        // switch (name) {
        //     case "Terminal":
        //         localStorage.setItem('prepaidsFilter',JSON.stringify({...JSON.parse(localStorage.getItem('prepaidsFilter')), TerminalId: null}))
        //         break;
        //     case "Automat":
        //         localStorage.setItem('prepaidsFilter',JSON.stringify({...JSON.parse(localStorage.getItem('prepaidsFilter')), MachineId: null}))
        // }
        setQuery(prev => ({...prev, [name]: ""}))
    } 
    const handleChangeTime = (evt) => {
        const {name} = evt.target
        setQuery(prev => ({...prev, 
            StartDateTime: (function() {
                switch (name) {
                    case "Today":
                        return moment().format('YYYY-MM-DDT00:00:00')
                    case "LastWeek":
                        return moment().add(-7, 'd').format('YYYY-MM-DDT00:00:00')
                    case "LastMonth":
                        return moment().add(-1, 'M').format('YYYY-MM-DDT00:00:00')
                    case "Whenever":
                        return moment().add(-20, 'y').format('YYYY-MM-DDT00:00:00');
                    case "ActualMonth":
                        return moment().startOf("month").format('YYYY-MM-DDT00:00:00');
                    default: break;
                } 
            })(),
            EndDateTime: moment().format('YYYY-MM-DDT23:59:59')
        }))
        
    }
    const handleChange = (evt) => {
        const {name, value} = evt.target
        switch (name) {
            case "StartDateTime":
            case "EndDateTime":
                setQuery(prev => ({...prev, [name]: moment(value).format('YYYY-MM-DDTHH:mm:ss')}))
                break;
            default:
                setQuery(prev => ({...prev, [name]: value}))
                break
        }
    }
    const handleSubmit = () => {
        const payload = {}
        if (type === "cards") {
            for (let value in query) {
                switch (value) {
                    case "PrepaidName":
                        payload.PrepaidId = handleChangePrepaid(query[value])
                        break;
                    case "PrepaidPrintedCode":
                        if(!payload.PrepaidId) payload.PrepaidId = handleChangePrepaidPrintedCode(query[value])
                        break;
                    case "PrepaidCode":
                        if(!payload.PrepaidId) payload.PrepaidId = handleChangePrepaidCode(query[value])
                        break;
                    case "GroupName":
                        payload.PrepaidGroupId = handleChangePrepaidGroup(query[value])
                        break;
                    case "StatusName":
                        payload.PrepaidStatusId = handleChangePrepaidStatus(query[value])
                        break;
                    case "CardType":
                        payload.PrepaidTypeId = handleChangePrepaidType(query[value])
                        break;
                    case "CustomerName":
                        payload.CustomerAbbr = handleChangeCustomer(query[value])
                        break;
                }
            }
            
        } else if (type === "groups") {
            for (let value in query) {
                switch (value) {
                    case "GroupName":
                        payload.PrepaidGroupId = handleChangePrepaidGroup(query[value])
                        break;
                    case "Timespan":
                        payload.TimespanId = handleChangeTimespan(query[value])
                        break;
                    case "Enabled":
                    case "IsActive":
                    case "OverwriteCredit":
                        payload[value] = query[value] === "true" && true || query[value] === "false" && false || query[value] === "null" && null
                        break;
                    case "CustomerName":
                        payload.CustomerAbbr = handleChangeCustomer(query[value])
                        break;
                }
            }
        } else if (type === "transactions") {
            for (let value in query) {
                switch (value) {
                    case "PrepaidName":
                        payload.PrepaidId = handleChangePrepaid(query[value])
                        break;
                    case "PrepaidPrintedCode":
                        if(!payload.PrepaidId) payload.PrepaidId = handleChangePrepaidByPrintedCode(query[value])
                        break;
                    case "PrepaidCode":
                        if(!payload.PrepaidId) payload.PrepaidId = handleChangePrepaidByCode(query[value])
                        break;
                    case "GroupName":
                        payload.PrepaidGroupId = handleChangePrepaidGroup(query[value])
                        break;
                    case "Terminal":
                        payload.TerminalId = handleChangeTerminal(query[value])
                        break;
                    case "Automat":
                        payload.MachineId = handleChangeMachine(query[value])
                        break;
                    case "Price":
                        payload.Price = query.Price ? parseFloat(query.Price) : null
                        break;
                    case "ProductNo":
                        payload.ProductNo = query.ProductNo ? query.ProductNo : null
                        break;
                    case "IsSuccess":
                        payload.IsSuccess = query[value] === "true" && true || query[value] === "false" && false || query[value] === "null" && null
                        break;
                    case "StartDateTime":
                        payload.StartDateTime = moment(query.StartDateTime).add(-2, 'h').format('YYYY-MM-DDTHH:mm:ss')
                        break; 
                    case "EndDateTime":
                        payload.EndDateTime = moment(query.EndDateTime).add(-2, 'h').format('YYYY-MM-DDTHH:mm:ss')
                        break;
                    case "CustomerName":
                        payload.CustomerAbbr = handleChangeCustomer(query[value])
                        break;
                    default: 
                        break;
                }
            }
        }
        search(payload)
    }

    // ==========================================
    const export87 = (set87) => {
        set87(true)
        // const query = JSON.parse(localStorage.getItem('prepaidsFilter'))
        console.log(query)
        axios({
            method: "POST",
            url: `${API_URL}/api/reports/prepaid-trxs`,
            data:
            //  query?
             { 
                reportId: parseInt(87),
                fromCreateDateTime: moment(query.StartDateTime).add(1, 'h').format('YYYY-MM-DD HH:mm:ss') || moment().add(-1, "M").format("YYYY-MM-DD 00:00:00"),
                toCreateDateTime: moment(query.EndDateTime).add(1, 'h').format('YYYY-MM-DD HH:mm:ss') || moment().format("YYYY-MM-DD 23:59:59"),
                terminalId: handleChangeTerminal(query.Terminal),
                machineId: handleChangeMachine(query.Automat) ,
                // ownerData:  handleChangeCustomer(query.PrepaidName)  ,
                // printedCode: handleChangePrepaidByPrintedCode(query.PrepaidPrintedCode),
                // code: handleChangePrepaidByCode(query.PrepaidCode)  ,
                prepaidId: handleChangePrepaid(query.PrepaidName) || handleChangePrepaidByPrintedCode(query.PrepaidPrintedCode) ||  handleChangePrepaidByCode(query.PrepaidCode) ,
                productNo: query.ProductNo || null, 
                trxStatus: query.IsSuccess === "true" && true ||  query.IsSuccess  === "false" && false ||  query.IsSuccess  === "null" && null,
                prepaidGroupId: handleChangePrepaidGroup(query.GroupName)
            } 
            // : {
            //     reportId: parseInt(87),
            //     fromCreateDateTime:  moment().add(-1, "M").format("YYYY-MM-DD 00:00:00"),
            //     toCreateDateTime:  moment().format("YYYY-MM-DD 23:59:59"),
            // }
            ,
            responseType:'blob'
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `DZ87_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            set87(false)
        }).catch(err => {
            set87(false)
            if (err.response.data.message === "jwt malformed") window.location.reload();
            else NotificationManager.error(err.response?.data || err.toString())
        })
    
    }
    const export86 = (set86) => {
        set86(true)
        // const query = JSON.parse(localStorage.getItem('prepaidsFilter'))
        console.log(query)
        axios({
            method: "POST",
            url: `${API_URL}/api/reports/prepaid-trxs`,
            data:
            //  query ? 
            { 
                reportId: parseInt(86),
                fromCreateDateTime: moment(query.StartDateTime).add(1, 'h').format('YYYY-MM-DD HH:mm:ss') || moment().add(-1, "M").format("YYYY-MM-DD 00:00:00"),
                toCreateDateTime: moment(query.EndDateTime).add(1, 'h').format('YYYY-MM-DD HH:mm:ss') || moment().format("YYYY-MM-DD 23:59:59"),
                terminalId: handleChangeTerminal(query.Terminal),
                machineId: handleChangeMachine(query.Automat) ,
                // ownerData:  handleChangeCustomer(query.PrepaidName)  ,
                // printedCode: handleChangePrepaidByPrintedCode(query.PrepaidPrintedCode),
                // code: handleChangePrepaidByCode(query.PrepaidCode)  ,
                prepaidId: handleChangePrepaid(query.PrepaidName) || handleChangePrepaidByPrintedCode(query.PrepaidPrintedCode) ||  handleChangePrepaidByCode(query.PrepaidCode) ,
                productNo: query.ProductNo || null, 
                trxStatus: query.IsSuccess === "true" && true ||  query.IsSuccess  === "false" && false ||  query.IsSuccess  === "null" && null,
                prepaidGroupId: handleChangePrepaidGroup(query.GroupName)
            } 
            // : {
            //     reportId: parseInt(87),
            //     fromCreateDateTime:  moment().add(-1, "M").format("YYYY-MM-DD 00:00:00"),
            //     toCreateDateTime:  moment().format("YYYY-MM-DD 23:59:59"),
            // }
            ,
            responseType:'blob'
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `DZ86_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            set86(false)
        }).catch(err => {
            set86(false)
            if (err.response.data.message === "jwt malformed") window.location.reload();
            else NotificationManager.error(err.response?.data || err.toString())
        })
    
    }
    const export88 = (set88) => {
        set88(true)
        // const query = JSON.parse(localStorage.getItem('prepaidsFilter'))
        console.log(query)
        axios({
            method: "POST",
            url: `${API_URL}/api/reports/prepaid-trxs`,
            data:
            //  query ? 

            { 
                reportId: parseInt(88),
                fromCreateDateTime: moment(query.StartDateTime).add(1, 'h').format('YYYY-MM-DD HH:mm:ss') || moment().add(-1, "M").format("YYYY-MM-DD 00:00:00"),
                toCreateDateTime: moment(query.EndDateTime).add(1, 'h').format('YYYY-MM-DD HH:mm:ss') || moment().format("YYYY-MM-DD 23:59:59"),
                terminalId: handleChangeTerminal(query.Terminal),
                machineId: handleChangeMachine(query.Automat) ,
                // ownerData:  handleChangeCustomer(query.PrepaidName)  ,
                // printedCode: handleChangePrepaidByPrintedCode(query.PrepaidPrintedCode),
                // code: handleChangePrepaidByCode(query.PrepaidCode)  ,
                prepaidId: handleChangePrepaid(query.PrepaidName) || handleChangePrepaidByPrintedCode(query.PrepaidPrintedCode) ||  handleChangePrepaidByCode(query.PrepaidCode) || null,
                productNo: query.ProductNo || null, 
                trxStatus: query.IsSuccess === "true" && true ||  query.IsSuccess  === "false" && false ||  query.IsSuccess  === "null" && null,
                prepaidGroupId: handleChangePrepaidGroup(query.GroupName)
            } 
            // : {
            //     reportId: parseInt(87),
            //     fromCreateDateTime:  moment().add(-1, "M").format("YYYY-MM-DD 00:00:00"),
            //     toCreateDateTime:  moment().format("YYYY-MM-DD 23:59:59"),
            // }
            ,
            responseType:'blob'
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `DZ88_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            set88(false)
        }).catch(err => {
            console.log(err)
            set88(false)
            if (err.response.data.message === "jwt malformed") window.location.reload();
            else NotificationManager.error(err.response?.data || err.toString())
        })
    
    }
    // ==========================================

    const prepaidFilterReducer = (state, action) => {
        switch (action.type) {
            case "getPrepaidIdByOwnerData": {
                return handleChangePrepaid(query.PrepaidName)
            }
        }
    }
    const [state, reducer] = useReducer(prepaidFilterReducer, {query})
    // ==========================================



    return (
        <div>
        <div className="mb-4 rounded border bg-fade p-3 user-select-none" style={{display: visible ? "block" : "none"}}>
            <div>
            <div className="row px-3 d-flex justify-content-around">
                {type === "cards" && (
                    <>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Właściciel karty prepaid</h6>
                            <input
                                type='text'
                                name='PrepaidName'
                                value={query.PrepaidName || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='prepaidNames'
                                placeholder='Szukaj...'
                            />
                            <datalist id='prepaidNames'>
                                    {prepaids && prepaids.sort((a, b) => {
                                        if (String(a.OwnerData).toLowerCase() < String(b.OwnerData).toLowerCase()) {
                                            return -1;
                                        }
                                        if (String(a.OwnerData).toLowerCase() > String(b.OwnerData).toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((prepaid, idx) => (
                                        <option key={idx} value={prepaid.OwnerData}/>
                                    ))}
                                </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Nadrukowany numer karty</h6>
                            <input
                                type='text'
                                name='PrepaidPrintedCode'
                                value={query.PrepaidPrintedCode || ""} 
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='printedCodes'
                                placeholder='Szukaj...'
                            />
                            <datalist id='printedCodes'>
                                {prepaids && prepaids.sort((a, b) => {
                                    if (String(a.PrintedCode).toLowerCase() < String(b.PrintedCode).toLowerCase()) {
                                        return -1;
                                    }
                                    if (String(a.PrintedCode).toLowerCase() > String(b.PrintedCode).toLowerCase()) {
                                        return 1;
                                    }
                                    return 0;
                                }).map((prepaid, idx) => (
                                    <option key={idx} value={prepaid.PrintedCode}/>
                                ))}
                            </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Wewnętrzny numer karty</h6>
                            <input
                                type='text'
                                name='PrepaidCode'
                                value={query.PrepaidCode || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='prepaidCodes'
                                placeholder='Szukaj...'
                            />
                            <datalist id='prepaidCodes'>
                                {prepaids && prepaids.sort((a, b) => {
                                    if (String(a.Code).toLowerCase() < String(b.Code).toLowerCase()) {
                                        return -1;
                                    }
                                    if (String(a.Code).toLowerCase() > String(b.Code).toLowerCase()) {
                                        return 1;
                                    }
                                    return 0;   
                                }).map((prepaid, idx) => (
                                    <option key={idx} value={prepaid.Code}/>
                                ))}
                            </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Grupa prepaid</h6>
                            <input
                                type='text'
                                name='GroupName'
                                value={query.GroupName || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='groupsList'
                                placeholder='Szukaj...'
                            />
                            <datalist id='groupsList'>
                                {prepaidGroups && prepaidGroups.map((group, idx) => (
                                    <option key={idx} value={group.PrepaidGroupName}/>
                                ))}
                            </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Status</h6>
                            <input
                                type='text'
                                name='StatusName'
                                value={query.StatusName || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='statusesList'
                                placeholder='Szukaj...'
                            />
                            <datalist id='statusesList'>
                                {statuses && statuses.map((status, idx) => (
                                    <option key={idx} value={status.Name}/>
                                ))}
                            </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Typ karty prepaid</h6>
                            <input
                                type='text'
                                name='CardType'
                                value={query.CardType || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='typesList'
                                placeholder='Szukaj...'
                            />
                            <datalist id='typesList'>
                                {types && types.map((type, idx) => (
                                    <option key={idx} value={type.Name}/>
                                ))}
                            </datalist>
                        </div>
                    </>
                )}
                {
                    type === "groups" && (
                        <>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Nazwa</h6>
                            <input
                                type='text'
                                name='GroupName'
                                value={query.GroupName || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='groupsList'
                                placeholder='Szukaj...'
                            />
                            <datalist id='groupsList'>
                                {prepaidGroups && prepaidGroups.map((group, idx) => (
                                    <option key={idx} value={group.PrepaidGroupName}/>
                                ))}
                            </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Częstotliwość zasilania</h6>
                            <input
                                type='text'
                                name='Timespan'
                                value={query.Timespan || ""}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                list='timespanList'
                                placeholder='Szukaj...'
                            />
                            <datalist id='timespanList'>
                                {timespans && timespans.map((timespan, idx) => (
                                    <option key={idx} value={timespan.Name}/>
                                ))}
                            </datalist>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Aktywna</h6>
                            <select
                                name='IsActive'
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                value={query.IsActive || "null"}
                            >
                                <option value={"null"}>Wybierz</option>
                                <option value={"true"} >Tak</option>
                                <option value={"false"} >Nie</option>
                            </select>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Włączona</h6>
                            <select
                                name='Enabled'
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                value={query.Enabled || "null"}
                            >
                                <option value={"null"} >Wybierz</option>
                                <option value={"true"} >Tak</option>
                                <option value={"false"} >Nie</option>
                            </select>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        >
                            <h6 className="text-center mb-0">Nadpisywanie środków</h6>
                            <select
                                name='OverwriteCredit'
                                onChange={handleChange}
                                className='mt-2 form-control form-control-sm'
                                value={query.OverwriteCredit || "null"}
                            >
                                <option value={"null"} >Wybierz</option>
                                <option value={"true"} >Tak</option>
                                <option value={"false"} >Nie</option>
                            </select>
                        </div>
                        </>
                    )
                } 
                {
                    type === "transactions" && (
                        <>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Właściciel karty prepaid</h6>
                                <input
                                    type='text'
                                    name='PrepaidName'
                                    value={query.PrepaidName || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    list='prepaidNames'
                                    placeholder='Szukaj...'
                                />
                                <datalist id='prepaidNames'>
                                    {prepaids && prepaids.sort((a, b) => {
                                        if (String(a.OwnerData).toLowerCase() < String(b.OwnerData).toLowerCase()) {
                                            return -1;
                                        }
                                        if (String(a.OwnerData).toLowerCase() > String(b.OwnerData).toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((prepaid, idx) => (
                                        <option key={idx} value={prepaid.OwnerData}/>
                                    ))}
                                </datalist>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Nadrukowany numer karty</h6>
                                <input
                                    type='number'
                                    name='PrepaidPrintedCode'
                                    value={query.PrepaidPrintedCode || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    list='prepaidPrintedCodes'
                                    placeholder='Szukaj...'
                                />
                                <datalist id='prepaidPrintedCodes'>
                                    {prepaids && prepaids.sort((a, b) => {
                                        if (String(a.PrintedCode).toLowerCase() < String(b.PrintedCode).toLowerCase()) {
                                            return -1;
                                        }
                                        if (String(a.PrintedCode).toLowerCase() > String(b.PrintedCode).toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((prepaid, idx) => (
                                        <option key={idx} value={prepaid.PrintedCode}/>
                                    ))}
                                </datalist>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Wewnętrzny numer karty</h6>
                                <input
                                    type='number'
                                    name='PrepaidCode'
                                    value={query.PrepaidCode || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    list='prepaidCodes'
                                    placeholder='Szukaj...'
                                />
                                <datalist id='prepaidCodes'>
                                    {prepaids && prepaids.sort((a, b) => {
                                        if (String(a.Code).toLowerCase() < String(b.Code).toLowerCase()) {
                                            return -1;
                                        }
                                        if (String(a.Code).toLowerCase() > String(b.Code).toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;   
                                    }).map((prepaid, idx) => (
                                        <option key={idx} value={prepaid.Code}/>
                                    ))}
                                </datalist>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Grupa prepaid</h6>
                                <input
                                    type='text'
                                    name='GroupName'
                                    value={query.GroupName || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    list='groupsList'
                                    placeholder='Szukaj...'
                                />
                                <datalist id='groupsList'>
                                    {prepaidGroups && prepaidGroups.map((group, idx) => (
                                        <option key={idx} value={group.PrepaidGroupName}/>
                                    ))}
                                </datalist>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Terminal</h6>
                                <input
                                    type='text'
                                    name='Terminal'
                                    value={query.Terminal || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    list='terminalsList'
                                    placeholder='Szukaj...'
                                />
                                <datalist id='terminalsList'>
                                    {terminalList && terminalList.map((terminal, idx) => (
                                        <option key={idx} value={terminal.Name}/>
                                    ))}
                                </datalist> 
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Automat</h6>
                                <input
                                    type='text'
                                    name='Automat'
                                    value={query.Automat || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    list='machinesList'
                                    placeholder='Szukaj...'
                                />
                                <datalist id='machinesList'>
                                    {machineList && machineList.map((machine, idx) => (
                                        <option key={idx} value={`${machine.MachineName} (${machine.SerialNo})`}/>
                                    ))}
                                </datalist> 
                            </div>
                            {/* <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Cena</h6>
                                <input
                                    type='number'
                                    name='Price'
                                    value={query.Price || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    placeholder='Wpisz...'
                                />
                            </div> */}
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Nr wyboru</h6>
                                <input
                                    type='number'
                                    name='ProductNo'
                                    value={query.ProductNo || ""}
                                    onFocus={handleFocus}
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    placeholder='Wpisz...'
                                />
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <h6 className="text-center mb-0">Status transakcji</h6>
                                <select
                                    name='IsSuccess'
                                    onChange={handleChange}
                                    className='mt-2 form-control form-control-sm'
                                    defaultValue={query.Enabled || "null"}
                                >
                                    <option value={"null"} >Wybierz</option>
                                    <option value={"true"} >Pozytywna</option>
                                    <option value={"false"} >Zwrot</option>
                                    <option value={"null"} >Odmowa</option>
                                </select>
                            </div>
                            <div
                                className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                            >
                                <div className='d-flex flex-column justify-content-center'>
                                    <h6 className="text-center mb-1">Zakres czasowy</h6>
                                    <div className='d-flex justify-content-center'>
                                    <div>Od <input type="datetime-local" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date" name='StartDateTime' onChange={handleChange} value={query.StartDateTime}></input></div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                    <div>Do <input type="datetime-local" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date" name='EndDateTime' onChange={handleChange} value={query.EndDateTime} ></input></div>
                                    </div>
                                    <div className='d-flex flex-wrap justify-content-center'>
                                    <button
                                        className="m-1 btn btn-outline-primary btn-sm"
                                        name="Today"
                                        onClick={handleChangeTime}
                                    >
                                        {TRL_Pack.filter.dateRangeToday}
                                    </button>
                                    <button
                                        className="m-1 btn btn-outline-primary btn-sm"
                                        name="LastWeek"
                                        onClick={handleChangeTime}
                                    >
                                        {TRL_Pack.filter.dateRangeLastWeek}
                                    </button>
                                    <button
                                        className="m-1 btn btn-outline-primary btn-sm"
                                        name="ActualMonth"
                                        onClick={handleChangeTime}
                                    >
                                        Bieżący miesiąc 
                                    </button>
                                    <button
                                        className="m-1 btn btn-outline-primary btn-sm"
                                        name="LastMonth"
                                        onClick={handleChangeTime}
                                    >
                                        {TRL_Pack.filter.dateRangeLastMonth}
                                    </button>
                                    <button
                                        className="m-1 btn btn-outline-primary btn-sm"
                                        name="Whenever"
                                        onClick={handleChangeTime}
                                    >
                                        {TRL_Pack.filter.dateRangeWhenever}
                                    </button></div>
                                </div>
                            </div>
                        </>
                    )
                }
                {localStorage.getItem('clientId') === "console" && (
                    <div
                    className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                    >
                        <h6 className="text-center mb-0">Klient</h6>
                        <input
                            type='text'
                            name='CustomerName'
                            value={query.CustomerName || ""}
                            onFocus={handleFocus}
                            onChange={handleChange}
                            className='mt-2 form-control form-control-sm'
                            list='customers'
                            placeholder='Szukaj...'
                        />
                        <datalist id='customers'>
                            {customers && customers.map((customer, idx) => (
                                <option key={idx} value={`${customer.Name} (${customer.Abbreviation})`}/>
                            ))}
                        </datalist>
                    </div>
                )}
                </div>
                <div className="row align-items-center d-flex justify-content-center">
                    <button
                        className="d-inline m-2 btn btn-primary float-right"
                        onClick={toggleFilter}
                    >
                        Zamknij
                    </button>
                    <button
                        className="d-inline m-2 btn btn-primary float-right"
                        onClick={() => {setQuery(defaultFilter)
                            search()
                        }}
                    >
                        Przywróć domyślne
                    </button>
                    
                    <button
                        className="d-inline m-2 btn btn-primary float-right"
                        onClick={handleSubmit}
                    >
                        Szukaj
                    </button>
                </div>
            </div>
        </div>
                <PrepaidReports
                    type={type}
                    export86={export86}
                    export87={export87}
                    export88={export88}
                />
        </div>
    )
}
