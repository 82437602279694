import React from 'react'
import { useState } from 'react'
import useFetch from '../../../../hooks/fetchMSSQL-hook'

export default function IngredientForm({ingredient, ingredientsList, recipeId, closeForm}) {
    const {fetchMssqlApi} = useFetch([])
    const [controleVisible, setControleVisible] = useState(Object.values(ingredient).length ? false : true)
    const [newIngredient, setNewIngredient] = useState(true)
    const [ingredientData, setingredientData] = useState(ingredient || {
        Name: "",
        RecipeId: recipeId,
        RecipeProductId: null,
        Value: ""
    })
    const submitIngredient = evt => {
        evt.preventDefault()
        const {Name, Value} = evt.target.elements 
        // console.log(Name.value, Value.value, ingredient?.RecipeProductId || "new")
        const Ingredient = ingredientsList.find(ing => ing.Name === Name.value) || null
            const payload = {
                RecipeId: +recipeId,
                Ean: +Ingredient?.EAN,
                Value: +Value.value
            }
            if( !payload.Ean) {
                Name.value = ""
                return alert("Wybierz poprawny składnik")
            }
            console.log("payload: ", payload)
            const formData = new FormData()
            formData.append("RecipeId", +recipeId)
            formData.append("Ean", +Ingredient.EAN)
            formData.append("Value", +Value.value)
            !Object.values(ingredient).length && newIngredient
            ? fetchMssqlApi("recipe-product", {method: "POST", data: payload, hideNotification: true}, res => setingredientData(res.rows))
            : fetchMssqlApi(`recipe-product/${ingredientData.RecipeProductId}`, {method: "PUT", data: payload, hideNotification: true}, res => console.log(res))
            setNewIngredient(false)
            console.log(ingredientData)
    }       
    const style = {
        display: controleVisible ? "flex" : "none",
        transition: '2s'
    }
    const deleteIngredient = evt => {
        evt.preventDefault()
        fetchMssqlApi(`/recipe-product/${ingredientData.RecipeProductId}`, {method: "DELETE", data: {}, hideNotification: true}, answ => console.log(answ))
        // closeForm()
    }
    return (
        <div onFocus={() => setControleVisible(true)} onMouseEnter={() => Object.values(ingredientData).length && setControleVisible(true)} onMouseLeave={() => Object.values(ingredientData).length && setControleVisible(false)}>
            <form className='form-group' 
            onKeyDown={evt => {

                if (evt.key === "Enter") evt.preventDefault()
            }} 
            onSubmit={submitIngredient} id={ingredient ? ingredient.RecipeProductId : "new"}>
                <div className='row mb-1'>
                <div className='col-sm pr-0'>
                    <input className='form-control' placeholder='Nazwa składnika' type='text' name="Name"
                        defaultValue={ingredientData && ingredientData?.Name} list='ingredients'/>
                    <datalist id='ingredients'>
                        {ingredientsList.map( (ingredientItem, idx) => (
                            <option value={ingredientItem.Name} key={idx}/>
                        ))}
                    </datalist>
                </div>
                <div className='col- pl-0 pr-3'>
                    <input placeholder='Ilość składnika' type='number' pattern = "[0-9]" className='form-control' name="Value" defaultValue={ingredientData && ingredientData?.Value}/>
                </div>
                </div>
                {/* <div className={`form-group ${controleVisible ? `d-flex` : `d-none`} justify-content-between`}> */}
                <div className={`form-group (${Object.values(ingredientData).length} ? justify-content-between : justify-content-end)`} style={style}>
                    
                {/* <div className='col- pl-0 pr-3 align-self-center'> */}

                {Object.values(ingredientData).length && controleVisible && <button className='btn btn-sm btn-danger' 
                onClick={deleteIngredient}>Usuń</button> || ""}
                {/* </div> */}
                    <button 
                        className={`btn ${Object.values(ingredientData)[0] ? `btn-primary` :  `btn-success`} btn-sm`}
                        // disabled={Object.values(ingredientData).length && Object.values(ingredientData) == []}
                    >{Object.values(ingredientData)[0] ? "Zapisz" : "Dodaj"}</button>
                </div>
            </form>
        </div>
    )
}
