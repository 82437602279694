import React, { useState, useEffect, useContext } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import { LangContext } from '../../context/lang-context'
import { useParams } from 'react-router'
import useFetch from '../../hooks/fetchMSSQL-hook'
import useForm from '../../hooks/form-hook'
import useFilter from '../../hooks/filter-hook'

import NoResults from '../../components/NoResults/NoResults'
import Pagination from '../../components/Pagination/Pagination'


import filterItems from '../../util/filterItems'
import LocationForm from '../../components/Modals/LocationForm'
import AcceptForm from '../../components/Modals/AcceptForm'
import FilterEx from '../Filter/FilterEx'
// import locationsContext from '../../context/locationsContext'
import { NotificationManager } from 'react-notifications'
import { Link, useHistory } from 'react-router-dom'
import Tracks from '../../views/ClientView/Routes'

const MachineLocations = () => {

    const { fetchMssqlApi } = useFetch()
    const { setHeaderData } = useContext(NavigationContext)
    const { TRL_Pack } = useContext(LangContext)
    const { searchedText, updateSearchedText, page, updateCurrentPage } = useFilter()
    const history = useHistory()

    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    const resetFilter = () => setFilter(defaultFilter)
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const { form, openForm, closeForm } = useForm()
    const [locations, setLocations] = useState([])
    const [type, setType] = useState("Locations")
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))

    const getLocations = () => {
        fetchMssqlApi('locations', {}, (locations) => setLocations(locations))
    }
    const getLocation = () => {
        fetchMssqlApi(`location/${form}`)
    }

    const submitLocation = evt => {
        evt.preventDefault()
        const { 
            name
            , rent
            , comment
            , isActive = true
            , town
            , local
            , postDep
            , homeNr
            , index 
            , street
            // , customerName
        } = evt.target.elements
        let names = []
        locations.forEach(location => names.push(location.Name.toLowerCase()))
        const payload = {
            Name: name.value
            , Rent: (+rent.value).toFixed(2)
            , Comment: comment.value
            , Street: street.value
            , Town: town.value
            , Local: local ? local.value : ""
            , PostDep: postDep.value
            , HomeNr: homeNr.value
            , Index: index.value
            // , CustomerName: customerName.value
        }
        
        
        let unique = !names.includes(name.value.toLowerCase())

        if(name.value.length > 1
            && +rent.value <= 10000000) {
                let path, method
                if (form === 'new') {
                    path = `location`
                    method = 'POST'
                    if(unique) {
                    fetchMssqlApi(path, { method, data: {...payload, IsActive: "true"} }, () => {
                        // getLocation();
                        getLocations();
                        closeForm();
                        // console.log("updated: ",payload)
                    })} 
                    else {
                        NotificationManager.error("Please choose another name", "Name already registered"  ,3000)
                    }
                } else {
                    path = `location/${form}`
                    method = 'PATCH'
                    const fetchQuery = async () => {
                        await fetchMssqlApi(path, { method, data: {...payload, IsActive: evt.target.elements.isActive.value} }, () => {
                            closeForm()
                            getLocations()
                            getLocation()
                        })
                    }
                    fetchQuery()
                    console.log(document)

                }
        } else {
            NotificationManager.error((name.value.length < 1) ? " name is too short" : "rent is to long", "Please write correct data"  ,3000)
        }
    }

    const deleteLocation = form => {
        fetchMssqlApi(`location/${form}`, { method: 'DELETE' }, getLocations)
    }

    useEffect(() => {
        setHeaderData({ text: TRL_Pack.locations.header })
        getLocations()
    }, [])


    let defaultFilter;

    if (localStorage.getItem("clientId") !== "console" && sessionStorage.getItem("DB_TYPE") !== "mysql") {
        defaultFilter = {
            btnHiden: true,
            name: "locations",
            showIndexes: false,
            page: 1,
            rowsPerPage: 100,
            rowsPerPageOptions: [10, 25, 50, 100],
            // need to disable after end of work with filters
            visible: false,
            sortByColumns: true,
            sortBy: '9 | asc | text',
            activeTags: [],
            disableIndexes: "true",
            columns:  [
                // {
                //     id: 1,
                //     name: 'TRL_Pack.locations.filter.LocationId',
                //     type: "price",
                //     sortable: true,
                //     searchable: true,
                // },

                {
                    id: 2,
                    name:  TRL_Pack.locations.machineName,
                    sortable: true,
                    searchable: true,
                    // name:"Maszyna",
                    type: 'lookup',
                    searchbar: null,
                },
                {
                    id: 4,
                    name: TRL_Pack.locations.comment || "Komentarz",
                    sortable: false,
                    selectable: false,
                    type: "lookup",
                    value: null
                },
                {
                    id: 5,
                    name: TRL_Pack.locations.isActive ,
                    sortable: true,
                    selectable: true,
                    // type: "threeStateBool",
                    type: 'threeStateBoolByActivity',
                    selectbar: "true",
                    value: "true"
                    // type: 'threeStateBoolActive',
                },
                {
                    id: 6,
                    name: TRL_Pack.locations.AmountOfConectedMachines || "Liczba powiązanych automatów",
                    sortable: false,
                    selectable: false,
                    type: 'lookup',
                    // value: null
                    // type: 'threeStateBoolActive',
                },
                {
                    id: 7,
                    name: "Adres",
                    sortable: false,
                    selectable: false,
                },
                // {
                //     id: 8,
                //     name: "Nazwa podatnika",
                //     sortable: false,
                //     selectable: false,
                // },
                
                // { solid: "true" }
                
            ], 
        }

    }
    else {

        defaultFilter = {
            showIndexes: false,
            page: 1,
            rowsPerPage: 100,
            rowsPerPageOptions: [10, 25, 50, 100],
            visible: false,
            sortByColumns: true,
            sortBy: '3 | asc | text',
            activeTags: [],
            disableIndexes: "true",
            columns: [
                {
                    id: 1,
                    name: 'TRL_Pack.locations.filter.LocationId',
                    type: "price",
                    sortable: true,
                    searchable: true,
                },
                {
                    id: 2,
                    name: 'TRL_Pack.locations.filter.MachineName',
                    sortable: true,
                    searchable: true,
                    type: 'text',
                },
                { solid: "true" },
            ], 
        }
    }

    const reportFilter = row =>
        filter.columns
            .filter(col => col.searchbar ? col.searchbar : col.selectbar)
            .every(col => {
                const x = String(row[Object.keys(row)[col.id - 1]])
                    .toLowerCase()
                    .includes(col.searchbar ? col.searchbar.toLowerCase() : col.selectbar.toLowerCase())
                return x
            }
            )


    const sortRows = (a, b) => {
        const [id, order, type] = filter.sortBy.split(' | ')

        const col = Object.keys(locations[0])[Number(id) - 1]

        if (a[col] === b[col]) return 0
        else if (a[col] === null) return 1
        else if (b[col] === null) return -1

        let valueA, valueB
        if (type === 'text' || type === 'date' || type === "lookup") {
            valueA = a[col]
            valueB = b[col]
        }
        else if (type === "bool" || type === "threeStateBool") {
            valueA = Number(a[col])
            valueB = Number(b[col])
        }
        else if (type === 'price') {
            valueA = Number(a[col].replace(',', ''))
            valueB = Number(b[col].replace(',', ''))
        } else return 0
        if (order === 'asc') return valueA < valueB ? -1 : 1
        else return valueA < valueB ? 1 : -1
    }


    
    const returnParsedIsShared = (col) => {
        if (typeof col === 'string' || typeof col === 'number') {
            return col
        }

        else if (typeof col === 'boolean') {
            if (col === true) {
                return <input type='checkbox' 
                checked={true} 
                style={{width: "15px", height: "15px", marginTop: "6px"}}
                onChange={() => {}}></input>
            }
            else if (col === false) {
                return <input type='checkbox' 
                checked={false}
                style={{width: "15px", height: "15px", marginTop: "6px"}}
                onChange={() => {}}></input>
            }
            // return col
        }
    }

    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('locationsFilter')) {
            return JSON.parse(localStorage.getItem('locationsFilter'))
        } else return defaultFilter
    })
    useEffect(() => localStorage.setItem("locationsFilter", JSON.stringify(filter)), [filter])
    const filteredLocations = locations.filter(({ Name }) => filterItems(searchedText, Name))

    const handleModal = (LocationId, isSubscribed) => {
        // setSubscribed(isSubscribed)
        // setEan(LocationId)
        openForm("acceptModal")()

    }
    const returnFilterWithoutVisibleParams = (obj) => {
        const {visible, page, ...other} = obj
        return other
    }
    const [resetBtnVis, setResetBtnVis] = useState(false)
        useEffect(() => {
                (JSON.stringify(returnFilterWithoutVisibleParams(filter)) !== JSON.stringify(returnFilterWithoutVisibleParams(defaultFilter)))
                    ? setResetBtnVis(true) 
                    : setResetBtnVis(false)
        }, [filter])
    
    // console.log(locations.filter(c => c.LocationId).map(x => x.LocationId))
    const search = () => {
        const isActive = filter.columns[2].value || null
        const locationName = filter.columns[0].value
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if (col?.name === TRL_Pack.locations.machineName) col.selectbar = locationName
                if (col?.name === TRL_Pack.locations.isActive) col.selectbar = isActive
                return col
            })
        }))

    }
    return (
            <div style={{padding: "0px 50px"}}>
                    <ul className='nav nav-tabs machine-tabs mb-3'>
                <li className="nav-item">
                    <Link to='/machinelocations'>
                    <button
                    className={`nav-link btn active`}
                    tabIndex="0">
                        Lokalizacje
                    </button>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to='/routes'>
                    <button
                    className={`nav-link btn`}
                    tabIndex="0">
                        Trasy
                    </button>
                    </Link>
                </li>

        </ul>
        {type === "Locations" && locations.length ? (
                <>
                    {/* <SearchInput onSearch={updateSearchedText} /> */}
                    {/* <div>
                    </div> */}
                    <Pagination
                        totalItems={filteredLocations.length}
                        page={filter.page}
                        rowsPerPage={filter.rowsPerPage}
                        handleSwitchPage={handleSwitchPage}
                        filterVisibility={filter.visible}
                        toggleFilter={toggleFilter}
                        resetFilter={resetFilter}
                        resetBtnVis={resetBtnVis}
                    />
                    {filter.visible && (
                        <FilterEx
                            {...{
                                filter,
                                setFilter,
                                columns: filter.columns,
                                data: locations,
                                resetPage,
                                resetFilter,
                                toggleFilter,
                                search
                                // defaultFilter,
                            }}
                        />
                        
                    )}
                    
                    {!filteredLocations.length ? (
                        <NoResults buttonText={TRL_Pack.locations.addProductButton} onClick={() => { }} />
                    ) : (
                        <>
                            <div>
                                <button
                                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                                    onClick={openForm()}
                                >
                                    <i className="fas fa-plus mr-2" />
                                    {TRL_Pack.locations.addProductButton}
                                </button>
                            </div>
                            <div className="overflow-auto">
                                <table className="table table-striped border" style={{width: "100%"}}>
                                    <thead>
                                        <tr >
                                            {filter.showIndexes && <th className="text-center">#</th>}
                                            {filter.columns
                                                .filter(col => !col.hidden && !col.disabled)
                                                .map((col, idx) => (
                                                    <th key={idx} 
                                                    className={(col.name === TRL_Pack.locations.active) && "text-center" || ""}
                                                    >{col.name}</th>
                                                ))}
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredLocations
                                            .filter(reportFilter)
                                            .sort(sortRows)
                                            .slice(
                                                (filter.page - 1) * filter.rowsPerPage,
                                                filter.page * filter.rowsPerPage
                                            )
                                            .map((location, idx) => (
                                                
                                                <tr key={idx} className='align-text-center'>
                                                    {Object.keys(location)
                                                        .filter((col, col_idx) =>
                                                            filter.columns
                                                                .filter(col => !col.hidden && !col.disabled)
                                                                .map(col => col.id)
                                                                .includes(col_idx + 1)
                                                        )

                                                        .map((col, col_idx) => (
                                                            <td key={col_idx} className={(col !==  "IsActive") ? "small" : " d-flex justify-content-center"}>
                                                            <div
                                                                // style={{ wordBreak: 'break-word' }}
                                                                className="btn btn-link font-size-inherit text-reset text-decoration-none p-1 "
                                                                // key={col_idx+"div"}
                                                                // onClick={openForm(location.LocationId)}
                                                                >
                                                                {sessionStorage.getItem("DB_TYPE") !== "mysql" ?  returnParsedIsShared(location[col]) : location[col]}                                                                
                                                            </div>
                                                            </td>
                                                        ))}
                                                        <td className='d-flex justify-content-end mb-2 border-0'>
                                                        <button className="btn btn-outline-primary btn-sm "
                                                                    onClick={openForm(location.LocationId)}
                                                                    // key={location.id+"btn"}
                                                                    >
                                                                Edytuj
                                                            </button>
                                                        </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <NoResults buttonText={TRL_Pack.locations.addProduct} onClick={openForm()} />
            )
            }
            {type === "Traces" && <Tracks setType={setType}/>}
            {
                form === "acceptModal" &&  form && (
                    <AcceptForm handleClose={closeForm} deleteLocation={deleteLocation} />
                )
            }
            {
                form !== "acceptModal" && form && (
                    <LocationForm
                        form={form}
                        locationData={
                            form !== 'new' && form !== "acceptModal" ? filteredLocations.find(location => location.LocationId === form) : null
                        }
                        getLocations={getLocations}
                        deleteLocation={deleteLocation}
                        handleSubmit={submitLocation}
                        handleClose={closeForm}
                        locations={locations}
                    />
                )
            }
            {/* <button onClick={() => getNames}>log</button> */}
        </div>
            
    )
}

export default MachineLocations;