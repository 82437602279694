import React, { useContext, useEffect, useState } from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import langContext, { LangContext } from '../../context/lang-context'
import formHook from '../../hooks/form-hook'
import NoResults from '../../components/NoResults/NoResults'
import { NotificationManager } from 'react-notifications'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import BagForm from '../../components/Modals/BagForm'

export default function Bags() {


    const {fetchMssqlApi} = fetchHook()
    const { setHeaderData } = useContext(NavigationContext)
    const {form, openForm, closeForm} = formHook()
    const {TRL_Pack} = useContext(LangContext)
    const [bags, setBags] = useState([])


    //filter...
    const defaultFilter = {
        showIndexes: false,
        name: "bags",
        page: 1,
        disableIndexes: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
        {
            id: 3,
            name: "Aktywny",
            sortable: true,
            selectable: true,
            disabled: false,
            type: "threeStateBoolByActivity",
            value: "true",
            selectbar: "true"
        },
        ]
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('machineBagsFilter')) {
        return JSON.parse(localStorage.getItem('machineBagsFilter'));
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);

    }
    const reportFilter = row =>
    filter.columns
        .filter(col => col.selectbar)
        .every(col => {
        const x = String(row[Object.keys(row)[col.id - 1]])
            .toLowerCase() === col.selectbar.toLowerCase()
        return x
        }
    )
    const search = () => {
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                col.selectbar = col.value
                return col
            })
        }))
    }
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    //===================

    const getCheckboxFromBool = (bool) => (
        <div className='d-flex justify-content-center align-items-center'>
            <input
                type='checkbox'
                checked={bool}
                onChange={() => {}}
            />
        </div>
    ) 

    const getBags = () => {
        fetchMssqlApi('bags', {}, res => {
            setBags(res)
        })
    }

    const handleSubmit = (evt, setData) => {
        evt.preventDefault()
        const {name, isActive} = evt.target.elements
        const isAvaiable = bags.filter(bag => bag.BagId !== form).map(bag => bag.Name).indexOf(name.value) === -1
        const bag = bags.find(b => b.BagId === form)
        if (form !== "new" && String(bag.IsActive) !== isActive.value) {
            fetchMssqlApi(`bag/${form}`, {method:"PUT", data: {IsActive: String(!bag.IsActive), Name: bag.Name}}, () => {
                getBags()
                closeForm()
            })
            return
        }
        if (!isAvaiable) {
            NotificationManager.error("Taka nazwa już istnieje")
            setData(prev => ({...prev, Name: ""}))
            return
        }
        if (form === "new") {
            fetchMssqlApi('bag', {method: "POST", data: {Name: name.value}}, () => {
                getBags()
                closeForm()
            })
        } else {
            fetchMssqlApi(`bag/${form}`, {method:"PUT", data: {IsActive: bag.IsActive, Name: name.value}}, () => {
                getBags()
                closeForm()
            })
        }
    }
    useEffect(() => {
        setHeaderData({text: "Worki na monety"})
        getBags()
    }, [])
    return (
        <div>
            <Pagination 
                totalItems={bags.length}
                page={filter.page}
                handleSwitchPage={handleSwitchPage}
                rowsPerPage={filter.rowsPerPage}
                toggleFilter={toggleFilter}
                filterVisibility={filter.visible}
                resetFilter={resetFilter}
            />
            {filter.visible && (
                <FilterEx
                    filter={filter}
                    setFilter={setFilter}
                    columns={filter.columns}
                    resetPage={resetPage}
                    resetFilter={resetFilter}
                    search={search}
                    toggleFilter={toggleFilter}
                />
            )}
            {bags.length ? (
                <>
                <div>
                <button
                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                    onClick={openForm()}
                >
                    <i className="fas fa-plus mr-2" />
                    {TRL_Pack.locations.addProductButton}
                </button>
            </div>
                <table className='table table-striped border' style={{width: "100%"}}>
                    <thead>
                        <tr >
                            <th>Worek na monety</th>
                            <th style={{textAlign: "center"}}>Aktywny</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bags.filter(reportFilter).slice(
                            (filter.page - 1) * filter.rowsPerPage,
                            filter.page * filter.rowsPerPage
                        ).map((bag, idx) => (
                            <tr key={idx}>
                                <td>
                                    {bag.Name}
                                </td>
                                <td>
                                    {getCheckboxFromBool(bag.IsActive)}
                                </td>
                                <td className='d-flex justify-content-end mb-2 mt-2 border-0'>
                                    <button className="btn btn-outline-primary btn-sm "
                                        onClick={openForm(bag.BagId)}
                                    >
                                        Edytuj
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
            ) : (
                <NoResults buttonText={TRL_Pack.locations.addProduct} onClick={openForm()} />
            )} 
            {
                form && 
                <BagForm
                    bagData={bags.find(bag => bag.BagId === form)}
                    handleClose={closeForm}
                    handleSubmit={handleSubmit} 
                />
            }
        </div>
    )
}
