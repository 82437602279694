import React from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal-root');

const Modal = ({ hideModalHandler, header, children }) => {
    return createPortal(
        <div className='modal fade show d-block'>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content border-0'>
                    <div className='modal-header bg-light align-items-center'>
                        <h6 className='modal-title'>{header}</h6>
                        <button onClick={hideModalHandler} className='btn text-secondary px-2 py-1'>
                            <i className='fas fa-times' />
                        </button>
                    </div>
                    <div className='modal-body'>
                        {children}
                    </div>
                    <div className='modal-footer bg-light'>
                        <button type='submit' className='btn btn-success btn-sm' form='modal-form'>
                            Zapisz
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        modalRoot
    );
};

export default Modal;