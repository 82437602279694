import React, { useState, useEffect } from 'react'
import useFetch from '../../hooks/fetchMSSQL-hook'
// import { LangContext } from '../../context/lang-context'

import FormSkel from './FormSkel'

export default ({ postSubmit, machineData, handleClose, serialNoArr }) => {
  // const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const [clients, setClients] = useState([])
  const getClients = () => {
    fetchMssqlApi('clients', {}, clients => setClients(clients))
  }

  const [terminals, setTerminals] = useState([])
  const getTerminals = () => {
    fetchMssqlApi('terminal-list', {method: "POST", data: {customerAbbr: localStorage.getItem('clientId')}, hideNotification: true}, terminals => setTerminals(terminals))
  }

  const machineTypes = ['V10', 'V10S', 'V11', 'LOCKER', 'SK3']

  const submitMachine = evt => {
    evt.preventDefault()
    const { name, serialNo, type, client, terminal, useVMCTrx, useTrxForSales } = evt.target.elements
    console.log({ name: name.value, serialNo: serialNo.value, type: type.value, client: client.value, terminal: terminal.value })

    if (
      machineTypes.includes(type.value)   
      // clients.map(c => `${c.Name} (${c.Abbreviation.toLowerCase()})`).includes(client.value) &&
      // (!terminal.value || terminals.map(t => t.Name).includes(terminal.Name))
    ) {
      let path, method
      if (machineData) {
        path = `machine/${machineData.MachineId}`
        method = 'PUT'
      } else {
        path = 'machine'
        method = 'POST'
      }

      // console.log({
      //   ClientId: clients.find(c => `${c.Name} (${String(c.Abbreviation).toLowerCase()})` === client.value).CustomerId,
      //       TerminalId: terminal.value
      //         ? terminals.find(t => t.Name === terminal.value).TerminalId
      //         : null
      // })
      if(serialNoArr && serialNoArr.includes(serialNo.value)) {
        serialNo.value = serialNo.defaultValue
        alert('taki numer już istnieję')
        return
      }
      const terminalVerification = serNo => {
        const terminal = terminals.find(t => t.Name === serNo)
        if(terminal.MachineSerialNo) return false
        else return true
      }
      if(terminal.value && !terminalVerification(terminal.value)) return 
      console.log(serialNo.value)
      fetchMssqlApi(
        path,
        {
          method,
          data: {
            MachineName: name.value,
            SerialNo: serialNo.value,
            MachineType: type.value,
            ClientId: clients.find(c => `${c.Name} (${String(c.Abbreviation).toLowerCase()})` === client.value)?.CustomerId,
            TerminalId: (terminal.value.length)
              ? terminals.find(t => t.Name === terminal.value).TerminalId
              : null,
            UseVMCTrx: useVMCTrx.checked,
            UseTrxForSales: useTrxForSales.checked
          }
        },
        () => {
          handleClose()
          postSubmit()
        }
      )
      console.log({
              MachineName: name.value,
              SerialNo: serialNo.value,
              MachineType: type.value,
              ClientId: clients.find(c => `${c.Name} (${String(c.Abbreviation).toLowerCase()})` === client.value || c.Name === client.value.split(" (")[0]).CustomerId,
              TerminalId: terminal.value
                ? terminals.find(t => t.Name.toLowerCase() === terminal.value.toLowerCase()).TerminalId
                : null,
              UseVMCTrx: useVMCTrx.checked,
              useVMCTrx
            })
    }
  }
  const getTerminalType = type => {
    const terminalTypeMap = new Map()
    terminalTypeMap.set(1, "Vendotek")
    terminalTypeMap.set(2, "PAX")
    terminalTypeMap.set(3, "Nayax")
    const name = terminalTypeMap.get(type)
    return name
  }

  useEffect(() => {
    getClients()
    getTerminals()
  }, [])

  return (
    <FormSkel
      headerText={machineData ? 'Edycja automatu' : 'Dodaj automat'}
      handleClose={handleClose}
    >
      <form onSubmit={submitMachine} id="modal-form" autoComplete="off">
        <div className="form-group">
          <label className="h6">Nazwa</label>
          <input
            name="name"
            className="form-control"
            defaultValue={machineData && machineData.Name}
            required
          />
        </div>
        <div className="form-group">
          <label className="h6">Nr seryjny</label>
          <input
            name="serialNo"
            className="form-control"
            defaultValue={machineData ? machineData.SerialNo : 'VDM000000'}
            pattern="VDM[0-9]{8}"
            required
          />
        </div>
        <div className="form-group">
          <label className="h6">Typ</label>
          <input
            name="type"
            className="form-control"
            list="machineTypes"
            defaultValue={machineData && machineData.Type}
            required
          />
          <datalist id="machineTypes">
            {machineTypes.map((mt, idx) => (
              <option key={idx} value={mt} />
            ))}
          </datalist>
        </div>
        <div className="form-group">
          <label className="h6">Terminal</label>
          <input
            name="terminal"
            className="form-control"
            list="terminals"
            defaultValue={machineData?.TerminalSerialNo && `${getTerminalType(+machineData.TerminalTypeId)} ${machineData.TerminalSerialNo}`}
          />
          <datalist id="terminals">
            {terminals.map((t, idx) => (
              <option key={idx} value={t.Name} />
            ))}
          </datalist>
        </div>
        <div>
          <label className="h6">Klient</label>
          <input
            name="client"
            className="form-control"
            list="clients"
            defaultValue={machineData && `${machineData.CustomerName} (${machineData.Abbreviation})`}
            required
          />
          <datalist id="clients">
            {clients.map((c, idx) => (
              <option key={idx} value={`${c.Name} (${String(c.Abbreviation).toLowerCase()})`} />
            ))}
          </datalist>
          <div className='d-inline-block flex-row align-self-center form-check'> 
          <input className="form-check-input"
            type="checkbox"
            name="useVMCTrx"
            // onChange={handleChangeUseVMCTrx}
            defaultChecked={machineData?.UseVMCTrx}
            value={undefined}
          />
          <label className="h6">Rozliczanie sprzedaży przez Transakcje z płyty głównej R24 VMC</label>
         </div> 
          <div className='d-inline-block flex-row align-self-center form-check'> 
          <input className="form-check-input"
            type="checkbox"
            name="useTrxForSales"
            // onChange={handleChangeUseVMCTrx}
            defaultChecked={machineData?.UseTrxForSales}
            value={undefined}
          />
          <label className="h6">Rozliczanie sprzedaży przez Transakcje</label>
         </div> 
        </div>
      </form>
    </FormSkel>
  )
}
