import React, { useState, useEffect, useContext } from 'react';
import { NavigationContext } from '../../../context/navigation-context';
import { LangContext } from '../../../context/lang-context';
import useFilter from '../../../hooks/filter-hook';
import useHttp from '../../../hooks/use-http';
import useFetch from '../../../hooks/fetchMSSQL-hook'
import MachinesTable from '../../../components/Monitoring/MachinesTable';
import SearchInput from '../../../components/SearchInput/SearchInput';
import filterItems from '../../../util/filterItems';
import NoResults from '../../../components/NoResults/NoResults';

const Monitoring = () => {
  const { setHeaderData } = useContext(NavigationContext);
  const { TRL_Pack } = useContext(LangContext);

  const { searchedText, updateSearchedText } = useFilter();
  const { sendRequest: sendMonitoringsRequest } = useHttp(); // Use http hook

  const [machines, setMachines] = useState(null);
  const { fetchMssqlApi } = useFetch()

  useEffect(() => {
    setHeaderData({ text: TRL_Pack.monitoring.header });

    // const getMonitoringsResponse = machines => {
    //   setMachines(machines.data);
    // };

   // const getTerminals = () => {
      fetchMssqlApi('monitorings', { method: 'POST',  data: {
        customerAbbr: localStorage.getItem('clientId')
      }, hideNotification:true }, machines => setMachines(machines))
   // }

   //console.log(machines);
   //console.log(machines.data);
    // sendMonitoringsRequest({
    //   url: 'monitorings', 

    // }, getMonitoringsResponse);
  }, [sendMonitoringsRequest])

  const filteredMachines =
    machines &&
    machines
      .filter(({ name, type, customer_name, serialNo, terminal, ip }) =>
        filterItems(searchedText, name, type, customer_name, serialNo, terminal, ip)
      )
      .sort((a, b) => {
        return new Date(b.last_transaction.create_date_time) - new Date(a.last_transaction.create_date_time);
      })

  return <div className='container-xl'>
    <SearchInput
      onSearch={updateSearchedText}
    />
    {!filteredMachines || filteredMachines.length === 0 ? (
      <NoResults />
    ) :
      <MachinesTable
        machines={filteredMachines}
        searchedText={searchedText}
      />
    }
  </div>
}

export default Monitoring;