import React, { useEffect } from 'react'
import { useState } from 'react'
import useFetch from '../../../../hooks/fetchMSSQL-hook'
import formHook from '../../../../hooks/form-hook'
import RecipeForm from '../forms/RecipeForm'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Pagination from '../../../../components/Pagination/Pagination'
import FilterEx from '../../../../components/Filter/FilterEx'


export default function Recipes({products, vat, category, setProductType, data, eans}) {
    const {openForm, form, closeForm} = formHook()
    const {fetchMssqlApi} = useFetch()
    const [ingredients, setIngredients] = useState([]) 
    const [recipes, setRecipes] = useState(data)
    const [filteredRecipes, setFilteredRecipes] = useState(null)
    // const filteredRecipes = category && recipes.filter
    const getIngredients = () => {
        fetchMssqlApi('ingredients-list', {}, ingredients => setIngredients(ingredients))
    }
    const defaultFilter  = {
        showIndexes: false,
        page: 1,
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50],
        name: "recipes",
        visible: false,
        sortByColumns: false,
        sortBy: '3 | asc | text',
        activeTags: [],
        disableIndexes: "true",
        columns: [
                {
                id: 1,
                name: "EAN",
                type: "lookup",
                valueName: "EAN",
                // type: "text",
                sortable: true,
                searchable: true,
            },
            {
                id: 100,
                name: "Nazwa",
                valueName: "Name",
                sortable: true,
                searchable: true,
                type: 'lookup',
                // type: 'text',
                disabled: false,
                searchbar: ""
                },
            {
                id: 12,
                name: "Stawka VAT",
                valueName: "VatId",
                sortable: true,
                selectable: true,
                type: 'lookup',
                disabled: false
            },
            {
                id: 10,
                name: "Aktywny",
                valueName: "IsActive",
                sortable: true,
                selectable: true,
                disabled: false,
                type: "threeStateBoolByActivity",
                // type: "bool",
                value: "true",
                selectbar: "true"
                },

                { solid: "true" }
            ]
        }
    useEffect(() => {
        setFilteredRecipes(recipes)
    }, [recipes])

    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('recipeFilter')) {
            return JSON.parse(localStorage.getItem('recipeFilter'))
        } else return defaultFilter
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetFilter = () => setFilter(defaultFilter)
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    const search = () => {
        setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => {
        col.selectbar = col.value
        return col
        })
    }))
    }
    const reportFilter = row => {

        return filter.columns
        .filter(col => col.selectbar)
        .every(col => {
            const x = String(row[col.valueName])
            .toLowerCase() === col.selectbar.toLowerCase()
            return x
        }
        )
    }

    const sortRows = (a, b) => {
        const [id, order, type] = filter.sortBy.split(' | ')

        const col = Object.keys(products[0])[Number(id) - 1]

        if (a[col] === b[col]) return 0
        else if (a[col] === null) return 1
        else if (b[col] === null) return -1

        let valueA, valueB
        if (type === 'text' || type === 'date') {

        valueA = a[col]?.toLowerCase()
        valueB = b[col]?.toLowerCase()
        }
        else if (type === "bool") {
        valueA = Number(a[col])
        valueB = Number(b[col])
        }
        else if (type === 'price') {
        valueA = Number(a[col].replace(',', ''))
        valueB = Number(b[col].replace(',', ''))

        //   // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
        } else return 0
        if (order === 'asc') return valueA < valueB ? -1 : 1
        else return valueA < valueB ? 1 : -1
    }
    useEffect(() => localStorage.setItem('recipeFilter', JSON.stringify(filter)), [filter])
    const deleteRecipe = recipeId => {
        fetchMssqlApi(`recipe/${recipeId}`, {method: "DELETE", data:{}}, () => {
            getRecipes()
        })
    }
    const getRecipes = () => {
        fetchMssqlApi("/recipes", {}, rec => setRecipes(rec))
    }
    useEffect(() => {
        getIngredients()
    }, [])
    useEffect(() => {
        getRecipes()
    }, [form])

    return (
        <>
        <nav>
        <Pagination
            totalItems={recipes && recipes.length}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            handleSwitchPage={handleSwitchPage}
            filterVisibility={filter.visible}
            toggleFilter={toggleFilter}
            resetFilter={resetFilter}
        />
        {filter.visible &&  (
            <FilterEx
                filter={filter}
                setFilter={setFilter}
                columns={filter.columns}
                data={recipes}
                resetPage={resetPage}
                resetFilter={resetFilter}
                search={search}
                toggleFilter={toggleFilter}
            />
        )}
        <div>
                <button
                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                    onClick={openForm()}
                >
                    <i className="fas fa-plus mr-2" />
                </button>
            </div>
        </nav>
        <div className="card">
            {/* <div className='card-body overflow-auto' style={{maxHeight: 500}}> */}

            {recipes && (
                <table className="table table-striped border">
                    <thead>
                        <tr>
                            <th>EAN</th>
                            <th>Nazwa</th>
                            { window.innerWidth > 540 && <>

                                <th className='text-center'>Aktywna</th>
                                <th>Stawka VAT</th>
                            </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {[...recipes]
                        .filter(reportFilter)
                        .sort(sortRows)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((recipe, idx) => (
                            <tr key={idx}>
                                <td>{recipe.EAN}</td>
                                <td 
                                    // onClick={openForm(recipe.RecipeId)}
                                >{recipe.Name}</td>
                                {window.innerWidth > 540 &&
                                <>

                                <td>
                                    <div className='d-flex align-items-center justify-content-center'>
                                    <input type='checkbox'
                                        onChange={() => {}}
                                        checked={recipe.IsActive}/>
                                    </div>
                                </td>
                                <td>{recipe.VAT}%</td>
                                </>
                                }
                                <td className='d-flex justify-content-end '>
                                {/* <Link to={{pathname: `recipe/${recipe.RecipeId}`, state: {fromIng: false} }} className="btn btn-outline-primary btn-sm" > */}

                                <Link to={`recipe/${recipe.RecipeId}`} className="btn btn-outline-primary btn-sm" >

                                    {/* <i className="fas fa-trash text-danger" /> */}
                                    Edytuj
                                </Link>
                                </td>
                            </tr>
                        ) )}
                    </tbody>
                </table>
            )}
            {/* </div> */}
        </div>

        {form && <RecipeForm 
            handleClose={closeForm} 
            ingredients={ingredients} 
            form={form} 
            recipeData={recipes.find(recipe => recipe.RecipeId === form)}
            setRecipes={setRecipes}
            eans={eans}
            />}
        </>
    )
}
