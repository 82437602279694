import React, { useEffect, useState, useContext } from "react";
import useHttp from "../../hooks/use-http";
import { NavigationContext } from '../../context/navigation-context';

const Software = () => {
  const [software, setSoftware] = useState([]);
  const { sendRequest: sendPublicRequest } = useHttp();

  const { setHeaderData } = useContext(NavigationContext);

  const getSoftwareResponse = (response) => {
    if (response.status === 200) {
      setSoftware(response.data);
    }
  };

  const getSoftware = () => {
    sendPublicRequest({
      url: `softwares`
    }, getSoftwareResponse)
  };

  useEffect(() => {
    setHeaderData({
      text: 'Oprogramowanie'
    });
    getSoftware();
  }, []);

  const inputChangeHandler = (e, element) => {
    const name = e.target.name;
    const value = e.target.checked;

    const putSoftwareResponse = response => {
      if (response.status === 201) {
        const newSoftware = [...software];
        const index = newSoftware.findIndex(item => item.SoftwareId === element.SoftwareId);

        newSoftware[index] = {
          ...element,
          [name]: value
        }

        setSoftware(newSoftware);
      }
    }

    sendPublicRequest(
      {
        method: "PUT",
        url: `software/${element.SoftwareId}`,
        data: {
          VS: element.VS,
          VR: element.VR,
          VD: element.VD,
          RP: element.RP,
          MA: element.MA,
          MD: element.MD,
          RS: element.RS,
          Public: e.target.checked
        },
      },
      putSoftwareResponse
    );
  };

  const tableBody = software.map(element =>
    <tr key={element.SoftwareId}>
      <td className="text-truncate">{element.VS}</td>
      <td className="text-truncate">{element.VR}</td>
      <td className="text-truncate">{element.VD}</td>
      <td className="text-truncate">{element.RP}</td>
      <td className="text-truncate">{element.MA}</td>
      <td className="text-truncate">{element.MD}</td>
      <td className="text-truncate">{element.RS}</td>
      <td className="text-truncate">{new Date(element.CreateDateTime).toLocaleDateString()}</td>
      <td>
        <input
          type="checkbox"
          name="Public"
          onChange={(e) => inputChangeHandler(e, element)}
          checked={element.Public}
        />
      </td>
    </tr>
  );

  return (
    <>
      <div className="overflow-auto">
        <table className="table table-striped border">
          <thead>
            <tr>
              <th>VS</th>
              <th>VR</th>
              <th>VD</th>
              <th>RP</th>
              <th>MA</th>
              <th>MD</th>
              <th>RS</th>
              <th>Data zmian</th>
              <th>Publiczny</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
      </div>
    </>
  );
};

export default Software;