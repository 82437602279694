import React from 'react';
import './INLoader.css'
export default function INLoader ({text}) {
    return (
        <div className='loading'>
            <div className='loader-text'>
                {text}
            </div>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}