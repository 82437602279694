import React, {useState, useEffect, useContext} from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'



export default function RD() {
    const {fetchMssqlApi} = fetchHook()
    const params = useParams()
    , {id} = params
    const [RD, setRD] = useState(null)
    const {setHeaderData} = useContext(NavigationContext)



    const getRD = () => typeof(+id) === "number" && fetchMssqlApi(`cashRegisters/report/${id}`, {}, data => {
        setRD(data)
    })

    useEffect(() => {
        getRD()
        setHeaderData({text: "Raport dobowy"})
    }, [])

    const renderSum = ({Doc}) => {
            const sumTaxes = Doc.rapDob.sprzedPar.wartWgPTU.map(ptu => {
                const taxDisc = Doc.rapDob.stPTU.map(v => v.id === ptu.idStPTU && `Kwota PTU ${ptu.idStPTU} ${v.wart / 100}%`)[0]
                const taxValue = Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(ptu.vat / 100 || 0)
                const disc = `Sprzedaż opodatkowana PTU ${ptu.idStPTU}`
                const value = Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((ptu.netto + ptu.vat) / 100 || 0)
                return {taxDisc, taxValue, disc, value}
            })
        return (
            <div className='mr-4 ml-4'>
                {sumTaxes.map((i, idx) => (
                    <div key={idx}>
                        <div className='d-flex justify-content-between'>
                            <h6>
                                {i.disc}
                            </h6>
                            <h6>
                                {i.value}
                            </h6>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <h6>
                                {i.taxDisc}
                            </h6>
                            <h6>
                                {i.taxValue}
                            </h6>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    return (    
        <div>
            {RD && (
                <div className='container'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='w-100 d-flex justify-content-between align-items-center flex-wrap'>
                                <div>
                                    <b>
                                        {RD.NrUnik}
                                    </b>
                                </div>
                                <div className='d-flex align-items-center '>
                                    <div className='mr-4'>
                                        <b>
                                            Okres
                                        </b>
                                    </div>
                                    <div>
                                        <div>
                                            Wygenerowany {moment(RD.Doc.rapDob.zakRap).format('YYYY-MM-DD HH:mm:ss')}
                                        </div>
                                        <div>
                                            Od {moment(RD.Doc.rapDob.rozpSprzed).format('YYYY-MM-DD HH:mm:ss')}
                                        </div>
                                        <div>
                                            Do {moment(RD.Doc.rapDob.zakSprzed).format('YYYY-MM-DD HH:mm:ss')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div>
                                {renderSum(RD)}
                                <div className='mr-4 ml-4'>
                                <hr/>
                                    <div className='w-100 d-flex justify-content-between mb-2'>
                                        <h6 className='mb-0'>
                                            Anulowane paragony
                                        </h6>
                                            <h6 className='mb-0'>
                                                {RD.Doc.rapDob.liczbaParAnul}
                                            </h6>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between mb-2'>
                                        <h6 className='mb-0'>
                                            Kwota anulowanych paragonów
                                        </h6>
                                            <h6 className='mb-0'>
                                                {RD.Doc.rapDob.wartParAnul}
                                            </h6>
                                    </div>
                                <hr/>
                                <div className='w-100 d-flex justify-content-between mb-2'>
                                        <h6 className='mb-0'>
                                            Liczba paragonów
                                        </h6>
                                            <h6 className='mb-0'>
                                                {RD.Doc.rapDob.liczbaPar}   
                                            </h6>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between mb-2'>
                                        <h6 className='mb-0'>
                                            Waluta
                                        </h6>
                                            <h6 className='mb-0'>
                                                {RD.Doc.rapDob.waluta}
                                            </h6>
                                    </div>
                                <hr/>
                                <div className='w-100 d-flex justify-content-between mb-2'>
                                        <h6 className='mb-0'>
                                            Łączna kwota PTU
                                        </h6>
                                        <span className='badge badge-primary'>
                                            <h6 className='mb-0'>
                                                {Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(RD.Doc.rapDob.podatekNal / 100 || 0)}
                                            </h6>
                                        </span>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between mb-2'>
                                        <h6 className='mb-0'>
                                            Łączna kwota brutto
                                        </h6>
                                        <span className='badge badge-success'>
                                            <h6 className='mb-0'>
                                                {Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(RD.Doc.rapDob.sprzedBrutto / 100 || 0)}
                                            </h6>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
